// Security precaution
;(window as any).eval = global.eval = (payload: string) => {
  const error = new Error(`This app does not allow window.eval().`)
  Object.assign(error, { payload })

  throw error
}

import 'react-app-polyfill/stable'
import 'resize-observer-polyfill/dist/ResizeObserver.global'
import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()
import { App } from '@devhub/components/src/components/App'
import localforage from 'localforage'
import { ComponentType } from 'react'
// import iconFont from 'react-native-vector-icons/Fonts/MaterialIcons.ttf'
import { AppRegistry } from 'react-native-web'
import './index.css'

localforage.config({
  name: 'devhub',
  size: 100 * 1024 * 1024,
})
/*
const iconFontStyles = `@font-face {
  src: url(${iconFont});
  font-family: MaterialIcons;
}`

// Create stylesheet
const style = document.createElement('style')
style.type = 'text/css'
// @ts-ignore
if (style.styleSheet) {
  // @ts-ignore
  style.styleSheet.cssText = iconFontStyles
} else {
  style.appendChild(document.createTextNode(iconFontStyles))
}

// Inject stylesheet
document.head.appendChild(style)*/

const render = (AppComponent: ComponentType) => {
  AppRegistry.registerComponent('devhub', () => AppComponent)
  AppRegistry.runApplication('devhub', {
    rootTag: document.getElementById('root'),
  })
}

render(App)

/*
if (typeof module !== 'undefined' && (module as any).hot) {
  ;(module as any).hot.accept('@devhub/components/src/components/App', () => {
    const NewApp = require('@devhub/components/src/components/App').App
    render(NewApp)
  })
}
*/
