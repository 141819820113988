import { EnhancedDevice } from '@devhub/core/dist'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { ACTIVE, DEVICES_CONFIG, IParams, STATUS } from '../../stores/DeviceConfig'
import { RootStoreContext } from '../../stores/RootStore'
import { FLASH_MESSAGE_SCREEN_REF } from '../../stores/UIReferenceStore'
import { sharedStyles } from '../../styles/shared'
import { getParams } from './ControlPanel'
import { OnOffControlCardView } from './OnOffControlCardView'

export const SimpleOnOfControlView = React.memo(
  observer(({ deviceId, port, name, device }: { deviceId: string; port: number | undefined; name: string; device: EnhancedDevice }) => {
    const rootStore = useContext(RootStoreContext)
    const activeText = rootStore.deviceListStore.state[`${STATUS}_${deviceId}_${port}`]
    const deviceActive = rootStore.deviceListStore.state[`${ACTIVE}_${deviceId}`] === true || rootStore.deviceListStore.state[`${ACTIVE}_${deviceId}`] === 'true'

    const { isSMSTransport } = DEVICES_CONFIG[device?.type || ''] || {}

    return (
      <OnOffControlCardView
        key={`device-OnOffControlCardView-item-${deviceId}-${port}`}
        deviceId={deviceId}
        port={port}
        name={name}
        method={'setStatus'}
        active={activeText === '1' || activeText === 'on'}
        enableActionSchedule={false}
        isSimpleView={true}
        disabled={!deviceActive && !isSMSTransport}
        style={[{ width: 50 }, sharedStyles.marginHorizontalQuarter]}
        postValue={(params: IParams) => getParams(params)}
        containerStyles={[sharedStyles.controlContainer, sharedStyles.alignSelfCenter, sharedStyles.marginVerticalHalf]}
        iconSize={32}
        flashMessageRef={FLASH_MESSAGE_SCREEN_REF.DEVICE_LIST}
      />
    )
  }),
)
