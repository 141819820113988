import { AppState, InteractionManager } from 'react-native'
import { all, call, takeEvery } from 'redux-saga/effects'
import { emitter } from '../../libs/emitter'
import * as actions from '../actions'
import { ExtractActionFromActionCreator } from '../types/base'

function* onFetchedDevices(
  action: ExtractActionFromActionCreator<typeof actions.fetchedDevices>,
) {
  if (AppState.currentState === 'active')
    yield call(InteractionManager.runAfterInteractions)

  emitter.emit('FETCHED_DEVICES', action.payload)
}

function* handleSetCurrentDeviceId(
  action: ExtractActionFromActionCreator<typeof actions.setCurrentDeviceId>,
) {
  emitter.emit('SET_CURRENT_DEVICE_ID', action.payload)
}
export function* deviceSagas() {
  yield all([
    yield takeEvery('FETCHED_DEVICES', onFetchedDevices),
    yield takeEvery('SET_CURRENT_DEVICE_ID', handleSetCurrentDeviceId),
  ])
}
