import moment from 'moment'
import React from 'react'
import { Tooltip } from 'react-native-elements'
import { sharedStyles } from '../../styles/shared'
import { contentPadding } from '../../styles/variables'
import { getPastTime } from '../../utils/constants'
import { useTheme } from '../context/ThemeContext'
import { ThemedText } from '../themed/ThemedText'

export const TimeAgo = React.memo(({ value }: { value: number }) => {
  const theme = useTheme()
  return (
    <>
      {/* @ts-ignore*/}
      <Tooltip
        overlayColor={'rgba(250, 250, 250, 0)'}
        popover={
          <ThemedText style={[sharedStyles.textMedium]} numberOfLines={1} color={theme.white}>
            {moment(value).locale('vi').format('D MMMM - HH:mm:s')}
          </ThemedText>
        }
      >
        <ThemedText color={'foregroundColorMuted65'} style={[sharedStyles.itemCode, sharedStyles.textSmall, { marginBottom: contentPadding / 2 }]}>
          {getPastTime(value, true)}
        </ThemedText>
      </Tooltip>
    </>
  )
})
