import { getColumnHeaderDetails } from '@devhub/core'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useMemo, useRef } from 'react'
import FlashMessage from 'react-native-flash-message'
import { DeviceCardsContainerProps } from '../../containers/DeviceCardsContainer'
import { IconProp } from '../../libs/vector-icons'
import { RootStoreContext } from '../../stores/RootStore'
import { getDeviceConfigTooltip } from '../../stores/StoreUtils'
import { FLASH_MESSAGE_DURATION } from '../../stores/UIReferenceStore'
import { EmptyCards } from '../cards/EmptyCards'
import { ColumnRenderer, ColumnRendererProps } from './ColumnRenderer'
import { InputConfigRow } from './InputConfigRow'
import { InputConfigView } from './InputConfigView'

export interface InputConfigColumnProps extends Omit<DeviceCardsContainerProps, 'ownerIsKnown' | 'repoIsKnown'> {
  columnIndex: number
  headerDetails: ReturnType<typeof getColumnHeaderDetails>
  pagingEnabled?: boolean
}

export const InputConfigColumn = React.memo(
  observer((props: InputConfigColumnProps) => {
    const { columnId, columnIndex, headerDetails, pagingEnabled, pointerEvents, swipeable } = props

    const rootStore = useContext(RootStoreContext)
    const deviceId = rootStore.currentDeviceStore.getDeviceId()
    const { device } = rootStore?.currentDeviceStore?.state
    const flashMessageRef = useRef<FlashMessage>(null)

    useEffect(() => {
      if (flashMessageRef.current) {
        rootStore.uiReferenceStore.state.inputConfigFlashMessageRef = flashMessageRef.current
      }
    }, [flashMessageRef.current])

    const Children = useMemo<ColumnRendererProps['children']>(() => (deviceId ? <InputConfigView deviceId={deviceId} flashMessageRef={flashMessageRef} /> : null), [columnId, columnIndex, pointerEvents, swipeable, deviceId])

    if (!deviceId) return <EmptyCards clearMessage={`Hãy chọn 1 thiết bị`} columnId={columnId} />

    if (!headerDetails) return null

    headerDetails.title = 'Cấu hình'
    headerDetails.subtitle = device?.label || device?.name || '--'

    return (
      <>
        <ColumnRenderer
          key={`device-detail-column-${columnId}-inner`}
          avatarImageURL={headerDetails.avatarProps && headerDetails.avatarProps.imageURL}
          avatarLinkURL={headerDetails.avatarProps && headerDetails.avatarProps.linkURL}
          columnId={columnId}
          columnIndex={columnIndex}
          columnType="deviceDetail"
          icon={headerDetails.icon as IconProp}
          owner={headerDetails.owner}
          pagingEnabled={pagingEnabled}
          repo={headerDetails.repo}
          repoIsKnown={headerDetails.repoIsKnown}
          subtitle={headerDetails.subtitle}
          title={<InputConfigRow subject={headerDetails.title} tooltip={getDeviceConfigTooltip(device)} />}
          iconAdge={<></>}
          rightButtons={[]}
        >
          {Children}
        </ColumnRenderer>
        <FlashMessage ref={flashMessageRef} autoHide={true} duration={FLASH_MESSAGE_DURATION} />
      </>
    )
  }),
)

InputConfigColumn.displayName = 'InputConfigColumn'
