import React, { Dispatch, FC, ReactNode } from 'react'
import { StyleSheet, View } from 'react-native'
import { Button, Overlay } from 'react-native-elements'

import { sharedStyles } from '../../styles/shared'
import { contentPadding, normalTextSize, smallTextSize } from '../../styles/variables'
import { Spacer } from '../common/Spacer'
import { useTheme } from '../context/ThemeContext'
import { ThemedText } from '../themed/ThemedText'

interface Props {
  visible: boolean
  title: string
  setVisible: Dispatch<boolean>
  children: ReactNode | string
  onClickButtonRight: () => void
  width?: number
  loading?: boolean
  titleLeftButton?: string
  titleRightButton?: string
}

export const OverlayModal: FC<Props> = ({ visible, width = 300, setVisible, loading, title, titleLeftButton = 'Hủy bỏ', titleRightButton = 'Đồng ý', children, onClickButtonRight }) => {
  const theme = useTheme()

  return (
    // @ts-ignore
    <Overlay overlayStyle={{ width }} isVisible={visible} onBackdropPress={() => setVisible(false)}>
      <View>
        <ThemedText style={[styles.textTitle, { fontSize: normalTextSize * 1.3 }]}>{title}</ThemedText>
      </View>
      <Spacer height={contentPadding / 2} />
      <View>{children}</View>
      <Spacer height={contentPadding} />
      <View style={[sharedStyles.horizontal, sharedStyles.justifyContentFlexEnd]}>
        <Button buttonStyle={[styles.buttonLeft, { borderColor: theme.black }]} titleStyle={{ fontSize: smallTextSize * 1.1, color: theme.black }} type="outline" title={titleLeftButton} onPress={() => setVisible(false)} />
        <View style={styles.spaceButton} />
        <Button loading={loading} buttonStyle={[styles.buttonRight, { backgroundColor: theme.primaryBackgroundColor }]} titleStyle={{ fontSize: smallTextSize * 1.1 }} title={titleRightButton} onPress={onClickButtonRight} />
      </View>
    </Overlay>
  )
}

const styles = StyleSheet.create({
  textTitle: {
    fontWeight: 'bold',
    color: '#353535',
  },
  spaceButton: {
    marginRight: 10,
  },
  buttonLeft: {
    width: 70,
    height: 35,
  },
  buttonRight: {
    width: 70,
    height: 35,
  },
})

OverlayModal.displayName = 'OverlayModal'
