import { User } from '@devhub/core/dist'
import { Reducer } from '../../types'

export interface State {
  app: User['github']['app']
  oauth: User['github']['oauth']
  personal: User['github']['personal']
  user: User['github']['user'] | undefined
}

const initialState: State = {
  app: undefined,
  oauth: undefined,
  personal: undefined,
  user: undefined,
}

// @ts-ignore
export const githubAuthReducer: Reducer<State> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        app: action.payload.appToken,
        oauth: action.payload.appToken,
        personal: action.payload.user.name,
        user: action.payload.user,
      }
    case 'REPLACE_PERSONAL_TOKEN_DETAILS':
      return {
        ...state,
        personal: action.payload.tokenDetails,
      }

    case 'LOGIN_FAILURE':
      return initialState

    default:
      return state
  }
}
