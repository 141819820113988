import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { View } from 'react-native'
import { Button } from 'react-native-elements'
import { Platform } from '../../libs/platform'
import { DEVICES_CONFIG, IParams, openAppSMS, SCHEDULE, SCHEDULE_ON } from '../../stores/DeviceConfig'
import { RootStoreContext } from '../../stores/RootStore'
import { FLASH_MESSAGE_SCREEN_REF } from '../../stores/UIReferenceStore'
import { sharedStyles } from '../../styles/shared'
import { smallPadding } from '../../styles/variables'
import { ThemedText } from '../themed/ThemedText'
import { getParams } from './ControlPanel'
import { IOnOffControlRef, OnOffControlCardView } from './OnOffControlCardView'
import { ScheduleIndicatorList, ScheduleIndicatorListRef, ScheduleIndicatorListValue } from './ScheduleIndicatorList'

export interface ScheduleForSingleOutputRef {
  isModified: () => boolean
  scheduleOn: () => boolean
  schedule: () => ScheduleIndicatorListValue | undefined
  onSaved: () => any
}

const enableSendSingleOutputSMS = false

export const ScheduleForSingleOutput = React.memo(
  observer(
    ({ deviceId, name, outputIndex }: { deviceId: string; name: string; outputIndex: number }, ref) => {
      const rootStore = useContext(RootStoreContext)
      const schedulesByPort = rootStore.deviceListStore.state[`${deviceId}_${SCHEDULE}_${outputIndex}`]
      const storeValue = rootStore.deviceListStore.state[`${deviceId}_${SCHEDULE_ON}_${outputIndex}`]
      const scheduleOn = storeValue === '1' || storeValue === 'on'
      const [scheduleModified, setScheduleModified] = useState<boolean>(false)
      const [scheduleOnModified, setScheduleOnModified] = useState<boolean>(false)

      const [showPanel, setShowPanel] = useState<boolean>(false)
      useEffect(() => setShowPanel(!!schedulesByPort), [schedulesByPort])

      const scheduleRef = useRef<ScheduleIndicatorListRef>(null)
      const scheduleOnRef = useRef<IOnOffControlRef>(null)
      const device = rootStore.deviceListStore.findDeviceById(deviceId)
      const { isSMSTransport, buildScheduleMessage } = DEVICES_CONFIG[device?.type || ''] || {}

      const onSaved = useCallback(() => {
        if (scheduleModified) {
          scheduleRef?.current?.save()
        }
        if (scheduleOnModified) {
          scheduleOnRef?.current?.onSubmitFinish()
        }
      }, [scheduleModified, scheduleOnModified])

      useImperativeHandle(
        ref,
        () => ({
          isModified: () => scheduleOnModified || scheduleModified,
          scheduleOn: () => scheduleOnRef.current?.value(),
          schedule: () => scheduleRef.current?.value(),
          onSaved: () => onSaved(),
        }),
        [scheduleModified, scheduleOnModified, onSaved],
      )

      const moreActions = useMemo(
        () =>
          isSMSTransport && enableSendSingleOutputSMS
            ? [
                <View key={`moreaction_send_sms`} style={[sharedStyles.center, sharedStyles.marginVertical]}>
                  <Button
                    disabled={!(scheduleOnModified || scheduleModified) || Platform.OS === 'android'}
                    title="Gửi SMS"
                    type={`solid`}
                    iconRight={true}
                    icon={{
                      name: 'send',
                      size: 15,
                      color: 'white',
                      type: 'material',
                    }}
                    containerStyle={{
                      width: 150,
                    }}
                    onPress={() => {
                      if (device?.label) {
                        const sOn = scheduleOnRef?.current?.value()
                        const { inputKey, newValue } = scheduleRef?.current?.value() || {}
                        if (newValue) {
                          if (buildScheduleMessage) {
                            if (scheduleModified) {
                              scheduleRef?.current?.save()
                            }
                            if (scheduleOnModified) {
                              scheduleOnRef?.current?.onSubmitFinish()
                            }
                            const smsBody = buildScheduleMessage(device, sOn, inputKey, newValue, outputIndex)
                            openAppSMS(device?.label, smsBody)
                          } else {
                            rootStore.uiReferenceStore.danger(`Thiết bị ${device?.type} chưa cài đặt cú pháp sms lịch biểu`, FLASH_MESSAGE_SCREEN_REF.DEVICE_DETAIL)
                          }
                        }
                      } else {
                        rootStore.uiReferenceStore.danger(`Thiết bị chưa được cài đặt số điện thoại`, FLASH_MESSAGE_SCREEN_REF.DEVICE_DETAIL)
                      }
                    }}
                  />
                </View>,
              ]
            : [],
        [isSMSTransport, buildScheduleMessage, scheduleOnModified, scheduleModified],
      )
      return (
        <View style={[sharedStyles.vertical, sharedStyles.bodySchedule]}>
          <View style={[sharedStyles.horizontal, sharedStyles.justifyContentSpaceBetween, sharedStyles.alignItemsCenter]}>
            <ThemedText style={[sharedStyles.subtitle, { paddingLeft: smallPadding }]} color="foregroundColorMuted65">
              {name}
            </ThemedText>
            <OnOffControlCardView
              deviceId={deviceId}
              isToggle={true}
              method={'setScheduleOn'}
              active={scheduleOn}
              port={outputIndex}
              ref={scheduleOnRef}
              postValue={(params: IParams) => getParams(params)}
              onChange={(modified: boolean) => setScheduleOnModified(modified)}
              rightElement={<Button iconRight={true} title="Lịch Biểu" type={`clear`} buttonStyle={[sharedStyles.paddingHorizontalQuarter]} onPress={() => setShowPanel(!showPanel)} />}
            />
          </View>
          {showPanel ? (
            <ScheduleIndicatorList
              deviceId={deviceId}
              ref={scheduleRef}
              schedulesByPort={schedulesByPort}
              flashMessageRef={FLASH_MESSAGE_SCREEN_REF.DEVICE_DETAIL}
              buildBody={(schedules: any[]) => ({
                inputKey: `${SCHEDULE}_${outputIndex}`,
                newValue: schedules,
              })}
              onChange={(isModified) => setScheduleModified(isModified)}
              moreActions={moreActions}
            />
          ) : null}
        </View>
      )
    },
    { forwardRef: true },
  ),
)
