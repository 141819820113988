import { EnhancedDevice } from '@devhub/core/dist'
import { observer } from 'mobx-react-lite'
import React, { memo, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useMutation } from 'react-apollo'
import { TouchableOpacity, View } from 'react-native'
import Accordion from 'react-native-collapsible/Accordion'
import { Button } from 'react-native-elements'
import { SAVE_SHARED_SCOPE_ATTRIBUTE } from '../../graphql/gqls'
import { DEVICES_CONFIG, SCHEDULE } from '../../stores/DeviceConfig'
import { RootStoreContext } from '../../stores/RootStore'
import { FLASH_MESSAGE_SCREEN_REF } from '../../stores/UIReferenceStore'
import { sharedStyles } from '../../styles/shared'
import { contentPadding } from '../../styles/variables'
import { extractDaysOfWeek } from '../../utils/constants'
import { findInvalidStartStopIndex, formatStartStop, getMessageError } from '../../utils/helpers/shared'
import { IconButton } from '../common/IconButton'
import { Spacer } from '../common/Spacer'
import { ThemedIcon } from '../themed/ThemedIcon'
import { ThemedText } from '../themed/ThemedText'
import { ISchedule, ScheduleIndicator } from './ScheduleIndicator'

const initSchedule = { day: 255, timer: '' }
const iconSize = 16

export interface ScheduleIndicatorListValue {
  inputKey: string
  newValue: any
}

export interface ScheduleIndicatorListProps {
  deviceId: string
  schedulesByPort: ISchedule[] | undefined
  flashMessageRef: FLASH_MESSAGE_SCREEN_REF
  device?: EnhancedDevice
  enableRemove?: boolean
  buildBody?: (schedules: any[]) => ScheduleIndicatorListValue
  moreActions?: React.ReactElement[]
  onChange?: (isModified: boolean) => any
}

export interface ScheduleIndicatorListRef {
  value: () => ScheduleIndicatorListValue | undefined
  save: () => any
  isModified: boolean
}

export const ScheduleIndicatorList = memo(
  observer(
    (
      {
        deviceId,
        schedulesByPort,
        flashMessageRef,
        enableRemove = false,
        device,
        moreActions = [],
        buildBody = (schedules: any[]) => ({
          inputKey: `${SCHEDULE}`,
          newValue: { '1': schedules },
        }),
        onChange,
      }: ScheduleIndicatorListProps,
      scRef,
    ) => {
      const rootStore = useContext(RootStoreContext)
      const [scheduleValue, setScheduleValue] = useState<ISchedule[]>([])
      const [isModified, setIsModified] = useState<boolean>(false)
      const [activeSections, setActiveSections] = useState<number[]>([])
      const foundDevice = device ? device : rootStore.deviceListStore.findDeviceById(deviceId)
      const { isSMSTransport, maxOfSchedule, maxCountScheduleTimer } = DEVICES_CONFIG[foundDevice?.type || ''] || {}

      const simpleMode = isSMSTransport
      const showCycle = !isSMSTransport

      const refs = [...Array(maxOfSchedule).keys()].map(() => {
        return useRef(null as ISchedule | null)
      })

      const extractValue = useCallback(() => {
        const invalidates: number[] = []
        const allStartStop: number[][] = []
        const scheduleRefs = refs.filter((ref) => {
          if (ref && ref.current) {
            const { invalidateIndex } = ref.current
            if (invalidateIndex !== undefined && invalidateIndex > 0) {
              invalidates.push(invalidateIndex)
            }
            return ref.current.day && ref.current.timer
          }
          return false
        })
        const schedules = scheduleRefs.map((refSchedule, position) => {
          if (refSchedule && refSchedule.current) {
            const { day, timer, cycle, startStopArray } = refSchedule.current
            if (startStopArray) {
              allStartStop.push(...startStopArray)
            }
            return {
              day,
              timer,
              cycle,
              startStopArray,
            }
          }
        })

        if (invalidates.length > 0) {
          rootStore.uiReferenceStore.danger(`Mốc ${invalidates[0]} và Mốc ${invalidates[0] + 1} thời gian chồng lên nhau`, flashMessageRef)
          return
        }

        if (allStartStop.length > 0) {
          allStartStop.sort((a, b) => a[0] - b[0])
          const iIndex = findInvalidStartStopIndex(allStartStop)
          if (iIndex >= 0) {
            if (allStartStop[iIndex].length >= 2) {
              let invalidCrossDaysOfWeek = true
              if (schedules.length >= 2) {
                const schedulesGroupedByDayOfWeek = schedules.reduce((previousValue, currentValue, currentIndex, array) => {
                  const cssa = currentValue?.startStopArray
                  const day = currentValue?.day
                  if (cssa && day) {
                    const daysOfWeek = extractDaysOfWeek(Math.floor(day / 2))
                    if (daysOfWeek.length > 0) daysOfWeek.forEach((d) => (previousValue[d] = previousValue[d] ? [...previousValue[d], ...cssa] : cssa))
                  }
                  return previousValue
                }, {} as Record<string, number[][]>)
                if (Object.keys(schedulesGroupedByDayOfWeek).length >= 2) {
                  const invalidDay = Object.keys(schedulesGroupedByDayOfWeek).filter((dayOfWeek) => {
                    const sorted = schedulesGroupedByDayOfWeek[dayOfWeek]?.sort((a, b) => a[0] - b[0])
                    if (sorted) return findInvalidStartStopIndex(sorted) >= 0
                    return false
                  })
                  invalidCrossDaysOfWeek = invalidDay.length >= 1
                }
              }
              if (invalidCrossDaysOfWeek) {
                rootStore.uiReferenceStore.danger(`Mốc ${formatStartStop(allStartStop[iIndex], ' - ', simpleMode)} đang chồng lên mốc khác!`, flashMessageRef)
                return
              }
            }
          }
        }
        return buildBody(schedules)
      }, [refs])

      useEffect(() => {
        if (onChange) {
          onChange(isModified)
        }
      }, [isModified])

      useImperativeHandle(
        scRef,
        () => ({
          value: () => extractValue(),
          save: () => saveSchedule(),
          isModified,
        }),
        [extractValue, isModified],
      )

      const [saveAttribute, { error, loading }] = useMutation(SAVE_SHARED_SCOPE_ATTRIBUTE)

      const resetSchedules = (byPort: ISchedule[] | undefined) => {
        const schedule =
          byPort && Array.isArray(byPort)
            ? byPort
                .filter((singleSchedule: ISchedule) => {
                  return singleSchedule && (!!singleSchedule.timer || !!singleSchedule.day)
                })
                .map((singleSchedule: ISchedule) => {
                  return {
                    day: singleSchedule.day,
                    timer: singleSchedule.timer,
                    cycle: singleSchedule.cycle,
                  }
                })
            : []
        setScheduleValue(schedule)
        setIsModified(false)
        return schedule
      }

      useEffect(() => {
        const schedule = resetSchedules(schedulesByPort)
        if (schedule.length >= 1 && schedulesByPort && schedulesByPort.length >= 1) {
          setActiveSections([0])
        }
      }, [schedulesByPort])

      const submitSaveAttribute = (inputKey: string, newValue: any, actionText: string) => {
        const variables = {
          variables: {
            deviceId,
            input: {
              [inputKey]: newValue,
            },
          },
        }
        if (saveAttribute) {
          saveAttribute(variables)
            .then((res) => {
              rootStore.uiReferenceStore.success(`${actionText} thành công`, flashMessageRef)
              rootStore.currentDeviceStore.setSchedule(newValue, inputKey)
            })
            .catch((e) => {
              console.debug(`saveAttribute schedule error`, e)
              rootStore.uiReferenceStore.danger(e?.networkError?.statusCode === 403 ? `${getMessageError(e)}` : `${actionText} không thành công ${e.toString()}`, flashMessageRef)
            })
        }
      }

      const onDeleteScheduleAtPosition = useCallback(
        (position: number) => {
          if (position >= 0 && position < scheduleValue.length) {
            const ss = refs.filter((ref, cIndex) => cIndex !== position && ref && ref.current).map((refSchedule) => refSchedule.current)
            // @ts-ignore
            setScheduleValue([...ss])
            setIsModified(true)
          }
        },
        [scheduleValue],
      )

      const onAddSchedule = useCallback(() => {
        if (scheduleValue.length < refs.length && refs[scheduleValue.length]) {
          // @ts-ignore
          refs[scheduleValue.length].current = initSchedule
          const ss = refs.filter((ref, cIndex) => ref && ref.current).map((refSchedule) => refSchedule.current)
          // @ts-ignore
          setScheduleValue([...ss])
          setIsModified(true)
        }
      }, [scheduleValue])

      const saveSchedule = useCallback(() => {
        const { inputKey, newValue } = extractValue() || {}
        if (inputKey && newValue) {
          submitSaveAttribute(inputKey, newValue, 'Lưu lịch biểu')
        }
      }, [extractValue])

      return (
        <View style={[sharedStyles.paddingHorizontalQuarter, sharedStyles.flex, sharedStyles.vertical, sharedStyles.justifyContentCenter, sharedStyles.flexGrowMore, sharedStyles.advanceContainer]}>
          {scheduleValue && Array.isArray(scheduleValue) ? (
            <>
              {scheduleValue.length <= 0 ? (
                <View style={[sharedStyles.horizontal, sharedStyles.justifyContentSpaceBetween]}>
                  <ThemedText color="foregroundColorMuted65">{`Lịch biểu`}</ThemedText>
                </View>
              ) : null}
              <Accordion
                activeSections={activeSections}
                sections={scheduleValue}
                touchableComponent={TouchableOpacity}
                expandMultiple={true}
                sectionContainerStyle={sharedStyles.sectionAccordion}
                renderHeader={(schedule: ISchedule, position, isActive) => {
                  return (
                    <View style={[sharedStyles.horizontal, sharedStyles.justifyContentSpaceBetween, sharedStyles.alignItemsCenter]}>
                      <View style={[sharedStyles.horizontal, sharedStyles.justifyContentSpaceBetween]}>
                        <ThemedText color="foregroundColorMuted65">{`Lịch biểu ${scheduleValue.length > 1 ? position + 1 : ''}`}</ThemedText>
                        <View style={{ marginTop: 1, marginLeft: 5 }}>
                          <ThemedIcon family="octicon" name={isActive ? 'chevron-up' : 'chevron-down'} size={16} color={'foregroundColorMuted65'} />
                        </View>
                      </View>
                      <IconButton family="material" name="delete" type={'danger'} size={16} tooltip="xóa lịch biểu" onPress={() => onDeleteScheduleAtPosition(position)} />
                    </View>
                  )
                }}
                renderContent={(schedule: ISchedule, position, isActive) => {
                  return (
                    <ScheduleIndicator schedule={schedule} position={position} valueRef={refs[position]} simpleMode={simpleMode} showCycle={showCycle} maxCountScheduleTimer={maxCountScheduleTimer} onChange={() => setIsModified(true)} />
                  )
                }}
                duration={400}
                onChange={(sections) => setActiveSections(sections)}
                renderAsFlatList={false}
              />
            </>
          ) : null}
          {error && !loading ? (
            <>
              <Spacer height={contentPadding} />
              <ThemedText color="foregroundColorMuted65">{getMessageError(error)}</ThemedText>
            </>
          ) : null}
          <Spacer height={contentPadding} />
          <View style={[sharedStyles.horizontal, sharedStyles.justifyContentSpaceBetween]}>
            {enableRemove ? (
              <Button
                loading={loading}
                title="Xóa"
                type={`clear`}
                onPress={() => {
                  const { inputKey } = buildBody([])
                  submitSaveAttribute(inputKey, '', 'Xóa Nhóm')
                }}
                icon={{
                  name: 'remove-circle-outline',
                  size: iconSize,
                  color: 'white',
                  type: 'material',
                }}
              />
            ) : null}
            <Button
              disabled={!isModified}
              icon={{
                name: 'undo',
                size: iconSize,
                color: 'white',
                type: 'material',
              }}
              title="Hủy"
              type={`clear`}
              onPress={() => resetSchedules(schedulesByPort)}
            />
            <Button
              title="Thêm"
              type={`clear`}
              disabled={!scheduleValue || scheduleValue.length >= maxOfSchedule}
              onPress={() => onAddSchedule()}
              icon={{
                name: 'add-circle-outline',
                size: iconSize,
                color: 'white',
                type: 'material',
              }}
            />
            <Button
              disabled={!isModified}
              icon={{
                name: 'save',
                size: iconSize,
                color: 'white',
                type: 'material',
              }}
              title="Lưu"
              loading={loading}
              type={`outline`}
              onPress={() => saveSchedule()}
            />
          </View>
          {moreActions}
        </View>
      )
    },
    { forwardRef: true },
  ),
)

// ScheduleIndicatorList.displayName = 'ScheduleIndicatorList'
