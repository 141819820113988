import { MenuItem, OverflowMenu } from '@ui-kitten/components'
import React, { FC, useContext, useState } from 'react'
import { View } from 'react-native'
import { TYPE_VIEW } from '../../stores/AppConfig'
import { RootStoreContext } from '../../stores/RootStore'
import { scaleFactor } from '../../styles/variables'
import { useTheme } from '../context/ThemeContext'
import { ThemedIcon } from '../themed/ThemedIcon'

interface Props {}

export const ChangeViewDeviceList: FC<Props> = ({}) => {
  const rootStore = useContext(RootStoreContext)
  const theme = useTheme()
  const [visible, setVisible] = useState<boolean>(false)
  const typeDisplay =
    rootStore.appConfig.state.typeDisplayDevice || TYPE_VIEW.LIST

  const renderToggleView = () => (
    <View>
      <ThemedIcon
        family="octicon"
        name="filter"
        size={20 * scaleFactor}
        color="foregroundColor"
        onPress={() => setVisible(true)}
      />
    </View>
  )
  return (
    <OverflowMenu
      visible={visible}
      anchor={renderToggleView}
      onBackdropPress={() => setVisible(false)}
      style={{ width: 130 }}
    >
      <MenuItem
        title={'Hiển thị dạng lưới'}
        disabled={true}
        style={{
          backgroundColor:
            typeDisplay === 'gird' ? theme.primaryBackgroundColor : '',
        }}
        onPress={() => {
          setVisible(false)
          rootStore.appConfig.onDisplayDeviceView(TYPE_VIEW.GIRD)
        }}
      />
      <MenuItem
        title={'Hiển thị theo danh sách'}
        style={{
          backgroundColor:
            typeDisplay === 'list' ? theme.primaryBackgroundColor : '',
        }}
        onPress={() => {
          setVisible(false)
          rootStore.appConfig.onDisplayDeviceView(TYPE_VIEW.LIST)
        }}
      />
    </OverflowMenu>
  )
}
