import React, { Fragment } from 'react'
import { Provider as PaperProvider } from 'react-native-paper'

import FlashMessage from 'react-native-flash-message'
import '../libs/analytics'
import { ErrorBoundary } from '../libs/bugsnag'
import { AppNavigator } from '../navigation/AppNavigator'
import { enableNetworkInterceptors } from '../network-interceptor'
import { AppGlobalStyles } from './AppGlobalStyles'
import { AppProviders } from './AppProviders'
import { AppIconBadge } from './common/AppIconBadge'
import { UnreadCountProvider } from './context/UnreadCountContext'

enableNetworkInterceptors()

// TODO: Enable StrictMode after react-native fixes it
// @see https://github.com/facebook/react-native/issues/22186
const StrictModePlaceholder = Fragment

export function App() {
  return (
    <StrictModePlaceholder>
      <ErrorBoundary>
        <AppProviders>
          <PaperProvider>
            <AppGlobalStyles key="app-global-styles" />
            <AppNavigator key="app-navigator" />
            <FlashMessage position="top" />
            <UnreadCountProvider>
              <AppIconBadge />
            </UnreadCountProvider>
          </PaperProvider>
        </AppProviders>
      </ErrorBoundary>
    </StrictModePlaceholder>
  )
}
