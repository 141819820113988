import immer from 'immer'
import _ from 'lodash'

import { EnhancedDevice } from '@devhub/core/dist'
import { Reducer } from '../types'

export interface State {
  allIds: string[]
  byId: Record<string, EnhancedDevice | undefined>
  updatedAt: string | null
  currentDeviceId: string | undefined
}

const initialState: State = {
  allIds: [],
  byId: {},
  updatedAt: null,
  currentDeviceId: undefined,
}

export const deviceReducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCHED_DEVICES':
      return immer(state, draft => {
        draft.allIds = draft.allIds || []
        draft.byId = draft.byId || {}

        const items = action.payload || []
        if (!(items && items.length)) return

        items.forEach(newItem => {
          const deviceId = newItem.idInfo.id
          if (!deviceId) return false
          if (!draft.allIds.includes(deviceId)) draft.allIds.push(deviceId)
          if (!draft.byId) return false
          draft.byId[deviceId] = newItem
        })
        draft.allIds = _.uniq(draft.allIds)
      })
    case 'SET_CURRENT_DEVICE_ID':
      return immer(state, draft => {
        draft.currentDeviceId = action.payload
      })
    default:
      return state
  }
}
