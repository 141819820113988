import React, { FC, FormEvent, useState } from 'react'
import { View } from 'react-native'
import * as Yup from 'yup'

import { Mutation } from '@apollo/react-components'
import { LOGIN } from '../../graphql/gqls'
import { useReduxAction } from '../../hooks/use-redux-action'
import * as actions from '../../redux/actions'
import { IEnvPageProps, PageType } from '../../screens/StackNavigator'
import { sharedStyles } from '../../styles/shared'
import { contentPadding, scaleFactor } from '../../styles/variables'
import { getMessageError } from '../../utils/helpers/shared'
import { getButtonColors } from '../common/Button'
import { GitHubLoginButton } from '../common/GitHubLoginButton'
import { Spacer } from '../common/Spacer'
import { ThemedTextInputPasswordWithIcon } from '../elements/ThemedTextInputPasswordWithIcon'
import { ThemedIcon } from '../themed/ThemedIcon'
import { ThemedText } from '../themed/ThemedText'
import { ThemedTextInput } from '../themed/ThemedTextInput'

export const AppLoginScreen: FC<IEnvPageProps> = ({ setPageType }) => {
  const loginRequest = useReduxAction(actions.loginRequest)

  const { foregroundThemeColor } = getButtonColors()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [inputError, setInputError] = useState({ email: '' })
  const [isShowPassword, setIsShowPassword] = useState<boolean>(true)

  const validateInput = () => {
    let errEmail = ''
    const schema = Yup.string().email()
    if (!schema.isValidSync(email)) {
      errEmail = 'Email không hợp lệ'
    }

    if (errEmail) {
      setInputError({ ...inputError, email: errEmail })
      return false
    }
    setInputError({ ...inputError, email: '' })

    return true
  }

  const handleSubmit =
    (
      login: (params: {
        variables: { input: { username: string; password: string } }
      }) => void,
    ) =>
    async (event: FormEvent<any>) => {
      if (!validateInput()) return
      login({
        variables: {
          input: {
            username: email,
            password,
          },
        },
      })
    }

  return (
    <View>
      <Mutation
        mutation={LOGIN}
        // @ts-ignore
        update={(
          cache: any,
          {
            data: {
              // @ts-ignore
              login: { token, refreshToken },
            },
          },
        ) => {
          // console.log("token", token)
          if (!token) throw new Error('No token')
          loginRequest({ appToken: token, refreshToken })
        }}
      >
        {(
          login: (params: {
            variables: { input: { username: string; password: string } }
          }) => void,
          { error: errorLogin, loading }: any,
        ) => (
          <View>
            <Spacer height={contentPadding} />
            <View style={[sharedStyles.fullWidth, sharedStyles.fullMinWidth]}>
              <ThemedTextInput
                editable={!loading}
                placeholder="Email"
                textInputKey="email"
                value={email}
                onChangeText={(text) => {
                  setEmail(text.trim())
                }}
              />
            </View>
            {inputError && inputError.email !== '' && (
              <ThemedText color="red">{inputError.email}</ThemedText>
            )}
            <Spacer height={contentPadding} />
            <View style={[sharedStyles.fullWidth, sharedStyles.fullMinWidth]}>
              <ThemedTextInputPasswordWithIcon
                editable={!loading}
                textInputKey="mk"
                placeholder="Mật khẩu"
                secureTextEntry={isShowPassword}
                style={{ paddingRight: 35 }}
                value={password}
                onChangeText={(text) => {
                  setPassword(text)
                }}
              />
              <ThemedIcon
                family="octicon"
                name={isShowPassword ? 'eye-closed' : 'eye'}
                size={16 * scaleFactor}
                color={foregroundThemeColor}
                style={{ position: 'absolute', top: 12, right: 15 }}
                onPress={() => setIsShowPassword(!isShowPassword)}
              />
            </View>
            {errorLogin && (
              <ThemedText color="red">{getMessageError(errorLogin)}</ThemedText>
            )}
            <Spacer height={2 * contentPadding} />
            <GitHubLoginButton
              disabled={!email || !password}
              analyticsLabel="github_login_public"
              loading={loading}
              // @ts-ignore
              onPress={handleSubmit(login)}
              style={sharedStyles.button}
              title="Đăng nhập"
            />
          </View>
        )}
      </Mutation>
      <Spacer height={contentPadding} />
      <ThemedText
        style={[sharedStyles.textCenter]}
        color="foregroundColorMuted65"
      >
        Tạo tài khoản?{' '}
        <ThemedText color="blue" onPress={() => setPageType(PageType.REGISTER)}>
          Đăng ký
        </ThemedText>
      </ThemedText>

      <Spacer height={contentPadding} />
      <ThemedText
        style={[sharedStyles.textCenter]}
        color="foregroundColorMuted65"
      >
        <ThemedText
          color="blue"
          onPress={() => setPageType(PageType.PASSWORD_FORGET)}
        >
          Quên mật khẩu
        </ThemedText>
      </ThemedText>
    </View>
  )
}
