import { IDict, IDictArray } from '@devhub/core/dist'
import _ from 'lodash'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { FlatGrid } from 'react-native-super-grid'
import { sharedStyles } from '../../styles/shared'
import { useColumnWidth } from '../context/ColumnWidthContext'
import { ThemedText } from '../themed/ThemedText'

export interface TimeSeriesListRef {
  refreshIfEmpty: () => any
}

export const TimeSeriesList = forwardRef<TimeSeriesListRef, { renderItem: any; onRefresh: () => Promise<IDictArray | undefined>; refreshing: boolean }>(({ onRefresh, renderItem, refreshing }, ref) => {
  const columnWidth = useColumnWidth()
  const [timeSeries, setTimeSeries] = useState<IDict[] | undefined>(undefined)

  const doRefresh = () => {
    onRefresh().then((data) => {
      const merges: IDict[] = []
      const entries = Object.entries(data || {})
      entries.forEach(([key, tsValues]) => {
        const bundle = tsValues?.map((value) => ({ ...value, key }))
        if (bundle) {
          merges.push(...bundle)
        }
      })
      if (entries.length > 1) {
        merges.sort((a, b) => b.ts - a.ts)
      }
      setTimeSeries(merges)
    })
  }

  useImperativeHandle(
    ref,
    () => ({
      refreshIfEmpty: () => {
        if (!timeSeries || timeSeries.length <= 0) {
          doRefresh()
        }
      },
    }),
    [timeSeries],
  )

  useEffect(() => doRefresh(), [])

  return (
    <View>
      {timeSeries && !_.isEmpty(timeSeries) ? (
        <FlatGrid refreshing={refreshing} itemDimension={columnWidth * 0.9} data={timeSeries} onRefresh={onRefresh} renderItem={renderItem} spacing={10} />
      ) : (
        <View style={[StyleSheet.absoluteFill, sharedStyles.center, { height: columnWidth / 2 }]} pointerEvents="none">
          <ThemedText color="foregroundColorMuted65"> {`Không có dữ liệu`} </ThemedText>
        </View>
      )}
    </View>
  )
})
