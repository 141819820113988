import { IDict } from '@devhub/core/dist'
import { Button, Layout, Popover, Radio, RadioGroup } from '@ui-kitten/components'
import _ from 'lodash'
import React, { forwardRef, ReactElement, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { SENSOR_ADC_TYPE } from '../../stores/DeviceModels'
import { RootStoreContext } from '../../stores/RootStore'
import { sharedStyles } from '../../styles/shared'
import { IInputSpinnerRowRef, InputSpinnerRow } from '../elements/InputSpinnerRow'
import { EditableCollapsibleText } from '../views/EditableCollapsibleText'

interface IADCParams {
  selected: string
  inputValue: string
  code?: string
  unit?: string
  min?: number
  max?: number
}

interface SelectWithInputPopOverProps {
  items: IDict[]
  initValue: IDict
  anchorView: ReactElement | null
  action?: ReactElement
  onUpdateValue: (params: IADCParams) => any
  submitTitle?: string
  itemTextRender?: (item: IDict) => string
}

export interface SelectWithInputPopOverRef {
  hide: () => any
}

export const SelectWithInputPopOver = forwardRef<SelectWithInputPopOverRef, SelectWithInputPopOverProps>(({ items, initValue, anchorView, onUpdateValue, action, submitTitle = 'Cập nhật', itemTextRender }, ref) => {
  const rootStore = useContext(RootStoreContext)
  const { inputValue, code, min, max, delta } = initValue
  const [visible, setVisible] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState<number>(-1)
  const [inputValueState, setInputValueState] = useState<string>(inputValue)
  const [selectedItem, setSelectedItem] = useState<IDict | null>(null)
  const [customMin, setMin] = useState(min)
  const [customMax, setMax] = useState(max)
  const [customDelta, setCustomDelta] = useState(delta)

  useEffect(() => setMin(min), [min])
  useEffect(() => setMax(max), [max])
  useEffect(() => setCustomDelta(customDelta), [delta])

  const downRef = useRef<IInputSpinnerRowRef | null>(null)
  const upRef = useRef<IInputSpinnerRowRef | null>(null)
  const deltaRef = useRef<IInputSpinnerRowRef | null>(null)

  useImperativeHandle(ref, () => ({ hide: () => setVisible(false) }), [])

  const initSelect = useCallback(() => {
    if (code && items.length > 0) {
      const foundIndex = items.findIndex((item) => item?.code === code)
      setSelectedIndex(foundIndex)
      setSelectedItem(foundIndex >= 0 ? items[foundIndex] : null)
    }
    setMin(min)
    setMax(max)
    setCustomDelta(delta)
    downRef?.current?.resetValue()
    upRef?.current?.resetValue()
    deltaRef?.current?.resetValue()
  }, [code, items])

  const initInput = useCallback(() => setInputValueState(inputValue), [inputValue])

  useEffect(() => {
    if (visible) {
      initSelect()
      initInput()
    }
  }, [visible])

  const isCustomSelected = useMemo(() => selectedItem?.code === SENSOR_ADC_TYPE.custom || selectedItem?.code === SENSOR_ADC_TYPE.sensor_4_20mA, [selectedItem])
  const onPress = useCallback(() => {
    if (selectedIndex >= 0 && selectedIndex < items.length) {
      if (inputValueState && !_.isEmpty(inputValueState.trim())) {
        let customParams = {}
        if (isCustomSelected) {
          const cMin = Number(customMin)
          const cMax = Number(customMax)
          if (Number.isNaN(cMin) || Number.isNaN(cMax) || cMin > cMax) {
            rootStore.uiReferenceStore.danger(`Cận dưới hoặc cận trên chưa hợp lệ hoăc cận dưới lớn hơn cận trên!`)
            return
          }
          customParams = { min: cMin, max: cMax, delta: customDelta }
        }
        onUpdateValue?.({ selected: items[selectedIndex].code, inputValue: inputValueState.trim(), ...customParams })
        setVisible(false)
      } else {
        rootStore.uiReferenceStore.danger(`Hãy nhập nội dung hợp lệ`)
      }
    } else {
      rootStore.uiReferenceStore.danger(`Bạn cần lựa chọn trước đã!`)
    }
  }, [items, selectedIndex, inputValueState, isCustomSelected, customMin, customMax, customDelta])

  const renderToggleView = useCallback(() => <TouchableOpacity onPress={() => setVisible(true)}>{anchorView}</TouchableOpacity>, [anchorView])

  return (
    <Popover
      visible={visible}
      anchor={renderToggleView}
      onBackdropPress={() => {
        setVisible(false)
      }}
    >
      <Layout style={[sharedStyles.contentContainer, sharedStyles.fullWidth]}>
        <RadioGroup
          selectedIndex={selectedIndex}
          onChange={(index) => {
            setSelectedIndex(index)
            setInputValueState(items[index].title)
            setSelectedItem(items[index])
          }}
        >
          {items.map((item, iIndex) => (
            <Radio key={`Radio-${iIndex}`}>{itemTextRender ? itemTextRender(item) : item?.title}</Radio>
          ))}
        </RadioGroup>
        <EditableCollapsibleText title={inputValueState} isInline={true} showIcon={true} onUpdate={(updateText: string) => setInputValueState(updateText)} />
        {isCustomSelected ? (
          <>
            <InputSpinnerRow
              ref={downRef}
              subject={`Cận dưới`}
              valueType={'real'}
              minValue={Number.NEGATIVE_INFINITY}
              maxValue={Number.POSITIVE_INFINITY}
              initValue={customMin}
              onChange={(num: number) => setMin(num)}
              containerStyle={sharedStyles.marginVerticalQuarter}
            />
            <InputSpinnerRow
              ref={upRef}
              subject={`Cận trên`}
              valueType={'real'}
              minValue={Number.NEGATIVE_INFINITY}
              maxValue={Number.POSITIVE_INFINITY}
              initValue={customMax}
              onChange={(num: number) => setMax(num)}
              containerStyle={sharedStyles.marginVerticalQuarter}
            />
            <InputSpinnerRow
              ref={deltaRef}
              subject={`Hiệu chỉnh`}
              valueType={'real'}
              minValue={Number.NEGATIVE_INFINITY}
              maxValue={Number.POSITIVE_INFINITY}
              initValue={customDelta}
              onChange={(num: number) => setCustomDelta(num)}
              containerStyle={sharedStyles.marginVerticalQuarter}
            />
          </>
        ) : null}
        <View style={[sharedStyles.flex, sharedStyles.horizontal, sharedStyles.justifyContentSpaceBetween]}>
          {action}
          <Button style={[sharedStyles.button]} appearance="outline" onPress={onPress} disabled={!(selectedIndex >= 0 && selectedIndex < items.length && !!inputValueState && !_.isEmpty(inputValueState.trim()))}>
            {submitTitle}
          </Button>
        </View>
      </Layout>
    </Popover>
  )
})
