import { IDict } from '@devhub/core/dist'
import React, { MutableRefObject, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import StepIndicator from 'react-native-step-indicator'
import { Platform } from '../../libs/platform'
import { RootStoreContext } from '../../stores/RootStore'
import { smallerTextSize, smallPadding } from '../../styles/variables'
import { extractDaysOfWeek } from '../../utils/constants'
import { convertText2StartStop, convertToTime, diffInMinutes, findInvalidStartStopIndex, formatStartStop, getSecondsInDay, getTimeCouple } from '../../utils/helpers/shared'
import { IconButton } from '../common/IconButton'
import { Spacer } from '../common/Spacer'
import { useTheme } from '../context/ThemeContext'
import { ThemedIcon } from '../themed/ThemedIcon'
import { ThemedText } from '../themed/ThemedText'
import { AppTimePickerModal } from '../widgets/AppTimePickerModal'
import { DayOfWeekRadios } from './DayOfWeekRadios'
import { InputTimeCoupleModal } from './InputTimeCoupleModal'
import { PulseLine } from './PulseLine'
import { TimelineItemPopover } from './TimelineItemPopover'

export const INDICATOR_MAX_SIZE = 6
const MAX_OF_TIMER = 30

export interface ISchedule {
  day: number
  timer: string
  cycle?: string
  invalidateIndex?: number
  numOfStartStop?: number
  startStopArray?: number[][]
}

const customStyles = {
  stepIndicatorSize: 25,
  currentStepIndicatorSize: 30,
  separatorStrokeWidth: 2,
  currentStepStrokeWidth: 3,
  stepStrokeCurrentColor: '#fe7013',
  stepStrokeWidth: 3,
  stepStrokeFinishedColor: '#fe7013',
  stepStrokeUnFinishedColor: '#aaaaaa',
  separatorFinishedColor: '#fe7013',
  separatorUnFinishedColor: '#aaaaaa',
  stepIndicatorFinishedColor: '#fe7013',
  stepIndicatorUnFinishedColor: '#ffffff',
  stepIndicatorCurrentColor: '#ffffff',
  stepIndicatorLabelFontSize: 13,
  currentStepIndicatorLabelFontSize: 13,
  stepIndicatorLabelCurrentColor: '#fe7013',
  stepIndicatorLabelFinishedColor: '#ffffff',
  stepIndicatorLabelUnFinishedColor: '#aaaaaa',
  labelColor: '#999999',
  labelSize: 13,
  currentStepLabelColor: '#fe7013',
}

export const extractTimer = (timer: string) => {
  let startStopArray: number[][] = []
  if (timer) {
    const splits = timer.split(':')
    startStopArray = splits.map((split) => convertText2StartStop(split)).filter((item) => item.length > 0)
  }
  startStopArray.sort((a, b) => a[0] - b[0])
  return startStopArray
}

const extractCycle = (cycle: string | undefined) => {
  const splits = cycle ? cycle.split(':') : []
  const cycles: any[] = splits
    .map((split) => {
      const values = split.split('_')
      if (values.length >= 2) {
        return {
          turnOn: values[0] === 'B',
          duration: Number(values[1]),
        }
      }
      return null
    })
    .filter((item) => !!item)

  return cycles
}

export const days2Int = (daysOfWeek: number[]) => {
  let value = 0
  if (daysOfWeek) {
    daysOfWeek.forEach((day) => (value += 2 ** (7 - day)))
  }
  return value * 2
}

export const ScheduleIndicator = (props: {
  deviceId?: string
  schedule: ISchedule
  position?: number
  valueRef: MutableRefObject<null | ISchedule>
  showCycle?: boolean
  style?: StyleProp<ViewStyle>
  simpleMode?: boolean
  maxCountScheduleTimer?: number
  onChange?: () => void
}) => {
  const rootStore = useContext(RootStoreContext)
  const { deviceId = rootStore.currentDeviceStore.getDeviceId(), schedule, showCycle = true, valueRef, style = {}, simpleMode = false, maxCountScheduleTimer = MAX_OF_TIMER, onChange } = props

  const theme = useTheme()
  const [scheduleState, setScheduleState] = useState<IDict>({
    daysOfWeek: 0,
    startStopArray: [],
    cycles: [],
  })
  useEffect(() => {
    setScheduleState({
      daysOfWeek: extractDaysOfWeek(Math.floor(schedule.day / 2)),
      startStopArray: extractTimer(schedule.timer),
      cycles: extractCycle(schedule.cycle),
    })
  }, [schedule])

  const { daysOfWeek = 0, startStopArray = [], cycles = [] } = scheduleState

  const { labels, indicatorCount, invalidateIndex, lastIndicatorLength, indicatorIndexArray } = useMemo(() => {
    const extractLabels = startStopArray.map((couple: number[]) => formatStartStop(couple, '\n', simpleMode))
    if (extractLabels.length < maxCountScheduleTimer) {
      extractLabels.push(`Thêm`)
    }
    const iCount = Math.ceil(extractLabels.length / INDICATOR_MAX_SIZE)
    const lastLength = extractLabels.length % INDICATOR_MAX_SIZE || INDICATOR_MAX_SIZE
    const indexArray = [...Array(iCount).keys()]

    const iIndex = findInvalidStartStopIndex(startStopArray)

    return {
      labels: extractLabels,
      indicatorCount: iCount,
      lastIndicatorLength: lastLength,
      indicatorIndexArray: indexArray,
      invalidateIndex: iIndex,
    }
  }, [startStopArray])

  const [visible, setVisible] = useState(false)
  const [cycleInputVisible, setCycleInputVisible] = useState(false)
  const [currentStartStopIndex, setCurrentStartStopIndex] = useState(-1)
  const [currentCycleIndex, setCurrentCycleIndex] = useState(-1)

  const currentTime = useMemo(() => {
    if (currentStartStopIndex >= 0 && currentStartStopIndex < startStopArray.length) {
      const startStop = startStopArray[currentStartStopIndex]
      return getTimeCouple(startStop)
    }
    const endMoment = convertToTime(86399)
    const now = new Date()
    return {
      hours: now.getHours(),
      minutes: now.getMinutes(),
      seconds: now.getSeconds(),
      endHours: endMoment.hours(),
      endMinutes: endMoment.minutes(),
      endSeconds: endMoment.seconds(),
    }
  }, [currentStartStopIndex])

  const updateValueRef = () => {
    if (valueRef) {
      valueRef.current = {
        day: days2Int(daysOfWeek),
        timer: startStopArray ? startStopArray.map((ss: number[]) => ss.join('_')).join(':') : '',
        cycle: cycles ? cycles.map((c: IDict) => `${c.turnOn ? 'B' : 'T'}_${c.duration}`).join(':') : '',
        invalidateIndex,
        numOfStartStop: startStopArray ? startStopArray.length : 0,
        startStopArray,
      }
    }
  }

  useEffect(() => {
    updateValueRef()
    return () => {
      valueRef.current = null
    }
  }, [])

  const currentCycle = useMemo(() => {
    if (currentCycleIndex >= 0 && currentCycleIndex < cycles.length - 1) {
      const on = cycles[currentCycleIndex]
      const off = cycles[currentCycleIndex + 1]
      return {
        onDuration: on.duration,
        offDuration: off.duration,
      }
    }
    return {
      onDuration: 30,
      offDuration: 30,
    }
  }, [currentCycleIndex, cycles])

  const onDismiss = useCallback(() => {
    setVisible(false)
  }, [setVisible])

  const sortAndSet = (newArray: number[][]) => {
    newArray.sort((a, b) => a[0] - b[0])
    setScheduleState({ ...scheduleState, startStopArray: newArray })
    if (onChange) onChange()
  }

  const onConfirmTime = useCallback(
    ({ hours, minutes, endHours, endMinutes, seconds, endSeconds }) => {
      const startTime = getSecondsInDay(hours, minutes, seconds || 0)
      let endTime = getSecondsInDay(endHours, endMinutes, endSeconds || 0)
      endTime = endTime === 0 ? 86400 : endTime
      let newArray: number[][]
      let insertStartStops = [[startTime, endTime]]
      if (endTime < startTime) {
        if (simpleMode) {
          insertStartStops = [[startTime, endTime + 86400]]
        } else {
          insertStartStops = [
            [0, endTime],
            [startTime, 86400],
          ]
        }
      }

      if (currentStartStopIndex >= 0) {
        startStopArray[currentStartStopIndex] = insertStartStops[0]
        newArray = [...startStopArray, ...(insertStartStops.length >= 2 ? [insertStartStops[1]] : [])]
      } else {
        newArray = [...startStopArray, ...insertStartStops]
      }
      // sort
      sortAndSet(newArray)
      setVisible(false)
    },
    [startStopArray, currentStartStopIndex, simpleMode],
  )

  const onConfirmCycles = useCallback(
    ({ afterSecond, duration }) => {
      let newArray: number[][]
      const on = { turnOn: true, duration: afterSecond }
      const off = { turnOn: false, duration }
      if (currentCycleIndex >= 0) {
        cycles[currentCycleIndex] = on
        cycles[currentCycleIndex + 1] = off
        newArray = [...cycles]
      } else {
        newArray = [...cycles, on, off]
      }
      setCycleInputVisible(false)
      setScheduleState({ ...scheduleState, cycles: newArray })
      if (onChange) onChange()
    },
    [cycles, scheduleState, currentCycleIndex],
  )

  useEffect(() => {
    updateValueRef()
  }, [daysOfWeek, startStopArray, cycles])

  return (
    <View style={style}>
      {indicatorIndexArray.map((indicatorIndex) => {
        const isLastIndicator = indicatorIndex >= indicatorCount - 1
        const currentPosition = isLastIndicator && labels.length > startStopArray.length ? startStopArray.length % INDICATOR_MAX_SIZE : INDICATOR_MAX_SIZE
        return (
          <View key={`StepIndicator-${deviceId}-${indicatorIndex}`}>
            <StepIndicator
              renderStepIndicator={({ position: aPosition }) => {
                const currentIndex = indicatorIndex * INDICATOR_MAX_SIZE + aPosition
                if (currentIndex >= startStopArray.length) {
                  return (
                    <IconButton
                      family="octicon"
                      disabled={scheduleState.startStopArray.length >= 8}
                      name="plus-circle"
                      type={'primary'}
                      size={18}
                      tooltip="Thêm"
                      onPress={() => {
                        setCurrentStartStopIndex(-1)
                        setVisible(true)
                      }}
                    />
                  )
                }
                {
                  const startStop = startStopArray[currentIndex]
                  const diffInMinute = startStop.length >= 2 ? diffInMinutes(startStop[0], startStop[1]) : ''
                  return (
                    <TimelineItemPopover
                      enablePaddingTouch={true}
                      toggleView={
                        invalidateIndex === currentIndex ? (
                          <ThemedIcon color="white" family="octicon" name="alert" size={smallerTextSize} {...Platform.select({ web: { title: 'lỗi' } })} />
                        ) : (
                          <ThemedText color={theme.white}>{`${diffInMinute}`}</ThemedText>
                        )
                      }
                      onUpdateItemPress={() => {
                        setCurrentStartStopIndex(currentIndex)
                        setVisible(true)
                      }}
                      onDeleteItemPress={() => {
                        startStopArray.splice(currentIndex, 1)
                        setScheduleState({
                          ...scheduleState,
                          startStopArray: [...startStopArray],
                        })
                        if (onChange) onChange()
                      }}
                    />
                  )
                }
              }}
              stepCount={isLastIndicator ? lastIndicatorLength : INDICATOR_MAX_SIZE}
              customStyles={customStyles}
              currentPosition={currentPosition}
              labels={labels.slice(indicatorIndex * INDICATOR_MAX_SIZE, isLastIndicator ? labels.length : (indicatorIndex + 1) * INDICATOR_MAX_SIZE)}
            />
            <Spacer height={smallerTextSize} />
          </View>
        )
      })}
      {showCycle ? (
        <>
          <Spacer height={smallPadding} />
          <ThemedText color="foregroundColorMuted65">{`Chia mốc theo chu kỳ`}</ThemedText>
          <Spacer height={smallPadding} />
          <PulseLine
            cycles={cycles}
            onAddPulseItemPress={() => {
              setCurrentCycleIndex(-1)
              setCycleInputVisible(true)
            }}
            onUpdatePulseItemPress={(aPosition) => {
              setCurrentCycleIndex(aPosition)
              setCycleInputVisible(true)
            }}
            onDeletePulseItemPress={(aPosition) => {
              cycles.splice(aPosition, aPosition < cycles.length - 1 ? 2 : 1)
              setScheduleState({ ...scheduleState, cycles: [...cycles] })
              if (onChange) onChange()
            }}
          />
        </>
      ) : null}
      <Spacer height={smallPadding} />
      {deviceId && !simpleMode && (
        <>
          <ThemedText color="foregroundColorMuted65">{`Lặp theo tuần`}</ThemedText>
          <Spacer height={smallPadding} />
          <DayOfWeekRadios
            deviceId={deviceId}
            days={daysOfWeek}
            onDataChange={(days: number[]) => {
              setScheduleState({ ...scheduleState, daysOfWeek: days })
              if (onChange) onChange()
            }}
          />
          <Spacer height={smallerTextSize} />
        </>
      )}
      {currentTime ? (
        <AppTimePickerModal
          pickerType={'picker'}
          enableEndltStart={true}
          visible={visible}
          onDismiss={onDismiss}
          onConfirm={onConfirmTime}
          hours={currentTime.hours}
          minutes={currentTime.minutes}
          seconds={simpleMode ? undefined : currentTime.seconds}
          endHours={currentTime.endHours}
          endMinutes={currentTime.endMinutes}
          endSeconds={simpleMode ? undefined : currentTime.endSeconds}
        />
      ) : null}
      {currentCycle ? (
        <InputTimeCoupleModal
          showPicker={cycleInputVisible}
          afterSecond={currentCycle.onDuration}
          duration={currentCycle.offDuration}
          textAfterSecondUp={'Bật'}
          textDurationUp={'Tắt'}
          label="Thiết lập chu kỳ"
          onDismiss={() => setCycleInputVisible(false)}
          onConfirm={onConfirmCycles}
        />
      ) : null}
    </View>
  )
}
