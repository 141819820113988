import _ from 'lodash'
import React, { memo, useEffect, useMemo } from 'react'
import { DataTable } from 'react-native-paper'
import { ChartDataPoint } from 'react-native-responsive-linechart-patch/lib/types'
import { sharedStyles } from '../../styles/shared'
import { formatTimeByInterval } from '../../utils/constants'
import { useTheme } from '../context/ThemeContext'
import { ThemedText } from '../themed/ThemedText'

export interface AppDatatableProps {
  data: ChartDataPoint[]
  renders: Array<{ title: string; formatLabel?: (value: number) => any }>
  interval: number
}

const optionsPerPage = [10, 30, 50]

export const AppDatatable = memo(({ data, renders, interval }: AppDatatableProps) => {
  const [page, setPage] = React.useState<number>(0)
  const theme = useTheme()
  const [itemsPerPage] = React.useState(optionsPerPage[0])
  const visibleItems = useMemo(() => {
    if (data.length <= itemsPerPage) return data
    return data.slice(page * itemsPerPage, Math.min((page + 1) * itemsPerPage - 1, data.length - 1))
  }, [itemsPerPage, page, data])
  useEffect(() => setPage(0), [data])

  // @ts-ignore
  return _.isEmpty(data) ? null : (
    <DataTable>
      <DataTable.Header focusable={false}>
        {renders.map((item, index) => (
          <DataTable.Title key={`DataTable.Header-${index}`} style={index === renders.length - 1 ? sharedStyles.justifyContentFlexEnd : {}}>
            1<ThemedText color="foregroundColorMuted65">{item.title}</ThemedText>
          </DataTable.Title>
        ))}
      </DataTable.Header>
      {
        // @ts-ignore
        data.length > itemsPerPage ? (
          <DataTable.Pagination
            focusable={false}
            page={page}
            numberOfPages={Math.ceil(data.length / itemsPerPage)}
            onPageChange={(p) => setPage(p)}
            label={`${page * itemsPerPage + 1}-${Math.min((page + 1) * itemsPerPage, data.length)} / tổng ${data.length} bản ghi`}
          />
        ) : null
      }
      {
        // @ts-ignore
        visibleItems.map((item, index) => (
          <DataTable.Row key={`DataTable.Row-${index}`}>
            <DataTable.Cell>
              <ThemedText color={theme.primaryBackgroundColor}>{formatTimeByInterval(item.x, interval)}</ThemedText>
            </DataTable.Cell>
            <DataTable.Cell style={sharedStyles.justifyContentFlexEnd}>
              <ThemedText color={theme.primaryBackgroundColor}>{renders[1]?.formatLabel ? renders[1].formatLabel(item.y) : item.y}</ThemedText>
            </DataTable.Cell>
          </DataTable.Row>
        ))
      }
      {
        // @ts-ignore
        data.length > itemsPerPage ? (
          <DataTable.Pagination
            focusable={false}
            page={page}
            numberOfPages={Math.ceil(data.length / itemsPerPage)}
            onPageChange={(p) => setPage(p)}
            label={`${page * itemsPerPage + 1}-${Math.min((page + 1) * itemsPerPage, data.length)} / tổng ${data.length}`}
            /*showFastPaginationControls={true}
                    selectPageDropdownLabel={'Số phần tử/trang'}
                    onItemsPerPageChange={setItemsPerPage}
                    numberOfItemsPerPage={itemsPerPage}
                    numberOfItemsPerPageList={optionsPerPage}*/
          />
        ) : null
      }
    </DataTable>
  )
})
