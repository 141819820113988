import { constants } from '@devhub/core'
import React, { Dispatch, MutableRefObject, useContext, useEffect, useRef, useState } from 'react'
import { Image, View } from 'react-native'
import { useDispatch } from 'react-redux'
import { InputConfigRow } from '../components/columns/InputConfigRow'
import { AppVersion } from '../components/common/AppVersion'
import { Button } from '../components/common/Button'
import { FullHeightScrollView } from '../components/common/FullHeightScrollView'
import { Link } from '../components/common/Link'
import { Screen } from '../components/common/Screen'
import { Spacer } from '../components/common/Spacer'
import { AppLoginScreen } from '../components/screens/AppLoginScreen'
import { AppPasswordScreen } from '../components/screens/AppPasswordScreen'
import { AppRegisterScreen } from '../components/screens/AppRegisterScreen'
import { ThemedText } from '../components/themed/ThemedText'
import { ThemedTextInput } from '../components/themed/ThemedTextInput'
import { ContactView } from '../components/views/ContactView'
import { IMenuItemData, SimpleMenu } from '../components/views/SimpleMenu'
import { useReduxState } from '../hooks/use-redux-state'
import { analytics } from '../libs/analytics'
import { FirebaseUIAuth } from '../libs/firebaseui-auth/FirebaseUIAuth'
import * as selectors from '../redux/selectors'
import { RootStoreContext } from '../stores/RootStore'
import { sharedStyles } from '../styles/shared'
import { contentPadding, scaleFactor } from '../styles/variables'
import { HOSTS } from '../utils/constants'
import { isMobile } from '../utils/helpers/shared'

export const logo = require('@devhub/components/assets/logo_circle.png') // tslint:disable-line

export enum PageType {
  LOGIN = 0,
  REGISTER = 1,
  PASSWORD_FORGET = 2,
  PASSWORD_CREATE = 3,
  LOGIN_USE_FIREBASE,
}

export interface IEnvPageProps {
  setPageType: Dispatch<PageType>
  envRef?: MutableRefObject<{ selectedData: IMenuItemData } | undefined>
}

export const StackNavigator = React.memo(() => {
  const rootStore = useContext(RootStoreContext)
  const error = useReduxState(selectors.authErrorSelector)
  const initialErrorRef = useRef(error)
  const [pageType, setPageType] = useState<PageType>(PageType.LOGIN_USE_FIREBASE)
  const [showDropdownSelectEnv, setShowDropdownSelectEnv] = useState<boolean>(false)
  const [customizeHost, setCustomizeHost] = useState<string>('')
  const [customizeTenantId, setCustomizeTenantId] = useState<string>('')
  let counterPress = 0
  const dispatch = useDispatch()

  useEffect(() => {
    analytics.trackScreenView('LOGIN_SCREEN')
  })

  const currentEnv = rootStore.appConfig.getCurrentEnv()

  useEffect(() => {
    if (currentEnv !== 'product') {
      setShowDropdownSelectEnv(true)
    }
  }, [currentEnv])

  useEffect(() => {
    if (!error || initialErrorRef.current === error) return

    const message = error && error.message
    alert(`Login failed. Please try again. ${message ? ` \nError: ${message}` : ''}`)
  }, [error])

  const envRef = useRef<any>(null)

  return (
    <Screen>
      <FullHeightScrollView alwaysBounceVertical={false} style={sharedStyles.container} contentContainerStyle={sharedStyles.contentContainer}>
        <View style={sharedStyles.header} />
        <View style={sharedStyles.mainContentContainer}>
          <Spacer height={contentPadding} />
          <Link
            analyticsCategory="loginscreen"
            analyticsLabel="logo"
            href={constants.API_BASE_URL}
            openOnNewTab
            style={sharedStyles.footerLink}
            textProps={{
              color: 'foregroundColorMuted65',
              style: sharedStyles.footerLinkText,
            }}
          >
            <Image resizeMode="contain" source={logo} style={sharedStyles.logo} />
          </Link>

          <Spacer height={contentPadding} />

          <ThemedText
            color="foregroundColor"
            style={sharedStyles.title}
            onPress={() => {
              counterPress++
              if (counterPress >= 15) {
                setShowDropdownSelectEnv(true)
                counterPress = 0
              }
            }}
          >
            Chào mừng đến với Lazico
          </ThemedText>

          <Spacer height={contentPadding / 2} />

          {showDropdownSelectEnv ? (
            <>
              <Spacer height={contentPadding} />
              <SimpleMenu
                items={HOSTS}
                onSelectItem={(host) => {
                  setCustomizeHost(host.env === 'customize' ? host.host : '')
                  setCustomizeTenantId(host.env === 'customize' ? host.tenants[0]?.id : '')
                  if (host.env !== 'customize') {
                    rootStore.appConfig.setCurrentEnv(host.env)
                  }
                }}
                defaultValue={rootStore.appConfig.getCurrentEnvIndex()}
              />
              {Object.keys(customizeHost).length > 0 ? (
                <View>
                  <InputConfigRow subject={`host`} tooltip={`host`} inline={true} style={{ marginTop: 10 }} />
                  <View style={{ marginTop: 10 }}>
                    <ThemedTextInput placeholder="Nhập host" textInputKey="text" value={customizeHost} onChangeText={(text: string) => setCustomizeHost(text)} />
                  </View>
                  <InputConfigRow subject={`tenantId`} tooltip={`tenantId`} inline={true} style={{ marginTop: 10 }} />
                  <View style={{ marginTop: 10 }}>
                    <ThemedTextInput placeholder="Nhập tenantId" textInputKey="text" value={customizeTenantId} onChangeText={(text: string) => setCustomizeTenantId(text)} />
                  </View>
                  <Button
                    size={30 * scaleFactor}
                    type="primary"
                    style={[sharedStyles.btnSave]}
                    onPress={() => {
                      const foundIndex = HOSTS.findIndex((h) => h.env === 'customize')
                      if (foundIndex >= 0) {
                        HOSTS[foundIndex].host = customizeHost
                        HOSTS[foundIndex].tenants = [{ ...HOSTS[foundIndex].tenants[0], id: customizeTenantId }]
                        rootStore.appConfig.setCurrentEnv(HOSTS[foundIndex].env)
                      }
                    }}
                  >
                    Update
                  </Button>
                </View>
              ) : null}
            </>
          ) : null}
          <Spacer height={contentPadding} />
          {pageType === PageType.LOGIN_USE_FIREBASE ? (
            <FirebaseUIAuth />
          ) : (
            <>
              {pageType === PageType.LOGIN ? (
                <AppLoginScreen setPageType={setPageType} />
              ) : pageType === PageType.REGISTER ? (
                <AppRegisterScreen setPageType={setPageType} envRef={envRef} />
              ) : (
                <AppPasswordScreen pageType={pageType} setPageType={setPageType} />
              )}
            </>
          )}
          <Spacer height={contentPadding * 5} />
        </View>
        <View style={[sharedStyles.footer]}>
          <View style={sharedStyles.vertical}>
            <ContactView />
            <AppVersion />
          </View>
        </View>
      </FullHeightScrollView>
    </Screen>
  )
})

// StackNavigator.displayName = 'StackNavigator'
