import { MenuItem, OverflowMenu } from '@ui-kitten/components'
import React, { useState } from 'react'
import { View } from 'react-native'
import { sharedStyles } from '../../styles/shared'
import { TouchableOpacity } from '../common/TouchableOpacity'
import { ThemedText } from '../themed/ThemedText'
import { TCommandTimeline } from './ScheduleTimeline'

export const TimelineItemPopover = ({
  commandTimeline,
  onUpdateItemPress,
  onAddItemPress,
  onDeleteItemPress,
  toggleView,
  enablePaddingTouch,
}: {
  commandTimeline?: TCommandTimeline | { title: string; description?: string }
  toggleView?: React.ReactElement
  onUpdateItemPress?: () => void
  onAddItemPress?: () => void
  onDeleteItemPress?: () => void
  enablePaddingTouch?: boolean
}) => {
  const [visible, setVisible] = useState(false)

  const renderToggleView = toggleView
    ? () => (
        <TouchableOpacity
          style={[
            sharedStyles.displayFlex,
            sharedStyles.horizontal,
            enablePaddingTouch ? sharedStyles.padding : {},
          ]}
          onPress={() => {
            setVisible(true)
          }}
        >
          {toggleView}
        </TouchableOpacity>
      )
    : () => (
        <View style={{ flex: 1 }}>
          <ThemedText
            onPress={() => {
              setVisible(true)
            }}
          >
            {commandTimeline ? commandTimeline.title : ''}
          </ThemedText>
          {commandTimeline && commandTimeline.description ? (
            <ThemedText color="white">{commandTimeline.description}</ThemedText>
          ) : null}
        </View>
      )
  return (
    <OverflowMenu
      visible={visible}
      anchor={renderToggleView}
      onBackdropPress={() => setVisible(false)}
    >
      <MenuItem
        title="Sửa"
        onPress={() => {
          setVisible(false)
          if (onUpdateItemPress) onUpdateItemPress()
        }}
      />
      {(onAddItemPress && (
        <MenuItem
          title="Thêm"
          onPress={() => {
            setVisible(false)
            if (onAddItemPress) onAddItemPress()
          }}
        />
      )) || <></>}
      <MenuItem
        title="Xóa"
        onPress={() => {
          setVisible(false)
          if (onDeleteItemPress) onDeleteItemPress()
        }}
      />
    </OverflowMenu>
  )
}
