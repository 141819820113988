import { observable } from 'mobx'
import { persist } from 'mobx-persist'
import { RootStore } from './RootStore'

const data = observable({
  customerId: '',
  page: 0,
  loading: false,
})

const schema = {
  customerId: true,
  pageSize: true,
  page: true,
  loading: true,
}

const state = persist(schema)(data)

export class UserStore {
  rootStore: RootStore
  state: any

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.state = state
  }
}
