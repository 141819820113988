import { AppUser } from '@devhub/core'
import { createAction, createErrorAction } from '../helpers'
import { AuthError, State as AuthState } from '../reducers/auth'

export function loginRequest(payload: {
  appToken: string
  refreshToken: string
}) {
  return createAction('LOGIN_REQUEST', payload)
}

export function loginSuccess(payload: {
  appToken: string
  refreshToken: string
  user: NonNullable<AppUser>
}) {
  return createAction('LOGIN_SUCCESS', payload)
}

export function loginFailure<E extends AuthError>(error: E) {
  return createErrorAction('LOGIN_FAILURE', error)
}

export function logout() {
  return createAction('LOGOUT')
}

export function refreshToken(payload: { refreshToken: string }) {
  return createAction('REFRESH_TOKEN', payload)
}

export function updateUserData(
  payload: Pick<Partial<NonNullable<AuthState['user']>>, 'customerId'>,
) {
  return createAction('UPDATE_USER_DATA', payload)
}

export function deleteAccountRequest() {
  return createAction('DELETE_ACCOUNT_REQUEST')
}

export function deleteAccountSuccess() {
  return createAction('DELETE_ACCOUNT_SUCCESS')
}

export function deleteAccountFailure<E extends Error>(error: E) {
  return createErrorAction('DELETE_ACCOUNT_FAILURE', error)
}
