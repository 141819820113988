import { Layout, Radio } from '@ui-kitten/components'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { smallerTextSize } from '../../styles/variables'
import { ThemedText } from '../themed/ThemedText'

const WEEKDAYS = [
  { status: 'primary', text: 'CN' },
  { status: 'success', text: 'T2' },
  { status: 'info', text: 'T3' },
  { status: 'warning', text: 'T4' },
  { status: 'danger', text: 'T5' },
  { status: 'basic', text: 'T6' },
  { status: 'info', text: 'T7' },
]

export const DayOfWeekRadios = ({ days, onDataChange, deviceId }: { deviceId: string; days: number[] | undefined; onDataChange: (days: number[]) => void }) => {
  const checks = WEEKDAYS.reduce((accumulator: Record<string, boolean>, currentValue, dayIndex) => {
    accumulator[`${dayIndex}`] = days ? days.includes(7 - dayIndex) : true
    return accumulator
  }, {})
  return (
    <View style={{ width: '100%' }} key={`checkday-list-${deviceId}`}>
      <Layout style={evaStyles.container} level="1">
        {Object.values(checks).map((check, index) => {
          return (
            <Radio
              key={`radio-weekdat-${index}`}
              style={evaStyles.radio}
              status={WEEKDAYS[index].status}
              checked={check}
              onChange={(checked) => {
                checks[`${index}`] = checked
                const newChecks = { ...checks }
                const nDays = Object.keys(newChecks)
                  .filter((dayIndexString) => newChecks[dayIndexString])
                  .map((dayIndex) => 7 - Number(dayIndex))
                onDataChange(nDays)
              }}
            >
              {() => <ThemedText color={'foregroundColor'} style={{ fontSize: smallerTextSize }}>{` ${WEEKDAYS[index].text}`}</ThemedText>}
            </Radio>
          )
        })}
      </Layout>
    </View>
  )
}

const evaStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: '#00000000',
  },
  radio: {
    margin: 2,
  },
})
