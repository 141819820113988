import { EnhancedDevice } from '@devhub/core/dist'
import { observer } from 'mobx-react-lite'
import React, { useContext, useMemo } from 'react'
import { View } from 'react-native'
import { emitter } from '../../libs/emitter'
import { Platform } from '../../libs/platform'
import { ACTIVE, DEVICES_CONFIG, IParams, STATUS } from '../../stores/DeviceConfig'
import { RootStoreContext } from '../../stores/RootStore'
import { FLASH_MESSAGE_SCREEN_REF } from '../../stores/UIReferenceStore'
import { getActiveColor, sharedStyles } from '../../styles/shared'
import { scaleFactor } from '../../styles/variables'
import { TouchableOpacity } from '../common/TouchableOpacity'
import { useTheme } from '../context/ThemeContext'
import { ThemedIcon } from '../themed/ThemedIcon'
import { ThemedText } from '../themed/ThemedText'
import { getParams } from './ControlPanel'
import { OnOffControlCardView } from './OnOffControlCardView'

export const DeviceControlItem = React.memo(
  observer(({ device, position, displayOnly = false }: { device: EnhancedDevice; position: number; displayOnly?: boolean }) => {
    const rootStore = useContext(RootStoreContext)
    const isDisplayListView = true
    // rootStore.appConfig.state.typeDisplayDevice === TYPE_VIEW.LIST
    const deviceId = device.id.id

    const active = rootStore.deviceListStore.state[`${STATUS}_${deviceId}`]
    const theme = useTheme()

    const item = useMemo(
      () => ({
        deviceId,
        method: 'setStatus',
        active: active === 'on',
        postValue: (params: IParams) => getParams(params),
      }),
      [active],
    )

    const deviceActive = rootStore.deviceListStore.state[`${ACTIVE}_${deviceId}`] === true || rootStore.deviceListStore.state[`${ACTIVE}_${deviceId}`] === 'true'

    const deviceColors = useMemo(
      () => ({
        backgroundColor: getActiveColor(deviceActive, '66'),
        borderColor: getActiveColor(deviceActive),
      }),
      [deviceActive],
    )

    const { isSMSTransport } = DEVICES_CONFIG[device?.type || ''] || {}

    return (
      <TouchableOpacity
        key={`device-controller-item-${position}`}
        onPress={() => {
          rootStore.currentDeviceStore.setDevice(device)
          emitter.emit('FOCUS_ON_COLUMN', {
            animated: true,
            columnId: 'deviceDetail',
            highlight: true,
            scrollTo: true,
          })
        }}
      >
        <View
          style={[
            isDisplayListView ? sharedStyles.alignItemsFlexEnd : sharedStyles.itemContainer,
            Platform.OS === 'web' && isDisplayListView ? sharedStyles.horizontalReverse : null,
            sharedStyles.paddingHorizontalHalf,
            sharedStyles.borderFrame,
            sharedStyles.justifyContentSpaceBetween,
            deviceColors,
            {
              minHeight: 74,
            },
          ]}
        >
          {displayOnly ? (
            <ThemedText color={theme.primaryBackgroundColor}>{`Thiết bị đang phát triển`}</ThemedText>
          ) : (
            <OnOffControlCardView
              {...item}
              enableActionSchedule={false}
              disabled={!deviceActive && !isSMSTransport}
              key={`DeviceList-OnOffControlCardView-${deviceId}`}
              containerStyles={[sharedStyles.controlContainer, sharedStyles.alignSelfCenter, sharedStyles.marginVerticalHalf]}
              iconSize={32}
              flashMessageRef={FLASH_MESSAGE_SCREEN_REF.DEVICE_LIST}
            />
          )}
          <View style={[sharedStyles.justifyContentCenter, isDisplayListView ? sharedStyles.itemCodeList : null]}>
            <ThemedText style={[sharedStyles.itemCode]}>{device.label || device.name}</ThemedText>
            {!deviceActive && !isSMSTransport ? (
              <View style={{ flexDirection: 'row' }}>
                <ThemedIcon style={{ marginRight: 2 }} family="material" name={'wifi'} size={12 * scaleFactor} color={'foregroundColorMuted65'} />
                <ThemedText style={[sharedStyles.itemCode, sharedStyles.textSmall]}>chưa kết nối</ThemedText>
              </View>
            ) : null}
          </View>
        </View>
      </TouchableOpacity>
    )
  }),
)
