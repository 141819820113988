import { Button, MenuItem, OverflowMenu } from '@ui-kitten/components'
import React, { useCallback, useEffect } from 'react'
import { OcticonIconProps } from '../../libs/vector-icons'
import { scaleFactor } from '../../styles/variables'
import { foregroundThemeColor } from '../common/Button'
import { useTheme } from '../context/ThemeContext'
import { ThemedIcon } from '../themed/ThemedIcon'
import { ThemedText } from '../themed/ThemedText'
import { AppRadio } from './AppRadio'

export const INIT_DISPLAY_NUM = 4

export const IconTextPopOver = ({
  icon,
  title,
  items,
  onSelect,
  renderItemTitle = (item) => `${item}`,
  pageSize = INIT_DISPLAY_NUM,
  initDisplayNum = INIT_DISPLAY_NUM + 1,
}: {
  icon: OcticonIconProps['name']
  title?: string
  items: any[]
  onSelect: (selections: any[]) => any
  renderItemTitle?: (item: any) => string
  pageSize?: number
  initDisplayNum?: number
}) => {
  const [displayNumber, setDisplayNumber] = React.useState(initDisplayNum)
  const [visible, setVisible] = React.useState(false)
  const [checks, setChecks] = React.useState<boolean[]>([])
  const [renderItems, setRenderItems] = React.useState<any[]>([])
  const theme = useTheme()

  const initParams = useCallback(
    (reset: boolean, displayItemNumber: number) => {
      let update = false
      if (reset) {
        checks.splice(0, checks.length)
        renderItems.splice(0, renderItems.length)
        update = true
      }
      const numberItems = renderItems.filter((item) => item !== 'done' && item !== 'more')
      const more: any[] = items.length <= displayItemNumber ? [] : ['more']
      for (let i = checks.length; i < displayItemNumber; i++) {
        if (items[i]) {
          checks.push(false)
          numberItems.push(items[i])
          update = true
        }
      }
      if (update) {
        setChecks([...checks])
        setRenderItems([...numberItems, ...more, 'done'])
      }
    },
    [items, renderItems, checks],
  )

  useEffect(() => {
    setDisplayNumber(initDisplayNum)
    initParams(true, initDisplayNum)
  }, [items])

  const renderToggleView = () => (
    <ThemedText onPress={() => setVisible(true)} color={theme.blue}>
      {title ? `${title}  ` : ''}
      <ThemedIcon family="octicon" name={icon} size={16 * scaleFactor} color={foregroundThemeColor} />
    </ThemedText>
  )

  return (
    <OverflowMenu visible={visible} anchor={renderToggleView} onBackdropPress={() => setVisible(false)}>
      {renderItems.map((item, iIndex) => {
        const isItem = item !== 'done' && item !== 'more'
        return (
          <MenuItem
            key={`menuitem-${iIndex}`}
            title={isItem ? '' : item === 'more' ? 'hiển thị thêm' : `      `}
            accessoryLeft={() => {
              return isItem ? (
                <AppRadio
                  title={renderItemTitle(item)}
                  initValue={checks[iIndex]}
                  onChange={(isChecked) => {
                    checks[iIndex] = isChecked
                  }}
                />
              ) : (
                <></>
              )
            }}
            accessoryRight={() => {
              return item === 'done' ? (
                <Button
                  appearance="outline"
                  onPress={() => {
                    setVisible(false)
                    const selections: any[] = []
                    checks.forEach((checked, cIndex) => {
                      if (checked) selections.push(renderItems[cIndex])
                    })
                    if (selections.length > 0) {
                      onSelect(selections)
                    }
                  }}
                >
                  {`Xong`}
                </Button>
              ) : (
                <></>
              )
            }}
            onPress={() => {
              if (item === 'more') {
                const newDisplayNumber = displayNumber + pageSize
                setDisplayNumber(newDisplayNumber)
                initParams(false, newDisplayNumber)
              }
            }}
          />
        )
      })}
    </OverflowMenu>
  )
}
