import { EnhancedDevice } from '@devhub/core/dist'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useMemo } from 'react'
import { View } from 'react-native'
import { SectionGrid } from 'react-native-super-grid'
import { CardsSearchHeader } from '../components/cards/CardsSearchHeader'
import { DeviceCardsProps } from '../components/cards/DeviceCards'
import { ColumnLoadingIndicator } from '../components/columns/ColumnLoadingIndicator'
import { getButtonColors, loadingIndicatorStyle } from '../components/common/Button'
import { IconButton } from '../components/common/IconButton'
import { ThemedActivityIndicator } from '../components/themed/ThemedActivityIndicator'
import { ThemedText } from '../components/themed/ThemedText'
import { DeviceControlItem } from '../components/views/DeviceControlItem'
import { MultiOutputControlItem } from '../components/views/MultiOutputControlItem'
import { useDimensions } from '../hooks/use-dimensions'
import { ACTIVE, DEVICES_CONFIG } from '../stores/DeviceConfig'
import { pageSize } from '../stores/DeviceListStore'
import { RootStoreContext } from '../stores/RootStore'
import { getDeviceGroupName, getDevicePortNumber, getOrder } from '../stores/StoreUtils'
import { sharedStyles } from '../styles/shared'
import { scaleFactor } from '../styles/variables'

const { foregroundThemeColor } = getButtonColors()

export interface DeviceCardsContainerProps
  extends Omit<DeviceCardsProps, 'column' | 'errorMessage' | 'fetchNextPage' | 'getItemByNodeIdOrId' | 'isShowingOnlyBookmarks' | 'itemNodeIdOrIds' | 'lastFetchSuccessAt' | 'refresh' | 'data' | 'loading'> {
  columnId: string
}

export const DeviceCardsContainer = React.memo(
  observer((props: DeviceCardsContainerProps) => {
    const { columnId, ...otherProps } = props
    const rootStore = useContext(RootStoreContext)
    const isDisplayListView = true
    // rootStore.appConfig.state.typeDisplayDevice === TYPE_VIEW.LIST
    const dimensions = useDimensions()

    const fixedHeaderComponent = useMemo(
      () => (
        <View style={[sharedStyles.relative, sharedStyles.fullWidth]}>
          <CardsSearchHeader key={`cards-search-header-column-devices`} columnId={'devices'} />

          <ColumnLoadingIndicator columnId={columnId} />
        </View>
      ),
      [columnId],
    )

    const ListEmptyComponent = (
      <View style={[sharedStyles.flex, sharedStyles.fullWidth, sharedStyles.fullHeight, sharedStyles.justifyContentCenter]}>
        {rootStore.deviceListStore.state.loading !== false ? (
          <ThemedActivityIndicator color={foregroundThemeColor} size="small" style={[loadingIndicatorStyle, { marginTop: 20 }]} />
        ) : (
          <>
            <View style={[sharedStyles.flex, sharedStyles.justifyContentCenter]}>
              <ThemedText color="foregroundColor" style={[sharedStyles.textCenter]}>
                Hãy chọn + để thêm
              </ThemedText>
            </View>
            <View style={[sharedStyles.absolute, { top: '52%', left: '46%' }]}>
              <IconButton key={`right-button-${columnId}-reload`} family="octicon" name="sync" size={16 * scaleFactor} tooltip={'Tải lại'} onPress={() => rootStore.deviceListStore.fetchAllDevices()} />
            </View>
          </>
        )}
      </View>
    )

    const itemNodeIdOrIds = rootStore.deviceListStore.state.data
    const shareWithMe = rootStore.deviceListStore.state.shareWithMeDevices

    const { deviceCount, sections } = useMemo(() => {
      const deviceBySortNumber = itemNodeIdOrIds
        ? itemNodeIdOrIds.reduce((result: { [keyObject: string]: EnhancedDevice[] }, item) => {
            const dActive = rootStore.deviceListStore.state[`${ACTIVE}_${item.id?.id}`]
            const keyGroup = dActive === 'true' || dActive === true ? 'active' : getDevicePortNumber(item.type)

            if (!result[keyGroup]) {
              result[keyGroup] = []
            }
            result[keyGroup].push(item)
            return result
          }, {})
        : {}

      let ss: any[] = []
      let activeGroup
      Object.keys(deviceBySortNumber).forEach((groupKey) => {
        const item = {
          groupKey,
          title: getDeviceGroupName(groupKey),
          data: deviceBySortNumber[groupKey].sort((a, b) => getOrder(a) - getOrder(b)),
        }
        if (groupKey === 'active') {
          activeGroup = item
        } else {
          ss.push(item)
        }
      })

      if (shareWithMe && shareWithMe.length) {
        ss.unshift({
          groupKey: 'shareWithMe',
          title: 'Chia sẻ cho tôi',
          data: shareWithMe,
        })
      }
      if (activeGroup) {
        ss = [activeGroup, ...ss]
      }
      return {
        deviceCount: itemNodeIdOrIds.length,
        sections: ss,
      }
    }, [itemNodeIdOrIds, shareWithMe])

    const renderItem = useCallback(({ item, index }: { item: EnhancedDevice; index: number }) => {
      const { hasOutputConfig, singleOutput } = DEVICES_CONFIG[item?.type || ''] || {}
      if (hasOutputConfig) {
        return <MultiOutputControlItem device={item} position={index} />
      }
      if (singleOutput) {
        return <DeviceControlItem device={item} position={index} />
      }
      return <DeviceControlItem device={item} position={index} displayOnly={true} />
    }, [])

    const isFewItems = useCallback(() => shareWithMe.length + deviceCount <= pageSize / 2, [deviceCount, shareWithMe.length])

    return (
      <View style={sharedStyles.flex} {...otherProps}>
        {fixedHeaderComponent}
        <SectionGrid
          itemDimension={isDisplayListView ? dimensions.width : 90}
          sections={sections}
          style={[sharedStyles.gridView, sharedStyles.paddingHorizontalQuarter]}
          refreshing={rootStore.deviceListStore.state.loading !== false}
          onRefresh={() => rootStore.deviceListStore.refresh()}
          onEndReached={({ distanceFromEnd }: { distanceFromEnd: number }) => {
            if (distanceFromEnd < 0 || isFewItems()) return
            rootStore.deviceListStore.fetchNextPage()
          }}
          ListEmptyComponent={ListEmptyComponent}
          renderItem={renderItem}
          renderSectionHeader={({ section }) => <ThemedText style={sharedStyles.sectionHeader}>{section.title}</ThemedText>}
        />
      </View>
    )
  }),
)

DeviceCardsContainer.displayName = 'DeviceCardsContainer'
