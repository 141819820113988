import { IDict } from '@devhub/core/dist'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Mutation } from 'react-apollo'
import { TouchableOpacity, View } from 'react-native'
import Collapsible from 'react-native-collapsible'
import { RPCCallingInput, RPCCallingResponse, SAVE_SHARED_SCOPE_ATTRIBUTE } from '../../graphql/gqls'
import {
  ADC_STRINGS,
  ADCS,
  BIT_GROUP,
  DEVICES_CONFIG,
  DONE_OPERATION,
  DONE_OPERATION_OUTPUTS,
  HAPPENING_OPERATIONS,
  HAPPENING_OPERATIONS_OUTPUTS,
  INPUT_CONFIG,
  INPUT_SPEC_TYPE,
  INPUT_TYPE,
  InputTypeStrings,
  OPERATION_TYPE_ALIAS,
  SEQUENCE_GROUP,
} from '../../stores/DeviceConfig'
import { DEVICE_MODELS, MULTI_OUTPUTS_MODELS, SENSOR_NAME_CODE, SEQUENCE_MODELS } from '../../stores/DeviceModels'
import { RootStoreContext } from '../../stores/RootStore'
import { extractInputConfigValue } from '../../stores/StoreUtils'
import { FLASH_MESSAGE_SCREEN_REF } from '../../stores/UIReferenceStore'
import { sharedStyles } from '../../styles/shared'
import { contentPadding, scaleFactor } from '../../styles/variables'
import { IValueTitle } from '../../utils/types'
import { Button } from '../common/Button'
import { Spacer } from '../common/Spacer'
import { Switch } from '../common/Switch'
import { useTheme } from '../context/ThemeContext'
import { ReadMoreText } from '../elements/ReadTextMore'
import { IThresholdRangeRef, IThresholdRangeValue, ThresholdRange } from '../elements/ThresholdRange'
import { ButtonOverMenu } from '../elements/TimeRangeIntervalFilter'
import { ThemedIcon } from '../themed/ThemedIcon'
import { ThemedText } from '../themed/ThemedText'
import { ActionTimeList, ActionTimeListRef, OutputType } from '../views/ActionTimeList'
import { AppButtonGroup } from '../views/AppButtonGroup'
import { EditableTextWithSave, RefEditableTextWithSave } from '../views/EditableTextWithSave'
import { IconTextPopOver } from '../views/IconTextPopOver'
import { getUnitByDuration, InputTimeCoupleModal, TimeUnit } from '../views/InputTimeCoupleModal'
import { getInputName } from '../views/MutableInputMessage'
import { ISchedule, ScheduleIndicator } from '../views/ScheduleIndicator'
import { SimpleMenu } from '../views/SimpleMenu'
import { InputConfigRow } from './InputConfigRow'

export const initInputSchedule = {
  day: 255,
  timer: '0_86399',
}

interface IOpenTime {
  openTimeClearProblem: boolean
  openTimeProblem: boolean
  openTimeFinishProblem: boolean
  openTimeDetecting: boolean
}

const getNameProbeChecked = (probeStatus: boolean) => {
  return probeStatus ? 'đã bật' : 'tắt'
}

export const getInputSpecType = (orderNumber: number) => (orderNumber <= 2 ? INPUT_SPEC_TYPE.BIT : INPUT_SPEC_TYPE.SEQUENCE)

const formatTime = (duration: number = 0): number | string => {
  const unit = getUnitByDuration(duration)
  return duration > 0 ? `${duration / TimeUnit[unit].value} ${TimeUnit[unit].text}` : 'Ngay lập tức'
}

const getFormatTime = (action: string | number, duration: number = 0, afterSecond: number = 0, outputIndex?: number, turnOnTime?: number, turnOffTime?: number): number | string => {
  let time: number | string
  switch (action) {
    case OPERATION_TYPE_ALIAS[OPERATION_TYPE_ALIAS.B]:
    case OPERATION_TYPE_ALIAS.B:
      time = outputIndex !== undefined || (duration === 0 && afterSecond === 0) ? 'B' : `B_${duration}_${afterSecond}`
      break
    case OPERATION_TYPE_ALIAS[OPERATION_TYPE_ALIAS.H]:
    case OPERATION_TYPE_ALIAS.H:
      const cycleText = turnOnTime && turnOffTime ? `_${turnOnTime}_${turnOffTime}` : ''
      time = `H_${duration}_${afterSecond}${cycleText}`
      break
    default:
      time = action
      break
  }
  if (outputIndex !== undefined) {
    time = `${outputIndex}_${time}`
  }
  return time
}

export const buildTimeWithUnit = (timeNumber: number | undefined) => {
  let formatUnit = ''
  if (timeNumber !== undefined) {
    const unit = getUnitByDuration(timeNumber)
    formatUnit = timeNumber > 0 ? `${timeNumber / TimeUnit[unit].value} ${TimeUnit[unit].text}` : ''
  }
  return formatUnit
}

export const formatOperationTime = (action: string | number, duration: number = 0, afterSecond: number = 0, isSingle: boolean = true, turnOnTime?: number, turnOffTime?: number): string => {
  const unit = getUnitByDuration(afterSecond)
  const newAfterSecond = afterSecond > 0 ? `sau ${afterSecond / TimeUnit[unit].value} ${TimeUnit[unit].text}` : 'ngay lập tức'
  switch (action) {
    case OPERATION_TYPE_ALIAS[OPERATION_TYPE_ALIAS.T]:
    case OPERATION_TYPE_ALIAS.T:
      return newAfterSecond
    case OPERATION_TYPE_ALIAS[OPERATION_TYPE_ALIAS.B]:
    case OPERATION_TYPE_ALIAS.B:
    case OPERATION_TYPE_ALIAS.H:
    case OPERATION_TYPE_ALIAS[OPERATION_TYPE_ALIAS.H]:
      if (!isSingle && (action === OPERATION_TYPE_ALIAS.B || OPERATION_TYPE_ALIAS[OPERATION_TYPE_ALIAS.B] === action)) {
        return ''
      }
      const durationText = buildTimeWithUnit(duration)
      const newDuration = durationText ? `trong vòng ${durationText}` : ''

      let cycleText = ''
      if (turnOnTime && turnOffTime) {
        const turnOnTimeText = buildTimeWithUnit(turnOnTime)
        const turnOffTimeText = buildTimeWithUnit(turnOffTime)
        cycleText = `, chu kỳ bật ${turnOnTimeText} tắt ${turnOffTimeText}`
      }
      return `${newAfterSecond} ${newDuration}${cycleText}`
    default:
      return ''
  }
}

export const hasTimeValue = (action: string | number, isSingle?: boolean) =>
  action === OPERATION_TYPE_ALIAS.H || action === OPERATION_TYPE_ALIAS[OPERATION_TYPE_ALIAS.H] || (isSingle && (action === OPERATION_TYPE_ALIAS.B || action === OPERATION_TYPE_ALIAS[OPERATION_TYPE_ALIAS.B]))

const isUseDependence = (inputType: INPUT_TYPE | string) => inputType === INPUT_TYPE.AS || inputType === INPUT_TYPE.DC || inputType === INPUT_TYPE[INPUT_TYPE.AS] || inputType === INPUT_TYPE[INPUT_TYPE.DC]

const enableRenderText = false

export interface DeviceCommonViewProps {
  deviceId: string | undefined
  inputOrderNumber: number
  deviceType?: string
  flashMessageRef?: FLASH_MESSAGE_SCREEN_REF
  showInputMessage?: boolean
  isSimpleInputConfig?: boolean
  outputs?: OutputType[]
}

export const NOTE_PROBE = {
  [INPUT_TYPE.NO]: 'Cặp chân ĐDx-GND. Nếu chân ĐDx và chân GND TÁCH nhau thì đầu dò không báo lỗi. Ngược lại, nếu chân ĐDx và chân GND NỐI với nhau (quá thời lượng dò) thì đầu dò báo lỗi.',
  [INPUT_TYPE.NC]: 'Cặp chân ĐDx-GND. Nếu chân ĐDx và chân GND NỐI với nhau thì đầu dò không báo lỗi. Ngược lại, nếu chân ĐDx và chân GND TÁCH nhau (quá quá thời lượng dò) thì đầu dò báo lỗi.',
  [INPUT_TYPE.AS]:
    'Cặp chân ĐDx-GND. Đầu dò sẽ bắt đầu dò sự cố khi ĐẦU RA PHỤ THUỘC ở trạng thái BẬT. SAU ĐÓ, nếu chân ĐDx và chân GND TÁCH nhau thì đầu dò không báo lỗi, ngược lại nếu chân ĐDx và chân GND NỐI nhau (quá thời lượng dò) thì đầu dò báo lỗi.',
  [INPUT_TYPE.DC]:
    'Cặp chân ĐDx-GND. Đầu dò sẽ bắt đầu dò sự cố khi ĐẦU RA PHỤ THUỘC ở trạng thái BẬT. SAU ĐÓ, nếu chân ĐDx và chân GND NỐI nhau thì đầu dò không báo lỗi, ngược lại nếu chân ĐDx và chân GND TÁCH nhau (quá thời lượng dò) thì đầu dò báo lỗi.',
  [INPUT_TYPE.LM]: 'Chế độ LÀM MÁT theo nhiệt độ đo được. Khi nhiệt độ > NGƯỠNG TRÊN thì sẽ xảy ra sự cố. Sự cố sẽ được khắc phục khi nhiệt độ < NGƯỠNG DƯỚI hoặc sau 1 THỜI LƯỢNG XÓA SỰ CỐ.',
  [INPUT_TYPE.SU]: 'Chế độ SƯỞI theo nhiệt độ đo được. Khi nhiệt độ < NGƯỠNG DƯỚI thì sẽ xảy ra sự cố. Sự cố sẽ được khắc phục khi nhiệt độ > NGƯỠNG TRÊN hoặc sau 1 THỜI LƯỢNG XÓA SỰ CỐ.',
  [INPUT_TYPE.LA]: 'Chế độ LÀM ẨM theo độ ẩm đo được. Khi độ ẩm < NGƯỠNG DƯỚI thì sẽ xảy ra sự cố. Sự cố sẽ được khắc phục khi độ ẩm > NGƯỠNG TRÊN hoặc sau 1 THỜI LƯỢNG XÓA SỰ CỐ.',
  [INPUT_TYPE.LK]: 'Chế độ LÀM KHÔ theo độ ẩm đo được. Khi độ ẩm > NGƯỠNG TRÊN thì sẽ xảy ra sự cố. Sự cố sẽ được khắc phục khi độ ẩm <NGƯỠNG DƯỚI hoặc sau 1 THỜI LƯỢNG XÓA SỰ CỐ.',
} as { [key: number]: string }

const problemActionDescription = 'Bỏ (Bỏ qua) : Thiết bị chỉ gửi cảnh báo sự cố, các lệnh TRƯỚC khi có sự cố vẫn được thực hiện bình thường.'
const fixedProblemActionDescription =
  'Tiếp (Tiếp tục) : Thiết bị TIẾP tục thực hiện các lệnh được thiết lập trong phần "Xử lý khi có sự cố". Bỏ (Bỏ qua) : Thiết bị chỉ gửi cảnh báo sự cố, các lệnh TRƯỚC khi có sự cố vẫn được thực hiện bình thường.'
const problemActionSubject = `Xử lý khi có sự cố`
const problemDoneActionSubject = `Xử lý xong sự cố`

const initADC = { title: 'Hãy Chọn', type: undefined }

export const InputConfigRender: FC<DeviceCommonViewProps> = React.memo(
  observer(({ deviceId, inputOrderNumber, deviceType, flashMessageRef, showInputMessage = true, isSimpleInputConfig = true, outputs = [] }) => {
    const fmr = flashMessageRef !== undefined ? flashMessageRef : FLASH_MESSAGE_SCREEN_REF.INPUT_CONFIG
    const theme = useTheme()
    const rootStore = useContext(RootStoreContext)
    const inputConfig = rootStore.deviceListStore.state[`${INPUT_CONFIG}_${inputOrderNumber}_${deviceId}`]
    const inputSpecType = getInputSpecType(inputOrderNumber)
    const [inputData, setInputData] = useState<IDict>(SENSOR_NAME_CODE)
    const { sensorFields: fields = [], outputCountDefault = 5 } = DEVICES_CONFIG[deviceType || ''] || {}
    for (let i = 0; i < fields.length; i++) /* tslint:disable-line*/ {
      const field = fields[i]
      const customize = rootStore.deviceListStore.state[`customize-${field}_${deviceId}`]
      useEffect(() => {
        inputData[field] = {
          name: customize?.value?.name || field,
          code: customize?.value?.code || field,
          min: customize?.value?.min,
          max: customize?.value?.max,
          unit: customize?.value?.unit,
          delta: customize?.value?.delta,
        }
        setInputData({ ...inputData })
      }, [customize])
    }

    const [inputConfigState, setInputConfigState] = useState<any>(undefined)
    const [configState, setConfigState] = useState<any>({})
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [expanded, setExpanded] = useState<boolean>(false)
    const scheduleRef = useRef<ISchedule | null>(null)
    const editSaveMessageRef = useRef<RefEditableTextWithSave | null>(null)
    const [thresholdRange, setThresholdRange] = useState<IThresholdRangeValue>({})
    const thresholdRangeRef = useRef<IThresholdRangeRef>(null)

    const [openTime, setOpenTime] = useState<IOpenTime>({
      openTimeClearProblem: false,
      openTimeProblem: false,
      openTimeFinishProblem: false,
      openTimeDetecting: false,
    })

    useEffect(() => {
      setInputConfigState(inputConfig)
    }, [inputConfig])

    useEffect(() => {
      if (inputConfigState) {
        const config = extractInputConfigValue(inputConfigState.content)
        setConfigState(config)
      } else {
        setConfigState({})
      }
    }, [inputConfigState])

    const shouldShowPanel = useMemo(() => inputConfigState?.content !== undefined && typeof inputConfigState?.content === 'object' && Object.keys(inputConfigState?.content).length > 0, [inputConfigState])

    useEffect(() => {
      if (configState.inputStatus && (!configState.schedule || configState.schedule.length <= 0 || configState.schedule[0].timer === undefined)) {
        setConfigState({
          ...configState,
          schedule: [initInputSchedule],
        })
      }
    }, [configState.inputStatus])

    const onConfirmTime = useCallback(
      ({ duration, afterSecond }, key) => {
        switch (key) {
          case 'openTimeClearProblem':
            setOpenTime({ ...openTime, openTimeClearProblem: false })
            setConfigState({
              ...configState,
              inputProblemRemove: duration,
            })
            break
          case 'openTimeProblem':
            setOpenTime({ ...openTime, openTimeProblem: false })
            setConfigState({
              ...configState,
              inputProblemAction: {
                ...(configState.inputProblemAction ? configState.inputProblemAction : {}),
                duration,
                afterSecond,
              },
            })
            break
          case 'openTimeFinishProblem':
            setOpenTime({ ...openTime, openTimeFinishProblem: false })
            setConfigState({
              ...configState,
              inputProblemDoneAction: {
                ...(configState.inputProblemDoneAction ? configState.inputProblemDoneAction : {}),
                duration,
                afterSecond,
              },
            })
            break
          case 'openTimeDetecting':
            setOpenTime({ ...openTime, openTimeDetecting: false })
            setConfigState({
              ...configState,
              inputScanDuration: duration,
            })
            break
          default:
            break
        }
      },
      [openTime, setOpenTime, configState],
    )

    const [dataOutput, setDataOutput] = useState<string>('')

    const outputMap = outputs.reduce((pre, cur) => {
      pre[cur.outputIndex] = cur
      return pre
    }, {} as IDict)

    const range = useMemo(() => {
      if (
        (configState?.inputType === INPUT_TYPE[INPUT_TYPE.SU] || configState?.inputType === INPUT_TYPE[INPUT_TYPE.LA] || configState?.greaterOrLess === false) &&
        configState?.upLow &&
        configState.upLow.length >= 2 &&
        !configState?.greaterOrLess
      ) {
        return [configState.upLow[1], configState.upLow[0]]
      }
      return configState?.upLow
    }, [configState?.upLow, configState?.inputType, configState?.greaterOrLess])

    const advanceType = useMemo(() => {
      if (configState && outputs.length > 0 && (configState?.inputType === INPUT_TYPE[INPUT_TYPE.AS] || configState?.inputType === INPUT_TYPE[INPUT_TYPE.DC])) {
        return (
          <>
            <InputConfigRow subject={`Phụ thuộc vào các đầu ra`} tooltip={`Phụ thuộc vào các đầu ra`} inline={false}>
              <IconTextPopOver
                icon={'plus-circle'}
                items={outputs}
                initDisplayNum={outputCountDefault}
                renderItemTitle={(item: IDict) => item.title}
                title={configState.dependencies?.map((dependency: number) => outputMap[dependency]?.title || dependency).join(', ')}
                onSelect={(selections: IDict[]) => {
                  setConfigState({
                    ...configState,
                    dependencies: selections.map((selection) => selection.outputIndex),
                  })
                }}
              />
            </InputConfigRow>
            <Spacer height={contentPadding / 2} />
          </>
        )
      }
      return null
    }, [configState])

    const { nameOfADC, inputTypeDes, adcs }: { nameOfADC: IDict; inputTypeDes?: string; adcs: IValueTitle[] } = useMemo(() => {
      const ipt = configState?.inputType as InputTypeStrings | undefined
      return {
        nameOfADC: ipt !== undefined && ADC_STRINGS.includes(ipt) ? { title: inputData[ipt]?.name || ipt, type: INPUT_TYPE[ipt] } : initADC,
        inputTypeDes: ipt !== undefined ? NOTE_PROBE[INPUT_TYPE[ipt]] : undefined,
        adcs: ADCS.map((type) => ({ title: inputData[INPUT_TYPE[type]]?.name || INPUT_TYPE[type], value: type })),
      }
    }, [configState, inputData])

    const typeGroup = useMemo(() => (inputSpecType === INPUT_SPEC_TYPE.BIT ? BIT_GROUP : [...SEQUENCE_GROUP, nameOfADC]), [inputSpecType, nameOfADC])
    const models = useMemo(() => (inputSpecType === INPUT_SPEC_TYPE.SEQUENCE ? SEQUENCE_MODELS : isSimpleInputConfig ? DEVICE_MODELS : MULTI_OUTPUTS_MODELS), [inputSpecType, isSimpleInputConfig])

    const problemActionRef = useRef<ActionTimeListRef>(null)
    const problemDoneActionRef = useRef<ActionTimeListRef>(null)

    const validateConfig = useCallback(() => {
      if (inputSpecType === INPUT_SPEC_TYPE.SEQUENCE) {
        const cRange = thresholdRangeRef.current?.value().range
        if (cRange && cRange.length < 2) return `Ngưỡng không hợp lệ`
        if (thresholdRange.invalidText) return thresholdRange.invalidText
      } else if (inputSpecType === INPUT_SPEC_TYPE.BIT && (configState?.inputType === INPUT_TYPE[INPUT_TYPE.DC] || configState?.inputType === INPUT_TYPE[INPUT_TYPE.AS])) {
        if (!configState.inputProblemRemove) return `Thời lượng Xóa Sự Cố phải được thiết lập xảy ra sau 1 khoảng thời gian`
      }
      let validateText = problemActionRef.current?.validate({ header: problemActionSubject })
      if (validateText) {
        return validateText
      }
      validateText = problemDoneActionRef.current?.validate({ header: problemDoneActionSubject })
      if (validateText) {
        return validateText
      }
      return undefined
    }, [inputSpecType, thresholdRange, configState])

    const buildConfigText = useCallback(() => {
      const problemActionValue = problemActionRef.current?.value()
      const problemActionText = problemActionValue?.map(({ action, duration, afterSecond, outputIndex, turnOnTime, turnOffTime }) => getFormatTime(action, duration, afterSecond, outputIndex, turnOnTime, turnOffTime)).join(':')
      const problemDoneActionValue = problemDoneActionRef.current?.value()
      const problemDoneActionText = problemDoneActionValue?.map(({ action, duration, afterSecond, outputIndex, turnOnTime, turnOffTime }) => getFormatTime(action, duration, afterSecond, outputIndex, turnOnTime, turnOffTime)).join(':')

      let cb = configState.inputType
      let tx = configState.inputProblemRemove
      let upLowText = ''
      if (inputSpecType === INPUT_SPEC_TYPE.SEQUENCE) {
        let cRange = thresholdRangeRef.current?.value().range
        let greaterOrLess: boolean | undefined = true
        if (cRange && cRange?.length >= 2) {
          if (cRange[0] < cRange[1]) {
            cRange = [cRange[1], cRange[0]]
            greaterOrLess = false
          } else if (cRange[0] === cRange[1]) {
            greaterOrLess = thresholdRange.greaterOrLess
          }
          if (cRange[0] !== cRange[1] && configState.inputProblemRemove) {
            tx = 0
          }
        }
        upLowText = `;UPLOW:${(cRange || [0, 0]).join(':')}`
        if (ADC_STRINGS.includes(cb)) {
          const pre = cb.substr(0, 3)
          const adcIndexText = cb.substr(3)
          if (adcIndexText.length > 0) {
            cb = `${pre}_${greaterOrLess ? 'S' : 'G'}:${Number(adcIndexText)}`
          }
        }
      }

      const renderedText = _.isEmpty(configState)
        ? ''
        : `${configState.inputStatus ? 'B' : 'T'};CB:${cb};TD:${configState.inputScanDuration};EX:${problemActionText};TX:${tx};ES:${problemDoneActionText}${
            isUseDependence(configState.inputType) && configState.dependencies ? `;PT:${configState.dependencies?.join(':')}` : ''
          }${upLowText}`

      return renderedText
    }, [problemActionRef, problemDoneActionRef, configState, thresholdRange.greaterOrLess])

    const onSubmitAttribute = useCallback(
      (saveInput, aLoading, resetEmptyInputConfig = false) => {
        if (aLoading) {
          return
        }
        const validate = resetEmptyInputConfig ? '' : validateConfig()
        if (validate) {
          rootStore.uiReferenceStore.danger(validate, fmr)
          return
        }
        if (resetEmptyInputConfig && scheduleRef.current) {
          const { invalidateIndex = -1 } = scheduleRef.current
          if (invalidateIndex >= 0) {
            rootStore.uiReferenceStore.danger(`Mốc ${invalidateIndex} và Mốc ${invalidateIndex + 1} thời gian chồng lên nhau`, flashMessageRef)
            return
          }
        }
        setIsLoading(true)
        if (resetEmptyInputConfig && editSaveMessageRef?.current) {
          editSaveMessageRef.current.saveInputMessage()
        }

        const newInputConfig = resetEmptyInputConfig
          ? {}
          : {
              content: buildConfigText(),
              schedule: [
                {
                  day: scheduleRef.current ? scheduleRef.current.day : 0,
                  timer: scheduleRef.current ? scheduleRef.current.timer : '',
                },
              ],
            }
        saveInput({
          variables: {
            deviceId,
            input: {
              [`${INPUT_CONFIG}_${inputOrderNumber}`]: newInputConfig,
            },
          },
        })
          .then(() => {
            // save local
            rootStore.currentDeviceStore.setAttributeByNumber(newInputConfig, `${INPUT_CONFIG}_${inputOrderNumber}`, inputOrderNumber, moment().valueOf())
            rootStore.uiReferenceStore.success(`Lưu cấu hình đầu dò thành công`, fmr)
            setIsLoading(false)
          })
          .catch((e: any) => {
            console.debug(`saveInput `, e)
            setIsLoading(false)
            rootStore.uiReferenceStore.danger(`Có lỗi khi lưu cấu hình đầu dò. Hãy thử lại sau!`, fmr)
          })
      },
      [inputOrderNumber, buildConfigText, validateConfig],
    )

    return (
      <View style={[sharedStyles.paddingQuarter]}>
        <InputConfigRow subject={getInputName(inputOrderNumber)} tooltip={`Chức năng của đầu dò`} style={sharedStyles.advanceContainer}>
          <SimpleMenu
            items={models}
            onSelectItem={(item) => {
              setInputConfigState({
                content: item.inputConfig,
              })
            }}
          />
        </InputConfigRow>
        <Spacer height={contentPadding} />
        {shouldShowPanel ? (
          <Mutation<RPCCallingResponse, RPCCallingInput> mutation={SAVE_SHARED_SCOPE_ATTRIBUTE}>
            {(updateCalling, { error, loading: aLoading }) => (
              <View>
                {error ? <ThemedText color="foregroundColorMuted65">{error.message}</ThemedText> : null}
                <InputConfigRow subject={`Đầu dò`} tooltip={`trạng thái Đầu dò`} style={sharedStyles.advanceContainer}>
                  <>
                    <ThemedText color={theme.blue} style={[sharedStyles.statusModel]}>
                      {`${getNameProbeChecked(configState.inputStatus).toUpperCase()}`}
                    </ThemedText>
                    <Switch
                      analyticsLabel="input_config_change_status"
                      onValueChange={(turnOn) =>
                        setConfigState({
                          ...configState,
                          inputStatus: turnOn,
                        })
                      }
                      value={configState.inputStatus}
                    />
                  </>
                </InputConfigRow>
                <Spacer height={contentPadding} />
                <View style={[sharedStyles.vertical, sharedStyles.advanceContainer]}>
                  <TouchableOpacity onPress={() => setExpanded(!expanded)} style={[sharedStyles.horizontal, sharedStyles.justifyContentSpaceBetween]}>
                    <InputConfigRow subject={`Lịch biểu`} tooltip={`Lịch biểu hoạt động đầu dò`} />
                    <ThemedIcon family="octicon" name={expanded ? 'chevron-up' : 'chevron-down'} size={16 * scaleFactor} color={'foregroundColorMuted65'} />
                  </TouchableOpacity>
                  <Collapsible collapsed={expanded} duration={300}>
                    <Spacer height={contentPadding} />
                    {configState.schedule && configState.schedule.length > 0 ? <ScheduleIndicator schedule={configState.schedule[0]} valueRef={scheduleRef} showCycle={false} /> : null}
                  </Collapsible>
                </View>
                <Spacer height={contentPadding / 2} />
                <View style={[sharedStyles.vertical, sharedStyles.advanceContainer]}>
                  <InputConfigRow subject={`Loại đầu dò`} tooltip={`các Loại đầu dò`} inline={false}>
                    <>{inputTypeDes ? <ReadMoreText text={inputTypeDes} /> : null}</>
                  </InputConfigRow>
                  <AppButtonGroup
                    initValue={typeGroup.findIndex((item) => item.type !== undefined && INPUT_TYPE[item.type] === configState.inputType)}
                    onPress={(cindex: number) => {
                      const selectedInputType = typeGroup[cindex].type
                      if (selectedInputType !== undefined) {
                        setConfigState({
                          ...configState,
                          inputType: INPUT_TYPE[selectedInputType],
                        })
                      }
                    }}
                    buttons={typeGroup.map(({ title, type }, index) => ({
                      element: () =>
                        type !== undefined && type < INPUT_TYPE.ADC0 ? (
                          <ThemedText color={theme.foregroundColorMuted40}>{title}</ThemedText>
                        ) : (
                          <ButtonOverMenu
                            value={type === undefined ? -1 : type}
                            title={title}
                            items={adcs}
                            onSelect={({ value }) => {
                              setConfigState({
                                ...configState,
                                inputType: INPUT_TYPE[value],
                              })
                            }}
                          />
                        ),
                    }))}
                  />
                  {advanceType ? (
                    <>
                      <Spacer height={contentPadding / 2} />
                      {advanceType}
                    </>
                  ) : null}
                  {inputSpecType === INPUT_SPEC_TYPE.SEQUENCE ? (
                    <ThresholdRange
                      ref={thresholdRangeRef}
                      inputData={inputData}
                      inputType={configState?.inputType}
                      range={range}
                      inputProblemRemove={configState?.inputProblemRemove}
                      greaterOrLess={configState?.greaterOrLess}
                      onChange={(newThreshHold) => setThresholdRange(newThreshHold)}
                      onSingleThreshold={() => onConfirmTime({ duration: 3600 }, 'openTimeClearProblem')}
                    />
                  ) : null}
                </View>
                <Spacer height={contentPadding} />
                <InputConfigRow subject={`Thời lượng dò`} tooltip={`thời lượng dò`} style={sharedStyles.advanceContainer}>
                  <>
                    <ThemedText color={theme.blue} onPress={() => setOpenTime({ ...openTime, openTimeDetecting: true })}>
                      {formatTime(configState.inputScanDuration || 0)}
                    </ThemedText>
                    <InputTimeCoupleModal
                      showPicker={openTime.openTimeDetecting}
                      textDurationUp={'Thời lượng'}
                      label="Thiết lập"
                      duration={configState.inputScanDuration || 0}
                      onDismiss={() => setOpenTime({ ...openTime, openTimeDetecting: false })}
                      onConfirm={({ duration }) => onConfirmTime({ duration }, 'openTimeDetecting')}
                    />
                  </>
                </InputConfigRow>
                <Spacer height={contentPadding / 2} />
                <InputConfigRow subject={problemActionSubject} tooltip={`Xử lý khi có sự cố`} inline={false} style={sharedStyles.advanceContainer}>
                  <ActionTimeList
                    key={`ActionTimeList-inputProblemAction-${inputOrderNumber}`}
                    outputs={outputs}
                    actionOptions={isSimpleInputConfig ? HAPPENING_OPERATIONS : HAPPENING_OPERATIONS_OUTPUTS}
                    isSingle={isSimpleInputConfig}
                    bundles={configState.inputProblemAction || []}
                    ref={problemActionRef}
                    initItemDisplayNum={outputCountDefault}
                    description={problemActionDescription}
                  />
                </InputConfigRow>
                <Spacer height={contentPadding / 2} />
                {isSimpleInputConfig || inputSpecType === INPUT_SPEC_TYPE.BIT || thresholdRange.useInputProblemRemove ? (
                  <InputConfigRow subject={`Thời lượng xóa sự cố`} tooltip={`Thời lượng xóa sự cố`} style={sharedStyles.advanceContainer}>
                    <>
                      <ThemedText
                        color={theme.blue}
                        onPress={() =>
                          setOpenTime({
                            ...openTime,
                            openTimeClearProblem: true,
                          })
                        }
                      >
                        {formatTime(configState.inputProblemRemove)}
                      </ThemedText>
                      <InputTimeCoupleModal
                        showPicker={openTime.openTimeClearProblem}
                        textDurationUp={'Thời lượng'}
                        label="Thiết lập"
                        duration={configState.inputProblemRemove}
                        onDismiss={() =>
                          setOpenTime({
                            ...openTime,
                            openTimeClearProblem: false,
                          })
                        }
                        onConfirm={({ duration }) => onConfirmTime({ duration }, 'openTimeClearProblem')}
                      />
                    </>
                  </InputConfigRow>
                ) : null}
                <Spacer height={contentPadding / 2} />
                <InputConfigRow subject={problemDoneActionSubject} tooltip={`Xử lý xong sự cố`} inline={false} style={sharedStyles.advanceContainer}>
                  <ActionTimeList
                    key={`ActionTimeList-inputProblemDoneAction-${inputOrderNumber}`}
                    outputs={outputs}
                    initItemDisplayNum={outputCountDefault}
                    actionOptions={isSimpleInputConfig ? DONE_OPERATION : DONE_OPERATION_OUTPUTS}
                    isSingle={isSimpleInputConfig}
                    bundles={configState.inputProblemDoneAction || []}
                    ref={problemDoneActionRef}
                    description={fixedProblemActionDescription}
                  />
                </InputConfigRow>
                <Spacer height={contentPadding} />
                {showInputMessage ? (
                  <>
                    <Spacer height={contentPadding} />
                    <EditableTextWithSave deviceId={deviceId} inputOrderNumber={inputOrderNumber} eRef={editSaveMessageRef} />
                  </>
                ) : null}
                <Spacer height={contentPadding} />
                {dataOutput ? <ThemedText color={theme.red}>{dataOutput}</ThemedText> : null}
                {enableRenderText ? (
                  <Button
                    onPress={() => {
                      const resultText = buildConfigText()
                      setDataOutput(resultText)
                    }}
                  >
                    Render Result (use ref)
                  </Button>
                ) : null}

                <Button key="save-button" onPress={() => onSubmitAttribute(updateCalling, aLoading)} loading={isLoading}>
                  Lưu
                </Button>
              </View>
            )}
          </Mutation>
        ) : (
          <Mutation<RPCCallingResponse, RPCCallingInput>
            mutation={SAVE_SHARED_SCOPE_ATTRIBUTE}
            update={(cache: any, { data: responseData }) => {
              console.log('SAVE_ATTRIBUTE inputconfig  ', responseData)
            }}
          >
            {(updateCalling, { error, loading: aLoading }) => (
              <View>
                {error ? <ThemedText color="foregroundColorMuted65">{error.message}</ThemedText> : null}
                <Spacer height={contentPadding} />
                <Button key="advanced-button" onPress={() => onSubmitAttribute(updateCalling, aLoading, true)} loading={isLoading}>
                  Lưu
                </Button>
              </View>
            )}
          </Mutation>
        )}
      </View>
    )
  }),
)

InputConfigRender.displayName = 'InputConfigRender'
