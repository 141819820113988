import { IDict } from '@devhub/core/dist'
import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { View } from 'react-native'
import { DataTable } from 'react-native-paper'
import { OPERATION_TYPE_ALIAS, OUTPUT_TYPE } from '../../stores/DeviceConfig'
import { extendWidenCellStyle, moreWidenCellStyle, sharedStyles, widenCellStyle } from '../../styles/shared'
import { contentPadding } from '../../styles/variables'
import { formatOperationTime, hasTimeValue } from '../columns/InputConfigRender'
import { Spacer } from '../common/Spacer'
import { useTheme } from '../context/ThemeContext'
import { BaseOption, DynamicStateButton } from '../elements/DynamicStateButton'
import { ReadMoreText } from '../elements/ReadTextMore'
import { ThemedText } from '../themed/ThemedText'
import { AppButtonGroup } from './AppButtonGroup'
import { IconTextPopOver } from './IconTextPopOver'
import { IInputTimeCoupleBase, InputTimeCoupleModal } from './InputTimeCoupleModal'
import { SimpleMenu } from './SimpleMenu'
import { SwipeListUserShare } from './SwipeListUserShare'

export interface OutputType {
  title: string
  outputIndex: number
  outputType: OUTPUT_TYPE
}

export interface ActionTimeBase extends IInputTimeCoupleBase {
  outputIndex?: number
}

export interface ActionTimeItemProps extends ActionTimeBase {
  action: string | number
  actionOptions?: BaseOption[]
  outputs?: OutputType[]
  currentOutputs?: ActionTimeItemProps[]
  isSingle?: boolean
  outputType?: OUTPUT_TYPE
  onKeyChange?: (updateValue: ActionTimeItemProps) => any
}

export interface ActionTimeItemRef {
  value: () => ActionTimeItemProps
}

export const ActionTimeItem = React.forwardRef<ActionTimeItemRef, ActionTimeItemProps>(
  ({ action, duration, afterSecond, outputIndex, turnOnTime, turnOffTime, actionOptions = [], isSingle = false, outputs = [], currentOutputs, onKeyChange }, ref) => {
    const theme = useTheme()
    const [newValue, setNewValue] = useState<ActionTimeItemProps>({
      action,
      duration,
      afterSecond,
      outputIndex,
      isSingle,
      turnOnTime,
      turnOffTime,
    })
    useEffect(() => setNewValue({ action, duration, afterSecond, outputIndex, isSingle, turnOnTime, turnOffTime }), [action, duration, afterSecond, outputIndex, isSingle])
    useImperativeHandle(ref, () => ({ value: () => newValue }), [newValue])

    const timeSetup = useMemo(
      () =>
        hasTimeValue(newValue.action, newValue.isSingle) ? (
          <ActionTimeResult
            value={newValue}
            onConfirm={({ duration: d, afterSecond: as, turnOnTime: onTime, turnOffTime: offTime }) => {
              setNewValue({ ...newValue, duration: d, afterSecond: as, turnOnTime: onTime, turnOffTime: offTime })
            }}
          />
        ) : null,
      [newValue],
    )

    return isSingle ? (
      <View style={[sharedStyles.vertical]}>
        <AppButtonGroup
          initValue={actionOptions.findIndex((item) => item.code === action)}
          onPress={(cIndex: number) => setNewValue({ ...newValue, action: actionOptions[cIndex].code })}
          buttons={actionOptions.map((item) => ({
            element: () => <ThemedText color={theme.foregroundColorMuted40}>{item.title}</ThemedText>,
          }))}
        />
        {timeSetup}
      </View>
    ) : (
      // @ts-ignore
      <DataTable.Row style={[sharedStyles.paddingHorizontalNone, sharedStyles.paddingVerticalQuarter]}>
        {outputs?.length > 1 ? (
          <View key={`ActionTimeItem-row-${outputIndex}-cell-output`} style={[moreWidenCellStyle]}>
            <SimpleMenu
              items={outputs}
              disableItem={(item) => item.outputType === OUTPUT_TYPE.TAI_EX || !!currentOutputs?.find((output) => output.outputIndex === item.outputIndex)}
              defaultValue={outputs.findIndex((item) => item.outputIndex === outputIndex)}
              onSelectItem={(item) => {
                const updateValue = { ...newValue, outputIndex: item.outputIndex }
                setNewValue(updateValue)
                if (onKeyChange) {
                  onKeyChange(updateValue)
                }
              }}
            />
          </View>
        ) : null}
        <View key={`ActionTimeItem-row-${outputIndex}-cell-action`} style={[widenCellStyle, sharedStyles.alignItemsCenter, sharedStyles.justifyContentCenter]}>
          <DynamicStateButton size={'small'} states={actionOptions} initValue={{ code: action }} onStateChange={(newState) => setNewValue({ ...newValue, action: newState.code })} />
        </View>
        <View key={`ActionTimeItem-row-${outputIndex}-cell-time`} style={[extendWidenCellStyle, sharedStyles.alignItemsCenter, sharedStyles.justifyContentCenter]}>
          {timeSetup}
        </View>
      </DataTable.Row>
    )
  },
)

export interface ActionTimeListProps {
  bundles: ActionTimeItemProps[]
  outputs: OutputType[]
  actionOptions: BaseOption[]
  initItemDisplayNum?: number
  isSingle?: boolean
  description?: string
}

export interface ActionTimeListRef {
  value: () => ActionTimeItemProps[]
  validate: (params?: IDict) => string | undefined
}

export const ActionTimeList = React.forwardRef<ActionTimeListRef, ActionTimeListProps>(({ bundles, outputs, actionOptions, isSingle = false, initItemDisplayNum = 5, description }, ref) => {
  const [list, setList] = useState<ActionTimeItemProps[]>([])
  useEffect(() => setList(bundles.map((item) => ({ ...item }))), [bundles])
  const remainOutputs = useMemo(() => outputs.filter((output) => output.outputType !== OUTPUT_TYPE.TAI_EX && list.findIndex((item) => item.outputIndex === output.outputIndex) < 0), [outputs, list])

  const itemsRef = useRef<Array<ActionTimeItemRef | null>>([])

  useImperativeHandle(
    ref,
    () => ({
      value: () => {
        const values: ActionTimeItemProps[] = []
        itemsRef.current?.forEach((iRef) => {
          const value = iRef?.value()
          if (value) values.push(value)
        })
        return values
      },
      validate: (params?: IDict) => {
        if (!isSingle && list.find((itemList) => outputs.find((item) => item.outputIndex === itemList.outputIndex)?.outputType === OUTPUT_TYPE.TAI_EX)) {
          return `${params?.header} có 1 trong các đầu ra là Bơm hoặc Tải chính. Hãy đổi đầu ra hoặc thiết lập lại loại đầu ra bên phần cấu hình`
        }
        return undefined
      },
    }),
    [list, outputs, isSingle],
  )

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, list.length)
  }, [list])

  const addItem = useMemo(
    () =>
      !isSingle && remainOutputs.length > 0 ? (
        <>
          <Spacer height={contentPadding} />
          <View style={[sharedStyles.horizontal, sharedStyles.justifyContentFlexEnd]}>
            <IconTextPopOver
              icon={'plus-circle'}
              title={'thêm đầu ra'}
              items={remainOutputs}
              initDisplayNum={initItemDisplayNum}
              renderItemTitle={({ title }: OutputType) => title}
              onSelect={(selections: OutputType[]) => {
                const newItems = selections.map((s) => ({
                  action: actionOptions[0].code,
                  duration: 60,
                  afterSecond: 60,
                  outputIndex: s.outputIndex,
                }))
                list.push(...newItems)
                setList([...list])
              }}
            />
          </View>
        </>
      ) : null,
    [list, remainOutputs, isSingle],
  )

  return (
    <DataTable>
      {list.map(({ action, duration, afterSecond, outputIndex, turnOnTime, turnOffTime }, index) => (
        <SwipeListUserShare
          key={`SwipeListUserShare-${action}-${index}`}
          enabled={list.length > 1}
          children={
            <ActionTimeItem
              action={action}
              duration={duration}
              afterSecond={afterSecond}
              outputIndex={outputIndex}
              turnOnTime={turnOnTime}
              turnOffTime={turnOffTime}
              outputs={outputs}
              currentOutputs={list}
              actionOptions={actionOptions}
              isSingle={isSingle}
              onKeyChange={(updateValue) => {
                list[index] = updateValue
                setList([...list])
              }}
              ref={(ele) => (itemsRef.current[index] = ele)}
            />
          }
          onClick={() => {
            if (list.length > 1) {
              list.splice(index, 1)
              setList([...list])
            }
          }}
        />
      ))}
      {addItem}
      {description ? <ReadMoreText text={description} /> : null}
    </DataTable>
  )
})

export const ActionTimeResult = React.memo(({ value: { action, duration, afterSecond, turnOnTime, turnOffTime, isSingle }, onConfirm }: { value: ActionTimeItemProps; onConfirm: (base: IInputTimeCoupleBase) => any }) => {
  const theme = useTheme()
  const [openTime, setOpenTime] = useState<boolean>(false)

  return (
    <InputTimeCoupleModal
      showPicker={openTime}
      afterSecond={afterSecond}
      duration={
        action === OPERATION_TYPE_ALIAS[OPERATION_TYPE_ALIAS.H] || action === OPERATION_TYPE_ALIAS.H || (isSingle && (action === OPERATION_TYPE_ALIAS[OPERATION_TYPE_ALIAS.B] || action === OPERATION_TYPE_ALIAS.B))
          ? duration !== undefined
            ? duration
            : 60
          : undefined
      }
      onCycle={turnOnTime}
      offCycle={turnOffTime}
      textAfterSecondUp={'Sau'}
      textDurationUp={'Thời lượng'}
      label="Thiết lập"
      enableCycle={!isSingle}
      onDismiss={() => setOpenTime(false)}
      onConfirm={onConfirm}
    >
      <ThemedText color={theme.blue} style={[sharedStyles.textRight]}>
        {formatOperationTime(action, duration, afterSecond, isSingle, turnOnTime, turnOffTime)}
      </ThemedText>
    </InputTimeCoupleModal>
  )
})
