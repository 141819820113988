import { IDict } from '@devhub/core/dist'
import { Icon, Toggle } from '@ui-kitten/components'
import { observer } from 'mobx-react-lite'
import moment, { Moment } from 'moment'
import React, { MutableRefObject, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { View } from 'react-native'
import { Tooltip } from 'react-native-elements'
import { IAttributeByNumber, RPC_RESULT_SET_SCHEDULE_ON, RPC_RESULT_SET_STATUS } from '../../stores/CurrentDeviceStore'
import { SCHEDULE_ON } from '../../stores/DeviceConfig'
import { RootStoreContext } from '../../stores/RootStore'
import { getActiveBackGround, getColorTextActive, sharedStyles } from '../../styles/shared'
import { contentPadding, scaleFactor, smallPadding } from '../../styles/variables'
import { convertText2StartStop, formatMomentToTimeInDay, getEpochTime, getMomentRange } from '../../utils/helpers/shared'
import { IconButton } from '../common/IconButton'
import { Spacer } from '../common/Spacer'
import { useTheme } from '../context/ThemeContext'
import { ThemedText } from '../themed/ThemedText'
import { AppTimePickerModal } from '../widgets/AppTimePickerModal'
import { ScheduleActionControl } from './ScheduleActionControl'

const toStartStop = (time: Moment[]) => time.map((momentTime) => momentTime.unix())

export interface IScheduleTextMethod {
  reset: () => void
  showPicker: (show: boolean) => void
  startNowWithPicker: () => void
  onUndoFinish: () => void
}

export const hasEndTime = (momentRange: any[]) => {
  return momentRange.length >= 2
}

const defaultTextContainerStyle = [sharedStyles.boderItemCode, sharedStyles.borderIconPower, sharedStyles.horizontal, sharedStyles.marginHorizontalHalf]

export const ScheduleActionText = React.memo(
  observer(
    ({
      deviceId,
      active,
      loading,
      portNumber,
      onConfirm,
      valueRef,
      now = moment().utc(true),
      textStyle = defaultTextContainerStyle,
      disabledAction = false,
      alwaysEnd = false,
      hideSecond = false,
      enableUndo = false,
      onHandleUndo,
      ...others
    }: {
      deviceId: string
      extraAction?: IAttributeByNumber
      active: boolean
      loading: boolean
      portNumber: number | undefined
      onConfirm: ({ startStop, delayActive }: { startStop: number[]; delayActive: boolean; startNow?: boolean }) => any
      valueRef?: MutableRefObject<null | IScheduleTextMethod>
      now?: Moment
      disabledAction?: boolean
      alwaysEnd?: boolean
      hideSecond?: boolean
      enableUndo?: boolean
      onHandleUndo?: () => any
    } & IDict) => {
      const rootStore = useContext(RootStoreContext)
      const nowMoment = moment()
      const theme = useTheme()
      const extraAction = rootStore.deviceListStore.state[`${RPC_RESULT_SET_STATUS}_${portNumber || 0}_${deviceId}`]
      const extraActionValue = extraAction?.content?.rpc?.params?.value
      const contentResult = extraAction?.content?.result
      const timer = extraAction?.content?.rpc?.params?.timer

      const [delayActive, setDelayActive] = useState(extraActionValue === undefined ? active : extraActionValue === 'on')
      const [onOff, setOnOff] = useState(delayActive)
      const [isModified, setModified] = useState(false)

      const [RPCResult, setRPCResult] = useState<string>(contentResult || '')

      useEffect(() => {
        if (contentResult) {
          setRPCResult(contentResult)
        }
      }, [contentResult])

      useEffect(() => setDelayActive(disabledAction || extraActionValue === 'on'), [extraActionValue, disabledAction])

      const [visible, setVisible] = useState<boolean>(false)
      const [isStartNow, setIsStartNow] = useState<boolean>(false)
      const [renderStartNow, setRenderStartNow] = useState<boolean>(false)

      const [currentTime, setCurrentTime] = useState<Moment[]>([])

      const originMomentRange = useMemo(() => getMomentRange(convertText2StartStop(timer)), [timer])

      const [enableEnd, setEnableEnd] = useState<boolean>(delayActive && hasEndTime(originMomentRange))
      const [currentPickerTime, setCurrentPickerTime] = useState<Moment[]>(currentTime)

      useEffect(() => {
        if (valueRef) {
          valueRef.current = {
            reset: () => {
              setCurrentTime(originMomentRange)
              setCurrentPickerTime(originMomentRange)
            },
            showPicker: (show) => setVisible(show),
            startNowWithPicker: () => {
              setIsStartNow(true)
              setVisible(true)
            },
            onUndoFinish: () => setModified(false),
          }
        }
        setCurrentTime(originMomentRange)
        setCurrentPickerTime(originMomentRange)
        return () => {
          if (valueRef) {
            valueRef.current = null
          }
        }
      }, [originMomentRange])

      const storeValue = rootStore.deviceListStore.state[`${deviceId}_${SCHEDULE_ON}${portNumber === undefined ? '' : `_${portNumber}`}`]
      const scheduleOn = storeValue === 'true' || storeValue === true || storeValue === '1' || storeValue === 1
      const rpcResultBundle = rootStore.deviceListStore.state[`${RPC_RESULT_SET_SCHEDULE_ON}_${portNumber || 0}_${deviceId}`]

      const possibleShow = useMemo(() => {
        if (!scheduleOn && extraAction && extraAction.lastUpdateTs) {
          return !(rpcResultBundle && rpcResultBundle.lastUpdateTs && rpcResultBundle.lastUpdateTs > extraAction.lastUpdateTs)
        }
        return false
      }, [extraAction, portNumber, scheduleOn, rpcResultBundle])

      const { actionText: scheduleActionText, showResultIcon } = useMemo(() => {
        let actionText = `hẹn giờ`
        let show = false
        if (possibleShow) {
          if (hasEndTime(currentTime) && now.isBefore(currentTime[1])) {
            const start = currentTime[0].unix()
            const end = currentTime[1].unix()
            const enableHiddenSeconds = end % 60 === 0 && start % 60 === 0
            actionText = `${delayActive ? 'bật' : 'tắt'} ${renderStartNow ? 'Bây giờ' : formatMomentToTimeInDay(currentTime[0], enableHiddenSeconds, hideSecond)} - ${formatMomentToTimeInDay(currentTime[1], enableHiddenSeconds, hideSecond)}`
            show = true
          } else if (currentTime.length > 0 && now.isBefore(currentTime[0])) {
            actionText = `${delayActive ? 'bật' : 'tắt'} ${renderStartNow ? 'Bây giờ' : formatMomentToTimeInDay(currentTime[0], true, hideSecond)}`
            show = true
          }
        }
        return {
          showResultIcon: show && !loading,
          actionText: `${actionText}`,
        }
      }, [delayActive, currentTime, possibleShow, now, renderStartNow, hideSecond])

      const { hours, minutes, seconds, endHours, endMinutes, endSeconds } = useMemo(() => {
        const hasEnd = enableEnd && (onOff || alwaysEnd)
        return {
          hours: currentPickerTime.length >= 1 ? currentPickerTime[0].hours() : nowMoment.hours(),
          minutes: currentPickerTime.length >= 1 ? currentPickerTime[0].minutes() : nowMoment.minutes(),
          seconds: currentPickerTime.length >= 1 ? currentPickerTime[0].seconds() : nowMoment.seconds(),
          endHours: hasEnd ? (currentPickerTime.length >= 2 ? currentPickerTime[1].hours() : 23) : undefined,
          endMinutes: hasEnd ? (currentPickerTime.length >= 2 ? currentPickerTime[1].minutes() : 59) : undefined,
          endSeconds: hasEnd ? (currentPickerTime.length >= 2 ? currentPickerTime[1].seconds() : 59) : undefined,
        }
      }, [currentPickerTime, onOff, enableEnd, alwaysEnd])

      const onEnableEndChange = useCallback(
        (enable) => {
          if (enable && !hasEndTime(currentPickerTime)) {
            if (currentPickerTime.length <= 0) setCurrentPickerTime([moment(nowMoment).add(5, 'minute'), moment(nowMoment).add(10, 'minute')])
            else if (currentPickerTime.length === 1) setCurrentPickerTime([currentPickerTime[0], moment(currentPickerTime[0]).add(5, 'minute')])
          }
          setEnableEnd(enable)
        },
        [currentPickerTime],
      )

      useEffect(() => {
        if (alwaysEnd) onEnableEndChange(true)
      }, [alwaysEnd])

      const onTimeRangeConfirm = useCallback(
        ({ hours: sHours, minutes: sMinutes, seconds: sSeconds, endHours: eHours, endMinutes: eMinutes, endSeconds: eSeconds }) => {
          const startTime = isStartNow ? moment().utc(true) : getEpochTime(sHours, sMinutes, sSeconds)
          const momentRange = [startTime]
          if ((onOff || alwaysEnd) && enableEnd && eHours !== null && eMinutes !== null) {
            momentRange.push(getEpochTime(eHours, eMinutes, eSeconds))
          }
          const startStop = toStartStop(momentRange)
          setCurrentTime(momentRange)
          setCurrentPickerTime(momentRange)
          setVisible(false)
          setRenderStartNow(isStartNow)
          setDelayActive(onOff)
          setModified(true)
          onConfirm({ startStop, delayActive: onOff, startNow: isStartNow })
        },
        [enableEnd, onOff, alwaysEnd, isStartNow],
      )

      return (
        <View>
          <View style={[{ borderColor: theme.primaryBackgroundColor }, ...textStyle]}>
            <ScheduleActionControl
              style={[sharedStyles.itemCode, sharedStyles.displayFlex]}
              color={theme.primaryBackgroundColor}
              onPress={() => {
                setIsStartNow(false)
                setVisible(true)
              }}
              title={scheduleActionText}
              icon={
                showResultIcon && RPCResult ? (
                  //  @ts-ignore
                  <Tooltip
                    overlayColor={'rgba(250, 250, 250, 0)'}
                    popover={
                      <ThemedText style={[{ padding: smallPadding }, sharedStyles.textMedium]} color={theme.white}>
                        {RPCResult === 'success' ? 'Thiết bị đã xử lý' : RPCResult === 'sent' ? 'Chờ thiết bị phản hồi' : RPCResult === 'timeout' ? 'Thiết bị không phản hồi' : 'Có lỗi'}
                      </ThemedText>
                    }
                  >
                    <Icon
                      style={sharedStyles.normalIcon}
                      fill={RPCResult === 'success' || RPCResult === 'sent' ? theme.primaryBackgroundColor : theme.red}
                      name={RPCResult === 'success' ? 'done-all-outline' : RPCResult === 'sent' ? 'arrowhead-up-outline' : RPCResult === 'timeout' ? 'wifi-off-outline' : 'alert-circle-outline'}
                    />
                  </Tooltip>
                ) : (
                  <></>
                )
              }
              {...others}
            />
            {enableUndo && isModified ? <IconButton family="material" name="undo" onPress={() => (onHandleUndo ? onHandleUndo() : undefined)} size={16 * scaleFactor} tooltip="bỏ thay đổi" /> : null}
          </View>
          <AppTimePickerModal
            visible={visible}
            textTimeStart={isStartNow ? 'Bắt đầu NGAY BÂY GIỜ' : 'Bắt đầu'}
            enableEndltStart={true}
            pickerType={isStartNow ? 'keyboard' : 'picker'}
            hours={isStartNow ? undefined : hours}
            minutes={isStartNow ? undefined : minutes}
            seconds={hideSecond ? undefined : isStartNow ? undefined : seconds}
            endHours={endHours}
            endMinutes={endMinutes}
            endSeconds={hideSecond ? undefined : endSeconds}
            onConfirm={onTimeRangeConfirm}
            onDismiss={() => setVisible(false)}
            footerLeft={
              <>
                <View style={[sharedStyles.vertical]}>
                  <View style={[getActiveBackGround(onOff, '#6200ee'), { borderRadius: 50 }]}>
                    <IconButton
                      disabled={disabledAction}
                      style={[sharedStyles.relative, { top: 2, left: 0.5 }]}
                      family="material"
                      name="power-settings-new"
                      size={16 * scaleFactor}
                      type="white"
                      tooltip="status"
                      active={onOff}
                      onPress={() => setOnOff(!onOff)}
                    />
                  </View>
                  <ThemedText color={getColorTextActive(delayActive, theme, '#6200ee')} style={{ marginLeft: 6 }}>
                    {onOff ? 'Bật' : 'Tắt'}
                  </ThemedText>
                </View>
                <Spacer width={contentPadding / 4} />
                {onOff || alwaysEnd ? (
                  <View style={[sharedStyles.vertical]}>
                    <Toggle style={[sharedStyles.toggle]} status="primary" checked={enableEnd || alwaysEnd} onChange={onEnableEndChange} disabled={alwaysEnd} />
                    <ThemedText style={{ marginLeft: 4 }} color={getColorTextActive(enableEnd, theme, '#6200ee')}>{`Kết thúc`}</ThemedText>
                  </View>
                ) : null}
              </>
            }
          />
        </View>
      )
    },
  ),
)
