import { IDict } from '@devhub/core/dist'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { memo, useContext, useMemo, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import Collapsible from 'react-native-collapsible'
import { TimeSeriesValue } from '../../stores/DeviceConfig'
import { SENSOR_ADC_BUNDLE } from '../../stores/DeviceModels'
import { RootStoreContext } from '../../stores/RootStore'
import { sharedStyles } from '../../styles/shared'
import { contentPadding, normalTextSize, scaleFactor } from '../../styles/variables'
import { A_DAY, formatTimeByInterval, getName, getPastTime, MINUTE10 } from '../../utils/constants'
import { InputConfigRow } from '../columns/InputConfigRow'
import { ModalColumn } from '../columns/ModalColumn'
import { Spacer } from '../common/Spacer'
import { useAppLayout } from '../context/LayoutContext'
import { useTheme } from '../context/ThemeContext'
import { AppDatatable } from '../elements/AppDatatable'
import { AreLineChart } from '../elements/AreaLineChart'
import { TimeRangeIntervalFilter } from '../elements/TimeRangeIntervalFilter'
import { ThemedIcon } from '../themed/ThemedIcon'
import { ThemedText } from '../themed/ThemedText'

export const DataHistoryModal = memo(
  observer((props: IDict) => {
    const endTs = Date.now()
    const { sizename } = useAppLayout()
    const theme = useTheme()
    const rootStore = useContext(RootStoreContext)
    const device = rootStore.currentDeviceStore.state.device
    const { showBackButton, deviceId, field, bundle = {} } = props
    const [expanded, setExpanded] = useState<boolean>(false)
    const history = rootStore.deviceListStore.state[`history_${field}_${deviceId}`]
    const customize = rootStore.deviceListStore.state[`customize-${field}_${deviceId}`]
    const { values = [] as TimeSeriesValue[], variables = { startTs: endTs - A_DAY, endTs, interval: MINUTE10 } } = history || {}
    const { interval = MINUTE10 } = variables
    const code = bundle.code
    // @ts-ignore
    const { mapValueFunction } = code ? SENSOR_ADC_BUNDLE[code] || {} : {}

    const { name, unit, min, max, delta } = useMemo(() => customize?.value || {}, [customize])

    const data = useMemo(
      () =>
        values?.map((item: TimeSeriesValue) => {
          const num = Number(item.value)
          return { x: item.ts, y: Math.round(mapValueFunction?.({ num, min, max, delta }) * 100) / 100 || num } || []
        }),
      [values, min, max],
    )

    return (
      <ModalColumn hideCloseButton={sizename === '1-small'} name="DATA_HISTORY" showBackButton={showBackButton} title="Lịch sử dữ liệu">
        <View style={sharedStyles.padding}>
          <TouchableOpacity style={[sharedStyles.horizontal, sharedStyles.justifyContentSpaceBetween]} onPress={() => setExpanded(!expanded)}>
            <InputConfigRow subject={`Chi tiết thông số`} tooltip={`Chi tiết thông số`} inline={false} />
            <ThemedIcon family="octicon" name={expanded ? 'chevron-up' : 'chevron-down'} size={16 * scaleFactor} color={'foregroundColorMuted65'} />
          </TouchableOpacity>
          <Collapsible collapsed={expanded}>
            <View>
              <View style={[sharedStyles.horizontal, sharedStyles.marginVerticalHalf, sharedStyles.justifyContentSpaceBetween]}>
                <ThemedText color="foregroundColorMuted65">{`tên thiết bị`}</ThemedText>
                <ThemedText color={theme.blue} style={{ textAlign: 'right' }}>
                  {device?.label || device?.name || deviceId}
                </ThemedText>
              </View>
              <View style={[sharedStyles.horizontal, sharedStyles.marginVerticalHalf, sharedStyles.justifyContentSpaceBetween]}>
                <ThemedText color="foregroundColorMuted65">{`Thông số`}</ThemedText>
                <ThemedText color={theme.blue} style={{ textAlign: 'right' }}>
                  {`${name || getName(bundle)} ${unit || ''}`}
                </ThemedText>
              </View>
              <View style={[sharedStyles.horizontal, sharedStyles.marginVerticalHalf, sharedStyles.justifyContentSpaceBetween]}>
                <ThemedText color="foregroundColorMuted65">{`Giá trị mới nhất (${getPastTime(bundle.lastUpdateTs, true)})`}</ThemedText>
                <ThemedText color={theme.blue} style={{ textAlign: 'right' }}>
                  {bundle.value}
                </ThemedText>
              </View>
            </View>
          </Collapsible>
          <Spacer height={contentPadding} />
          <TimeRangeIntervalFilter
            {...variables}
            onChange={({ endTs: end, startTs: start, interval: itv }) => {
              rootStore.deviceListStore
                .loadHistory(deviceId, {
                  keys: [field],
                  endTs: end,
                  startTs: start,
                  interval: itv,
                })
                .then()
            }}
          />
          <Spacer height={contentPadding / 2} />
          {_.isEmpty(data) ? (
            <ThemedText color="foregroundColor" style={{ fontSize: normalTextSize }}>{`Không có dữ liệu`}</ThemedText>
          ) : (
            <>
              {data?.length > 1 ? (
                <>
                  <AreLineChart data={data} interval={interval} />
                  <Spacer height={contentPadding} />
                </>
              ) : null}
              <View>
                {values ? (
                  <AppDatatable
                    data={data}
                    interval={interval}
                    renders={[
                      { title: 'Thời gian', formatLabel: (value: number) => formatTimeByInterval(value, interval) },
                      { title: 'Giá trị', formatLabel: (value: number) => `${Math.round(value * 10000) / 10000}` },
                    ]}
                  />
                ) : null}
              </View>
            </>
          )}
        </View>
      </ModalColumn>
    )
  }),
)
