import { EnhancedDevice, IDict } from '@devhub/core/dist'
import { OUTPUT_MODEL_TYPE, outputModels, outputModelTypes } from '../components/views/MultiOutputConfig'
import { A_MONTH } from '../utils/constants'
import { INPUT_CONFIG_RESULT, RPC_RESULT_SET_STATUS } from './CurrentDeviceStore'
import { ACTIVE, DEVICES_CONFIG, INPUT_ERROR, IOutputConfig, IOutputConfigs, OUTPUT_CONFIG, OUTPUT_TYPE, SCHEDULE_ON, splitOnOffValue, STATUS } from './DeviceConfig'
import { MapValueFunction } from './DeviceModels'

export interface IOutputConfigViewProps extends IOutputConfig {
  title: string
  outputIndex: number
  childOutputs?: IOutputConfigViewProps[]
}

export interface IOutputConfigItem {
  title: string
  data: IOutputConfigViewProps[]
  outputIndex?: number
}

export const getRequestOption = (token: string, method: string = 'GET') => {
  return {
    method,
    headers: {
      'Content-Type': 'application/json',
      'X-Authorization': `Bearer ${token}`,
    },
  }
}

export const initTimeSeriesParams = (notificationKey: string) => {
  const endTs = Date.now()
  const variables = {
    limit: 100,
    startTs: endTs - A_MONTH,
    endTs,
  }
  const params = Object.entries({ keys: notificationKey, ...variables })
    .map(([key, val]) => `${key}=${val}`)
    .join('&')

  return { params, variables }
}

export const getKeys = (count: number) => [...Array(count).keys()]

export const getDeviceInputCount = (type?: string) => DEVICES_CONFIG[type || '']?.inputCount || 2

export const getDevicePortNumber = (type?: string) => DEVICES_CONFIG[type || '']?.outputCountDefault || 1

export const getDeviceStatusText = (action?: string | boolean, connectionSignal?: string) => {
  switch (action) {
    case 'true':
    case true:
      return connectionSignal
    default:
      return 'Chưa kết nối'
  }
}

export const getDeviceGroupName = (key?: string | number) => {
  switch (key) {
    case 'active':
      return 'Đang hoạt động'
    default:
      const numOfPort = Number(key)
      if (!Number.isNaN(numOfPort) && numOfPort > 4) return `nhiều kênh`
      return `${key} kênh`
  }
}

export const getInputConfigAction = (actionText: string) => {
  const parts = actionText.split('_')
  let action = ''
  let duration = 0
  let afterSecond = 0
  let outputIndex
  let turnOnTime
  let turnOffTime

  if (parts.length > 0) {
    const firstNumber = Number(parts[0])
    const isSingle = isNaN(firstNumber)
    if (isSingle) {
      action = parts[0]
      if (action === 'B') {
        duration = parts.length >= 2 ? Number(parts[1]) : 0
        afterSecond = parts.length >= 3 ? Number(parts[2]) : 0
      } else if (action === 'T') {
        afterSecond = parts.length >= 2 ? Number(parts[1]) : 0
      }
    } else {
      outputIndex = firstNumber
      if (parts.length > 1) {
        action = parts[1]
        if (action === 'H') {
          duration = parts.length >= 3 ? Number(parts[2]) : 0
          afterSecond = parts.length >= 4 ? Number(parts[3]) : 0
          turnOnTime = parts.length >= 5 ? Number(parts[4]) : undefined
          turnOffTime = parts.length >= 6 ? Number(parts[5]) : undefined
        }
      }
    }
  }
  return {
    action,
    duration,
    afterSecond,
    outputIndex,
    turnOnTime,
    turnOffTime,
  }
}

export const extractInputConfigValue = (inputConfig: IDict) => {
  if (inputConfig && typeof inputConfig === 'object') {
    const { content, schedule } = inputConfig
    if (content) {
      const splits = content.split(';')
      if (splits && splits.length >= 6) {
        let inputType = 'NO'
        let inputScanDuration = 0
        let inputProblemRemove = 0
        let dependencies
        let upLow = [0, 0]
        let greaterOrLess
        let inputProblemAction = [
          {
            action: 'WARNING',
            duration: 0,
            afterSecond: 0,
          },
        ]
        let inputProblemDoneAction = [
          {
            action: 'WARNING',
            duration: 0,
            afterSecond: 0,
          },
        ]
        try {
          for (let i = 1; i < splits.length; i++) {
            const tmps = splits[i].split(':')
            if (tmps.length >= 2) {
              switch (tmps[0]) {
                case 'CB':
                  inputType = tmps[1]
                  if (tmps.length >= 3) {
                    const strings = inputType.split('_')
                    if (strings.length >= 2) {
                      greaterOrLess = strings[1] === 'S'
                      inputType = `${strings[0]}${tmps[2]}`
                    }
                  }
                  break
                case 'TD':
                  inputScanDuration = Number(tmps[1])
                  break
                case 'EX':
                  inputProblemAction = []
                  for (let j = 1; j < tmps.length; j++) {
                    inputProblemAction.push(getInputConfigAction(tmps[j]))
                  }
                  break
                case 'TX':
                  inputProblemRemove = Number(tmps[1])
                  break
                case 'ES':
                  inputProblemDoneAction = []
                  for (let j = 1; j < tmps.length; j++) {
                    inputProblemDoneAction.push(getInputConfigAction(tmps[j]))
                  }
                  break
                case 'PT':
                  dependencies = tmps.slice(1)
                  break
                case 'UPLOW':
                  if (tmps.length >= 3) {
                    upLow = [Number(tmps[1]), Number(tmps[2])]
                  }
                  break
              }
            }
          }

          return {
            inputStatus: splits[0] === 'B',
            inputType,
            inputScanDuration,
            inputProblemAction,
            inputProblemDoneAction,
            inputProblemRemove,
            dependencies,
            upLow,
            greaterOrLess,
            schedule:
              Array.isArray(schedule) && schedule.length > 0 && schedule[0]?.timer !== undefined && schedule[0]?.day !== undefined
                ? [
                    {
                      timer: schedule[0].timer,
                      day: schedule[0].day,
                      cycle: schedule[0].cycle,
                    },
                  ]
                : [],
          }
        } catch (e) {
          console.log('extractInputConfigValue error when parse input config', e)
        }
      }
    }
  }
  return {}
}

export const getOutputNumbers = (device: EnhancedDevice, extra?: IDict) => {
  let ports: number[]
  const type = device?.type || ''
  if (DEVICES_CONFIG[type]?.hasOutputConfig && extra && extra[OUTPUT_CONFIG]) {
    ports = Object.keys(extra[OUTPUT_CONFIG]).map((inputNumberText) => Number(inputNumberText))
  } else {
    ports = getKeys(getDevicePortNumber(device.type))
  }
  return ports
}

export const getKeysByNumber = (device: EnhancedDevice, extra?: IDict) => {
  const inputs = getKeys(getDeviceInputCount(device.type))
  const ports = getOutputNumbers(device, extra)
  return [...inputs.map((value) => `${INPUT_ERROR}_${value}`), ...inputs.map((value) => `${INPUT_CONFIG_RESULT}_${value}`), ...ports.map((value) => `${RPC_RESULT_SET_STATUS}_${value}`)]
}

// TODO update key for list device page
export const getTelemetryKeys = (device: EnhancedDevice, extra?: IDict) => {
  const byNumberKeys = getKeysByNumber(device, extra)
  const sensorTelemetry = DEVICES_CONFIG[device.type]?.sensorFields || []
  return [ACTIVE, STATUS, SCHEDULE_ON, ...byNumberKeys, ...sensorTelemetry]
}

export const formatSensorData = (value: string | number) => {
  const sourceNumber = typeof value === 'string' ? Number.parseFloat(value) : value
  return Number.isNaN(sourceNumber) ? '-' : Math.round(sourceNumber * 100) / 100
}

export const getDeviceConfigTooltip = (device?: EnhancedDevice) => {
  const type = device?.type || ''
  if (DEVICES_CONFIG[type]?.inputCount > 2) {
    return `Thiết lập cho đầu vào và đầu ra`
  }
  return `Giữ đầu giò và kéo`
}

export const extractOnOffValues = (valueText: string, device?: EnhancedDevice) => {
  if (DEVICES_CONFIG[device?.type || '']?.hasOutputConfig) {
    return splitOnOffValue(valueText)
  }
  return [valueText]
}

export const getOutputName = (outputIndex: string, names: IDict = {}, prependOrderNumber = true) => `${prependOrderNumber ? `${Number(outputIndex) + 1}.` : ''}${names[outputIndex] || `Đầu ra ${Number(outputIndex) + 1}`}`

export const getOutputModel = (outputModel: string | undefined) => {
  if (outputModel) {
    let model: OUTPUT_MODEL_TYPE = outputModelTypes[outputModel]
    model = model !== undefined ? model : OUTPUT_MODEL_TYPE.GENERAL
    return model
  }
  return undefined
}

export const getOutputTypeName = (oType: OUTPUT_TYPE, oModel: string | undefined) => {
  const modelType = getOutputModel(oModel)
  if (modelType !== undefined) {
    const bundle = outputModels[modelType]
    return `Nhóm ${bundle.names[oType].title}`
  }
  return 'Nhóm riêng'
}

export const groupOutputsByDependence = (configsState: IOutputConfigs, names: IDict, outputModel: string, isSMSTransport: boolean = false) => {
  const groupOutputs = Object.keys(configsState).reduce(
    (pre, outputIndexText) => {
      const currentOutput = configsState[outputIndexText]
      const title = getOutputName(outputIndexText, names, false)
      const outputIndex = Number(outputIndexText)

      switch (currentOutput.outputType) {
        case OUTPUT_TYPE.TAI:
          pre.independence.data.push({
            ...currentOutput,
            title,
            outputIndex,
          })
          break
        case OUTPUT_TYPE.TAI_EX:
          if (!pre[outputIndexText]) {
            pre[outputIndexText] = {
              title: ``,
              data: [],
            }
          }
          pre[outputIndexText].title = `Nhóm ${title}`
          pre[outputIndexText].outputIndex = outputIndex
          pre[outputIndexText].data.push({
            ...currentOutput,
            title,
            outputIndex,
          })
          break
        case OUTPUT_TYPE.VAN:
          const dependency = configsState[outputIndexText].dependency
          if (dependency !== undefined) {
            if (!pre[`${dependency}`]) {
              pre[`${dependency}`] = {
                title: `Nhóm ${dependency}`,
                data: [],
              }
            }
            pre[`${dependency}`].data.push({
              ...currentOutput,
              title,
              outputIndex,
            })
          } else if (isSMSTransport) {
            if (!pre[`Van`]) {
              pre[`Van`] = {
                title: `Nhóm Van`,
                data: [],
              }
            }
            pre[`Van`].data.push({
              ...currentOutput,
              title,
              outputIndex,
            })
          }
          break
      }
      return pre
    },
    {
      independence: {
        title: getOutputTypeName(OUTPUT_TYPE.TAI, outputModel),
        data: [],
      },
    } as {
      [key: string]: IOutputConfigItem
    },
  )
  const groupedPorts = Object.values(groupOutputs)
    .filter((item) => {
      const keep = item.data.length > 0
      if (keep && item.outputIndex !== undefined) {
        const found = item.data.findIndex((p) => p.outputIndex === item.outputIndex)
        if (found >= 0) {
          const foundItem = item.data[found]
          item.data.splice(found, 1)
          item.data.unshift(foundItem)
        }
      }
      return keep
    })
    .sort((a, b) => b.data.length - a.data.length)
  if (isSMSTransport && groupedPorts.length > 1) {
    groupedPorts.push({
      title: 'Tất Cả Các Kênh',
      data: [],
      outputIndex: -1,
    })
  }
  return groupedPorts
}

export const getOrder = (device: EnhancedDevice) => DEVICES_CONFIG[device.type]?.order || 0
