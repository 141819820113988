import { IDict } from '@devhub/core/dist'

export const DEVICE_MODELS = [
  {
    title: 'Không dùng',
    inputConfig: {},
  },
  {
    title: 'Phao tự động',
    inputConfig: {
      content: 'B;CB:NO;TD:1;EX:B;TX:0;ES:T',
      schedule: [{ day: 255, timer: '0_86399' }],
    },
  },
  {
    title: 'Báo cháy',
    inputConfig: {
      content: 'B;CB:NO;TD:3;EX:B;TX:300;ES:T',
      schedule: [{ day: 255, timer: '0_86399' }],
    },
  },
  {
    title: 'Báo cửa mở',
    inputConfig: {
      content: 'B;CB:NC;TD:1;EX:B_300;TX.0;ES:EX',
      schedule: [{ day: 255, timer: '0_86399' }],
    },
  },
  {
    title: 'Báo cạn nước',
    inputConfig: {
      content: 'B;CB:DC;TD:60;EX:T;TX:1800;ES:T',
      schedule: [{ day: 255, timer: '0_86399' }],
    },
  },
  {
    title: 'Báo áp suất cao',
    inputConfig: {
      content: 'B;CB:AS;TD:5;EX:T;TX:180;ES:T',
      schedule: [{ day: 255, timer: '0_86399' }],
    },
  },
]

export const MULTI_OUTPUTS_MODELS = [
  {
    title: 'Không dùng',
    inputConfig: {},
  },
  {
    title: 'Báo cạn nước',
    inputConfig: {
      content: 'B;CB:DC;TD:1;EX:0_B;TX:0;ES:0_T;PT:0',
      schedule: [{ day: 255, timer: '0_86399' }],
    },
  },
]

export const SEQUENCE_MODELS = [
  {
    title: 'Không dùng',
    inputConfig: {},
  },
  {
    title: 'Phun sương nấm',
    inputConfig: {
      content: 'B;CB:LA;TD:1;EX:0_B;TX:0;ES:0_T;UPLOW:30:24',
      schedule: [{ day: 255, timer: '0_86399' }],
    },
  },
]

export interface IMapValueFunction {
  num: number
  min?: number
  max?: number
  delta?: number
  reverse?: boolean
  round2Decimal?: boolean
}

export type MapValueFunction = (params: IMapValueFunction) => number

export const defaultMapValueFunction = ({ num, min = 0, max = 255, delta = 0, reverse = false, round2Decimal = false }: IMapValueFunction) => {
  return max === min ? num : reverse ? Math.round(((num - min - delta) * 255) / (max - min)) : (round2Decimal ? Math.round((((max - min) * num) / 255 + min) * 100) / 100 : ((max - min) * num) / 255 + min) + delta
}

export const mapValue420mAFunction = ({ num, min = 0, max = 255, delta = 0, reverse = false, round2Decimal = false }: IMapValueFunction) => {
  return max === min
    ? num
    : reverse
    ? Math.round((((num - min - delta) * 126976) / (max - min) + 31744) / 825)
    : (round2Decimal ? Math.round((((max - min) * (825 * num - 31744)) / 126976 + min) * 100) / 100 : ((max - min) * (825 * num - 31744)) / 126976 + min) + delta
}

export const DEFAULT_NAMES: IDict = {
  temperature: 'Nhiệt độ',
  humidity: 'Độ ẩm',
}

export enum SENSOR_ADC_TYPE {
  temperature = 'temperature',
  humidity = 'humidity',
  moisture = 'moisture',
  ground_temperature = 'ground_temperature',
  ec = 'ec',
  sensor_4_20mA = '4_20mA',
  custom = 'custom',
}

export const SENSOR_ADC_BUNDLE: IDict = {
  [SENSOR_ADC_TYPE.temperature]: {
    title: DEFAULT_NAMES.temperature,
    unit: '°',
    code: SENSOR_ADC_TYPE.temperature,
    icon: 'temp',
    min: -100,
    max: 200,
    mapValueFunction: ({ num }: IMapValueFunction) => num,
  },
  [SENSOR_ADC_TYPE.humidity]: {
    title: DEFAULT_NAMES.humidity,
    unit: '%',
    code: SENSOR_ADC_TYPE.humidity,
    icon: 'humidity',
    min: -100,
    max: 100,
    mapValueFunction: ({ num }: IMapValueFunction) => num,
  },
  /*[SENSOR_ADC_TYPE.moisture]: {
    title: 'Độ Giữ Ẩm',
    unit: '%',
    code: SENSOR_ADC_TYPE.moisture,
    icon: 'moisture',
    min: 0,
    max: 100,
    mapValueFunction: (option: IMapValueFunction) => defaultMapValueFunction({ min: SENSOR_ADC_BUNDLE[SENSOR_ADC_TYPE.moisture].min, max: SENSOR_ADC_BUNDLE[SENSOR_ADC_TYPE.moisture].max, ...option }),
  },
  [SENSOR_ADC_TYPE.ground_temperature]: {
    title: 'Nhiệt Độ Đất',
    unit: '°',
    code: SENSOR_ADC_TYPE.ground_temperature,
    icon: 'temp',
    min: -30,
    max: 70,
    mapValueFunction: (option: IMapValueFunction) => defaultMapValueFunction({ min: SENSOR_ADC_BUNDLE[SENSOR_ADC_TYPE.ground_temperature].min, max: SENSOR_ADC_BUNDLE[SENSOR_ADC_TYPE.ground_temperature].max, ...option }),
  },
  [SENSOR_ADC_TYPE.ec]: {
    title: 'EC',
    unit: 'mS/cm',
    code: SENSOR_ADC_TYPE.ec,
    icon: 'pressure',
    min: 0,
    max: 10,
    mapValueFunction: (option: IMapValueFunction) => defaultMapValueFunction({ min: SENSOR_ADC_BUNDLE[SENSOR_ADC_TYPE.ec].min, max: SENSOR_ADC_BUNDLE[SENSOR_ADC_TYPE.ec].max, ...option }),
  }, */
  [SENSOR_ADC_TYPE.sensor_4_20mA]: {
    title: '4-20mA',
    unit: '',
    code: SENSOR_ADC_TYPE.sensor_4_20mA,
    icon: 'pressure',
    min: 0,
    max: 255,
    mapValueFunction: mapValue420mAFunction,
  },
  [SENSOR_ADC_TYPE.custom]: {
    title: 'Tùy biến',
    unit: '',
    code: SENSOR_ADC_TYPE.custom,
    icon: 'temp',
    min: 0,
    max: 255,
    mapValueFunction: defaultMapValueFunction,
  },
}

export const SENSOR_NAME_CODE: IDict = Object.entries(SENSOR_ADC_BUNDLE).reduce((pre, [field, { title, code }]) => {
  pre[field] = { name: title, code }
  return pre
}, {} as IDict)
