import _ from 'lodash'
import React, { Fragment } from 'react'
import { Image, ImageProps } from 'react-native'

import { genericParseText } from '../shared'

const emojis = {
  '+1': 'unicode/1f44d',
  '-1': 'unicode/1f44e',
  '100': 'unicode/1f4af',
  '1234': 'unicode/1f522',
  '1st_place_medal': 'unicode/1f947',
  '2nd_place_medal': 'unicode/1f948',
  '3rd_place_medal': 'unicode/1f949',
  '8ball': 'unicode/1f3b1',
  a: 'unicode/1f170',
  ab: 'unicode/1f18e',
  abc: 'unicode/1f524',
  abcd: 'unicode/1f521',
  accept: 'unicode/1f251',
  aerial_tramway: 'unicode/1f6a1',
  afghanistan: 'unicode/1f1e6-1f1eb',
  airplane: 'unicode/2708',
  aland_islands: 'unicode/1f1e6-1f1fd',
  alarm_clock: 'unicode/23f0',
  albania: 'unicode/1f1e6-1f1f1',
  alembic: 'unicode/2697',
  algeria: 'unicode/1f1e9-1f1ff',
  alien: 'unicode/1f47d',
  ambulance: 'unicode/1f691',
  american_samoa: 'unicode/1f1e6-1f1f8',
  amphora: 'unicode/1f3fa',
  anchor: 'unicode/2693',
  andorra: 'unicode/1f1e6-1f1e9',
  angel: 'unicode/1f47c',
  anger: 'unicode/1f4a2',
  angola: 'unicode/1f1e6-1f1f4',
  angry: 'unicode/1f620',
  anguilla: 'unicode/1f1e6-1f1ee',
  anguished: 'unicode/1f627',
  ant: 'unicode/1f41c',
  antarctica: 'unicode/1f1e6-1f1f6',
  antigua_barbuda: 'unicode/1f1e6-1f1ec',
  apple: 'unicode/1f34e',
  aquarius: 'unicode/2652',
  argentina: 'unicode/1f1e6-1f1f7',
  aries: 'unicode/2648',
  armenia: 'unicode/1f1e6-1f1f2',
  arrow_backward: 'unicode/25c0',
  arrow_double_down: 'unicode/23ec',
  arrow_double_up: 'unicode/23eb',
  arrow_down: 'unicode/2b07',
  arrow_down_small: 'unicode/1f53d',
  arrow_forward: 'unicode/25b6',
  arrow_heading_down: 'unicode/2935',
  arrow_heading_up: 'unicode/2934',
  arrow_left: 'unicode/2b05',
  arrow_lower_left: 'unicode/2199',
  arrow_lower_right: 'unicode/2198',
  arrow_right: 'unicode/27a1',
  arrow_right_hook: 'unicode/21aa',
  arrow_up: 'unicode/2b06',
  arrow_up_down: 'unicode/2195',
  arrow_up_small: 'unicode/1f53c',
  arrow_upper_left: 'unicode/2196',
  arrow_upper_right: 'unicode/2197',
  arrows_clockwise: 'unicode/1f503',
  arrows_counterclockwise: 'unicode/1f504',
  art: 'unicode/1f3a8',
  articulated_lorry: 'unicode/1f69b',
  artificial_satellite: 'unicode/1f6f0',
  aruba: 'unicode/1f1e6-1f1fc',
  asterisk: 'unicode/002a-20e3',
  astonished: 'unicode/1f632',
  athletic_shoe: 'unicode/1f45f',
  atm: 'unicode/1f3e7',
  atom: 'atom',
  atom_symbol: 'unicode/269b',
  australia: 'unicode/1f1e6-1f1fa',
  austria: 'unicode/1f1e6-1f1f9',
  avocado: 'unicode/1f951',
  azerbaijan: 'unicode/1f1e6-1f1ff',
  b: 'unicode/1f171',
  baby: 'unicode/1f476',
  baby_bottle: 'unicode/1f37c',
  baby_chick: 'unicode/1f424',
  baby_symbol: 'unicode/1f6bc',
  back: 'unicode/1f519',
  bacon: 'unicode/1f953',
  badminton: 'unicode/1f3f8',
  baggage_claim: 'unicode/1f6c4',
  baguette_bread: 'unicode/1f956',
  bahamas: 'unicode/1f1e7-1f1f8',
  bahrain: 'unicode/1f1e7-1f1ed',
  balance_scale: 'unicode/2696',
  balloon: 'unicode/1f388',
  ballot_box: 'unicode/1f5f3',
  ballot_box_with_check: 'unicode/2611',
  bamboo: 'unicode/1f38d',
  banana: 'unicode/1f34c',
  bangbang: 'unicode/203c',
  bangladesh: 'unicode/1f1e7-1f1e9',
  bank: 'unicode/1f3e6',
  bar_chart: 'unicode/1f4ca',
  barbados: 'unicode/1f1e7-1f1e7',
  barber: 'unicode/1f488',
  baseball: 'unicode/26be',
  basecamp: 'basecamp',
  basecampy: 'basecampy',
  basketball: 'unicode/1f3c0',
  basketball_man: 'unicode/26f9',
  basketball_woman: 'unicode/26f9-2640',
  bat: 'unicode/1f987',
  bath: 'unicode/1f6c0',
  bathtub: 'unicode/1f6c1',
  battery: 'unicode/1f50b',
  beach_umbrella: 'unicode/1f3d6',
  bear: 'unicode/1f43b',
  bed: 'unicode/1f6cf',
  bee: 'unicode/1f41d',
  beer: 'unicode/1f37a',
  beers: 'unicode/1f37b',
  beetle: 'unicode/1f41e',
  beginner: 'unicode/1f530',
  belarus: 'unicode/1f1e7-1f1fe',
  belgium: 'unicode/1f1e7-1f1ea',
  belize: 'unicode/1f1e7-1f1ff',
  bell: 'unicode/1f514',
  bellhop_bell: 'unicode/1f6ce',
  benin: 'unicode/1f1e7-1f1ef',
  bento: 'unicode/1f371',
  bermuda: 'unicode/1f1e7-1f1f2',
  bhutan: 'unicode/1f1e7-1f1f9',
  bicyclist: 'unicode/1f6b4',
  bike: 'unicode/1f6b2',
  biking_man: 'unicode/1f6b4',
  biking_woman: 'unicode/1f6b4-2640',
  bikini: 'unicode/1f459',
  biohazard: 'unicode/2623',
  bird: 'unicode/1f426',
  birthday: 'unicode/1f382',
  black_circle: 'unicode/26ab',
  black_flag: 'unicode/1f3f4',
  black_heart: 'unicode/1f5a4',
  black_joker: 'unicode/1f0cf',
  black_large_square: 'unicode/2b1b',
  black_medium_small_square: 'unicode/25fe',
  black_medium_square: 'unicode/25fc',
  black_nib: 'unicode/2712',
  black_small_square: 'unicode/25aa',
  black_square_button: 'unicode/1f532',
  blonde_man: 'unicode/1f471',
  blonde_woman: 'unicode/1f471-2640',
  blossom: 'unicode/1f33c',
  blowfish: 'unicode/1f421',
  blue_book: 'unicode/1f4d8',
  blue_car: 'unicode/1f699',
  blue_heart: 'unicode/1f499',
  blush: 'unicode/1f60a',
  boar: 'unicode/1f417',
  boat: 'unicode/26f5',
  bolivia: 'unicode/1f1e7-1f1f4',
  bomb: 'unicode/1f4a3',
  book: 'unicode/1f4d6',
  bookmark: 'unicode/1f516',
  bookmark_tabs: 'unicode/1f4d1',
  books: 'unicode/1f4da',
  boom: 'unicode/1f4a5',
  boot: 'unicode/1f462',
  bosnia_herzegovina: 'unicode/1f1e7-1f1e6',
  botswana: 'unicode/1f1e7-1f1fc',
  bouquet: 'unicode/1f490',
  bow: 'unicode/1f647',
  bow_and_arrow: 'unicode/1f3f9',
  bowing_man: 'unicode/1f647',
  bowing_woman: 'unicode/1f647-2640',
  bowling: 'unicode/1f3b3',
  bowtie: 'bowtie',
  boxing_glove: 'unicode/1f94a',
  boy: 'unicode/1f466',
  brazil: 'unicode/1f1e7-1f1f7',
  bread: 'unicode/1f35e',
  bride_with_veil: 'unicode/1f470',
  bridge_at_night: 'unicode/1f309',
  briefcase: 'unicode/1f4bc',
  british_indian_ocean_territory: 'unicode/1f1ee-1f1f4',
  british_virgin_islands: 'unicode/1f1fb-1f1ec',
  broken_heart: 'unicode/1f494',
  brunei: 'unicode/1f1e7-1f1f3',
  bug: 'unicode/1f41b',
  building_construction: 'unicode/1f3d7',
  bulb: 'unicode/1f4a1',
  bulgaria: 'unicode/1f1e7-1f1ec',
  bullettrain_front: 'unicode/1f685',
  bullettrain_side: 'unicode/1f684',
  burkina_faso: 'unicode/1f1e7-1f1eb',
  burrito: 'unicode/1f32f',
  burundi: 'unicode/1f1e7-1f1ee',
  bus: 'unicode/1f68c',
  business_suit_levitating: 'unicode/1f574',
  busstop: 'unicode/1f68f',
  bust_in_silhouette: 'unicode/1f464',
  busts_in_silhouette: 'unicode/1f465',
  butterfly: 'unicode/1f98b',
  cactus: 'unicode/1f335',
  cake: 'unicode/1f370',
  calendar: 'unicode/1f4c6',
  call_me_hand: 'unicode/1f919',
  calling: 'unicode/1f4f2',
  cambodia: 'unicode/1f1f0-1f1ed',
  camel: 'unicode/1f42b',
  camera: 'unicode/1f4f7',
  camera_flash: 'unicode/1f4f8',
  cameroon: 'unicode/1f1e8-1f1f2',
  camping: 'unicode/1f3d5',
  canada: 'unicode/1f1e8-1f1e6',
  canary_islands: 'unicode/1f1ee-1f1e8',
  cancer: 'unicode/264b',
  candle: 'unicode/1f56f',
  candy: 'unicode/1f36c',
  canoe: 'unicode/1f6f6',
  cape_verde: 'unicode/1f1e8-1f1fb',
  capital_abcd: 'unicode/1f520',
  capricorn: 'unicode/2651',
  car: 'unicode/1f697',
  card_file_box: 'unicode/1f5c3',
  card_index: 'unicode/1f4c7',
  card_index_dividers: 'unicode/1f5c2',
  caribbean_netherlands: 'unicode/1f1e7-1f1f6',
  carousel_horse: 'unicode/1f3a0',
  carrot: 'unicode/1f955',
  cat: 'unicode/1f431',
  cat2: 'unicode/1f408',
  cayman_islands: 'unicode/1f1f0-1f1fe',
  cd: 'unicode/1f4bf',
  central_african_republic: 'unicode/1f1e8-1f1eb',
  chad: 'unicode/1f1f9-1f1e9',
  chains: 'unicode/26d3',
  champagne: 'unicode/1f37e',
  chart: 'unicode/1f4b9',
  chart_with_downwards_trend: 'unicode/1f4c9',
  chart_with_upwards_trend: 'unicode/1f4c8',
  checkered_flag: 'unicode/1f3c1',
  cheese: 'unicode/1f9c0',
  cherries: 'unicode/1f352',
  cherry_blossom: 'unicode/1f338',
  chestnut: 'unicode/1f330',
  chicken: 'unicode/1f414',
  children_crossing: 'unicode/1f6b8',
  chile: 'unicode/1f1e8-1f1f1',
  chipmunk: 'unicode/1f43f',
  chocolate_bar: 'unicode/1f36b',
  christmas_island: 'unicode/1f1e8-1f1fd',
  christmas_tree: 'unicode/1f384',
  church: 'unicode/26ea',
  cinema: 'unicode/1f3a6',
  circus_tent: 'unicode/1f3aa',
  city_sunrise: 'unicode/1f307',
  city_sunset: 'unicode/1f306',
  cityscape: 'unicode/1f3d9',
  cl: 'unicode/1f191',
  clamp: 'unicode/1f5dc',
  clap: 'unicode/1f44f',
  clapper: 'unicode/1f3ac',
  classical_building: 'unicode/1f3db',
  clinking_glasses: 'unicode/1f942',
  clipboard: 'unicode/1f4cb',
  clock1: 'unicode/1f550',
  clock10: 'unicode/1f559',
  clock1030: 'unicode/1f565',
  clock11: 'unicode/1f55a',
  clock1130: 'unicode/1f566',
  clock12: 'unicode/1f55b',
  clock1230: 'unicode/1f567',
  clock130: 'unicode/1f55c',
  clock2: 'unicode/1f551',
  clock230: 'unicode/1f55d',
  clock3: 'unicode/1f552',
  clock330: 'unicode/1f55e',
  clock4: 'unicode/1f553',
  clock430: 'unicode/1f55f',
  clock5: 'unicode/1f554',
  clock530: 'unicode/1f560',
  clock6: 'unicode/1f555',
  clock630: 'unicode/1f561',
  clock7: 'unicode/1f556',
  clock730: 'unicode/1f562',
  clock8: 'unicode/1f557',
  clock830: 'unicode/1f563',
  clock9: 'unicode/1f558',
  clock930: 'unicode/1f564',
  closed_book: 'unicode/1f4d5',
  closed_lock_with_key: 'unicode/1f510',
  closed_umbrella: 'unicode/1f302',
  cloud: 'unicode/2601',
  cloud_with_lightning: 'unicode/1f329',
  cloud_with_lightning_and_rain: 'unicode/26c8',
  cloud_with_rain: 'unicode/1f327',
  cloud_with_snow: 'unicode/1f328',
  clown_face: 'unicode/1f921',
  clubs: 'unicode/2663',
  cn: 'unicode/1f1e8-1f1f3',
  cocktail: 'unicode/1f378',
  cocos_islands: 'unicode/1f1e8-1f1e8',
  coffee: 'unicode/2615',
  coffin: 'unicode/26b0',
  cold_sweat: 'unicode/1f630',
  collision: 'unicode/1f4a5',
  colombia: 'unicode/1f1e8-1f1f4',
  comet: 'unicode/2604',
  comoros: 'unicode/1f1f0-1f1f2',
  computer: 'unicode/1f4bb',
  computer_mouse: 'unicode/1f5b1',
  confetti_ball: 'unicode/1f38a',
  confounded: 'unicode/1f616',
  confused: 'unicode/1f615',
  congo_brazzaville: 'unicode/1f1e8-1f1ec',
  congo_kinshasa: 'unicode/1f1e8-1f1e9',
  congratulations: 'unicode/3297',
  construction: 'unicode/1f6a7',
  construction_worker: 'unicode/1f477',
  construction_worker_man: 'unicode/1f477',
  construction_worker_woman: 'unicode/1f477-2640',
  control_knobs: 'unicode/1f39b',
  convenience_store: 'unicode/1f3ea',
  cook_islands: 'unicode/1f1e8-1f1f0',
  cookie: 'unicode/1f36a',
  cool: 'unicode/1f192',
  cop: 'unicode/1f46e',
  copyright: 'unicode/00a9',
  corn: 'unicode/1f33d',
  costa_rica: 'unicode/1f1e8-1f1f7',
  cote_divoire: 'unicode/1f1e8-1f1ee',
  couch_and_lamp: 'unicode/1f6cb',
  couple: 'unicode/1f46b',
  couple_with_heart: 'unicode/1f491',
  couple_with_heart_man_man: 'unicode/1f468-2764-1f468',
  couple_with_heart_woman_man: 'unicode/1f491',
  couple_with_heart_woman_woman: 'unicode/1f469-2764-1f469',
  couplekiss_man_man: 'unicode/1f468-2764-1f48b-1f468',
  couplekiss_man_woman: 'unicode/1f48f',
  couplekiss_woman_woman: 'unicode/1f469-2764-1f48b-1f469',
  cow: 'unicode/1f42e',
  cow2: 'unicode/1f404',
  cowboy_hat_face: 'unicode/1f920',
  crab: 'unicode/1f980',
  crayon: 'unicode/1f58d',
  credit_card: 'unicode/1f4b3',
  crescent_moon: 'unicode/1f319',
  cricket: 'unicode/1f3cf',
  croatia: 'unicode/1f1ed-1f1f7',
  crocodile: 'unicode/1f40a',
  croissant: 'unicode/1f950',
  crossed_fingers: 'unicode/1f91e',
  crossed_flags: 'unicode/1f38c',
  crossed_swords: 'unicode/2694',
  crown: 'unicode/1f451',
  cry: 'unicode/1f622',
  crying_cat_face: 'unicode/1f63f',
  crystal_ball: 'unicode/1f52e',
  cuba: 'unicode/1f1e8-1f1fa',
  cucumber: 'unicode/1f952',
  cupid: 'unicode/1f498',
  curacao: 'unicode/1f1e8-1f1fc',
  curly_loop: 'unicode/27b0',
  currency_exchange: 'unicode/1f4b1',
  curry: 'unicode/1f35b',
  custard: 'unicode/1f36e',
  customs: 'unicode/1f6c3',
  cyclone: 'unicode/1f300',
  cyprus: 'unicode/1f1e8-1f1fe',
  czech_republic: 'unicode/1f1e8-1f1ff',
  dagger: 'unicode/1f5e1',
  dancer: 'unicode/1f483',
  dancers: 'unicode/1f46f',
  dancing_men: 'unicode/1f46f-2642',
  dancing_women: 'unicode/1f46f',
  dango: 'unicode/1f361',
  dark_sunglasses: 'unicode/1f576',
  dart: 'unicode/1f3af',
  dash: 'unicode/1f4a8',
  date: 'unicode/1f4c5',
  de: 'unicode/1f1e9-1f1ea',
  deciduous_tree: 'unicode/1f333',
  deer: 'unicode/1f98c',
  denmark: 'unicode/1f1e9-1f1f0',
  department_store: 'unicode/1f3ec',
  derelict_house: 'unicode/1f3da',
  desert: 'unicode/1f3dc',
  desert_island: 'unicode/1f3dd',
  desktop_computer: 'unicode/1f5a5',
  detective: 'unicode/1f575',
  diamond_shape_with_a_dot_inside: 'unicode/1f4a0',
  diamonds: 'unicode/2666',
  disappointed: 'unicode/1f61e',
  disappointed_relieved: 'unicode/1f625',
  dizzy: 'unicode/1f4ab',
  dizzy_face: 'unicode/1f635',
  djibouti: 'unicode/1f1e9-1f1ef',
  do_not_litter: 'unicode/1f6af',
  dog: 'unicode/1f436',
  dog2: 'unicode/1f415',
  dollar: 'unicode/1f4b5',
  dolls: 'unicode/1f38e',
  dolphin: 'unicode/1f42c',
  dominica: 'unicode/1f1e9-1f1f2',
  dominican_republic: 'unicode/1f1e9-1f1f4',
  door: 'unicode/1f6aa',
  doughnut: 'unicode/1f369',
  dove: 'unicode/1f54a',
  dragon: 'unicode/1f409',
  dragon_face: 'unicode/1f432',
  dress: 'unicode/1f457',
  dromedary_camel: 'unicode/1f42a',
  drooling_face: 'unicode/1f924',
  droplet: 'unicode/1f4a7',
  drum: 'unicode/1f941',
  duck: 'unicode/1f986',
  dvd: 'unicode/1f4c0',
  'e-mail': 'unicode/1f4e7',
  eagle: 'unicode/1f985',
  ear: 'unicode/1f442',
  ear_of_rice: 'unicode/1f33e',
  earth_africa: 'unicode/1f30d',
  earth_americas: 'unicode/1f30e',
  earth_asia: 'unicode/1f30f',
  ecuador: 'unicode/1f1ea-1f1e8',
  egg: 'unicode/1f95a',
  eggplant: 'unicode/1f346',
  egypt: 'unicode/1f1ea-1f1ec',
  eight: 'unicode/0038-20e3',
  eight_pointed_black_star: 'unicode/2734',
  eight_spoked_asterisk: 'unicode/2733',
  el_salvador: 'unicode/1f1f8-1f1fb',
  electric_plug: 'unicode/1f50c',
  electron: 'electron',
  elephant: 'unicode/1f418',
  email: 'unicode/2709',
  end: 'unicode/1f51a',
  envelope: 'unicode/2709',
  envelope_with_arrow: 'unicode/1f4e9',
  equatorial_guinea: 'unicode/1f1ec-1f1f6',
  eritrea: 'unicode/1f1ea-1f1f7',
  es: 'unicode/1f1ea-1f1f8',
  estonia: 'unicode/1f1ea-1f1ea',
  ethiopia: 'unicode/1f1ea-1f1f9',
  eu: 'unicode/1f1ea-1f1fa',
  euro: 'unicode/1f4b6',
  european_castle: 'unicode/1f3f0',
  european_post_office: 'unicode/1f3e4',
  european_union: 'unicode/1f1ea-1f1fa',
  evergreen_tree: 'unicode/1f332',
  exclamation: 'unicode/2757',
  expressionless: 'unicode/1f611',
  eye: 'unicode/1f441',
  eye_speech_bubble: 'unicode/1f441-1f5e8',
  eyeglasses: 'unicode/1f453',
  eyes: 'unicode/1f440',
  face_with_head_bandage: 'unicode/1f915',
  face_with_thermometer: 'unicode/1f912',
  facepunch: 'unicode/1f44a',
  factory: 'unicode/1f3ed',
  falkland_islands: 'unicode/1f1eb-1f1f0',
  fallen_leaf: 'unicode/1f342',
  family: 'unicode/1f46a',
  family_man_boy: 'unicode/1f468-1f466',
  family_man_boy_boy: 'unicode/1f468-1f466-1f466',
  family_man_girl: 'unicode/1f468-1f467',
  family_man_girl_boy: 'unicode/1f468-1f467-1f466',
  family_man_girl_girl: 'unicode/1f468-1f467-1f467',
  family_man_man_boy: 'unicode/1f468-1f468-1f466',
  family_man_man_boy_boy: 'unicode/1f468-1f468-1f466-1f466',
  family_man_man_girl: 'unicode/1f468-1f468-1f467',
  family_man_man_girl_boy: 'unicode/1f468-1f468-1f467-1f466',
  family_man_man_girl_girl: 'unicode/1f468-1f468-1f467-1f467',
  family_man_woman_boy: 'unicode/1f46a',
  family_man_woman_boy_boy: 'unicode/1f468-1f469-1f466-1f466',
  family_man_woman_girl: 'unicode/1f468-1f469-1f467',
  family_man_woman_girl_boy: 'unicode/1f468-1f469-1f467-1f466',
  family_man_woman_girl_girl: 'unicode/1f468-1f469-1f467-1f467',
  family_woman_boy: 'unicode/1f469-1f466',
  family_woman_boy_boy: 'unicode/1f469-1f466-1f466',
  family_woman_girl: 'unicode/1f469-1f467',
  family_woman_girl_boy: 'unicode/1f469-1f467-1f466',
  family_woman_girl_girl: 'unicode/1f469-1f467-1f467',
  family_woman_woman_boy: 'unicode/1f469-1f469-1f466',
  family_woman_woman_boy_boy: 'unicode/1f469-1f469-1f466-1f466',
  family_woman_woman_girl: 'unicode/1f469-1f469-1f467',
  family_woman_woman_girl_boy: 'unicode/1f469-1f469-1f467-1f466',
  family_woman_woman_girl_girl: 'unicode/1f469-1f469-1f467-1f467',
  faroe_islands: 'unicode/1f1eb-1f1f4',
  fast_forward: 'unicode/23e9',
  fax: 'unicode/1f4e0',
  fearful: 'unicode/1f628',
  feelsgood: 'feelsgood',
  feet: 'unicode/1f43e',
  female_detective: 'unicode/1f575-2640',
  ferris_wheel: 'unicode/1f3a1',
  ferry: 'unicode/26f4',
  field_hockey: 'unicode/1f3d1',
  fiji: 'unicode/1f1eb-1f1ef',
  file_cabinet: 'unicode/1f5c4',
  file_folder: 'unicode/1f4c1',
  film_projector: 'unicode/1f4fd',
  film_strip: 'unicode/1f39e',
  finland: 'unicode/1f1eb-1f1ee',
  finnadie: 'finnadie',
  fire: 'unicode/1f525',
  fire_engine: 'unicode/1f692',
  fireworks: 'unicode/1f386',
  first_quarter_moon: 'unicode/1f313',
  first_quarter_moon_with_face: 'unicode/1f31b',
  fish: 'unicode/1f41f',
  fish_cake: 'unicode/1f365',
  fishing_pole_and_fish: 'unicode/1f3a3',
  fist: 'unicode/270a',
  fist_left: 'unicode/1f91b',
  fist_oncoming: 'unicode/1f44a',
  fist_raised: 'unicode/270a',
  fist_right: 'unicode/1f91c',
  five: 'unicode/0035-20e3',
  flags: 'unicode/1f38f',
  flashlight: 'unicode/1f526',
  fleur_de_lis: 'unicode/269c',
  flight_arrival: 'unicode/1f6ec',
  flight_departure: 'unicode/1f6eb',
  flipper: 'unicode/1f42c',
  floppy_disk: 'unicode/1f4be',
  flower_playing_cards: 'unicode/1f3b4',
  flushed: 'unicode/1f633',
  fog: 'unicode/1f32b',
  foggy: 'unicode/1f301',
  football: 'unicode/1f3c8',
  footprints: 'unicode/1f463',
  fork_and_knife: 'unicode/1f374',
  fountain: 'unicode/26f2',
  fountain_pen: 'unicode/1f58b',
  four: 'unicode/0034-20e3',
  four_leaf_clover: 'unicode/1f340',
  fox_face: 'unicode/1f98a',
  fr: 'unicode/1f1eb-1f1f7',
  framed_picture: 'unicode/1f5bc',
  free: 'unicode/1f193',
  french_guiana: 'unicode/1f1ec-1f1eb',
  french_polynesia: 'unicode/1f1f5-1f1eb',
  french_southern_territories: 'unicode/1f1f9-1f1eb',
  fried_egg: 'unicode/1f373',
  fried_shrimp: 'unicode/1f364',
  fries: 'unicode/1f35f',
  frog: 'unicode/1f438',
  frowning: 'unicode/1f626',
  frowning_face: 'unicode/2639',
  frowning_man: 'unicode/1f64d-2642',
  frowning_woman: 'unicode/1f64d',
  fu: 'unicode/1f595',
  fuelpump: 'unicode/26fd',
  full_moon: 'unicode/1f315',
  full_moon_with_face: 'unicode/1f31d',
  funeral_urn: 'unicode/26b1',
  gabon: 'unicode/1f1ec-1f1e6',
  gambia: 'unicode/1f1ec-1f1f2',
  game_die: 'unicode/1f3b2',
  gb: 'unicode/1f1ec-1f1e7',
  gear: 'unicode/2699',
  gem: 'unicode/1f48e',
  gemini: 'unicode/264a',
  georgia: 'unicode/1f1ec-1f1ea',
  ghana: 'unicode/1f1ec-1f1ed',
  ghost: 'unicode/1f47b',
  gibraltar: 'unicode/1f1ec-1f1ee',
  gift: 'unicode/1f381',
  gift_heart: 'unicode/1f49d',
  girl: 'unicode/1f467',
  globe_with_meridians: 'unicode/1f310',
  goal_net: 'unicode/1f945',
  goat: 'unicode/1f410',
  goberserk: 'goberserk',
  godmode: 'godmode',
  golf: 'unicode/26f3',
  golfing_man: 'unicode/1f3cc',
  golfing_woman: 'unicode/1f3cc-2640',
  gorilla: 'unicode/1f98d',
  grapes: 'unicode/1f347',
  greece: 'unicode/1f1ec-1f1f7',
  green_apple: 'unicode/1f34f',
  green_book: 'unicode/1f4d7',
  green_heart: 'unicode/1f49a',
  green_salad: 'unicode/1f957',
  greenland: 'unicode/1f1ec-1f1f1',
  grenada: 'unicode/1f1ec-1f1e9',
  grey_exclamation: 'unicode/2755',
  grey_question: 'unicode/2754',
  grimacing: 'unicode/1f62c',
  grin: 'unicode/1f601',
  grinning: 'unicode/1f600',
  guadeloupe: 'unicode/1f1ec-1f1f5',
  guam: 'unicode/1f1ec-1f1fa',
  guardsman: 'unicode/1f482',
  guardswoman: 'unicode/1f482-2640',
  guatemala: 'unicode/1f1ec-1f1f9',
  guernsey: 'unicode/1f1ec-1f1ec',
  guinea: 'unicode/1f1ec-1f1f3',
  guinea_bissau: 'unicode/1f1ec-1f1fc',
  guitar: 'unicode/1f3b8',
  gun: 'unicode/1f52b',
  guyana: 'unicode/1f1ec-1f1fe',
  haircut: 'unicode/1f487',
  haircut_man: 'unicode/1f487-2642',
  haircut_woman: 'unicode/1f487',
  haiti: 'unicode/1f1ed-1f1f9',
  hamburger: 'unicode/1f354',
  hammer: 'unicode/1f528',
  hammer_and_pick: 'unicode/2692',
  hammer_and_wrench: 'unicode/1f6e0',
  hamster: 'unicode/1f439',
  hand: 'unicode/270b',
  handbag: 'unicode/1f45c',
  handshake: 'unicode/1f91d',
  hankey: 'unicode/1f4a9',
  hash: 'unicode/0023-20e3',
  hatched_chick: 'unicode/1f425',
  hatching_chick: 'unicode/1f423',
  headphones: 'unicode/1f3a7',
  hear_no_evil: 'unicode/1f649',
  heart: 'unicode/2764',
  heart_decoration: 'unicode/1f49f',
  heart_eyes: 'unicode/1f60d',
  heart_eyes_cat: 'unicode/1f63b',
  heartbeat: 'unicode/1f493',
  heartpulse: 'unicode/1f497',
  hearts: 'unicode/2665',
  heavy_check_mark: 'unicode/2714',
  heavy_division_sign: 'unicode/2797',
  heavy_dollar_sign: 'unicode/1f4b2',
  heavy_exclamation_mark: 'unicode/2757',
  heavy_heart_exclamation: 'unicode/2763',
  heavy_minus_sign: 'unicode/2796',
  heavy_multiplication_x: 'unicode/2716',
  heavy_plus_sign: 'unicode/2795',
  helicopter: 'unicode/1f681',
  herb: 'unicode/1f33f',
  hibiscus: 'unicode/1f33a',
  high_brightness: 'unicode/1f506',
  high_heel: 'unicode/1f460',
  hocho: 'unicode/1f52a',
  hole: 'unicode/1f573',
  honduras: 'unicode/1f1ed-1f1f3',
  honey_pot: 'unicode/1f36f',
  honeybee: 'unicode/1f41d',
  hong_kong: 'unicode/1f1ed-1f1f0',
  horse: 'unicode/1f434',
  horse_racing: 'unicode/1f3c7',
  hospital: 'unicode/1f3e5',
  hot_pepper: 'unicode/1f336',
  hotdog: 'unicode/1f32d',
  hotel: 'unicode/1f3e8',
  hotsprings: 'unicode/2668',
  hourglass: 'unicode/231b',
  hourglass_flowing_sand: 'unicode/23f3',
  house: 'unicode/1f3e0',
  house_with_garden: 'unicode/1f3e1',
  houses: 'unicode/1f3d8',
  hugs: 'unicode/1f917',
  hungary: 'unicode/1f1ed-1f1fa',
  hurtrealbad: 'hurtrealbad',
  hushed: 'unicode/1f62f',
  ice_cream: 'unicode/1f368',
  ice_hockey: 'unicode/1f3d2',
  ice_skate: 'unicode/26f8',
  icecream: 'unicode/1f366',
  iceland: 'unicode/1f1ee-1f1f8',
  id: 'unicode/1f194',
  ideograph_advantage: 'unicode/1f250',
  imp: 'unicode/1f47f',
  inbox_tray: 'unicode/1f4e5',
  incoming_envelope: 'unicode/1f4e8',
  india: 'unicode/1f1ee-1f1f3',
  indonesia: 'unicode/1f1ee-1f1e9',
  information_desk_person: 'unicode/1f481',
  information_source: 'unicode/2139',
  innocent: 'unicode/1f607',
  interrobang: 'unicode/2049',
  iphone: 'unicode/1f4f1',
  iran: 'unicode/1f1ee-1f1f7',
  iraq: 'unicode/1f1ee-1f1f6',
  ireland: 'unicode/1f1ee-1f1ea',
  isle_of_man: 'unicode/1f1ee-1f1f2',
  israel: 'unicode/1f1ee-1f1f1',
  it: 'unicode/1f1ee-1f1f9',
  izakaya_lantern: 'unicode/1f3ee',
  jack_o_lantern: 'unicode/1f383',
  jamaica: 'unicode/1f1ef-1f1f2',
  japan: 'unicode/1f5fe',
  japanese_castle: 'unicode/1f3ef',
  japanese_goblin: 'unicode/1f47a',
  japanese_ogre: 'unicode/1f479',
  jeans: 'unicode/1f456',
  jersey: 'unicode/1f1ef-1f1ea',
  jordan: 'unicode/1f1ef-1f1f4',
  joy: 'unicode/1f602',
  joy_cat: 'unicode/1f639',
  joystick: 'unicode/1f579',
  jp: 'unicode/1f1ef-1f1f5',
  kaaba: 'unicode/1f54b',
  kazakhstan: 'unicode/1f1f0-1f1ff',
  kenya: 'unicode/1f1f0-1f1ea',
  key: 'unicode/1f511',
  keyboard: 'unicode/2328',
  keycap_ten: 'unicode/1f51f',
  kick_scooter: 'unicode/1f6f4',
  kimono: 'unicode/1f458',
  kiribati: 'unicode/1f1f0-1f1ee',
  kiss: 'unicode/1f48b',
  kissing: 'unicode/1f617',
  kissing_cat: 'unicode/1f63d',
  kissing_closed_eyes: 'unicode/1f61a',
  kissing_heart: 'unicode/1f618',
  kissing_smiling_eyes: 'unicode/1f619',
  kiwi_fruit: 'unicode/1f95d',
  knife: 'unicode/1f52a',
  koala: 'unicode/1f428',
  koko: 'unicode/1f201',
  kosovo: 'unicode/1f1fd-1f1f0',
  kr: 'unicode/1f1f0-1f1f7',
  kuwait: 'unicode/1f1f0-1f1fc',
  kyrgyzstan: 'unicode/1f1f0-1f1ec',
  label: 'unicode/1f3f7',
  lantern: 'unicode/1f3ee',
  laos: 'unicode/1f1f1-1f1e6',
  large_blue_circle: 'unicode/1f535',
  large_blue_diamond: 'unicode/1f537',
  large_orange_diamond: 'unicode/1f536',
  last_quarter_moon: 'unicode/1f317',
  last_quarter_moon_with_face: 'unicode/1f31c',
  latin_cross: 'unicode/271d',
  latvia: 'unicode/1f1f1-1f1fb',
  laughing: 'unicode/1f606',
  leaves: 'unicode/1f343',
  lebanon: 'unicode/1f1f1-1f1e7',
  ledger: 'unicode/1f4d2',
  left_luggage: 'unicode/1f6c5',
  left_right_arrow: 'unicode/2194',
  leftwards_arrow_with_hook: 'unicode/21a9',
  lemon: 'unicode/1f34b',
  leo: 'unicode/264c',
  leopard: 'unicode/1f406',
  lesotho: 'unicode/1f1f1-1f1f8',
  level_slider: 'unicode/1f39a',
  liberia: 'unicode/1f1f1-1f1f7',
  libra: 'unicode/264e',
  libya: 'unicode/1f1f1-1f1fe',
  liechtenstein: 'unicode/1f1f1-1f1ee',
  light_rail: 'unicode/1f688',
  link: 'unicode/1f517',
  lion: 'unicode/1f981',
  lips: 'unicode/1f444',
  lipstick: 'unicode/1f484',
  lithuania: 'unicode/1f1f1-1f1f9',
  lizard: 'unicode/1f98e',
  lock: 'unicode/1f512',
  lock_with_ink_pen: 'unicode/1f50f',
  lollipop: 'unicode/1f36d',
  loop: 'unicode/27bf',
  loud_sound: 'unicode/1f50a',
  loudspeaker: 'unicode/1f4e2',
  love_hotel: 'unicode/1f3e9',
  love_letter: 'unicode/1f48c',
  low_brightness: 'unicode/1f505',
  luxembourg: 'unicode/1f1f1-1f1fa',
  lying_face: 'unicode/1f925',
  m: 'unicode/24c2',
  macau: 'unicode/1f1f2-1f1f4',
  macedonia: 'unicode/1f1f2-1f1f0',
  madagascar: 'unicode/1f1f2-1f1ec',
  mag: 'unicode/1f50d',
  mag_right: 'unicode/1f50e',
  mahjong: 'unicode/1f004',
  mailbox: 'unicode/1f4eb',
  mailbox_closed: 'unicode/1f4ea',
  mailbox_with_mail: 'unicode/1f4ec',
  mailbox_with_no_mail: 'unicode/1f4ed',
  malawi: 'unicode/1f1f2-1f1fc',
  malaysia: 'unicode/1f1f2-1f1fe',
  maldives: 'unicode/1f1f2-1f1fb',
  male_detective: 'unicode/1f575',
  mali: 'unicode/1f1f2-1f1f1',
  malta: 'unicode/1f1f2-1f1f9',
  man: 'unicode/1f468',
  man_artist: 'unicode/1f468-1f3a8',
  man_astronaut: 'unicode/1f468-1f680',
  man_cartwheeling: 'unicode/1f938-2642',
  man_cook: 'unicode/1f468-1f373',
  man_dancing: 'unicode/1f57a',
  man_facepalming: 'unicode/1f926-2642',
  man_factory_worker: 'unicode/1f468-1f3ed',
  man_farmer: 'unicode/1f468-1f33e',
  man_firefighter: 'unicode/1f468-1f692',
  man_health_worker: 'unicode/1f468-2695',
  man_in_tuxedo: 'unicode/1f935',
  man_judge: 'unicode/1f468-2696',
  man_juggling: 'unicode/1f939-2642',
  man_mechanic: 'unicode/1f468-1f527',
  man_office_worker: 'unicode/1f468-1f4bc',
  man_pilot: 'unicode/1f468-2708',
  man_playing_handball: 'unicode/1f93e-2642',
  man_playing_water_polo: 'unicode/1f93d-2642',
  man_scientist: 'unicode/1f468-1f52c',
  man_shrugging: 'unicode/1f937-2642',
  man_singer: 'unicode/1f468-1f3a4',
  man_student: 'unicode/1f468-1f393',
  man_teacher: 'unicode/1f468-1f3eb',
  man_technologist: 'unicode/1f468-1f4bb',
  man_with_gua_pi_mao: 'unicode/1f472',
  man_with_turban: 'unicode/1f473',
  mandarin: 'unicode/1f34a',
  mans_shoe: 'unicode/1f45e',
  mantelpiece_clock: 'unicode/1f570',
  maple_leaf: 'unicode/1f341',
  marshall_islands: 'unicode/1f1f2-1f1ed',
  martial_arts_uniform: 'unicode/1f94b',
  martinique: 'unicode/1f1f2-1f1f6',
  mask: 'unicode/1f637',
  massage: 'unicode/1f486',
  massage_man: 'unicode/1f486-2642',
  massage_woman: 'unicode/1f486',
  mauritania: 'unicode/1f1f2-1f1f7',
  mauritius: 'unicode/1f1f2-1f1fa',
  mayotte: 'unicode/1f1fe-1f1f9',
  meat_on_bone: 'unicode/1f356',
  medal_military: 'unicode/1f396',
  medal_sports: 'unicode/1f3c5',
  mega: 'unicode/1f4e3',
  melon: 'unicode/1f348',
  memo: 'unicode/1f4dd',
  men_wrestling: 'unicode/1f93c-2642',
  menorah: 'unicode/1f54e',
  mens: 'unicode/1f6b9',
  metal: 'unicode/1f918',
  metro: 'unicode/1f687',
  mexico: 'unicode/1f1f2-1f1fd',
  micronesia: 'unicode/1f1eb-1f1f2',
  microphone: 'unicode/1f3a4',
  microscope: 'unicode/1f52c',
  middle_finger: 'unicode/1f595',
  milk_glass: 'unicode/1f95b',
  milky_way: 'unicode/1f30c',
  minibus: 'unicode/1f690',
  minidisc: 'unicode/1f4bd',
  mobile_phone_off: 'unicode/1f4f4',
  moldova: 'unicode/1f1f2-1f1e9',
  monaco: 'unicode/1f1f2-1f1e8',
  money_mouth_face: 'unicode/1f911',
  money_with_wings: 'unicode/1f4b8',
  moneybag: 'unicode/1f4b0',
  mongolia: 'unicode/1f1f2-1f1f3',
  monkey: 'unicode/1f412',
  monkey_face: 'unicode/1f435',
  monorail: 'unicode/1f69d',
  montenegro: 'unicode/1f1f2-1f1ea',
  montserrat: 'unicode/1f1f2-1f1f8',
  moon: 'unicode/1f314',
  morocco: 'unicode/1f1f2-1f1e6',
  mortar_board: 'unicode/1f393',
  mosque: 'unicode/1f54c',
  motor_boat: 'unicode/1f6e5',
  motor_scooter: 'unicode/1f6f5',
  motorcycle: 'unicode/1f3cd',
  motorway: 'unicode/1f6e3',
  mount_fuji: 'unicode/1f5fb',
  mountain: 'unicode/26f0',
  mountain_bicyclist: 'unicode/1f6b5',
  mountain_biking_man: 'unicode/1f6b5',
  mountain_biking_woman: 'unicode/1f6b5-2640',
  mountain_cableway: 'unicode/1f6a0',
  mountain_railway: 'unicode/1f69e',
  mountain_snow: 'unicode/1f3d4',
  mouse: 'unicode/1f42d',
  mouse2: 'unicode/1f401',
  movie_camera: 'unicode/1f3a5',
  moyai: 'unicode/1f5ff',
  mozambique: 'unicode/1f1f2-1f1ff',
  mrs_claus: 'unicode/1f936',
  muscle: 'unicode/1f4aa',
  mushroom: 'unicode/1f344',
  musical_keyboard: 'unicode/1f3b9',
  musical_note: 'unicode/1f3b5',
  musical_score: 'unicode/1f3bc',
  mute: 'unicode/1f507',
  myanmar: 'unicode/1f1f2-1f1f2',
  nail_care: 'unicode/1f485',
  name_badge: 'unicode/1f4db',
  namibia: 'unicode/1f1f3-1f1e6',
  national_park: 'unicode/1f3de',
  nauru: 'unicode/1f1f3-1f1f7',
  nauseated_face: 'unicode/1f922',
  neckbeard: 'neckbeard',
  necktie: 'unicode/1f454',
  negative_squared_cross_mark: 'unicode/274e',
  nepal: 'unicode/1f1f3-1f1f5',
  nerd_face: 'unicode/1f913',
  netherlands: 'unicode/1f1f3-1f1f1',
  neutral_face: 'unicode/1f610',
  new: 'unicode/1f195',
  new_caledonia: 'unicode/1f1f3-1f1e8',
  new_moon: 'unicode/1f311',
  new_moon_with_face: 'unicode/1f31a',
  new_zealand: 'unicode/1f1f3-1f1ff',
  newspaper: 'unicode/1f4f0',
  newspaper_roll: 'unicode/1f5de',
  next_track_button: 'unicode/23ed',
  ng: 'unicode/1f196',
  ng_man: 'unicode/1f645-2642',
  ng_woman: 'unicode/1f645',
  nicaragua: 'unicode/1f1f3-1f1ee',
  niger: 'unicode/1f1f3-1f1ea',
  nigeria: 'unicode/1f1f3-1f1ec',
  night_with_stars: 'unicode/1f303',
  nine: 'unicode/0039-20e3',
  niue: 'unicode/1f1f3-1f1fa',
  no_bell: 'unicode/1f515',
  no_bicycles: 'unicode/1f6b3',
  no_entry: 'unicode/26d4',
  no_entry_sign: 'unicode/1f6ab',
  no_good: 'unicode/1f645',
  no_good_man: 'unicode/1f645-2642',
  no_good_woman: 'unicode/1f645',
  no_mobile_phones: 'unicode/1f4f5',
  no_mouth: 'unicode/1f636',
  no_pedestrians: 'unicode/1f6b7',
  no_smoking: 'unicode/1f6ad',
  'non-potable_water': 'unicode/1f6b1',
  norfolk_island: 'unicode/1f1f3-1f1eb',
  north_korea: 'unicode/1f1f0-1f1f5',
  northern_mariana_islands: 'unicode/1f1f2-1f1f5',
  norway: 'unicode/1f1f3-1f1f4',
  nose: 'unicode/1f443',
  notebook: 'unicode/1f4d3',
  notebook_with_decorative_cover: 'unicode/1f4d4',
  notes: 'unicode/1f3b6',
  nut_and_bolt: 'unicode/1f529',
  o: 'unicode/2b55',
  o2: 'unicode/1f17e',
  ocean: 'unicode/1f30a',
  octocat: 'octocat',
  octopus: 'unicode/1f419',
  oden: 'unicode/1f362',
  office: 'unicode/1f3e2',
  oil_drum: 'unicode/1f6e2',
  ok: 'unicode/1f197',
  ok_hand: 'unicode/1f44c',
  ok_man: 'unicode/1f646-2642',
  ok_woman: 'unicode/1f646',
  old_key: 'unicode/1f5dd',
  older_man: 'unicode/1f474',
  older_woman: 'unicode/1f475',
  om: 'unicode/1f549',
  oman: 'unicode/1f1f4-1f1f2',
  on: 'unicode/1f51b',
  oncoming_automobile: 'unicode/1f698',
  oncoming_bus: 'unicode/1f68d',
  oncoming_police_car: 'unicode/1f694',
  oncoming_taxi: 'unicode/1f696',
  one: 'unicode/0031-20e3',
  open_book: 'unicode/1f4d6',
  open_file_folder: 'unicode/1f4c2',
  open_hands: 'unicode/1f450',
  open_mouth: 'unicode/1f62e',
  open_umbrella: 'unicode/2602',
  ophiuchus: 'unicode/26ce',
  orange: 'unicode/1f34a',
  orange_book: 'unicode/1f4d9',
  orthodox_cross: 'unicode/2626',
  outbox_tray: 'unicode/1f4e4',
  owl: 'unicode/1f989',
  ox: 'unicode/1f402',
  package: 'unicode/1f4e6',
  page_facing_up: 'unicode/1f4c4',
  page_with_curl: 'unicode/1f4c3',
  pager: 'unicode/1f4df',
  paintbrush: 'unicode/1f58c',
  pakistan: 'unicode/1f1f5-1f1f0',
  palau: 'unicode/1f1f5-1f1fc',
  palestinian_territories: 'unicode/1f1f5-1f1f8',
  palm_tree: 'unicode/1f334',
  panama: 'unicode/1f1f5-1f1e6',
  pancakes: 'unicode/1f95e',
  panda_face: 'unicode/1f43c',
  paperclip: 'unicode/1f4ce',
  paperclips: 'unicode/1f587',
  papua_new_guinea: 'unicode/1f1f5-1f1ec',
  paraguay: 'unicode/1f1f5-1f1fe',
  parasol_on_ground: 'unicode/26f1',
  parking: 'unicode/1f17f',
  part_alternation_mark: 'unicode/303d',
  partly_sunny: 'unicode/26c5',
  passenger_ship: 'unicode/1f6f3',
  passport_control: 'unicode/1f6c2',
  pause_button: 'unicode/23f8',
  paw_prints: 'unicode/1f43e',
  peace_symbol: 'unicode/262e',
  peach: 'unicode/1f351',
  peanuts: 'unicode/1f95c',
  pear: 'unicode/1f350',
  pen: 'unicode/1f58a',
  pencil: 'unicode/1f4dd',
  pencil2: 'unicode/270f',
  penguin: 'unicode/1f427',
  pensive: 'unicode/1f614',
  performing_arts: 'unicode/1f3ad',
  persevere: 'unicode/1f623',
  person_fencing: 'unicode/1f93a',
  person_frowning: 'unicode/1f64d',
  person_with_blond_hair: 'unicode/1f471',
  person_with_pouting_face: 'unicode/1f64e',
  peru: 'unicode/1f1f5-1f1ea',
  philippines: 'unicode/1f1f5-1f1ed',
  phone: 'unicode/260e',
  pick: 'unicode/26cf',
  pig: 'unicode/1f437',
  pig2: 'unicode/1f416',
  pig_nose: 'unicode/1f43d',
  pill: 'unicode/1f48a',
  pineapple: 'unicode/1f34d',
  ping_pong: 'unicode/1f3d3',
  pisces: 'unicode/2653',
  pitcairn_islands: 'unicode/1f1f5-1f1f3',
  pizza: 'unicode/1f355',
  place_of_worship: 'unicode/1f6d0',
  plate_with_cutlery: 'unicode/1f37d',
  play_or_pause_button: 'unicode/23ef',
  point_down: 'unicode/1f447',
  point_left: 'unicode/1f448',
  point_right: 'unicode/1f449',
  point_up: 'unicode/261d',
  point_up_2: 'unicode/1f446',
  poland: 'unicode/1f1f5-1f1f1',
  police_car: 'unicode/1f693',
  policeman: 'unicode/1f46e',
  policewoman: 'unicode/1f46e-2640',
  poodle: 'unicode/1f429',
  poop: 'unicode/1f4a9',
  popcorn: 'unicode/1f37f',
  portugal: 'unicode/1f1f5-1f1f9',
  post_office: 'unicode/1f3e3',
  postal_horn: 'unicode/1f4ef',
  postbox: 'unicode/1f4ee',
  potable_water: 'unicode/1f6b0',
  potato: 'unicode/1f954',
  pouch: 'unicode/1f45d',
  poultry_leg: 'unicode/1f357',
  pound: 'unicode/1f4b7',
  pout: 'unicode/1f621',
  pouting_cat: 'unicode/1f63e',
  pouting_man: 'unicode/1f64e-2642',
  pouting_woman: 'unicode/1f64e',
  pray: 'unicode/1f64f',
  prayer_beads: 'unicode/1f4ff',
  pregnant_woman: 'unicode/1f930',
  previous_track_button: 'unicode/23ee',
  prince: 'unicode/1f934',
  princess: 'unicode/1f478',
  printer: 'unicode/1f5a8',
  puerto_rico: 'unicode/1f1f5-1f1f7',
  punch: 'unicode/1f44a',
  purple_heart: 'unicode/1f49c',
  purse: 'unicode/1f45b',
  pushpin: 'unicode/1f4cc',
  put_litter_in_its_place: 'unicode/1f6ae',
  qatar: 'unicode/1f1f6-1f1e6',
  question: 'unicode/2753',
  rabbit: 'unicode/1f430',
  rabbit2: 'unicode/1f407',
  racehorse: 'unicode/1f40e',
  racing_car: 'unicode/1f3ce',
  radio: 'unicode/1f4fb',
  radio_button: 'unicode/1f518',
  radioactive: 'unicode/2622',
  rage: 'unicode/1f621',
  rage1: 'rage1',
  rage2: 'rage2',
  rage3: 'rage3',
  rage4: 'rage4',
  railway_car: 'unicode/1f683',
  railway_track: 'unicode/1f6e4',
  rainbow: 'unicode/1f308',
  rainbow_flag: 'unicode/1f3f3-1f308',
  raised_back_of_hand: 'unicode/1f91a',
  raised_hand: 'unicode/270b',
  raised_hand_with_fingers_splayed: 'unicode/1f590',
  raised_hands: 'unicode/1f64c',
  raising_hand: 'unicode/1f64b',
  raising_hand_man: 'unicode/1f64b-2642',
  raising_hand_woman: 'unicode/1f64b',
  ram: 'unicode/1f40f',
  ramen: 'unicode/1f35c',
  rat: 'unicode/1f400',
  record_button: 'unicode/23fa',
  recycle: 'unicode/267b',
  red_car: 'unicode/1f697',
  red_circle: 'unicode/1f534',
  registered: 'unicode/00ae',
  relaxed: 'unicode/263a',
  relieved: 'unicode/1f60c',
  reminder_ribbon: 'unicode/1f397',
  repeat: 'unicode/1f501',
  repeat_one: 'unicode/1f502',
  rescue_worker_helmet: 'unicode/26d1',
  restroom: 'unicode/1f6bb',
  reunion: 'unicode/1f1f7-1f1ea',
  revolving_hearts: 'unicode/1f49e',
  rewind: 'unicode/23ea',
  rhinoceros: 'unicode/1f98f',
  ribbon: 'unicode/1f380',
  rice: 'unicode/1f35a',
  rice_ball: 'unicode/1f359',
  rice_cracker: 'unicode/1f358',
  rice_scene: 'unicode/1f391',
  right_anger_bubble: 'unicode/1f5ef',
  ring: 'unicode/1f48d',
  robot: 'unicode/1f916',
  rocket: 'unicode/1f680',
  rofl: 'unicode/1f923',
  roll_eyes: 'unicode/1f644',
  roller_coaster: 'unicode/1f3a2',
  romania: 'unicode/1f1f7-1f1f4',
  rooster: 'unicode/1f413',
  rose: 'unicode/1f339',
  rosette: 'unicode/1f3f5',
  rotating_light: 'unicode/1f6a8',
  round_pushpin: 'unicode/1f4cd',
  rowboat: 'unicode/1f6a3',
  rowing_man: 'unicode/1f6a3',
  rowing_woman: 'unicode/1f6a3-2640',
  ru: 'unicode/1f1f7-1f1fa',
  rugby_football: 'unicode/1f3c9',
  runner: 'unicode/1f3c3',
  running: 'unicode/1f3c3',
  running_man: 'unicode/1f3c3',
  running_shirt_with_sash: 'unicode/1f3bd',
  running_woman: 'unicode/1f3c3-2640',
  rwanda: 'unicode/1f1f7-1f1fc',
  sa: 'unicode/1f202',
  sagittarius: 'unicode/2650',
  sailboat: 'unicode/26f5',
  sake: 'unicode/1f376',
  samoa: 'unicode/1f1fc-1f1f8',
  san_marino: 'unicode/1f1f8-1f1f2',
  sandal: 'unicode/1f461',
  santa: 'unicode/1f385',
  sao_tome_principe: 'unicode/1f1f8-1f1f9',
  satellite: 'unicode/1f4e1',
  satisfied: 'unicode/1f606',
  saudi_arabia: 'unicode/1f1f8-1f1e6',
  saxophone: 'unicode/1f3b7',
  school: 'unicode/1f3eb',
  school_satchel: 'unicode/1f392',
  scissors: 'unicode/2702',
  scorpion: 'unicode/1f982',
  scorpius: 'unicode/264f',
  scream: 'unicode/1f631',
  scream_cat: 'unicode/1f640',
  scroll: 'unicode/1f4dc',
  seat: 'unicode/1f4ba',
  secret: 'unicode/3299',
  see_no_evil: 'unicode/1f648',
  seedling: 'unicode/1f331',
  selfie: 'unicode/1f933',
  senegal: 'unicode/1f1f8-1f1f3',
  serbia: 'unicode/1f1f7-1f1f8',
  seven: 'unicode/0037-20e3',
  seychelles: 'unicode/1f1f8-1f1e8',
  shallow_pan_of_food: 'unicode/1f958',
  shamrock: 'unicode/2630',
  shark: 'unicode/1f988',
  shaved_ice: 'unicode/1f367',
  sheep: 'unicode/1f411',
  shell: 'unicode/1f41a',
  shield: 'unicode/1f6e1',
  shinto_shrine: 'unicode/26e9',
  ship: 'unicode/1f6a2',
  shipit: 'shipit',
  shirt: 'unicode/1f455',
  shit: 'unicode/1f4a9',
  shoe: 'unicode/1f45e',
  shopping: 'unicode/1f6cd',
  shopping_cart: 'unicode/1f6d2',
  shower: 'unicode/1f6bf',
  shrimp: 'unicode/1f990',
  sierra_leone: 'unicode/1f1f8-1f1f1',
  signal_strength: 'unicode/1f4f6',
  singapore: 'unicode/1f1f8-1f1ec',
  sint_maarten: 'unicode/1f1f8-1f1fd',
  six: 'unicode/0036-20e3',
  six_pointed_star: 'unicode/1f52f',
  ski: 'unicode/1f3bf',
  skier: 'unicode/26f7',
  skull: 'unicode/1f480',
  skull_and_crossbones: 'unicode/2620',
  sleeping: 'unicode/1f634',
  sleeping_bed: 'unicode/1f6cc',
  sleepy: 'unicode/1f62a',
  slightly_frowning_face: 'unicode/1f641',
  slightly_smiling_face: 'unicode/1f642',
  slot_machine: 'unicode/1f3b0',
  slovakia: 'unicode/1f1f8-1f1f0',
  slovenia: 'unicode/1f1f8-1f1ee',
  small_airplane: 'unicode/1f6e9',
  small_blue_diamond: 'unicode/1f539',
  small_orange_diamond: 'unicode/1f538',
  small_red_triangle: 'unicode/1f53a',
  small_red_triangle_down: 'unicode/1f53b',
  smile: 'unicode/1f604',
  smile_cat: 'unicode/1f638',
  smiley: 'unicode/1f603',
  smiley_cat: 'unicode/1f63a',
  smiling_imp: 'unicode/1f608',
  smirk: 'unicode/1f60f',
  smirk_cat: 'unicode/1f63c',
  smoking: 'unicode/1f6ac',
  snail: 'unicode/1f40c',
  snake: 'unicode/1f40d',
  sneezing_face: 'unicode/1f927',
  snowboarder: 'unicode/1f3c2',
  snowflake: 'unicode/2744',
  snowman: 'unicode/26c4',
  snowman_with_snow: 'unicode/2603',
  sob: 'unicode/1f62d',
  soccer: 'unicode/26bd',
  solomon_islands: 'unicode/1f1f8-1f1e7',
  somalia: 'unicode/1f1f8-1f1f4',
  soon: 'unicode/1f51c',
  sos: 'unicode/1f198',
  sound: 'unicode/1f509',
  south_africa: 'unicode/1f1ff-1f1e6',
  south_georgia_south_sandwich_islands: 'unicode/1f1ec-1f1f8',
  south_sudan: 'unicode/1f1f8-1f1f8',
  space_invader: 'unicode/1f47e',
  spades: 'unicode/2660',
  spaghetti: 'unicode/1f35d',
  sparkle: 'unicode/2747',
  sparkler: 'unicode/1f387',
  sparkles: 'unicode/2728',
  sparkling_heart: 'unicode/1f496',
  speak_no_evil: 'unicode/1f64a',
  speaker: 'unicode/1f508',
  speaking_head: 'unicode/1f5e3',
  speech_balloon: 'unicode/1f4ac',
  speedboat: 'unicode/1f6a4',
  spider: 'unicode/1f577',
  spider_web: 'unicode/1f578',
  spiral_calendar: 'unicode/1f5d3',
  spiral_notepad: 'unicode/1f5d2',
  spoon: 'unicode/1f944',
  squid: 'unicode/1f991',
  squirrel: 'shipit',
  sri_lanka: 'unicode/1f1f1-1f1f0',
  st_barthelemy: 'unicode/1f1e7-1f1f1',
  st_helena: 'unicode/1f1f8-1f1ed',
  st_kitts_nevis: 'unicode/1f1f0-1f1f3',
  st_lucia: 'unicode/1f1f1-1f1e8',
  st_pierre_miquelon: 'unicode/1f1f5-1f1f2',
  st_vincent_grenadines: 'unicode/1f1fb-1f1e8',
  stadium: 'unicode/1f3df',
  star: 'unicode/2b50',
  star2: 'unicode/1f31f',
  star_and_crescent: 'unicode/262a',
  star_of_david: 'unicode/2721',
  stars: 'unicode/1f320',
  station: 'unicode/1f689',
  statue_of_liberty: 'unicode/1f5fd',
  steam_locomotive: 'unicode/1f682',
  stew: 'unicode/1f372',
  stop_button: 'unicode/23f9',
  stop_sign: 'unicode/1f6d1',
  stopwatch: 'unicode/23f1',
  straight_ruler: 'unicode/1f4cf',
  strawberry: 'unicode/1f353',
  stuck_out_tongue: 'unicode/1f61b',
  stuck_out_tongue_closed_eyes: 'unicode/1f61d',
  stuck_out_tongue_winking_eye: 'unicode/1f61c',
  studio_microphone: 'unicode/1f399',
  stuffed_flatbread: 'unicode/1f959',
  sudan: 'unicode/1f1f8-1f1e9',
  sun_behind_large_cloud: 'unicode/1f325',
  sun_behind_rain_cloud: 'unicode/1f326',
  sun_behind_small_cloud: 'unicode/1f324',
  sun_with_face: 'unicode/1f31e',
  sunflower: 'unicode/1f33b',
  sunglasses: 'unicode/1f60e',
  sunny: 'unicode/2600',
  sunrise: 'unicode/1f305',
  sunrise_over_mountains: 'unicode/1f304',
  surfer: 'unicode/1f3c4',
  surfing_man: 'unicode/1f3c4',
  surfing_woman: 'unicode/1f3c4-2640',
  suriname: 'unicode/1f1f8-1f1f7',
  sushi: 'unicode/1f363',
  suspect: 'suspect',
  suspension_railway: 'unicode/1f69f',
  swaziland: 'unicode/1f1f8-1f1ff',
  sweat: 'unicode/1f613',
  sweat_drops: 'unicode/1f4a6',
  sweat_smile: 'unicode/1f605',
  sweden: 'unicode/1f1f8-1f1ea',
  sweet_potato: 'unicode/1f360',
  swimmer: 'unicode/1f3ca',
  swimming_man: 'unicode/1f3ca',
  swimming_woman: 'unicode/1f3ca-2640',
  switzerland: 'unicode/1f1e8-1f1ed',
  symbols: 'unicode/1f523',
  synagogue: 'unicode/1f54d',
  syria: 'unicode/1f1f8-1f1fe',
  syringe: 'unicode/1f489',
  taco: 'unicode/1f32e',
  tada: 'unicode/1f389',
  taiwan: 'unicode/1f1f9-1f1fc',
  tajikistan: 'unicode/1f1f9-1f1ef',
  tanabata_tree: 'unicode/1f38b',
  tangerine: 'unicode/1f34a',
  tanzania: 'unicode/1f1f9-1f1ff',
  taurus: 'unicode/2649',
  taxi: 'unicode/1f695',
  tea: 'unicode/1f375',
  telephone: 'unicode/260e',
  telephone_receiver: 'unicode/1f4de',
  telescope: 'unicode/1f52d',
  tennis: 'unicode/1f3be',
  tent: 'unicode/26fa',
  thailand: 'unicode/1f1f9-1f1ed',
  thermometer: 'unicode/1f321',
  thinking: 'unicode/1f914',
  thought_balloon: 'unicode/1f4ad',
  three: 'unicode/0033-20e3',
  thumbsdown: 'unicode/1f44e',
  thumbsup: 'unicode/1f44d',
  ticket: 'unicode/1f3ab',
  tickets: 'unicode/1f39f',
  tiger: 'unicode/1f42f',
  tiger2: 'unicode/1f405',
  timer_clock: 'unicode/23f2',
  timor_leste: 'unicode/1f1f9-1f1f1',
  tipping_hand_man: 'unicode/1f481-2642',
  tipping_hand_woman: 'unicode/1f481',
  tired_face: 'unicode/1f62b',
  tm: 'unicode/2122',
  togo: 'unicode/1f1f9-1f1ec',
  toilet: 'unicode/1f6bd',
  tokelau: 'unicode/1f1f9-1f1f0',
  tokyo_tower: 'unicode/1f5fc',
  tomato: 'unicode/1f345',
  tonga: 'unicode/1f1f9-1f1f4',
  tongue: 'unicode/1f445',
  top: 'unicode/1f51d',
  tophat: 'unicode/1f3a9',
  tornado: 'unicode/1f32a',
  tr: 'unicode/1f1f9-1f1f7',
  trackball: 'unicode/1f5b2',
  tractor: 'unicode/1f69c',
  traffic_light: 'unicode/1f6a5',
  train: 'unicode/1f68b',
  train2: 'unicode/1f686',
  tram: 'unicode/1f68a',
  triangular_flag_on_post: 'unicode/1f6a9',
  triangular_ruler: 'unicode/1f4d0',
  trident: 'unicode/1f531',
  trinidad_tobago: 'unicode/1f1f9-1f1f9',
  triumph: 'unicode/1f624',
  trolleybus: 'unicode/1f68e',
  trollface: 'trollface',
  trophy: 'unicode/1f3c6',
  tropical_drink: 'unicode/1f379',
  tropical_fish: 'unicode/1f420',
  truck: 'unicode/1f69a',
  trumpet: 'unicode/1f3ba',
  tshirt: 'unicode/1f455',
  tulip: 'unicode/1f337',
  tumbler_glass: 'unicode/1f943',
  tunisia: 'unicode/1f1f9-1f1f3',
  turkey: 'unicode/1f983',
  turkmenistan: 'unicode/1f1f9-1f1f2',
  turks_caicos_islands: 'unicode/1f1f9-1f1e8',
  turtle: 'unicode/1f422',
  tuvalu: 'unicode/1f1f9-1f1fb',
  tv: 'unicode/1f4fa',
  twisted_rightwards_arrows: 'unicode/1f500',
  two: 'unicode/0032-20e3',
  two_hearts: 'unicode/1f495',
  two_men_holding_hands: 'unicode/1f46c',
  two_women_holding_hands: 'unicode/1f46d',
  u5272: 'unicode/1f239',
  u5408: 'unicode/1f234',
  u55b6: 'unicode/1f23a',
  u6307: 'unicode/1f22f',
  u6708: 'unicode/1f237',
  u6709: 'unicode/1f236',
  u6e80: 'unicode/1f235',
  u7121: 'unicode/1f21a',
  u7533: 'unicode/1f238',
  u7981: 'unicode/1f232',
  u7a7a: 'unicode/1f233',
  uganda: 'unicode/1f1fa-1f1ec',
  uk: 'unicode/1f1ec-1f1e7',
  ukraine: 'unicode/1f1fa-1f1e6',
  umbrella: 'unicode/2614',
  unamused: 'unicode/1f612',
  underage: 'unicode/1f51e',
  unicorn: 'unicode/1f984',
  united_arab_emirates: 'unicode/1f1e6-1f1ea',
  unlock: 'unicode/1f513',
  up: 'unicode/1f199',
  upside_down_face: 'unicode/1f643',
  uruguay: 'unicode/1f1fa-1f1fe',
  us: 'unicode/1f1fa-1f1f8',
  us_virgin_islands: 'unicode/1f1fb-1f1ee',
  uzbekistan: 'unicode/1f1fa-1f1ff',
  v: 'unicode/270c',
  vanuatu: 'unicode/1f1fb-1f1fa',
  vatican_city: 'unicode/1f1fb-1f1e6',
  venezuela: 'unicode/1f1fb-1f1ea',
  vertical_traffic_light: 'unicode/1f6a6',
  vhs: 'unicode/1f4fc',
  vibration_mode: 'unicode/1f4f3',
  video_camera: 'unicode/1f4f9',
  video_game: 'unicode/1f3ae',
  vietnam: 'unicode/1f1fb-1f1f3',
  violin: 'unicode/1f3bb',
  virgo: 'unicode/264d',
  volcano: 'unicode/1f30b',
  volleyball: 'unicode/1f3d0',
  vs: 'unicode/1f19a',
  vulcan_salute: 'unicode/1f596',
  walking: 'unicode/1f6b6',
  walking_man: 'unicode/1f6b6',
  walking_woman: 'unicode/1f6b6-2640',
  wallis_futuna: 'unicode/1f1fc-1f1eb',
  waning_crescent_moon: 'unicode/1f318',
  waning_gibbous_moon: 'unicode/1f316',
  warning: 'unicode/26a0',
  wastebasket: 'unicode/1f5d1',
  watch: 'unicode/231a',
  water_buffalo: 'unicode/1f403',
  watermelon: 'unicode/1f349',
  wave: 'unicode/1f44b',
  wavy_dash: 'unicode/3030',
  waxing_crescent_moon: 'unicode/1f312',
  waxing_gibbous_moon: 'unicode/1f314',
  wc: 'unicode/1f6be',
  weary: 'unicode/1f629',
  wedding: 'unicode/1f492',
  weight_lifting_man: 'unicode/1f3cb',
  weight_lifting_woman: 'unicode/1f3cb-2640',
  western_sahara: 'unicode/1f1ea-1f1ed',
  whale: 'unicode/1f433',
  whale2: 'unicode/1f40b',
  wheel_of_dharma: 'unicode/2638',
  wheelchair: 'unicode/267f',
  white_check_mark: 'unicode/2705',
  white_circle: 'unicode/26aa',
  white_flag: 'unicode/1f3f3',
  white_flower: 'unicode/1f4ae',
  white_large_square: 'unicode/2b1c',
  white_medium_small_square: 'unicode/25fd',
  white_medium_square: 'unicode/25fb',
  white_small_square: 'unicode/25ab',
  white_square_button: 'unicode/1f533',
  wilted_flower: 'unicode/1f940',
  wind_chime: 'unicode/1f390',
  wind_face: 'unicode/1f32c',
  wine_glass: 'unicode/1f377',
  wink: 'unicode/1f609',
  wolf: 'unicode/1f43a',
  woman: 'unicode/1f469',
  woman_artist: 'unicode/1f469-1f3a8',
  woman_astronaut: 'unicode/1f469-1f680',
  woman_cartwheeling: 'unicode/1f938-2640',
  woman_cook: 'unicode/1f469-1f373',
  woman_facepalming: 'unicode/1f926-2640',
  woman_factory_worker: 'unicode/1f469-1f3ed',
  woman_farmer: 'unicode/1f469-1f33e',
  woman_firefighter: 'unicode/1f469-1f692',
  woman_health_worker: 'unicode/1f469-2695',
  woman_judge: 'unicode/1f469-2696',
  woman_juggling: 'unicode/1f939-2640',
  woman_mechanic: 'unicode/1f469-1f527',
  woman_office_worker: 'unicode/1f469-1f4bc',
  woman_pilot: 'unicode/1f469-2708',
  woman_playing_handball: 'unicode/1f93e-2640',
  woman_playing_water_polo: 'unicode/1f93d-2640',
  woman_scientist: 'unicode/1f469-1f52c',
  woman_shrugging: 'unicode/1f937-2640',
  woman_singer: 'unicode/1f469-1f3a4',
  woman_student: 'unicode/1f469-1f393',
  woman_teacher: 'unicode/1f469-1f3eb',
  woman_technologist: 'unicode/1f469-1f4bb',
  woman_with_turban: 'unicode/1f473-2640',
  womans_clothes: 'unicode/1f45a',
  womans_hat: 'unicode/1f452',
  women_wrestling: 'unicode/1f93c-2640',
  womens: 'unicode/1f6ba',
  world_map: 'unicode/1f5fa',
  worried: 'unicode/1f61f',
  wrench: 'unicode/1f527',
  writing_hand: 'unicode/270d',
  x: 'unicode/274c',
  yellow_heart: 'unicode/1f49b',
  yemen: 'unicode/1f1fe-1f1ea',
  yen: 'unicode/1f4b4',
  yin_yang: 'unicode/262f',
  yum: 'unicode/1f60b',
  zambia: 'unicode/1f1ff-1f1f2',
  zap: 'unicode/26a1',
  zero: 'unicode/0030-20e3',
  zimbabwe: 'unicode/1f1ff-1f1fc',
  zipper_mouth_face: 'unicode/1f910',
  zzz: 'unicode/1f4a4',
}

const suffixes = _.invert(emojis)

export type GitHubEmoji = keyof typeof emojis

export function isEmojiValid(emoji: GitHubEmoji) {
  if (!emoji) return false
  return !!(emojis as any)[emoji]
}

export function getEmojiImageURL(emoji: GitHubEmoji) {
  if (!isEmojiValid(emoji)) return undefined
  return `https://github.githubassets.com/images/icons/emoji/${
    (emojis as any)[emoji]
  }.png?v8`
}

export function getEmojiImageURLForHex(hex: string | undefined) {
  if (!hex) return undefined
  if (!suffixes[`unicode/${hex}`]) return

  return `https://github.githubassets.com/images/icons/emoji/unicode/${hex}.png?v8`
}

export interface EmojiParseOptions {
  after?: React.ReactNode
  alt?: string
  before?: React.ReactNode
  imageProps?: Omit<ImageProps, 'source'>
  key?: string
  shouldStripEmojis?: boolean
}

function getComponent(
  emojiImageURL: string | undefined,
  { alt, before, after, imageProps, shouldStripEmojis }: EmojiParseOptions,
): React.ReactNode {
  if (shouldStripEmojis) return null
  if (!emojiImageURL) return null

  return (
    <>
      {before}
      <Image
        accessibilityLabel={alt}
        {...imageProps}
        source={{ uri: emojiImageURL }}
      />
      {after}
    </>
  )
}

function parseTextWithEmojisToReactComponents_1(
  text: string,
  options: EmojiParseOptions,
) {
  const pattern = /:[a-z0-9_\+]+:/

  return genericParseText(text, pattern, match => {
    const emoji = match.slice(1, -1)
    const emojiImageURL = getEmojiImageURL(emoji as GitHubEmoji)
    return emojiImageURL
      ? getComponent(emojiImageURL, { alt: emoji, ...options })
      : match
  })
}

function parseTextWithEmojisToReactComponents_2(
  text: string,
  options: EmojiParseOptions,
) {
  const pattern = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?(?:\u200d(?:[^\ud800-\udfff]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?)*/

  return genericParseText(text, pattern, match => {
    const codePoint1 = match.codePointAt(0)
    const codePoint2 =
      match.length > 2 ? match.codePointAt(match.length - 2) : undefined

    const hex1 = codePoint1 ? codePoint1.toString(16) : undefined
    const hex2 = codePoint2 ? codePoint2.toString(16) : undefined
    const hex = hex1 && hex2 ? `${hex1}-${hex2}` : hex1 || hex2

    const emojiImageURL = getEmojiImageURLForHex(hex)
    return emojiImageURL ? getComponent(emojiImageURL, options) : match
  })
}

export function parseTextWithEmojisToReactComponents(
  text: string,
  options: EmojiParseOptions,
) {
  if (!(text && typeof text === 'string')) return [text].filter(Boolean)

  return _.flatMap(
    parseTextWithEmojisToReactComponents_1(text, options).map(item => {
      if (typeof item !== 'string') return item
      return parseTextWithEmojisToReactComponents_2(item, options)
    }),
  ).map(
    (options.key &&
      ((item, index) => (
        <Fragment key={`${options.key}-${index}`}>{item}</Fragment>
      ))) ||
      (item => item),
  )
}

export function stripEmojis(text: string): string {
  return parseTextWithEmojisToReactComponents(text, { shouldStripEmojis: true })
    .join(' ')
    .replace(/\s\s/g, ' ')
    .trim()
}
