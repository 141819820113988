import React from 'react'
import { View } from 'react-native'
import { sharedStyles } from '../../styles/shared'
import { contentPadding, scaleFactor } from '../../styles/variables'
import { Spacer } from '../common/Spacer'
import { ThemedText } from '../themed/ThemedText'
import { ContactView } from './ContactView'

export const UpgradeDeviceModelView = () => (
  <View
    style={[
      sharedStyles.vertical,
      sharedStyles.fullWidth,
      sharedStyles.padding,
    ]}
    pointerEvents="box-none"
  >
    <ThemedText
      color="foregroundColorMuted65"
      style={[
        {
          fontSize: 15 * scaleFactor,
          fontWeight: '100',
        },
      ]}
    >
      {`Quý khách vui lòng liên hệ với công ty hoặc đại lý của Lazico để nâng cấp thêm tính năng đầu dò, cụ thể:\n`}
    </ThemedText>
    <Spacer height={contentPadding / 2} />
    <ThemedText
      color="foregroundColorMuted65"
      style={[
        {
          fontSize: 20 * scaleFactor,
          fontWeight: '200',
        },
      ]}
    >
      {`1. Giám sát các sự cố như báo mất nước, bơm nước khi bể cạn, báo áp suất, chống trộm, báo nhiệt độ, độ ẩm...`}
    </ThemedText>
    <Spacer height={contentPadding / 3} />
    <ThemedText
      color="foregroundColorMuted65"
      style={[
        {
          fontSize: 20 * scaleFactor,
          fontWeight: '200',
        },
      ]}
    >
      {`2. Xử lý tự động khi có sự cố trên...`}
    </ThemedText>
    <Spacer height={contentPadding} />
    <ContactView title={`Chi tiết trên`} />
  </View>
)
