import { EnhancedDevice, IDict } from '@devhub/core/dist'
import { Avatar } from '@ui-kitten/components'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { View } from 'react-native'
import { DEVICES_CONFIG, TimeSeriesValue } from '../../stores/DeviceConfig'
import { RootStoreContext } from '../../stores/RootStore'
import { commonCardStyles, getActiveColor, sharedStyles } from '../../styles/shared'
import { A_MONTH } from '../../utils/constants'
import { useTheme } from '../context/ThemeContext'
import { TimeAgo } from '../elements/TimeAgo'
import { ThemedText } from '../themed/ThemedText'
import { BaseHistoryPages, IHistoryPage } from './BaseHistoryPages'

export const DeviceHistoryPages = observer((props: { deviceId: string }) => {
  const { deviceId } = props
  const rootStore = useContext(RootStoreContext)
  const theme = useTheme()
  const [device, setDevice] = useState<EnhancedDevice | undefined>(undefined)
  const [pages, setPages] = useState<IHistoryPage[]>([])
  useEffect(() => {
    if (deviceId) {
      const foundDevice = rootStore.deviceListStore.findDeviceById(deviceId)
      setDevice(foundDevice)
      if (foundDevice?.type) {
        const historyKeys = DEVICES_CONFIG[foundDevice.type]?.historyKeys
        if (!_.isEmpty(historyKeys)) {
          const endTs = Date.now()
          const gotPages = historyKeys.map(({ title, keys }: IDict) => ({
            title,
            refreshing: rootStore.deviceListStore.state.loading,
            onRefresh: () => {
              return rootStore.deviceListStore.loadHistory(deviceId, { keys, endTs, startTs: endTs - 3 * A_MONTH, agg: 'NONE', interval: 0, limit: 50 })
            },
          }))
          setPages(gotPages)
        }
      }
    }
  }, [deviceId])

  const renderBody = useCallback(
    ({ value, key }: { value: string | number; key: string }) => {
      if (device) {
        const deviceConfig = DEVICES_CONFIG[device.type]
        return deviceConfig?.renderMessage(key, value, rootStore.deviceListStore.loadConfig(deviceId, deviceConfig?.configKeys)) || value
      }
      return {
        title: value,
        imageUrl: '',
      }
    },
    [device],
  )

  const renderItem = ({ item, index }: { item: TimeSeriesValue & { key: string }; index: number }) => {
    const { title, imageUrl } = renderBody(item)
    return (
      <View style={commonCardStyles}>
        <View style={[sharedStyles.justifyContentSpaceBetween, sharedStyles.flex, sharedStyles.vertical]}>
          <TimeAgo value={item.ts} />
          <ThemedText style={[sharedStyles.itemCode]}>{title}</ThemedText>
        </View>
        {imageUrl ? <Avatar size="small" source={imageUrl} /> : null}
      </View>
    )
  }

  return (
    <View>
      <View style={[sharedStyles.horizontal, sharedStyles.padding, sharedStyles.justifyContentSpaceBetween]}>
        <ThemedText color="foregroundColorMuted65">{`tên thiết bị`}</ThemedText>
        <ThemedText color={theme.blue} style={{ textAlign: 'right' }}>
          {device?.label || device?.name || deviceId}
        </ThemedText>
      </View>
      <BaseHistoryPages pages={pages} renderItem={renderItem} />
    </View>
  )
})
