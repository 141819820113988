import { IDict } from '@devhub/core/dist'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Mutation } from 'react-apollo'
import { View } from 'react-native'
import { DataTable } from 'react-native-paper'
import { RPCCallingInput, RPCCallingResponse, SAVE_SERVER_SCOPE_ATTRIBUTE, SAVE_SHARED_SCOPE_ATTRIBUTE } from '../../graphql/gqls'
import { Platform } from '../../libs/platform'
import { DEVICES_CONFIG, IOutputConfigs, openAppSMS, OUTPUT_CONFIG, OUTPUT_CONFIG_MODEL, OUTPUT_CONFIG_NAMES, OUTPUT_TYPE, OUTPUT_TYPE_SMS } from '../../stores/DeviceConfig'
import { RootStoreContext } from '../../stores/RootStore'
import { getOutputModel, getOutputName } from '../../stores/StoreUtils'
import { FLASH_MESSAGE_SCREEN_REF } from '../../stores/UIReferenceStore'
import { moreWidenCellStyle, sharedStyles, widenCellStyle } from '../../styles/shared'
import { contentPadding } from '../../styles/variables'
import { Button } from '../common/Button'
import { IconButton } from '../common/IconButton'
import { Spacer } from '../common/Spacer'
import { SubHeader } from '../common/SubHeader'
import { useTheme } from '../context/ThemeContext'
import { OutputConfigRow } from '../elements/OutputConfigRow'
import { ThemedText } from '../themed/ThemedText'
import { IconTextPopOver, INIT_DISPLAY_NUM } from './IconTextPopOver'
import { SimpleMenu } from './SimpleMenu'
import { SwipeListUserShare } from './SwipeListUserShare'

export const OUTPUT_NUM_MINIMUM = 1

export enum OUTPUT_MODEL_TYPE {
  BOM_VAN,
  GENERAL,
}

export const outputModelTypes = OUTPUT_MODEL_TYPE as IDict

export const outputModels: {
  [key: string]: {
    title: string
    modelType: OUTPUT_MODEL_TYPE
    names: { [key: string]: { title: string } }
  }
} = {
  [OUTPUT_MODEL_TYPE.BOM_VAN]: {
    title: 'Bơm-Van',
    modelType: OUTPUT_MODEL_TYPE.BOM_VAN,
    names: {
      [OUTPUT_TYPE.VAN]: { title: 'Van' },
      [OUTPUT_TYPE.TAI]: { title: 'Tải' },
      [OUTPUT_TYPE.TAI_EX]: { title: 'Bơm' },
    },
  },
  [OUTPUT_MODEL_TYPE.GENERAL]: {
    title: 'Thông thường',
    modelType: OUTPUT_MODEL_TYPE.GENERAL,
    names: {
      [OUTPUT_TYPE.VAN]: { title: 'Phụ thuộc' },
      [OUTPUT_TYPE.TAI]: { title: 'Độc lập' },
      [OUTPUT_TYPE.TAI_EX]: { title: 'Tải chính' },
    },
  },
}

export const outputModelArrays = Object.values(outputModels)

const flashMessageRef = FLASH_MESSAGE_SCREEN_REF.INPUT_CONFIG

export const MultiOutputConfig = observer(() => {
  const rootStore = useContext(RootStoreContext)
  const deviceId = rootStore.currentDeviceStore.getDeviceId()
  const configs = rootStore.deviceListStore.state[`${OUTPUT_CONFIG}_${deviceId}`]
  const names = rootStore.deviceListStore.state[`${OUTPUT_CONFIG_NAMES}_${deviceId}`]
  const outputModel = rootStore.deviceListStore.state[`${OUTPUT_CONFIG_MODEL}_${deviceId}`]

  const [currentOutputModel, setCurrentOutputModel] = useState(OUTPUT_MODEL_TYPE.BOM_VAN)
  const [outputConfigsState, setOutputConfigsState] = useState<IOutputConfigs>({})
  const [remainOutputNumbers, setRemainOutputNumbers] = useState<number[]>([])
  const [outputConfigNames, setOutputConfigNames] = useState<IDict>(names)
  const [possibleReset, setPossibleReset] = useState(false)

  const device = rootStore.deviceListStore.findDeviceById(deviceId)
  const { isSMSTransport, maxCountOfOutput, pickRemainOutputNumbers, buildOutputConfigMessage } = DEVICES_CONFIG[device?.type || ''] || {}

  useEffect(() => {
    if (outputModel) {
      const oType = getOutputModel(outputModel)
      if (oType !== undefined) {
        setCurrentOutputModel(oType)
      }
    }
  }, [outputModel])

  const resetConfig = useCallback(
    (toConfigs: IOutputConfigs | undefined, updateNames: boolean = true) => {
      if (toConfigs) {
        const cloneConfigs = Object.entries({ ...toConfigs }).reduce((pre, [outputIndex, config]) => {
          pre[outputIndex] = { ...config }
          return pre
        }, {} as IOutputConfigs)

        setOutputConfigsState(cloneConfigs)
        setRemainOutputNumbers(pickRemainOutputNumbers(cloneConfigs))
        setPossibleReset(false)
        if (updateNames) {
          setOutputConfigNames({ ...names })
        }
      }
    },
    [names, pickRemainOutputNumbers],
  )

  useEffect(() => resetConfig(configs, false), [configs])

  useEffect(() => setOutputConfigNames({ ...names }), [names])

  const outputTypeNames = useMemo(() => {
    const result: IDict = {}
    for (const currentValue in isSMSTransport ? OUTPUT_TYPE_SMS : OUTPUT_TYPE) {
      if (!isNaN(Number(currentValue))) {
        result[currentValue] = {
          title: outputModels[currentOutputModel].names[currentValue].title,
          outputType: Number(currentValue),
        }
      }
    }
    return result
  }, [currentOutputModel, isSMSTransport])

  const taiExes = useMemo(
    () =>
      Object.keys(outputConfigsState)
        .filter((ocs) => outputConfigsState[ocs].outputType === OUTPUT_TYPE.TAI_EX)
        .map((outputIndex) => ({
          title: getOutputName(outputIndex, outputConfigNames),
          outputIndex: Number(outputIndex),
        })),
    [outputConfigsState, outputConfigNames],
  )

  const findDependencyIndex = useCallback(
    (outputConfig) => {
      return outputConfig.dependency !== undefined ? taiExes.findIndex((item) => Number(item.outputIndex) === outputConfig.dependency) : -1
    },
    [taiExes],
  )

  const list = useMemo(
    () => (
      <>
        {Object.keys(outputConfigsState).map((outputIndex) => (
          <SwipeListUserShare
            key={`row-datatable-output-config-${outputIndex}`}
            onClick={() => {
              if (Object.keys(outputConfigsState).length > OUTPUT_NUM_MINIMUM) {
                delete outputConfigsState[outputIndex]
                remainOutputNumbers.push(Number(outputIndex))
                remainOutputNumbers.sort((a, b) => a - b)
                setOutputConfigsState({ ...outputConfigsState })
                setRemainOutputNumbers([...remainOutputNumbers])
                setPossibleReset(true)
              } else {
                rootStore.uiReferenceStore.danger(`Cần có ít nhất ${OUTPUT_NUM_MINIMUM} đầu ra`, FLASH_MESSAGE_SCREEN_REF.INPUT_CONFIG)
              }
            }}
          >
            <OutputConfigRow
              title={getOutputName(outputIndex, outputConfigNames)}
              isVanType={outputConfigsState[outputIndex].outputType === OUTPUT_TYPE.VAN}
              defaultInputText={getOutputName(outputIndex, outputConfigNames, false)}
              onUpdate={(updateText: string) => {
                outputConfigNames[outputIndex] = updateText
                setOutputConfigNames({ ...outputConfigNames })
                setPossibleReset(true)
              }}
              outputTypesProps={{
                items: Object.values(outputTypeNames),
                defaultValue: outputConfigsState[outputIndex].outputType,
                onSelectItem: (item) => {
                  outputConfigsState[outputIndex].outputType = item.outputType
                  setPossibleReset(true)
                  setOutputConfigsState({ ...outputConfigsState })
                },
              }}
              exOutputTypesProps={
                isSMSTransport
                  ? undefined
                  : {
                      items: taiExes,
                      defaultValue: findDependencyIndex(outputConfigsState[outputIndex]),
                      onSelectItem: (item) => {
                        outputConfigsState[outputIndex].dependency = item.outputIndex
                        setPossibleReset(true)
                        setOutputConfigsState({ ...outputConfigsState })
                      },
                    }
              }
            />
          </SwipeListUserShare>
        ))}
      </>
    ),
    [outputConfigsState, taiExes, outputTypeNames, remainOutputNumbers, outputConfigNames, isSMSTransport],
  )

  const actionRow = useMemo(
    () => (
      <>
        {
          // @ts-ignore
          possibleReset ? (
            <DataTable.Row>
              <DataTable.Cell>
                <IconButton family="material" name="undo" type={'danger'} size={16} tooltip="hủy thay đổi" onPress={() => resetConfig(configs)} />
              </DataTable.Cell>
            </DataTable.Row>
          ) : null
        }
      </>
    ),
    [possibleReset, configs],
  )

  const addItem = useMemo(
    () => (
      <>
        {outputConfigsState && Object.keys(outputConfigsState).length < maxCountOfOutput ? (
          <View style={[sharedStyles.flex, sharedStyles.alignItemsCenter, sharedStyles.justifyContentSpaceBetween]}>
            <Spacer height={contentPadding} />
            <IconTextPopOver
              icon={'plus-circle'}
              title={'thêm đầu ra'}
              items={remainOutputNumbers}
              initDisplayNum={INIT_DISPLAY_NUM}
              renderItemTitle={(item: number) => `${item + 1}.Đầu ra ${item + 1}`}
              onSelect={(selections) => {
                selections.forEach((outputNumber) => {
                  outputConfigsState[outputNumber] = {
                    outputType: OUTPUT_TYPE.TAI,
                  }
                })
                const updateStock = pickRemainOutputNumbers(outputConfigsState)
                setOutputConfigsState({ ...outputConfigsState })
                setRemainOutputNumbers(updateStock)
                setPossibleReset(true)
              }}
            />
          </View>
        ) : null}
      </>
    ),
    [outputConfigsState, remainOutputNumbers, pickRemainOutputNumbers],
  )

  const summaryConfig = useCallback(
    (currentConfigs: IOutputConfigs) => {
      const sortedConfigNumbers = Object.keys(currentConfigs).sort((a, b) => Number(a) - Number(b))
      const totalConfigs = Array.from(
        {
          length: Number(sortedConfigNumbers[sortedConfigNumbers.length - 1]) + 1,
        },
        () => 'h',
      )
      let errorMessage = ''

      sortedConfigNumbers.forEach((outputNumberText) => {
        const outputNumber = Number(outputNumberText)
        if (outputNumber >= 0 && outputNumber < totalConfigs.length) {
          const cf = currentConfigs[outputNumberText]
          switch (cf.outputType) {
            case OUTPUT_TYPE.TAI_EX:
              totalConfigs[outputNumber] = 'b'
              break
            case OUTPUT_TYPE.TAI:
              totalConfigs[outputNumber] = 't'
              break
            case OUTPUT_TYPE.VAN:
              if (cf.dependency !== undefined && cf.dependency >= 0 && cf.dependency < totalConfigs.length && currentConfigs[`${cf.dependency}`] && currentConfigs[`${cf.dependency}`].outputType === OUTPUT_TYPE.TAI_EX) {
                totalConfigs[outputNumber] = `v:${cf.dependency}`
              } else if (isSMSTransport) {
                totalConfigs[outputNumber] = 'v'
              } else {
                errorMessage = `${getOutputName(outputNumberText, outputConfigNames, false)}: Cần chọn phụ thuộc là loại đầu ra có tính năng ${outputTypeNames[OUTPUT_TYPE.TAI_EX]?.title}`
              }
              break
            default:
              totalConfigs[outputNumber] = 'h'
              break
          }
        }
      })
      return {
        content: totalConfigs.join(';'),
        error: errorMessage,
      }
    },
    [outputConfigNames, isSMSTransport],
  )

  const onSubmitAttribute = useCallback(
    (saveInput, saveServerScopeAttribute) => {
      const { content: newOutputConfig, error } = summaryConfig(outputConfigsState)
      if (error) {
        rootStore.uiReferenceStore.danger(error || 'Xin lỗi! lỗi này của chúng tôi chưa xác định', flashMessageRef)
        return
      }
      // save content
      saveInput({
        variables: {
          deviceId,
          input: {
            [OUTPUT_CONFIG]: newOutputConfig,
          },
        },
      })
        .then(() => {
          // save local
          rootStore.deviceListStore.state[`${OUTPUT_CONFIG}_${deviceId}`] = outputConfigsState
          rootStore.uiReferenceStore.success(`Lưu cấu hình đầu ra thành công`, flashMessageRef)
          setPossibleReset(false)
          if (isSMSTransport) {
            if (device?.label) {
              if (buildOutputConfigMessage) {
                const smsBody = buildOutputConfigMessage(device, newOutputConfig)
                openAppSMS(device?.label, smsBody)
              } else {
                rootStore.uiReferenceStore.danger(`Thiết bị ${device?.type} chưa cài đặt cú pháp sms cấu hình đầu ra`, flashMessageRef)
              }
            } else {
              rootStore.uiReferenceStore.danger(`Thiết bị chưa được cài đặt số điện thoại`, flashMessageRef)
            }
          }
        })
        .catch((e: any) => {
          console.log(`saveInput OUTPUT_CONFIG`, e)
          rootStore.uiReferenceStore.danger(`Có lỗi khi lưu cấu hình đầu ra. Hãy thử lại sau!`, flashMessageRef)
        })

      // save names
      saveServerScopeAttribute({
        variables: {
          deviceId,
          input: {
            [OUTPUT_CONFIG_NAMES]: outputConfigNames,
          },
        },
      })
        .then(() => {
          // save local
          rootStore.deviceListStore.state[`${OUTPUT_CONFIG_NAMES}_${deviceId}`] = outputConfigNames
          setPossibleReset(false)
        })
        .catch((e: any) => {
          console.log(`saveInput OUTPUT_CONFIG_NAMES`, e)
        })
    },
    [outputConfigsState, deviceId, outputTypeNames, outputConfigNames, isSMSTransport, buildOutputConfigMessage],
  )

  const submitOutputModel = useCallback(
    (saveInput, outputModelType: OUTPUT_MODEL_TYPE) => {
      const outputModelName = OUTPUT_MODEL_TYPE[outputModelType]
      saveInput({
        variables: {
          deviceId,
          input: {
            [OUTPUT_CONFIG_MODEL]: outputModelName,
          },
        },
      })
        .then(() => {
          // save local
          // rootStore.currentDeviceStore.state[`${OUTPUT_CONFIG_MODEL}_${deviceId}`] = outputModelName
          rootStore.deviceListStore.state[`${OUTPUT_CONFIG_MODEL}_${deviceId}`] = outputModelName
        })
        .catch((e: any) => {
          console.log(`saveInput OUTPUT_CONFIG_MODEL`, e)
        })
    },
    [deviceId],
  )

  return (
    <Mutation<RPCCallingResponse, RPCCallingInput> mutation={SAVE_SERVER_SCOPE_ATTRIBUTE}>
      {(saveServerScopeAttribute, { loading: subLoading }) => (
        <Mutation<RPCCallingResponse, RPCCallingInput> mutation={SAVE_SHARED_SCOPE_ATTRIBUTE}>
          {(updateCalling, { error, loading }) => (
            <View style={[sharedStyles.paddingVertical, sharedStyles.vertical]}>
              <SubHeader title="Ứng dụng của thiết bị">
                <Spacer flex={1} />
                <SimpleMenu
                  loading={subLoading}
                  items={outputModelArrays}
                  defaultValue={currentOutputModel}
                  onSelectItem={(item) => {
                    setCurrentOutputModel(item.modelType)
                    submitOutputModel(saveServerScopeAttribute, item.modelType)
                  }}
                />
              </SubHeader>
              <DataTable>
                {
                  // @ts-ignore
                  <DataTable.Header focusable={false}>
                    <ThemedText color="foregroundColorMuted65" style={[moreWidenCellStyle]}>
                      Đầu ra
                    </ThemedText>
                    <ThemedText color="foregroundColorMuted65" style={[widenCellStyle]}>
                      Tính năng
                    </ThemedText>
                    {isSMSTransport ? null : (
                      <ThemedText color="foregroundColorMuted65" style={[widenCellStyle]}>
                        Phụ thuộc
                      </ThemedText>
                    )}
                  </DataTable.Header>
                }
                {list}
                {actionRow}
                {addItem}
              </DataTable>
              <View style={[sharedStyles.padding, sharedStyles.vertical]}>
                {error ? <ThemedText color="foregroundColorMuted65">{error.message}</ThemedText> : null}
                <Spacer height={contentPadding * 2} />
                <Button key="advanced-button" disabled={!possibleReset || (Platform.OS === 'android' && isSMSTransport)} onPress={() => onSubmitAttribute(updateCalling, saveServerScopeAttribute)} loading={loading}>
                  {`Lưu${isSMSTransport ? ' & Gửi SMS' : ''}`}
                </Button>
              </View>
            </View>
          )}
        </Mutation>
      )}
    </Mutation>
  )
})
