import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { View } from 'react-native'
import { useDispatch } from 'react-redux'
import { Platform } from '../../libs/platform'
import * as actions from '../../redux/actions'
import { RootStoreContext } from '../../stores/RootStore'
import { sharedStyles } from '../../styles/shared'
import { contentPadding, scaleFactor } from '../../styles/variables'
import { openAppStore } from '../../utils/helpers/shared'
import { ColumnHeader } from '../columns/ColumnHeader'
import { ModalColumn } from '../columns/ModalColumn'
import { AppVersion } from '../common/AppVersion'
import { Button } from '../common/Button'
import { H3 } from '../common/H3'
import { Spacer } from '../common/Spacer'
import { SubHeader } from '../common/SubHeader'
import { Switch } from '../common/Switch'
import { ThemedIcon } from '../themed/ThemedIcon'
import { ContactView } from '../views/ContactView'
import { ThemePreference } from '../widgets/ThemePreference'
import { UserProfile } from './UserProfile'

export interface SettingsModalProps {
  showBackButton: boolean
}

export const SettingsModal = observer((props: SettingsModalProps) => {
  const rootStore = useContext(RootStoreContext)
  const { showBackButton } = props

  const dispatch = useDispatch()
  const currentEnv = rootStore.appConfig.getCurrentEnv()
  const showAdvance = false

  return (
    <ModalColumn
      hideCloseButton={true}
      name="SETTINGS"
      right={<ColumnHeader.Button analyticsLabel="modal" analyticsAction="notification_bell" family="octicon" name="bell" onPress={() => dispatch(actions.pushModal({ name: 'USER_NOTIFICATION' }))} tooltip={`Thông báo`} />}
      showBackButton={showBackButton}
      title="Cài đặt"
    >
      <>
        <UserProfile />
        {/* <DesktopPreferences /> */}
        <ThemePreference />
        <Spacer height={contentPadding} />

        {currentEnv !== 'product' ? (
          <View style={[sharedStyles.margin, sharedStyles.horizontal, sharedStyles.alignItemsCenter, sharedStyles.justifyContentSpaceBetween]}>
            <H3>enable debug info</H3>
            <Switch analyticsLabel="auto_theme" onValueChange={(enable) => rootStore.appConfig.toggleEnable(enable)} value={rootStore.appConfig.state.enableDebug} />
          </View>
        ) : null}

        <Spacer height={contentPadding} />

        {Platform.OS === 'ios' || Platform.OS === 'android' ? (
          <SubHeader title="Đánh giá & Phản hồi">
            <Spacer flex={1} />

            <Button analyticsLabel="rate_app" onPress={() => openAppStore({ showReviewModal: true })} size={32 * scaleFactor}>
              <ThemedIcon color="foregroundColor" family="octicon" name="star" size={16 * scaleFactor} />
            </Button>
          </SubHeader>
        ) : Platform.realOS === 'ios' || Platform.realOS === 'android' ? (
          <SubHeader title="Tải ứng dụng">
            <Spacer flex={1} />

            <Button analyticsLabel="download_native_app" onPress={() => openAppStore({ showReviewModal: false })} size={32 * scaleFactor}>
              <ThemedIcon color="foregroundColor" family="octicon" name="device-mobile" size={16 * scaleFactor} />
            </Button>
          </SubHeader>
        ) : null}

        <ContactView title={`LAZICO trên`} />
        <Spacer flex={1} minHeight={contentPadding} />
        <View style={sharedStyles.paddingHorizontal}>
          <AppVersion />
          {showAdvance ? (
            <>
              <Spacer height={contentPadding / 2} />
              <Button key="advanced-button" onPress={() => dispatch(actions.pushModal({ name: 'ADVANCED_SETTINGS' }))}>
                Cài đặt nâng cao
              </Button>
            </>
          ) : null}
        </View>
        <Spacer height={contentPadding / 2} />
      </>
    </ModalColumn>
  )
})

// SettingsModal.displayName = 'SettingsModal'
