import { IDict } from '@devhub/core/dist'
import _ from 'lodash'
import moment from 'moment'
import React, { memo, useCallback } from 'react'
import { Area, Chart, HorizontalAxis, Line, Tooltip, VerticalAxis } from 'react-native-responsive-linechart-patch'
import { ChartDataPoint } from 'react-native-responsive-linechart-patch/lib/types'
import { sharedStyles } from '../../styles/shared'
import { formatTimeByInterval } from '../../utils/constants'
import { useColumnWidth } from '../context/ColumnWidthContext'
import { useTheme } from '../context/ThemeContext'

export interface TimeSeriesChartProps {
  data: ChartDataPoint[]
  interval: number
}

const minMaxXY = (arr: ChartDataPoint[]) => {
  return _.isEmpty(arr)
    ? {}
    : arr.reduce((p, v) => {
        if (p.minX === undefined) p.minX = v.x
        else if (p.minX > v.x) p.minX = v.x
        if (p.maxX === undefined) p.maxX = v.x
        else if (p.maxX < v.x) p.maxX = v.x
        if (p.minY === undefined) p.minY = v.y
        else if (p.minY > v.y) p.minY = v.y
        if (p.maxY === undefined) p.maxY = v.y
        else if (p.maxY < v.y) p.maxY = v.y
        return p
      }, {} as IDict)
}

export const AreLineChart = memo(({ data, interval }: TimeSeriesChartProps) => {
  const theme = useTheme()
  const columnWidth = useColumnWidth() * 0.92
  const { /*minX=0, maxX=0, */ minY = 0, maxY = 0 } = minMaxXY(data)
  // const deltaX = (maxX-minX)/10
  const deltaY = (maxY - minY) / 5

  const formatter = useCallback((v: ChartDataPoint) => `(${formatTimeByInterval(v.x, interval)}) ${Math.round(v.y * 10000) / 10000}`, [interval])

  return data && data?.length > 1 ? (
    <Chart
      style={{ height: Math.round(columnWidth * 0.75), width: columnWidth }}
      svgStyle={sharedStyles.overflowVisible}
      disableGestures={false}
      disableTouch={false}
      data={data}
      padding={{ left: 40, bottom: 40, right: 10, top: 10 }}
      // xDomain={deltaX>0?{ min: minX-deltaX, max: maxX + deltaX }:{min:0, max:1}}
      yDomain={deltaY > 0 ? { min: minY - deltaY, max: maxY + deltaY } : { min: 0, max: 1 }}
    >
      <VerticalAxis
        tickCount={5}
        // includeOriginTick={false}
        theme={{
          labels: {
            label: {
              color: theme.primaryBackgroundColor,
            },
            formatter: (v) => `${Math.round(v * 10000) / 10000}`,
          },
          axis: {
            visible: true,
          },
          grid: {
            visible: true,
            /*stroke: {
                                                color: '#ccc',
                                                width: 1,
                                                opacity: 1,
                                                dashArray: [],
                                            },*/
          },
          ticks: {
            visible: false,
            stroke: {
              color: '#000',
              width: 1,
              opacity: 1,
            },
            dx: 0,
            length: 6,
          },
        }}
      />
      <HorizontalAxis
        // tickValues={data.length>=2?[data[0].x,data[data.length-1].x]:[]}
        tickCount={3}
        includeOriginTick={false}
        theme={{
          grid: {
            visible: true,
          },
          ticks: {
            visible: false,
          },
          labels: {
            label: {
              color: theme.primaryBackgroundColor,
              rotation: -20,
              dy: -20,
              dx: -20,
            },
            formatter: (v) => {
              return moment(v).locale('vi').format('D MMM HH:mm')
            },
          },
        }}
      />
      <Area theme={{ gradient: { from: { color: '#526FE4' }, to: { color: '#1462FF', opacity: 0.4 } } }} />
      <Line
        theme={{ stroke: { color: theme.primaryBackgroundColor, width: 5 }, scatter: { default: { width: 3, height: 3, rx: 1.5, color: '#5306FF' } } }}
        tooltipComponent={
          <Tooltip
            theme={{
              shape: { width: 160 },
              formatter,
            }}
          />
        }
      />
    </Chart>
  ) : null
})
