import { ListItem } from '@ui-kitten/components'
import React from 'react'
import { ImageURISource, View, ViewProps } from 'react-native'
import { Avatar } from 'react-native-elements/dist/avatar/Avatar'
import { getButtonColors, loadingIndicatorStyle } from '../../components/common/Button'
import { useTheme } from '../../components/context/ThemeContext'
import { ThemedActivityIndicator } from '../../components/themed/ThemedActivityIndicator'
import { getColorTextActive, sharedStyles } from '../../styles/shared'

const { foregroundThemeColor } = getButtonColors()

export interface FirebaseUIAuthProps {
  onUserSignedIn?: (idToken: string) => any
}

export interface FirebaseUIAuthViewProps extends FirebaseUIAuthProps, ViewProps {
  children?: React.ReactNode
}

export interface FirebaseUserRecord {
  displayName?: string | null
  phoneNumber?: string | null
  email?: string | null
  photoURL?: string | null
}

export const defaultAvatar = '@devhub/components/assets/logo_circle.png'

export const lazicoPhoneNumberIdentify = '@lazicophonenumber.com'
export const lazicoUidNumber = '@lazicouid.com'

export const formatPhoneNumber = (lazicoPhoneInEmail: string | null | undefined) => lazicoPhoneInEmail?.replace(lazicoPhoneNumberIdentify, '').replace('+84', '0')

export const formatUidNumber = (lazicoPhoneInEmail: string | null | undefined) => lazicoPhoneInEmail?.replace(lazicoUidNumber, '')

export const formatUserIdentify = (userIdentify: string | null | undefined) => {
  if (!userIdentify) return ''
  if (userIdentify.endsWith(lazicoPhoneNumberIdentify)) {
    return formatPhoneNumber(userIdentify)
  }
  if (userIdentify.endsWith(lazicoUidNumber)) {
    return formatUidNumber(userIdentify)
  }
  return userIdentify
}

export const AppFirebaseUser = ({ userRecord, loading = false, onPress, ...others }: { userRecord: FirebaseUserRecord; loading?: boolean; onPress?: () => any; [key: string]: any }) => {
  const theme = useTheme()
  const { displayName, phoneNumber, email, photoURL } = userRecord
  const userIdentify: string = email || formatPhoneNumber(phoneNumber) || ''
  const name: string = displayName || userIdentify
  const source: ImageURISource = { uri: photoURL || defaultAvatar }

  return (
    <ListItem
      style={{
        backgroundColor: getColorTextActive(true, theme, '#5D7B99'),
        borderRadius: 10,
        ...others,
      }}
      title={name}
      onPress={() => {
        if (onPress) onPress()
      }}
      description={userIdentify}
      accessoryLeft={(props) => (
        <View style={[sharedStyles.center]}>
          <Avatar rounded size="large" source={source} />
          {loading ? <ThemedActivityIndicator color={foregroundThemeColor} size="small" style={[loadingIndicatorStyle, sharedStyles.absolute]} /> : <></>}
        </View>
      )}
    />
  )
}
