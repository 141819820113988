import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { ButtonGroup } from 'react-native-elements'
import { useTheme } from '../context/ThemeContext'

export interface AppButtonGroupRef {
  setSelectedIndex: (index: number) => any
}
export const AppButtonGroup = forwardRef<
  AppButtonGroupRef,
  {
    onPress?: (itemSelect: any) => void
    initValue: number
    buttons: any[]
    effectByInitValue?: boolean
    [key: string]: any
  }
>(({ onPress, initValue, enableSelected = true, style = [], effectByInitValue = true, ...others }, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(initValue)
  const theme = useTheme()
  useImperativeHandle(ref, () => ({ setSelectedIndex: (index) => setSelectedIndex(index) }), [])

  useEffect(() => {
    if (effectByInitValue) {
      setSelectedIndex(initValue)
    }
  }, [initValue, effectByInitValue])

  return (
    <ButtonGroup
      selectedButtonStyle={{ backgroundColor: theme.primaryBackgroundColor }}
      onPress={(sIndex) => {
        if (enableSelected) {
          setSelectedIndex(sIndex)
        }
        if (onPress) {
          onPress(sIndex)
        }
      }}
      selectedIndex={selectedIndex}
      containerStyle={[{ height: 35, marginLeft: 0, marginRight: 0 }, ...style]}
      {...others}
    />
  )
})
