import {
  Column as ColumnT,
  columnHasAnyFilter,
  EnhancedGitHubEvent,
  EnhancedGitHubIssueOrPullRequest,
  EnhancedGitHubNotification,
  EnhancedItem,
  getItemNodeIdOrId,
  isItemRead,
  isItemSaved,
  ThemeColors,
} from '@devhub/core'
import { DeviceColumn, DeviceDetailColumnType } from '@devhub/core/dist'

import React, { ReactNode, useCallback, useRef } from 'react'
import { Dimensions, StyleSheet, View } from 'react-native'
import { useDispatch, useStore } from 'react-redux'

import { useAppViewMode } from '../../hooks/use-app-view-mode'
import { useColumnData } from '../../hooks/use-column-data'
import { AutoSizer } from '../../libs/auto-sizer'
import { emitter } from '../../libs/emitter'
import { IconProp } from '../../libs/vector-icons'
import * as actions from '../../redux/actions'
import * as selectors from '../../redux/selectors'
import { sharedStyles } from '../../styles/shared'
import { contentPadding } from '../../styles/variables'
import { useColumnWidth } from '../context/ColumnWidthContext'
import { useAppLayout } from '../context/LayoutContext'
import { Column } from './Column'
import { ColumnFiltersRenderer } from './ColumnFiltersRenderer'
import { ColumnHeader } from './ColumnHeader'
import { ColumnOptionsAccordion } from './ColumnOptionsAccordion'

export function getColumnCardThemeColors({
  isDark,
}: {
  isDark: boolean
}): {
  column: keyof ThemeColors
  card: keyof ThemeColors
  card__hover: keyof ThemeColors
  card__muted: keyof ThemeColors
  card__muted_hover: keyof ThemeColors
} {
  return {
    card: 'backgroundColorLighther1',
    card__hover: isDark ? 'backgroundColorLighther2' : 'backgroundColorDarker1',
    card__muted: isDark ? 'backgroundColor' : 'backgroundColorDarker1',
    card__muted_hover: isDark
      ? 'backgroundColorLighther1'
      : 'backgroundColorDarker2',
    column: 'backgroundColor',
  }
}

export function getCardBackgroundThemeColor({
  isDark,
  isMuted,
  isHovered,
}: {
  isDark: boolean
  isMuted: boolean
  isHovered?: boolean
}) {
  const backgroundThemeColors = getColumnCardThemeColors({ isDark })

  const _backgroundThemeColor =
    (isMuted &&
      (isHovered
        ? backgroundThemeColors.card__muted_hover
        : backgroundThemeColors.card__muted)) ||
    (isHovered ? backgroundThemeColors.card__hover : backgroundThemeColors.card)

  return _backgroundThemeColor
}

export interface ColumnRendererProps {
  avatarImageURL?: string
  avatarLinkURL?: string
  children: React.ReactNode
  columnId: string
  columnIndex: number
  columnType:
    | ColumnT['type']
    | DeviceColumn['type']
    | DeviceDetailColumnType['type']
  icon: IconProp
  owner: string | undefined
  pagingEnabled?: boolean
  repo: string | undefined
  repoIsKnown: boolean
  subtitle: string | undefined
  iconAdge?: ReactNode
  title: string | ReactNode
  settingButton?: ReactNode
  rightButtons?: any[]
  onPressIconLeft?: () => void
}

export const ColumnRenderer = React.memo((props: ColumnRendererProps) => {
  const {
    avatarImageURL,
    avatarLinkURL,
    children,
    columnId,
    columnIndex,
    columnType,
    icon,
    owner,
    pagingEnabled,
    repo,
    repoIsKnown,
    subtitle,
    iconAdge,
    title,
    settingButton,
    rightButtons,
    onPressIconLeft,
  } = props

  const columnOptionsRef = useRef<ColumnOptionsAccordion>(null)
  const appLayout = useAppLayout()
  const { appOrientation } = appLayout
  const appViewModeresult = useAppViewMode()
  const { appViewMode } = appViewModeresult
  const columnWidth = useColumnWidth()
  const columnData = useColumnData(columnId, {
    mergeSimilar: false,
  })
  const { hasCrossedColumnsLimit, filteredItems } = columnData

  const dispatch = useDispatch()
  const store = useStore()

  const hasItemsToMarkAsDone = !!(filteredItems as any[]).some(
    (
      item:
        | EnhancedGitHubEvent
        | EnhancedGitHubNotification
        | EnhancedGitHubIssueOrPullRequest,
    ) => {
      return !!(item && !isItemSaved(item)) /* && isItemRead(item) */
    },
  )

  const refresh = useCallback(() => {
    dispatch(
      actions.fetchColumnSubscriptionRequest({
        columnId,
        params: { page: 1, perPage: 10 },
        replaceAllItems: false,
      }),
    )
  }, [columnId])

  function focusColumn() {
    emitter.emit('FOCUS_ON_COLUMN', {
      columnId,
      highlight: false,
      scrollTo: false,
    })
  }

  const toggleOptions = () => {
    if (!columnOptionsRef.current) return

    focusColumn()
    columnOptionsRef.current.toggle()
  }

  const hasOneUnreadItem = (filteredItems as any[]).some(
    (
      item:
        | EnhancedGitHubNotification
        | EnhancedGitHubEvent
        | EnhancedGitHubIssueOrPullRequest,
    ) => !isItemRead(item),
  )

  const renderLeftSeparator =
    appViewMode === 'multi-column' &&
    !(columnIndex === 0 && appOrientation === 'landscape')

  const renderRightSeparator = appViewMode === 'multi-column'

  return (
    <Column
      key={`column-renderer-${columnId}-inner-container`}
      backgroundColor={getColumnCardThemeColors({ isDark: false }).column}
      columnId={columnId}
      pagingEnabled={pagingEnabled}
      renderLeftSeparator={renderLeftSeparator}
      renderRightSeparator={renderRightSeparator}
    >
      <ColumnHeader
        key={`column-renderer-${columnId}-header`}
        columnId={columnId}
        title={title}
        subtitle={subtitle}
        iconAdge={iconAdge}
        style={{ paddingRight: contentPadding / 2 }}
        {...(avatarImageURL
          ? { avatar: { imageURL: avatarImageURL, linkURL: avatarLinkURL! } }
          : { icon })}
        right={
          rightButtons ? (
            rightButtons
          ) : (
            <>
              <ColumnHeader.Button
                key="column-options-button-clear-column"
                analyticsLabel={
                  hasItemsToMarkAsDone ? 'clear_column' : 'unclear_column'
                }
                disabled={hasCrossedColumnsLimit || !hasItemsToMarkAsDone}
                family="octicon"
                name="check"
                onPress={() => {
                  dispatch(
                    actions.setColumnClearedAtFilter({
                      columnId,
                      clearedAt: hasItemsToMarkAsDone
                        ? new Date().toISOString()
                        : null,
                    }),
                  )

                  focusColumn()

                  if (!hasItemsToMarkAsDone) refresh()
                }}
                tooltip="Done"
              />

              <ColumnHeader.Button
                key="column-options-button-toggle-mark-as-read"
                analyticsLabel={
                  !hasOneUnreadItem ? 'mark_as_unread' : 'mark_as_read'
                }
                disabled={hasCrossedColumnsLimit || !filteredItems.length}
                family="octicon"
                name={!hasOneUnreadItem ? 'eye-closed' : 'eye'}
                onPress={() => {
                  const unread = !hasOneUnreadItem

                  const visibleItemNodeIdOrIds = (filteredItems as any[])
                    .map((item: EnhancedItem) => getItemNodeIdOrId(item))
                    .filter(Boolean) as string[]

                  const column = selectors.columnSelector(
                    store.getState(),
                    columnId,
                  )

                  const hasAnyFilter = columnHasAnyFilter(columnType, {
                    ...(column && column.filters),
                    clearedAt: undefined,
                  })

                  // column doesnt have any filter,
                  // so lets mark ALL notifications on github as read at once,
                  // instead of marking only the visible items one by one
                  if (
                    columnType === 'notifications' &&
                    !hasAnyFilter &&
                    !unread
                  ) {
                    if (repoIsKnown) {
                      if (owner && repo) {
                        dispatch(
                          actions.markRepoNotificationsAsReadOrUnread({
                            owner,
                            repo,
                            unread,
                          }),
                        )

                        return
                      }
                    } else {
                      dispatch(
                        actions.markAllNotificationsAsReadOrUnread({ unread }),
                      )
                      return
                    }
                  }

                  // mark only the visible items as read/unread one by one
                  dispatch(
                    actions.markItemsAsReadOrUnread({
                      type: columnType,
                      itemNodeIdOrIds: visibleItemNodeIdOrIds,
                      unread,
                    }),
                  )

                  focusColumn()
                }}
                tooltip={
                  !hasOneUnreadItem ? 'Mark all as unread' : 'Mark all as read'
                }
              />

              {settingButton ? (
                settingButton
              ) : (
                <ColumnHeader.Button
                  key="column-options-toggle-button"
                  analyticsAction="toggle"
                  analyticsLabel="column_options"
                  family="octicon"
                  name="settings"
                  onPress={toggleOptions}
                  tooltip="Options"
                />
              )}
            </>
          )
        }
        onPressIconLeft={onPressIconLeft}
      />

      <View
        style={[
          sharedStyles.flex,
          sharedStyles.fullWidth,
          sharedStyles.fullHeight,
        ]}
      >
        <AutoSizer
          defaultWidth={columnWidth}
          defaultHeight={Dimensions.get('window').height}
          style={[
            sharedStyles.relative,
            sharedStyles.flex,
            sharedStyles.fullWidth,
            sharedStyles.fullHeight,
          ]}
        >
          {({ width, height }) => (
            <View style={StyleSheet.absoluteFill}>
              <ColumnOptionsAccordion
                ref={columnOptionsRef}
                columnId={columnId}
              />

              <View style={{ width, height }}>{children}</View>
            </View>
          )}
        </AutoSizer>
      </View>

      <ColumnFiltersRenderer
        key="column-options-renderer"
        columnId={columnId}
        fixedPosition="right"
        header="header"
        type="local"
      />
    </Column>
  )
})

ColumnRenderer.displayName = 'ColumnRenderer'
