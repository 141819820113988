import { Toggle } from '@ui-kitten/components'
import { ToggleProps } from '@ui-kitten/components/ui/toggle/toggle.component'
import React, { memo, useEffect, useState } from 'react'
import { sharedStyles } from '../../styles/shared'
import { useTheme } from '../context/ThemeContext'
import { ThemedText } from '../themed/ThemedText'

export interface IAppToggle extends ToggleProps {
  initValue?: boolean
  activeText?: string
  inactiveText?: string
}

export const AppToggle = memo(({ initValue = true, activeText = 'active', inactiveText = 'inactive', onChange, ...others }: IAppToggle) => {
  const [checked, setChecked] = useState<boolean | undefined>(initValue)
  const theme = useTheme()
  useEffect(() => setChecked(initValue), [initValue])
  return (
    <Toggle
      style={[sharedStyles.toggle]}
      status="primary"
      checked={checked}
      onChange={(isChecked) => {
        setChecked(isChecked)
        if (onChange) {
          onChange(isChecked)
        }
      }}
      {...others}
    >
      {(evaProps) => (
        <ThemedText color={theme.blue} {...evaProps}>
          {checked ? activeText : inactiveText}
        </ThemedText>
      )}
    </Toggle>
  )
})
