import { IDict } from '@devhub/core/dist'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Mutation } from 'react-apollo'
import { View } from 'react-native'
import { NestableDraggableFlatList, RenderItemParams, ScaleDecorator } from 'react-native-draggable-flatlist'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { DataTable } from 'react-native-paper'
import { RPCCallingInput, RPCCallingResponse, SAVE_SHARED_SCOPE_ATTRIBUTE } from '../../graphql/gqls'
import { INPUT_CONFIG_ORDER } from '../../stores/CurrentDeviceStore'
import { OUTPUT_CONFIG, OUTPUT_CONFIG_NAMES } from '../../stores/DeviceConfig'
import { RootStoreContext } from '../../stores/RootStore'
import { getOutputName } from '../../stores/StoreUtils'
import { moreWidenCellStyle, sharedStyles, singleCellStyle } from '../../styles/shared'
import { InputConfigRender } from '../columns/InputConfigRender'
import { ThemedText } from '../themed/ThemedText'
import { InputConfigItemRow } from './InputConfigItemRow'
import { getInputName, IInputConfig } from './MutableInputMessage'
import { submitInputOrder } from './SortProbeInputConfig'

export const InputConfigForMultiOutputDevice = observer(() => {
  const rootStore = useContext(RootStoreContext)
  const deviceId = rootStore.currentDeviceStore.getDeviceId()
  const deviceType = rootStore.currentDeviceStore.state.device?.type
  const orders: number[] | undefined = rootStore.deviceListStore.state[`${INPUT_CONFIG_ORDER}_${deviceId}`]

  const [inputConfigs, setInputConfigs] = useState<IInputConfig[]>([])
  const [selectedConfig, setSelectedConfig] = useState<IInputConfig | undefined>(undefined)
  const configs = rootStore.deviceListStore.state[`${OUTPUT_CONFIG}_${deviceId}`]
  const names = rootStore.deviceListStore.state[`${OUTPUT_CONFIG_NAMES}_${deviceId}`]

  const outputs = useMemo(
    () =>
      configs
        ? Object.keys(configs).map((outputIndex) => ({
            title: getOutputName(outputIndex, names),
            outputIndex: Number(outputIndex),
            outputType: Number(configs[outputIndex].outputType),
          }))
        : [],
    [configs, names],
  )
  useEffect(() => {
    const invalidSort = orders?.some((orderNumber) => orderNumber === undefined || orderNumber === null || isNaN(orderNumber))
    const initInputConfig = rootStore.currentDeviceStore.getInputs().map((value) => ({
      inputOrderNumber: value,
      name: getInputName(value),
      message: `Có lỗi - ${getInputName(value)}`,
    }))
    if (!orders || orders.length <= 0 || invalidSort) {
      setInputConfigs(initInputConfig)
      setSelectedConfig(initInputConfig[0])
    } else {
      const map = initInputConfig.reduce((pre, current) => {
        pre[current.inputOrderNumber] = current
        return pre
      }, {} as IDict)
      const iConfigs = orders.map((oNumber) => map[oNumber])
      if (initInputConfig.length > orders.length) {
        const unSort = initInputConfig.filter((ic) => !orders.includes(ic.inputOrderNumber))
        unSort.forEach((ic) => iConfigs.push(map[ic.inputOrderNumber]))
      }
      setInputConfigs(iConfigs)
      setSelectedConfig(iConfigs[0])
    }
  }, [orders])

  return (
    <Mutation<RPCCallingResponse, RPCCallingInput> mutation={SAVE_SHARED_SCOPE_ATTRIBUTE}>
      {(updateCalling, { error, loading }) => (
        <>
          <View style={[sharedStyles.paddingVertical, sharedStyles.vertical]}>
            {error ? (
              <ThemedText color="foregroundColorMuted65" style={[sharedStyles.marginVerticalHalf]}>
                {error.message}
              </ThemedText>
            ) : null}
            <DataTable>
              <DataTable.Header focusable={false}>
                <ThemedText color="foregroundColorMuted65" style={[singleCellStyle]}>
                  Đầu dò
                </ThemedText>
                <ThemedText color="foregroundColorMuted65" style={[moreWidenCellStyle]}>
                  Nội dung cảnh báo
                </ThemedText>
              </DataTable.Header>
              <NestableDraggableFlatList
                data={inputConfigs}
                renderItem={({ item, drag }: RenderItemParams<IInputConfig>) => (
                  <ScaleDecorator>
                    <InputConfigItemRow deviceId={deviceId} config={item} highlight={selectedConfig === item} onLongPress={drag} onSelected={(selected) => setSelectedConfig(selected)} />
                  </ScaleDecorator>
                )}
                keyExtractor={({ inputOrderNumber }, index: number) => `draggable-item-input-config-${inputOrderNumber}-${index}`}
                onDragEnd={({ data: dragData, to, from }) => {
                  if (to !== from) {
                    setInputConfigs(dragData)
                    submitInputOrder(
                      deviceId,
                      dragData.map((item) => item.inputOrderNumber),
                      loading,
                      updateCalling,
                      rootStore,
                    )
                  }
                }}
              />
            </DataTable>
          </View>
          {selectedConfig ? <InputConfigRender deviceId={deviceId} outputs={outputs} inputOrderNumber={selectedConfig?.inputOrderNumber} showInputMessage={false} isSimpleInputConfig={false} deviceType={deviceType} /> : null}
        </>
      )}
    </Mutation>
  )
})
