import { Icon } from '@ui-kitten/components'
import React, { ReactNode } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { Tooltip } from 'react-native-elements'
import { sharedStyles } from '../../styles/shared'
import { smallPadding } from '../../styles/variables'
import { useTheme } from '../context/ThemeContext'
import { ThemedText } from '../themed/ThemedText'

export const InputConfigRow = React.memo(
  ({
    fontSize,
    subject,
    tooltip,
    icon,
    inline = true,
    children,
    style = {},
    subStyle,
  }: {
    fontSize?: number
    subject?: string
    tooltip?: string
    icon?: string | ReactNode
    placement?: string
    inline?: boolean
    children?: React.ReactElement
    style?: StyleProp<ViewStyle>
    subStyle?: StyleProp<ViewStyle>
  }) => {
    const theme = useTheme()
    return (
      <View style={[inline ? sharedStyles.horizontal : sharedStyles.vertical, sharedStyles.justifyContentSpaceBetween, style]}>
        {!!subject || !!tooltip ? (
          <View style={[sharedStyles.horizontal]}>
            {subject ? (
              <ThemedText color="foregroundColorMuted65" style={{ fontSize }}>
                {subject}
              </ThemedText>
            ) : null}
            {tooltip ? (
              // @ts-ignore
              <Tooltip
                overlayColor={'rgba(250, 250, 250, 0)'}
                popover={
                  <ThemedText style={[{ padding: smallPadding }, sharedStyles.textMedium]} color={theme.white}>
                    {tooltip}
                  </ThemedText>
                }
              >
                {icon ? icon : <Icon style={sharedStyles.icon} fill={theme.backgroundColorLess4} name="info" />}
              </Tooltip>
            ) : null}
          </View>
        ) : null}
        {inline ? <View style={subStyle ? subStyle : [sharedStyles.flex, sharedStyles.alignItemsFlexEnd, sharedStyles.alignSelfFlexEnd, sharedStyles.justifyContentFlexEnd]}>{children ? children : null}</View> : children ? children : null}
      </View>
    )
  },
)

InputConfigRow.displayName = 'InputConfigRow'
