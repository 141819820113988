import React, { useRef } from 'react'
import FlashMessage from 'react-native-flash-message'

import { observer } from 'mobx-react-lite'
import { contentPadding } from '../../styles/variables'
import { ModalColumn } from '../columns/ModalColumn'
import { Spacer } from '../common/Spacer'
import { DialogConsumer } from '../context/DialogContext'
import { useAppLayout } from '../context/LayoutContext'
import { ChangePasswordForUser } from '../views/ChangePasswordForUser'
import { LogoutView } from '../views/LogoutView'

export interface AdvancedSettingsModalProps {
  showBackButton: boolean
}

export const AdvancedSettingsModal = React.memo(
  observer((props: AdvancedSettingsModalProps) => {
    const { showBackButton } = props
    const { sizename } = useAppLayout()
    const flashMessageRef = useRef<FlashMessage>(null)

    return (
      <>
        <ModalColumn hideCloseButton={sizename === '1-small'} flashMessageRef={flashMessageRef} name="ADVANCED_SETTINGS" showBackButton={showBackButton} title="Cài đặt nâng cao">
          <DialogConsumer>
            {(Dialog) => (
              <>
                <ChangePasswordForUser flashMessageRef={flashMessageRef} />
                <Spacer flex={1} minHeight={contentPadding} />
                <LogoutView />
                <Spacer height={contentPadding / 2} />
              </>
            )}
          </DialogConsumer>
        </ModalColumn>
      </>
    )
  }),
)

// AdvancedSettingsModal.displayName = 'AdvancedSettingsModal'
