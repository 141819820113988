import React, { Dispatch, FC, useCallback, useState } from 'react'
import { View } from 'react-native'

import { Mutation } from '@apollo/react-components'
import {
  CREATE_PASSWORD,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from '../../graphql/gqls'
import { useReduxAction } from '../../hooks/use-redux-action'
import * as actions from '../../redux/actions'
import { PageType } from '../../screens/StackNavigator'
import { sharedStyles } from '../../styles/shared'
import { contentPadding } from '../../styles/variables'
import { getMessageError } from '../../utils/helpers/shared'
import { getButtonColors } from '../common/Button'
import { GitHubLoginButton } from '../common/GitHubLoginButton'
import { Spacer } from '../common/Spacer'
import { ThemedTextInputPasswordWithIcon } from '../elements/ThemedTextInputPasswordWithIcon'
import { ThemedText } from '../themed/ThemedText'
import { ThemedTextInput } from '../themed/ThemedTextInput'

interface IInputText {
  activateToken?: string
  resetToken?: string
  password: string
  passwordAgain: string
  email?: string
}

interface Props {
  pageType: PageType
  setPageType: Dispatch<PageType>
}

export const AppPasswordScreen: FC<Props> = ({ pageType, setPageType }) => {
  const loginRequest = useReduxAction(actions.loginRequest)
  const { foregroundThemeColor } = getButtonColors()
  const [inputError, setInputError] = useState<string>('')
  const [isLoadingSendForgotPass, setIsLoadingSendForgotPass] =
    useState<boolean>(false)
  const [isSendForgotPass, setIsSendForgotPass] = useState<boolean>(true)
  const [inputText, setInputText] = useState<IInputText>({
    activateToken: '',
    resetToken: '',
    password: '',
    passwordAgain: '',
    email: '',
  })
  const [isShowPassword, setIsShowPassword] = useState<boolean>(true)
  const [isShowRepeatPassword, setIsShowRepeatPassword] =
    useState<boolean>(true)

  const isResetPass = pageType === PageType.PASSWORD_FORGET
  const tokenField = isResetPass ? 'resetToken' : 'activateToken'

  const invalidateInput = useCallback(() => {
    let errorText = ''
    // @ts-ignore
    if (!inputText[tokenField] || inputText[tokenField].length !== 6)
      errorText = 'OTP không hợp lệ'
    if (!inputText.password || inputText.password.length < 8)
      errorText = 'Mật khẩu tối thiểu 8 ký tự'
    if (inputText.passwordAgain !== inputText.password)
      errorText = 'Mật khẩu lặp lại chưa khớp'
    setInputError(errorText)
    return !!errorText
  }, [inputText])

  const onChangeInputText = (name: string, value: string) => {
    setInputText((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const onSubmitForm = useCallback(
    (saveCustomer, loading) => {
      if (loading || invalidateInput()) return
      saveCustomer({
        variables: {
          input: {
            [tokenField]: inputText[tokenField],
            password: inputText.password,
          },
        },
      })
    },
    [inputText],
  )

  const onSubmitGetOtp = useCallback(
    async (sendForgotPassword, loading) => {
      if (loading) return
      setIsLoadingSendForgotPass(true)
      try {
        const response = await sendForgotPassword({
          variables: {
            input: {
              email: inputText.email,
            },
          },
        })
        if (response && response.data) {
          setTimeout(() => {
            setInputError('')
            setIsLoadingSendForgotPass(false)
            setIsSendForgotPass(false)
          }, 500)
        }
      } catch (error) {
        setInputError(getMessageError(error))
        setIsLoadingSendForgotPass(false)
      }
    },
    [inputText.email],
  )

  const checkExistsValue = (properties: IInputText) => {
    const allField = (value: string) => !!value
    return Object.values(properties).every(allField)
  }

  const { email, ...someFields } = inputText
  let fields: IInputText
  if (isResetPass) {
    const { activateToken, ...aFields } = someFields
    fields = aFields
  } else {
    const { resetToken, ...aFields } = someFields
    fields = aFields
  }

  const mutationComponent =
    isResetPass && isSendForgotPass ? (
      <Mutation<any> mutation={FORGOT_PASSWORD}>
        {(sendForgotPassword, { loading: aLoading }) => (
          <View>
            <Spacer height={contentPadding} />
            <ThemedTextInput
              placeholder="Email"
              textInputKey="email"
              value={inputText.email}
              onChangeText={(text) => {
                onChangeInputText('email', text)
              }}
            />
            {inputError ? (
              <ThemedText color="red">{inputError}</ThemedText>
            ) : null}
            <Spacer height={contentPadding} />
            <GitHubLoginButton
              analyticsLabel="send_email"
              disabled={!inputText.email}
              onPress={() =>
                onSubmitGetOtp(
                  sendForgotPassword,
                  aLoading || isLoadingSendForgotPass,
                )
              }
              style={sharedStyles.button}
              title="Gửi"
              loading={aLoading || isLoadingSendForgotPass}
            />
            <Spacer height={contentPadding} />
            <ThemedText
              style={[sharedStyles.textCenter]}
              color="blue"
              onPress={() => {
                setInputText({ ...inputText, email: '' })
                setIsSendForgotPass(true)
              }}
            >
              Lấy lại mã OTP?
            </ThemedText>
          </View>
        )}
      </Mutation>
    ) : (
      <Mutation<any>
        mutation={isResetPass ? RESET_PASSWORD : CREATE_PASSWORD}
        update={(
          cache: any,
          {
            data: {
              password: { token, refreshToken },
            },
          },
        ) => {
          if (!token) throw new Error('No token')
          loginRequest({ appToken: token, refreshToken })
        }}
      >
        {(createPassword, { error, loading }) => (
          <>
            <View>
              <View style={[sharedStyles.fullWidth, sharedStyles.fullMinWidth]}>
                <ThemedTextInput
                  placeholder="Mã OTP nhận được"
                  textInputKey={tokenField}
                  autoFocus={true}
                  editable={!loading}
                  value={inputText[tokenField]}
                  onChangeText={(text) => {
                    onChangeInputText(tokenField, text)
                  }}
                />
              </View>
              <Spacer height={contentPadding} />
              <View style={[sharedStyles.fullWidth, sharedStyles.fullMinWidth]}>
                <ThemedTextInputPasswordWithIcon
                  placeholder="Mật khẩu"
                  textInputKey="password"
                  value={inputText.password}
                  secureTextEntry={isShowPassword}
                  editable={!loading}
                  onChangeText={(text) => {
                    onChangeInputText('password', text)
                  }}
                />
              </View>
              <Spacer height={contentPadding} />
              <View style={[sharedStyles.fullWidth, sharedStyles.fullMinWidth]}>
                <ThemedTextInputPasswordWithIcon
                  placeholder="Nhập lại mật khẩu"
                  textInputKey="passwordAgain"
                  value={inputText.passwordAgain}
                  secureTextEntry={isShowRepeatPassword}
                  editable={!loading}
                  onChangeText={(text) => {
                    onChangeInputText('passwordAgain', text)
                  }}
                />
              </View>
              {inputError ? (
                <ThemedText color="red">{inputError}</ThemedText>
              ) : null}
              {error ? (
                <ThemedText color="red">{getMessageError(error)}</ThemedText>
              ) : null}
              <Spacer height={2 * contentPadding} />
              <GitHubLoginButton
                analyticsLabel="create_pass"
                disabled={!checkExistsValue(fields)}
                onPress={() => onSubmitForm(createPassword, loading)}
                style={sharedStyles.button}
                title="Hoàn Thành"
                loading={loading}
              />
            </View>
          </>
        )}
      </Mutation>
    )

  return (
    <View>
      {mutationComponent}
      <Spacer height={contentPadding} />
      <ThemedText
        style={[sharedStyles.textCenter]}
        color="foregroundColorMuted65"
      >
        Bạn đã có tài khoản?{' '}
        <ThemedText color="blue" onPress={() => setPageType(PageType.LOGIN)}>
          Đăng nhập
        </ThemedText>
      </ThemedText>
    </View>
  )
}
