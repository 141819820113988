import { observable } from 'mobx'
import { persist } from 'mobx-persist'
import FlashMessage, {
  MessageOptions,
  showMessage,
} from 'react-native-flash-message'
import { isMobile } from '../utils/helpers/shared'
import { RootStore } from './RootStore'
export const FLASH_MESSAGE_DURATION = 3000

export enum FLASH_MESSAGE_SCREEN_REF {
  DEVICE_LIST,
  DEVICE_DETAIL,
  INPUT_CONFIG,
  MODAL,
}

export interface UIReferenceStoreValue {
  deviceListFlashMessageRef: FlashMessage | null
  devicePanelFlashMessageRef: FlashMessage | null
  inputConfigFlashMessageRef: FlashMessage | null
  modalFlashMessageRef: FlashMessage | null
}

const uiReferences: UIReferenceStoreValue = observable({
  deviceListFlashMessageRef: null,
  devicePanelFlashMessageRef: null,
  inputConfigFlashMessageRef: null,
  modalFlashMessageRef: null,
})

const schema = {
  deviceListFlashMessageRef: false,
  devicePanelFlashMessageRef: false,
  inputConfigFlashMessageRef: false,
  modalFlashMessageRef: false,
}

const state = persist(schema)(uiReferences)

export class UIReferenceStore {
  rootStore: RootStore
  state: UIReferenceStoreValue

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.state = state
  }

  danger(message: string, flashMessageScreenRef?: FLASH_MESSAGE_SCREEN_REF) {
    this.showFlashMessage(
      {
        message,
        type: 'danger',
      },
      flashMessageScreenRef,
    )
  }

  success(message: string, flashMessageScreenRef?: FLASH_MESSAGE_SCREEN_REF) {
    this.showFlashMessage(
      {
        message,
        type: 'success',
      },
      flashMessageScreenRef,
    )
  }

  private showFlashMessage(
    param: MessageOptions,
    flashMessageScreenRef?: FLASH_MESSAGE_SCREEN_REF,
  ) {
    if (isMobile) {
      showMessage(param)
    } else {
      let flm: FlashMessage | null
      switch (flashMessageScreenRef) {
        case FLASH_MESSAGE_SCREEN_REF.DEVICE_LIST:
          flm = this.state.deviceListFlashMessageRef
          break
        case FLASH_MESSAGE_SCREEN_REF.DEVICE_DETAIL:
          flm = this.state.devicePanelFlashMessageRef
          break
        case FLASH_MESSAGE_SCREEN_REF.INPUT_CONFIG:
          flm = this.state.inputConfigFlashMessageRef
          break
        case FLASH_MESSAGE_SCREEN_REF.MODAL:
          flm = this.state.modalFlashMessageRef
          break
        default:
          flm = null
      }
      if (flm) {
        flm.showMessage(param)
      } else {
        showMessage(param)
      }
    }
  }
}
