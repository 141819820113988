import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { memo, useContext, useMemo, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { DEVICES_CONFIG } from '../../stores/DeviceConfig'
import { RootStoreContext } from '../../stores/RootStore'
import { sharedStyles } from '../../styles/shared'
import { normalTextSize } from '../../styles/variables'
import { ThemedText } from '../themed/ThemedText'
import { SensorPresentationView } from './SensorPresentationView'

const enableShowName = false

export const EWSensorPresentation = memo(
  observer(({ deviceId, deviceType }: { deviceId: string; deviceType: string }) => {
    const rootStore = useContext(RootStoreContext)
    const availableData: Record<string, boolean> = {}
    const sensorConnect = rootStore.deviceListStore.state[`sensorConnect_${deviceId}`]
    const fields: string[] = (DEVICES_CONFIG[deviceType]?.sensorFields || []).filter((field: string) => field.startsWith('ADC'))
    const [sensorDataAvailable, setSensorDataAvailable] = useState(false)
    const [adcDataAvailable, setAdcDataAvailable] = useState(false)
    return (
      <View style={[sharedStyles.paddingHalf, sensorDataAvailable ? {} : sharedStyles.displayNone]}>
        <View style={[sharedStyles.box]}>
          <View style={[sharedStyles.padding, styles.boxUp]}>
            {enableShowName ? (
              <>
                {sensorConnect?.value ? (
                  <ThemedText color="foregroundColor" style={{ fontSize: normalTextSize * 1.6 }}>
                    {sensorConnect?.value}
                  </ThemedText>
                ) : null}
                <View style={styles.seperate} />
              </>
            ) : null}
            <View style={[sharedStyles.horizontal, sharedStyles.justifyContentSpaceBetween, styles.temperature]}>
              <SensorPresentationView
                deviceId={deviceId}
                field={'temperature'}
                onDataExist={(exist) => {
                  availableData[`${deviceId}_temperature`] = exist
                  setSensorDataAvailable(!!Object.keys(availableData).find((field) => availableData[field]))
                }}
              />
              <SensorPresentationView
                deviceId={deviceId}
                field={'humidity'}
                onDataExist={(exist) => {
                  availableData[`${deviceId}_humidity`] = exist
                  setSensorDataAvailable(!!Object.keys(availableData).find((field) => availableData[field]))
                }}
              />
            </View>
          </View>
          <View style={[styles.boxDown, adcDataAvailable ? {} : sharedStyles.displayNone]}>
            <View style={[sharedStyles.horizontal, sharedStyles.justifyContentSpaceBetween]}>
              {fields?.length > 0
                ? fields.map((field) => (
                    <SensorPresentationView
                      key={`sensor_${field}`}
                      deviceId={deviceId}
                      field={field}
                      textColor={'#3D6AA2'}
                      timeBottom={true}
                      onDataExist={(exist) => {
                        availableData[`${deviceId}_${field}`] = exist
                        setSensorDataAvailable(exist || !!Object.keys(availableData).find((f) => availableData[f]))
                        setAdcDataAvailable(exist || !!fields.find((f) => availableData[`${deviceId}_${f}`]))
                      }}
                    />
                  ))
                : null}
            </View>
          </View>
        </View>
      </View>
    )
  }),
)

const styles = StyleSheet.create({
  boxUp: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: '#3D6AA2',
  },
  seperate: {
    marginTop: 5,
    borderBottomWidth: 3,
    width: 20,
    borderColor: '#fff',
  },
  boxDown: {
    padding: 15,
    backgroundColor: '#fff',
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
  },
  temperature: {
    marginTop: 10,
  },
})
