import { EnhancedDevice } from '@devhub/core'
import React, { useCallback, useContext, useState } from 'react'
import { useMutation } from 'react-apollo'
import { View } from 'react-native'
import { useDispatch } from 'react-redux'

import { DELETE_DEVICE, DELETE_DEVICE_SMS } from '../../graphql/gqls'
import * as actions from '../../redux/actions'
import { DEVICES_CONFIG, openAppSMS } from '../../stores/DeviceConfig'
import { RootStoreContext } from '../../stores/RootStore'
import { FLASH_MESSAGE_SCREEN_REF } from '../../stores/UIReferenceStore'
import { sharedStyles } from '../../styles/shared'
import { scaleFactor } from '../../styles/variables'
import { getMessageError } from '../../utils/helpers/shared'
import { ModalColumn } from '../columns/ModalColumn'
import { IconButton } from '../common/IconButton'
import { useAppLayout } from '../context/LayoutContext'
import { ThemedText } from '../themed/ThemedText'
import { UserApproveAttribute } from '../views/UserApproveAttribute'
import { OverlayModal } from '../widgets/OverlayModal'

interface AddDeviceModalProps {
  showBackButton: boolean
}

export const DeviceDetailModal = React.memo((props: AddDeviceModalProps) => {
  const { sizename } = useAppLayout()
  const dispatch = useDispatch()
  const rootStore = useContext(RootStoreContext)
  const { showBackButton } = props
  const [visible, setVisible] = useState<boolean>(false)
  const [deleteDeviceItem, { loading }] = useMutation(DELETE_DEVICE)
  const [deleteDeviceSMS, { loading: loadingSms }] = useMutation(DELETE_DEVICE_SMS)

  const device = rootStore.currentDeviceStore.state.device
  const { isSMSTransport } = DEVICES_CONFIG[device?.type || ''] || {}
  const warningText = isSMSTransport
    ? `Bạn chắc chắn xóa thiết bị này! Hãy gửi thêm sms KPCD sau khi xóa thành công nếu bạn muốn khôi phục cài đặt gốc trên thiết bị`
    : `Bạn chắc chắn xóa thiết bị này! Thiết bị của bạn nên được kết nối internet để có thể thực hiện khôi phục lại cài đặt gốc. Ngược lại, sau khi xóa thiết bị, bạn nên nhấn và giữ nút hơn 20 giây trên thiết bị để thực hiện việc khôi phục cài đặt gốc.`

  const onDeleteItem = useCallback(
    (deviceName?: EnhancedDevice) => {
      if (loading || loadingSms || !device) {
        return
      }
      if (!deviceName?.name) {
        return rootStore.uiReferenceStore.danger(`Thiết bị này không tồn tại`, FLASH_MESSAGE_SCREEN_REF.MODAL)
      }
      if (device?.type && DEVICES_CONFIG[device.type]?.isSMSTransport) {
        deleteDeviceSMS({
          variables: {
            deviceId: device.id.id,
          },
        })
          .then((res) => {
            if (res.data && res.data.deleteDeviceSMS) {
              rootStore.uiReferenceStore.success(`Đã xóa thiết bị ${device?.name}`, FLASH_MESSAGE_SCREEN_REF.MODAL)
              rootStore.deviceListStore.refresh()
              setVisible(false)
              dispatch(actions.popModal())
              rootStore.currentDeviceStore.reclaim()
              if (device?.label) {
                openAppSMS(device?.label, 'KPCD')
              } else {
                rootStore.uiReferenceStore.danger(`Thiết bị chưa được cài đặt số điện thoại`)
              }
            } else {
              rootStore.uiReferenceStore.danger(`Có lỗi xảy ra vui lòng thử lại sau!`, FLASH_MESSAGE_SCREEN_REF.MODAL)
            }
          })
          .catch((error) => {
            rootStore.uiReferenceStore.danger(`${getMessageError(error)}`, FLASH_MESSAGE_SCREEN_REF.MODAL)
          })
      } else if (device?.name) {
        deleteDeviceItem({
          variables: {
            deviceName: device?.name,
          },
        })
          .then((res) => {
            if (res.data && res.data.deleteDevice) {
              rootStore.uiReferenceStore.success(`Đã xóa thiết bị ${device?.name}`, FLASH_MESSAGE_SCREEN_REF.MODAL)
              rootStore.deviceListStore.refresh()
              setVisible(false)
              dispatch(actions.popModal())
              rootStore.currentDeviceStore.reclaim()
            } else {
              rootStore.uiReferenceStore.danger(`Có lỗi xảy ra vui lòng thử lại sau!`, FLASH_MESSAGE_SCREEN_REF.MODAL)
            }
          })
          .catch((error) => {
            rootStore.uiReferenceStore.danger(`${getMessageError(error)}`, FLASH_MESSAGE_SCREEN_REF.MODAL)
          })
      }
    },
    [device],
  )

  return device ? (
    <ModalColumn hideCloseButton={sizename === '1-small'} name="DETAIL_DEVICE" showBackButton={showBackButton} title="Chi tiết thiết bị">
      <View style={sharedStyles.padding}>
        <UserApproveAttribute icon={<IconButton key={`left-button-delete`} family="material" name="delete-forever" size={16 * scaleFactor} tooltip="Xóa thiết bị" onPress={() => setVisible(true)} />} />
      </View>
      <OverlayModal title="Xóa thiết bị" visible={visible} loading={loading} setVisible={setVisible} children={<ThemedText color={'#000000'}>{warningText}</ThemedText>} onClickButtonRight={() => onDeleteItem(device)} />
    </ModalColumn>
  ) : null
})
