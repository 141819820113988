import { IDict } from '@devhub/core/dist'
import { get } from 'dottie'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { SectionListRenderItem, View } from 'react-native'
import { Button } from 'react-native-elements'
import { SectionGrid } from 'react-native-super-grid'
import { Platform } from '../../libs/platform'
import { IOutputConfigItem, IOutputConfigViewProps } from '../../stores/StoreUtils'
import { sharedStyles } from '../../styles/shared'
import { ThemedText } from '../themed/ThemedText'

export interface IBaseMultiPortPanel extends IDict {
  deviceId: string
  sections: IOutputConfigItem[]
  renderItem: SectionListRenderItem<IOutputConfigViewProps>
  actionTitle?: string
  onGroupedAction?: (outputIndexes?: IOutputConfigViewProps[]) => any
}

export const BaseMultiPortPanel = React.memo(
  observer(({ deviceId, sections, actionTitle, renderItem, onGroupedAction, ...others }: IBaseMultiPortPanel) => {
    return (
      <SectionGrid
        key={`layout-control-panel-grid-${deviceId}`}
        style={sharedStyles.gridView}
        itemDimension={62}
        sections={sections}
        spacing={10}
        renderItem={renderItem}
        renderSectionHeader={({ section }) => (
          <View style={[sharedStyles.horizontal, sharedStyles.justifyContentSpaceBetween, sharedStyles.customBackgroundHeader]}>
            <ThemedText style={[sharedStyles.sectionHeader]}>{section.title}</ThemedText>
            {actionTitle ? (
              <Button
                title={actionTitle}
                disabled={Platform.OS === 'android'}
                type={'clear'}
                iconRight={true}
                icon={{
                  name: 'send',
                  size: 15,
                  color: 'white',
                  type: 'material',
                }}
                onPress={() => {
                  if (!onGroupedAction) return
                  if (section.outputIndex === -1) {
                    const all = sections.reduce((pre, current) => {
                      const outputIndexes = get(current, 'data', [])
                      if (outputIndexes.length > 0) {
                        pre.push(...outputIndexes)
                      }
                      return pre
                    }, [])
                    onGroupedAction(all)
                  } else {
                    const outputIndexes = get(section, 'originalData', [])
                    onGroupedAction(outputIndexes)
                  }
                }}
              />
            ) : null}
          </View>
        )}
        {...others}
      />
    )
  }),
)
