import React, { Fragment, useContext, useEffect, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import Collapsible from 'react-native-collapsible'
import { useReduxState } from '../../hooks/use-redux-state'
import { firebaseUIAuth } from '../../libs/firebaseui-auth'
import { formatUserIdentify } from '../../libs/firebaseui-auth/FirebaseUIAuth.shared'
import * as selectors from '../../redux/selectors'
import { RootStoreContext } from '../../stores/RootStore'
import { sharedStyles } from '../../styles/shared'
import { contentPadding } from '../../styles/variables'
import { Avatar } from '../common/Avatar'
import { foregroundThemeColor, loadingIndicatorStyle } from '../common/Button'
import { sidebarWidth } from '../common/SidebarOrBottomBar'
import { Spacer } from '../common/Spacer'
import { SubHeader } from '../common/SubHeader'
import { useTheme } from '../context/ThemeContext'
import { ThemedActivityIndicator } from '../themed/ThemedActivityIndicator'
import { ThemedText } from '../themed/ThemedText'
import { LogoutView } from '../views/LogoutView'

const itemsHeader = ['Tên hiển thị', 'Định danh']

const defaultAvatar = '@devhub/components/assets/logo_circle.png'

export const UserProfile = () => {
  const rootStore = useContext(RootStoreContext)
  const currentUser = rootStore.appConfig.state.currentFirebaseUser
  const theme = useTheme()
  const user = useReduxState(selectors.currentUserSelector)
  const [expanded, setExpanded] = useState<boolean>(true)

  useEffect(() => {
    if (!currentUser) {
      firebaseUIAuth.loadCurrentUser()
    }
  }, [currentUser])

  const itemsBody = [currentUser?.displayName || '--', formatUserIdentify(user?.email) || '--']

  const infoUserCurrent = (item: string, index: number) => {
    return (
      <Fragment key={`current-user-${index}`}>
        <View style={[sharedStyles.horizontal, sharedStyles.justifyContentSpaceBetween]}>
          <ThemedText color="foregroundColorMuted65">{itemsHeader[index]}</ThemedText>
          <ThemedText color="foregroundColor">{item}</ThemedText>
        </View>
        <View
          style={{
            borderBottomWidth: 1,
            borderColor: theme.foregroundColor,
            marginTop: contentPadding / 2,
            marginBottom: contentPadding / 2,
          }}
        />
      </Fragment>
    )
  }

  return (
    <View>
      <TouchableOpacity onPress={() => setExpanded(!expanded)}>
        <SubHeader title="Thông tin cá nhân">
          <Spacer flex={1} />
          {!currentUser ? (
            <ThemedActivityIndicator color={foregroundThemeColor} size="small" style={[loadingIndicatorStyle]} />
          ) : (
            <Avatar avatarUrl={currentUser?.photoURL || defaultAvatar} disableLink shape="circle" size={sidebarWidth * (3 / 5)} />
          )}
        </SubHeader>
      </TouchableOpacity>
      <Collapsible collapsed={expanded}>
        <View style={{ paddingHorizontal: contentPadding }}>{itemsBody.map((item: string, index: number) => infoUserCurrent(item, index))}</View>
        <LogoutView showDeActiveAccount={true} />
      </Collapsible>
    </View>
  )
}
