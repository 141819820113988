import { Layout, Popover } from '@ui-kitten/components'
import React, { Dispatch, FC } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { sharedStyles } from '../../styles/shared'
import { smallerTextSize } from '../../styles/variables'
import { getButtonColors } from '../common/Button'
import { ThemedIcon } from '../themed/ThemedIcon'
import { ThemedText } from '../themed/ThemedText'

type PLACEMENT_VALUE = 'top' | 'top start' | 'top end' | 'bottom' | 'bottom start' | 'bottom end' | 'left' | 'left start' | 'left end' | 'right' | 'right start' | 'right end'

interface Props {
  value?: string | number
  children: React.ReactNode
  placement?: PLACEMENT_VALUE
  visible: boolean
  setVisible: Dispatch<boolean>
}

export const DeviceListSMS: FC<Props> = ({ value, children, placement = 'top', visible, setVisible }) => {
  const { foregroundThemeColor } = getButtonColors()

  const renderToggleButton = () => (
    <TouchableOpacity onPress={() => setVisible(!visible)}>
      <View style={[sharedStyles.horizontal, sharedStyles.alignItemsCenter]}>
        <ThemedText style={{ fontSize: smallerTextSize, marginRight: 10 }} color={'foregroundColorMuted65'}>
          {value}
        </ThemedText>
        <ThemedIcon color={foregroundThemeColor} family="material" name={'format-indent-increase'} size={16} />
      </View>
    </TouchableOpacity>
  )

  const onClose = () => {
    setVisible(false)
  }

  return (
    <Popover placement={placement} visible={visible} anchor={renderToggleButton} onBackdropPress={onClose}>
      <Layout>{children}</Layout>
    </Popover>
  )
}
