import { ViewPager } from '@ui-kitten/components'
import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { View } from 'react-native'
import { Button, Tab } from 'react-native-elements'
import { useReduxState } from '../../hooks/use-redux-state'
import * as selectors from '../../redux/selectors'
import { RootStoreContext } from '../../stores/RootStore'
import { sharedStyles } from '../../styles/shared'
import { InputConfigForMultiOutputDevice } from './InputConfigForMultiOutputDevice'
import { MultiOutputConfig } from './MultiOutputConfig'
import { UserListShareDevice } from './UserListShareDevice'

export const OUTPUT = 'OUTPUT'
export const INPUT = 'INPUT'
export const MANAGER = 'MANAGER'

export interface IConfigTab {
  title: string
  code: string
  icon?: string
  iconRight?: boolean
}

export const MultiPortDeviceConfig = observer(
  ({
    params = [
      {
        title: 'Đầu ra',
        code: OUTPUT,
        icon: 'subdirectory-arrow-right',
      },
      {
        title: 'Đầu dò',
        code: INPUT,
        icon: 'input',
        iconRight: true,
      },
    ],
  }: {
    params?: IConfigTab[]
  }) => {
    const rootStore = useContext(RootStoreContext)
    const deviceId = rootStore.currentDeviceStore.getDeviceId()
    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const customerId = useReduxState(selectors.currentCustomerIdSelector)

    return (
      <View style={[sharedStyles.flex, sharedStyles.justifyContentFlexStart, sharedStyles.paddingVerticalHalf]}>
        <Tab key={`tab-config-multi-output-${deviceId}`}>
          <View style={[sharedStyles.flex, sharedStyles.alignItemsCenter, sharedStyles.horizontal, sharedStyles.justifyContentCenter]}>
            {params?.map(({ title, code, icon, iconRight }, index) => (
              <Button
                key={`tab_config_${code}_${index}`}
                title={title}
                type={selectedIndex === index ? 'solid' : `clear`}
                onPress={() => setSelectedIndex(index)}
                iconRight={iconRight}
                icon={{
                  name: icon,
                  size: 15,
                  color: 'white',
                  type: 'material',
                }}
              />
            ))}
          </View>
        </Tab>
        <ViewPager swipeEnabled={false} selectedIndex={selectedIndex}>
          {params?.map(({ code, icon, iconRight }, index) => {
            if (code === MANAGER) return <UserListShareDevice key={`layout-manager-panel-${code}`} deviceId={deviceId} currentCustomerId={customerId} style={[sharedStyles.paddingHorizontalHalf]} />
            if (code === INPUT) return <InputConfigForMultiOutputDevice key={`layout-input-config-${code}`} />
            return <MultiOutputConfig key={`layout-control-panel-${code}`} />
          })}
        </ViewPager>
      </View>
    )
  },
)
