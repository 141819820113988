import { observer } from 'mobx-react-lite'
import React, { useContext, useRef } from 'react'
import { View } from 'react-native'
import { Button } from 'react-native-elements'
import { Platform } from '../../libs/platform'
import { DEVICES_CONFIG, openAppSMS, SCHEDULE, SCHEDULE_ON } from '../../stores/DeviceConfig'
import { RootStoreContext } from '../../stores/RootStore'
import { FLASH_MESSAGE_SCREEN_REF } from '../../stores/UIReferenceStore'
import { sharedStyles } from '../../styles/shared'
import { contentPadding } from '../../styles/variables'
import { Spacer } from '../common/Spacer'
import { ControlPanel } from './ControlPanel'
import { IOnOffControlRef, OnOffControlCardView } from './OnOffControlCardView'
import { ScheduleIndicatorList, ScheduleIndicatorListRef } from './ScheduleIndicatorList'

export const SinglePortPanel = React.memo(
  observer(({ deviceId }: { deviceId: string }) => {
    const rootStore = useContext(RootStoreContext)
    const schedulesByPort = rootStore.deviceListStore.state[`${deviceId}_${SCHEDULE}`]
    const storeValue = rootStore.deviceListStore.state[`${deviceId}_${SCHEDULE_ON}`]
    const scheduleOn = storeValue === 'true' || storeValue === true

    const scheduleRef = useRef<ScheduleIndicatorListRef>(null)
    const scheduleOnRef = useRef<IOnOffControlRef>(null)
    const device = rootStore.deviceListStore.findDeviceById(deviceId)
    const { isSMSTransport, buildScheduleMessage } = DEVICES_CONFIG[device?.type || ''] || {}

    return (
      <>
        <ControlPanel deviceId={deviceId} isSimpleView={true} textStyle={[sharedStyles.marginVertical]} style={[sharedStyles.vertical, sharedStyles.justifyContentCenter, sharedStyles.alignItemsCenter]} />
        <Spacer height={contentPadding} />
        <OnOffControlCardView deviceId={deviceId} method={'setScheduleOn'} active={scheduleOn} isToggle={true} ref={scheduleOnRef} />
        <Spacer height={contentPadding} />
        {deviceId ? <ScheduleIndicatorList deviceId={deviceId} device={device} schedulesByPort={schedulesByPort ? schedulesByPort[`1`] : undefined} ref={scheduleRef} flashMessageRef={FLASH_MESSAGE_SCREEN_REF.DEVICE_DETAIL} /> : null}
        {isSMSTransport ? (
          <View style={[sharedStyles.center, sharedStyles.marginVertical]}>
            <Button
              disabled={Platform.OS === 'android'}
              title="Gửi SMS"
              type={`solid`}
              iconRight={true}
              icon={{
                name: 'send',
                size: 15,
                color: 'white',
                type: 'material',
              }}
              containerStyle={{
                width: 150,
              }}
              onPress={() => {
                if (device?.label) {
                  const sOn = scheduleOnRef?.current?.value()
                  const { inputKey, newValue } = scheduleRef?.current?.value() || {}
                  if (buildScheduleMessage) {
                    scheduleRef?.current?.save()
                    const smsBody = buildScheduleMessage(device, sOn, inputKey, newValue)
                    openAppSMS(device?.label, smsBody)
                  } else {
                    rootStore.uiReferenceStore.danger(`Thiết bị ${device?.type} chưa cài đặt cú pháp sms lịch biểu`, FLASH_MESSAGE_SCREEN_REF.DEVICE_DETAIL)
                  }
                } else {
                  rootStore.uiReferenceStore.danger(`Thiết bị chưa được cài đặt số điện thoại`, FLASH_MESSAGE_SCREEN_REF.DEVICE_DETAIL)
                }
              }}
            />
          </View>
        ) : null}
      </>
    )
  }),
)
