import { IDict, IKeyFunction, randomBetween } from '@devhub/core/dist'
import { get } from 'dottie'
import { LATEST_TELEMETRY, SubscriptionUpdate, UNKNOWN_SUBSCRIPTION } from './CurrentDeviceStore'
import { RootStore } from './RootStore'
import { WebSocketClient } from './WebSocketClient'

export class AppWebSocketClient {
  rootStore: RootStore
  client: WebSocketClient
  deviceIdSubscriptionMap: { [deviceId: string]: number }

  constructor(
    rootStore: RootStore,
    {
      onMessages,
      onOpens,
    }: {
      onMessages: Array<(subscriptionUpdate: SubscriptionUpdate | null) => boolean>
      onOpens?: Array<(client: WebSocketClient) => any>
    },
  ) {
    this.rootStore = rootStore
    this.deviceIdSubscriptionMap = {}
    this.client = new WebSocketClient()
    this.client.onopen = () => {
      if (onOpens) {
        onOpens.forEach((open) => open(this.client))
      }
    }

    this.client.onmessage = (message: { data: string }) => {
      const subscriptionUpdate = message ? (JSON.parse(message.data) as SubscriptionUpdate) : null
      if (subscriptionUpdate !== null) {
        if (subscriptionUpdate.errorCode === 0) {
          onMessages.every((onMessage) => onMessage(subscriptionUpdate))
        }
      }
    }
  }

  getSubscriptionType = (subscriptionId: number | undefined, deviceId: string) => {
    if (subscriptionId !== undefined) {
      if (this.deviceIdSubscriptionMap[deviceId] === subscriptionId) {
        return LATEST_TELEMETRY
      }
    }
    return UNKNOWN_SUBSCRIPTION
  }

  getDeviceId(subscriptionId: number | undefined) {
    let deviceId
    if (subscriptionId !== undefined) {
      deviceId = Object.keys(this.deviceIdSubscriptionMap).find((deviceIdKey) => this.deviceIdSubscriptionMap[deviceIdKey] === subscriptionId)
    }
    return deviceId
  }

  subscribeDevice(deviceId: string, keys: string[] = []) {
    if (deviceId) {
      console.log(`subscribeDevice sending message with deviceId: ${deviceId}`)
      let cmdId = this.deviceIdSubscriptionMap[deviceId]
      if (!cmdId) {
        cmdId = randomBetween(1, 10000)
        this.deviceIdSubscriptionMap[deviceId] = cmdId
      }
      const tsSubCmd: IDict = {
        entityType: 'DEVICE',
        entityId: deviceId,
        scope: 'LATEST_TELEMETRY',
        cmdId,
        // keys: [STATUS,SCHEDULE_ON].join(",")
      }
      if (keys && keys.length > 0) {
        tsSubCmd.keys = keys.join(',')
      }
      const variables = {
        tsSubCmds: [tsSubCmd],
      }
      this.client.send(JSON.stringify(variables))
    }
  }
}

export const extractValues = (subscriptionUpdate: SubscriptionUpdate, updateValues: IKeyFunction, path = 'data') => {
  const subscriptionData = get(subscriptionUpdate, path, {})
  if (Array.isArray(subscriptionData)) {
    subscriptionData.forEach((keyValue) => {
      const property = keyValue.key
      if (property) {
        const splits = property.split('_')
        const field = splits.length > 0 ? splits[0] : property
        const index = splits.length >= 2 ? Number(splits[splits.length - 1]) : -1

        if (!Number.isNaN(index)) {
          const lastUpdateTs = keyValue.lastUpdateTs
          if (updateValues[field]) {
            updateValues[field](keyValue.value, property, index, lastUpdateTs)
          }
        }
      }
    })
  } else {
    Object.keys(subscriptionData).forEach((property) => {
      const statuses: Array<[number, string | boolean] | any> = get(subscriptionData, `${property}`, [])
      if (statuses.length > 0) {
        const splits = property.split('_')
        const field = splits.length > 0 ? splits[0] : property
        const index = splits.length >= 2 ? Number(splits[splits.length - 1]) : -1
        if (updateValues[field] && !Number.isNaN(index)) {
          if (statuses[0].length >= 2) {
            const lastUpdateTs = statuses[0][0]
            updateValues[field](statuses[0][1], property, index, lastUpdateTs)
          } else if (statuses[0].value !== undefined) {
            const lastUpdateTs = statuses[0].ts
            updateValues[field](statuses[0].value, property, index, lastUpdateTs)
          }
        }
      }
    })
  }
}
