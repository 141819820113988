import React, { memo, useContext } from 'react'
import { Mutation } from 'react-apollo'
import { Button } from 'react-native-elements'
import { RPCCallingInput, RPCCallingResponse, SAVE_SERVER_SCOPE_ATTRIBUTE } from '../../graphql/gqls'
import { SHARED_TO_PHONE_NUMBERS } from '../../stores/DeviceConfig'
import { RootStoreContext } from '../../stores/RootStore'

interface SmsSharedButtonProps {
  deviceId: string
  users: string[]
  beShareUser: string
  deleteIndex?: number
  containerStyle?: any
  onSuccess?: () => any
}

export const SmsSharedButton = memo(({ deviceId, users = [], beShareUser, deleteIndex, containerStyle = {}, onSuccess }: SmsSharedButtonProps) => {
  const rootStore = useContext(RootStoreContext)
  const shouldDelete = deleteIndex !== undefined && deleteIndex >= 0 && deleteIndex < users.length
  return (
    <Mutation<RPCCallingResponse, RPCCallingInput> mutation={SAVE_SERVER_SCOPE_ATTRIBUTE}>
      {(updateCalling, { loading }) => (
        <Button
          loading={loading}
          title={shouldDelete ? `Xóa` : `Thêm quản lý ${beShareUser}`}
          type={shouldDelete ? 'clear' : `solid`}
          iconRight={true}
          icon={{
            name: beShareUser ? 'share' : 'delete',
            size: 15,
            color: 'white',
            type: 'material',
          }}
          containerStyle={containerStyle}
          onPress={() => {
            let newUsers = users
            if (shouldDelete) {
              users.splice(deleteIndex || 0, 1)
              newUsers = users
            } else if (deleteIndex === undefined) {
              newUsers = Array.from(new Set([...users, beShareUser]))
            }

            updateCalling({
              // @ts-ignore
              variables: {
                deviceId,
                input: {
                  [SHARED_TO_PHONE_NUMBERS]: newUsers,
                },
              },
            })
              .then(() => {
                rootStore.deviceListStore.state[`${SHARED_TO_PHONE_NUMBERS}_${deviceId}`] = newUsers
                if (onSuccess) {
                  onSuccess()
                }
              })
              .catch((e: any) => {
                console.error(`SHARED_TO_PHONE_NUMBERS updateCalling error`, e)
              })
          }}
        />
      )}
    </Mutation>
  )
})
