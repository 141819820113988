import { IDict, IDictArray } from '@devhub/core/dist'
import { observable } from 'mobx'
import { persist } from 'mobx-persist'
import moment from 'moment'
import 'moment/locale/vi'
import * as actions from '../redux/actions'
import { store } from '../redux/store'
import { HOSTS, hostsByEnv } from '../utils/constants'
import { RootStore } from './RootStore'
import { getRequestOption, initTimeSeriesParams } from './StoreUtils'

export const TOKEN_ALIVE_DURATION_IN_MINUTE = 60
export const TYPE_VIEW = {
  LIST: 'list',
  GIRD: 'gird',
}

export interface StoreValue {
  fcmDeviceToken: string
  token: string
  refreshToken: string
  currentEnv: string
  enableDebug: boolean
  typeDisplayDevice: string
  latestReceiveTokenTs: number
  currentFirebaseUser: any
  loadingNotification: boolean
  notifications: IDict
  generalNotifications: IDict
}

const data: StoreValue = observable({
  fcmDeviceToken: '',
  token: '',
  refreshToken: '',
  currentEnv: 'product',
  enableDebug: false,
  typeDisplayDevice: TYPE_VIEW.LIST,
  latestReceiveTokenTs: 0,
  currentFirebaseUser: undefined,
  loadingNotification: false,
  notifications: {},
  generalNotifications: {},
})

const schema = {
  fcmDeviceToken: true,
  token: true,
  refreshToken: true,
  currentEnv: true,
  enableDebug: true,
  typeDisplayDevice: true,
  latestReceiveTokenTs: true,
  currentFirebaseUser: false,
  loadingNotification: false,
  notifications: false,
  generalNotifications: false,
}

const state = persist(schema)(data)

export class AppConfigStore {
  rootStore: RootStore

  state: StoreValue

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.state = state
  }
  public reset() {
    this.state.token = ''
    this.state.refreshToken = ''
    this.state.latestReceiveTokenTs = 0
  }

  getCurrentEnv() {
    return this.state.currentEnv
  }

  getCurrentEnvIndex() {
    return HOSTS.findIndex((host) => host.env === this.state.currentEnv)
  }
  getCurrentTenantId() {
    return `${hostsByEnv[this.state.currentEnv]?.tenants[hostsByEnv[this.state.currentEnv].defaultTenantId].id}`
  }

  setCurrentEnv(envType: string) {
    this.state.currentEnv = envType || 'product'
  }

  getAppBaseHostUri() {
    return `${hostsByEnv[this.state.currentEnv]?.uri || 'https'}://${hostsByEnv[this.state.currentEnv]?.host}`
  }

  getWebSocketURI() {
    return `${hostsByEnv[this.state.currentEnv]?.websocketSchema || 'wss'}://${hostsByEnv[this.state.currentEnv]?.host}/api/ws/plugins/telemetry?token=${this.state.token}`
  }

  toggleEnable(value: boolean) {
    this.state.enableDebug = value
  }

  onDisplayDeviceView(value: string) {
    this.state.typeDisplayDevice = value
  }

  setToken(token: string, refreshToken: string) {
    this.state.token = token
    this.state.refreshToken = refreshToken
    if (token) {
      this.state.latestReceiveTokenTs = moment().unix()
      this.rootStore.appWebSocketClient.client.open(this.getWebSocketURI())
    } else {
      console.log('openSocket token empty')
    }
  }

  shouldRequestTokenIfExpired() {
    const expiredTokenMoment = moment.unix(this.state.latestReceiveTokenTs).add(TOKEN_ALIVE_DURATION_IN_MINUTE - 1, 'minutes')
    if (expiredTokenMoment.isBefore(moment())) {
      console.log('shouldRequestTokenIfExpired dispatch actions refreshToken')
      store.dispatch(
        actions.refreshToken({
          refreshToken: this.state.refreshToken,
        }),
      )
    } else {
      console.log('shouldRequestTokenIfExpired not at this time')
    }
  }

  signInByIdToken(idToken: string) {
    const tenantId = this.getCurrentTenantId()
    const fcm = this.state.fcmDeviceToken ? { fcmDeviceToken: this.state.fcmDeviceToken } : {}
    const body = { idToken, tenantId, ...fcm }
    fetch(`${this.rootStore.appConfig.getAppBaseHostUri()}/api/noauth/signin/idtoken`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        const { token, refreshToken }: { token: string; refreshToken: string } = jsonData
        if (token && refreshToken) {
          store.dispatch(
            actions.loginRequest({
              appToken: token,
              refreshToken,
            }),
          )
        } else {
          console.log(`signInByIdToken data error `, jsonData)
        }
      })
      .catch((e) => {
        console.log(`signInByIdToken error `, e)
      })
  }

  async fetchCustomerNotifications(): Promise<IDictArray | undefined> {
    if (this.state.loadingNotification) {
      return
    }
    const reduxState = store.getState()
    const customerId = reduxState?.auth?.user?.customerId?.id
    const token = this.state.token
    if (customerId && token) {
      console.log(`fetchNotifications of customerId: ${customerId}`)
      const requestOptions = getRequestOption(token)
      const notificationKey = `notification`
      const { params, variables } = initTimeSeriesParams(notificationKey)
      this.state.loadingNotification = true
      const result = await fetch(`${this.rootStore.appConfig.getAppBaseHostUri()}/api/plugins/telemetry/CUSTOMER/${customerId}/values/timeseries?${params}`, requestOptions)
        .then((response) => response.json())
        .then((timeSeries: IDictArray) => {
          this.rootStore.appConfig.state.notifications = { values: timeSeries[notificationKey], variables }
          this.state.loadingNotification = false
          return timeSeries
        })
        .catch((e) => {
          console.error(`fetchNotifications notification error`, e)
          this.state.loadingNotification = false
          return undefined
        })
      return result
    }
  }

  async fetchGeneralNotifications() {
    if (this.state.loadingNotification) {
      return
    }
    const token = this.state.token
    if (token) {
      console.log(`fetchGeneralNotifications`)
      const requestOptions = getRequestOption(token)
      const notificationKey = `notification`
      const { params, variables } = initTimeSeriesParams(notificationKey)
      this.state.loadingNotification = true
      const result = await fetch(`${this.rootStore.appConfig.getAppBaseHostUri()}/api/plugins/telemetry/notification/general?${params}`, requestOptions)
        .then((response) => response.json())
        .then((timeSeries: IDictArray) => {
          this.rootStore.appConfig.state.generalNotifications = { values: timeSeries[notificationKey], variables }
          this.state.loadingNotification = false
          return timeSeries
        })
        .catch((e) => {
          console.error(`fetchNotifications  generalNotifications error`, e)
          this.state.loadingNotification = false
          return undefined
        })
      return result
    }
  }

  setFCMDeviceToken(fcmDeviceToken: string) {
    this.state.fcmDeviceToken = fcmDeviceToken
  }
}
