import React from 'react'
import { View } from 'react-native'
import { sharedStyles } from '../../styles/shared'
import { contentPadding, scaleFactor, smallTextSize } from '../../styles/variables'
import { FACEBOOK_PAGE, YOUTUBE_PAGE } from '../../utils/constants'
import { Link } from '../common/Link'
import { Spacer } from '../common/Spacer'
import { SubHeader } from '../common/SubHeader'

export const ContactView = ({ title }: { title?: string }) => {
  const links = (
    <View style={[sharedStyles.horizontal, sharedStyles.justifyContentCenter, sharedStyles.flexWrap]}>
      <Link
        analyticsCategory="preferences_link"
        analyticsLabel="twitter"
        openOnNewTab
        textProps={{
          color: 'foregroundColor',
          style: {
            fontSize: smallTextSize,
            lineHeight: smallTextSize * 1.5,
            textAlign: 'center',
          },
        }}
        href={YOUTUBE_PAGE}
      >
        Youtube
      </Link>
      <Spacer width={1.5 * contentPadding} />
      <Link
        analyticsCategory="preferences_link"
        analyticsLabel="github"
        openOnNewTab
        textProps={{
          color: 'foregroundColor',
          style: {
            fontSize: smallTextSize,
            lineHeight: smallTextSize * 1.5,
            textAlign: 'center',
          },
        }}
        href={FACEBOOK_PAGE}
      >
        Facebook
      </Link>
      {title ? null : <></>}
    </View>
  )
  return (
    <View style={{ minHeight: 32 * scaleFactor }}>
      {title ? (
        <SubHeader title={title}>
          <Spacer flex={1} />
          {links}
        </SubHeader>
      ) : (
        links
      )}
    </View>
  )
}
