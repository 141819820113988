import React, { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import NumericInput, { INumericInputProps } from 'react-native-numeric-input'
import { RootStoreContext } from '../../stores/RootStore'
import { sharedStyles } from '../../styles/shared'
import { TOTAL_HEIGHT, TOTAL_WIDTH } from '../../utils/constants'
import { InputConfigRow } from '../columns/InputConfigRow'

export interface IInputSpinnerRow extends INumericInputProps {
  subject?: string
  tooltip?: string
}
export interface IInputSpinnerRowRef {
  resetValue: () => void
  value: () => number
}

export const InputSpinnerRow = forwardRef<IInputSpinnerRowRef, IInputSpinnerRow>(({ initValue = 0, subject = '', tooltip = '', onChange, minValue, maxValue, ...others }, ref) => {
  const rootStore = useContext(RootStoreContext)
  const [currentValue, setCurrentValue] = useState<number>(initValue)
  useImperativeHandle(ref, () => ({ resetValue: () => setCurrentValue(initValue), value: () => currentValue }), [initValue, currentValue])
  useEffect(() => setCurrentValue(initValue), [initValue])

  return (
    <InputConfigRow subject={subject} tooltip={tooltip} inline={true} fontSize={12} style={[sharedStyles.alignItemsCenter]} subStyle={[sharedStyles.marginHorizontalQuarter]}>
      <NumericInput
        initValue={currentValue}
        value={currentValue}
        step={1}
        minValue={minValue}
        maxValue={maxValue}
        rounded
        type="up-down"
        validateOnBlur
        totalWidth={TOTAL_WIDTH}
        totalHeight={TOTAL_HEIGHT}
        textColor="#59656F"
        buttonStyles={{ backgroundColor: '#59656F3F' }}
        upDownButtonsBackgroundColor={'transparent'}
        onChange={(num: number) => {
          setCurrentValue(num)
          if (onChange) {
            onChange(num)
          }
        }}
        onLimitReached={(isMax, msg) => {
          console.log(msg, isMax)
          rootStore.uiReferenceStore.danger(`Số phải nằm trong đoạn từ ${minValue} đến ${maxValue}`)
        }}
        {...others}
      />
    </InputConfigRow>
  )
})
