import React from 'react'
import { DeviceColumn } from '../components/columns/DeviceColumn'
import { DeviceDetailColumn } from '../components/columns/DeviceDetailColumn'
import { InputConfigColumn } from '../components/columns/InputConfigColumn'
import { useAppColumn } from '../hooks/use-column'
import { bugsnag } from '../libs/bugsnag'

export interface ColumnContainerProps {
  columnId: string
  pagingEnabled?: boolean
  swipeable: boolean
}

export const ColumnContainer = React.memo((props: ColumnContainerProps) => {
  const { columnId, pagingEnabled, swipeable } = props

  const { column, columnIndex, headerDetails } = useAppColumn(columnId)
  /*  const column: DeviceColumnType | Column = {
    id: 'device',
    type: 'device',
    subscriptionIds: [],
    subscriptionIdsHistory: [],
    createdAt: 'createdAt',
    updatedAt: 'updatedAt',
  } as DeviceColumnType*/

  if (!(column && columnIndex >= 0 && headerDetails)) return null

  switch (column.type) {
    case 'device': {
      return (
        <DeviceColumn
          key={`device-column-id`}
          columnId={columnId}
          columnIndex={columnIndex}
          headerDetails={headerDetails}
          pagingEnabled={pagingEnabled}
          swipeable={swipeable}
        />
      )
    }

    case 'deviceDetail': {
      return (
        <DeviceDetailColumn
          key={`deviceDetail-column-id`}
          columnId={columnId}
          columnIndex={columnIndex}
          headerDetails={headerDetails}
          pagingEnabled={pagingEnabled}
          swipeable={swipeable}
        />
      )
    }

    case 'inputConfig': {
      return (
        <InputConfigColumn
          key={`inputConfig-column-id`}
          columnId={columnId}
          columnIndex={columnIndex}
          headerDetails={headerDetails}
          pagingEnabled={pagingEnabled}
          swipeable={swipeable}
        />
      )
    }

    default: {
      const message = `Invalid Column type: ${column && (column as any).type}`
      console.error(message, { column })
      bugsnag.notify(new Error(message))
      return null
    }
  }
})

ColumnContainer.displayName = 'ColumnContainer'
