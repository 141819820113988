import { observer } from 'mobx-react-lite'
import React, { memo, useContext, useEffect, useMemo, useState } from 'react'
import { Mutation } from 'react-apollo'
import { View } from 'react-native'
import Collapsible from 'react-native-collapsible'
import { DataTable } from 'react-native-paper'
import { RPCCallingInput, RPCCallingResponse, SAVE_SERVER_SCOPE_ATTRIBUTE } from '../../graphql/gqls'
import { INPUT_CONFIG, INPUT_MESSAGE } from '../../stores/DeviceConfig'
import { RootStoreContext } from '../../stores/RootStore'
import { getActiveBackGround, getActiveColor, moreWidenCellStyle, sharedStyles, singleCellStyle, webSharedStyles } from '../../styles/shared'
import { contentPadding, smallTextSize } from '../../styles/variables'
import { Spacer } from '../common/Spacer'
import { TouchableOpacity } from '../common/TouchableOpacity'
import { useTheme } from '../context/ThemeContext'
import { ThemedTextInputWithIcon } from '../elements/ThemedTextInputWithIcon'
import { ThemedText } from '../themed/ThemedText'
import { submitMessage } from './EditableTextWithSave'
import { IInputConfig } from './MutableInputMessage'

export interface IInputConfigItemRow {
  deviceId: string
  config: IInputConfig
  highlight?: boolean
  onLongPress?: (e: any) => void
  onSelected?: (selected: IInputConfig) => void
}

export const InputConfigItemRow = memo(
  observer(({ deviceId, config, highlight = false, onLongPress, onSelected }: IInputConfigItemRow) => {
    const theme = useTheme()
    const rootStore = useContext(RootStoreContext)
    const [expanded, setExpanded] = useState<boolean>(false)
    const { inputOrderNumber, name } = config
    const inputConfig = rootStore.deviceListStore.state[`${INPUT_CONFIG}_${inputOrderNumber}_${deviceId}`]
    const inputMessage = rootStore.currentDeviceStore.getAttributeByNumber(INPUT_MESSAGE, config.inputOrderNumber)

    const inputMessageContent = useMemo(() => {
      const text = `${typeof inputMessage?.content === 'string' && inputMessage?.content ? inputMessage.content : ''}`
      return text || config?.message || ''
    }, [inputMessage, config])

    const [currentText, setCurrentText] = useState<string>(inputMessageContent)
    useEffect(() => setCurrentText(inputMessageContent), [inputMessageContent])

    const highlightStyle = highlight ? getActiveBackGround(true, '#424343') : {}
    return (
      <Mutation<RPCCallingResponse, RPCCallingInput> mutation={SAVE_SERVER_SCOPE_ATTRIBUTE}>
        {(updateCalling, { loading }) => (
          <View style={[sharedStyles.vertical, sharedStyles.fullWidth]}>
            <Collapsible collapsed={!expanded}>
              <DataTable.Row>
                <ThemedTextInputWithIcon
                  textInputKey="OutputConfigRow-output-input-key"
                  iconName={'save'}
                  family={'material'}
                  value={currentText}
                  style={[sharedStyles.fullWidth]}
                  autoCapitalize="none"
                  onChangeText={(text: string) => setCurrentText(text)}
                  onClick={() => {
                    setExpanded(false)
                    submitMessage(deviceId, updateCalling, loading, config.inputOrderNumber, currentText, rootStore)
                  }}
                />
              </DataTable.Row>
            </Collapsible>
            <View style={[sharedStyles.horizontal, sharedStyles.justifyContentFlexStart, sharedStyles.marginVerticalQuarter]}>
              <TouchableOpacity onLongPress={onLongPress} style={[sharedStyles.horizontal, sharedStyles.justifyContentCenter]}>
                <Spacer width={contentPadding * 1.5} backgroundColor={getActiveColor((inputConfig?.content?.content || '').startsWith('B'))} />
              </TouchableOpacity>
              <DataTable.Row onPress={() => (onSelected ? onSelected(config) : undefined)} style={[highlightStyle, sharedStyles.fullWidth]}>
                <DataTable.Cell style={[singleCellStyle]}>
                  <ThemedText color="foregroundColor" style={[moreWidenCellStyle, { fontSize: smallTextSize }]}>
                    {name}
                  </ThemedText>
                </DataTable.Cell>
                <DataTable.Cell style={[moreWidenCellStyle]}>
                  <View style={[moreWidenCellStyle, sharedStyles.justifyContentCenter]}>
                    <ThemedText color={theme.foregroundColor} numberOfLines={2} ellipsizeMode="tail" style={[sharedStyles.flexWrap, webSharedStyles.wrapText]} onPress={() => setExpanded((pre) => !pre)}>
                      {inputMessageContent}
                    </ThemedText>
                  </View>
                </DataTable.Cell>
              </DataTable.Row>
            </View>
          </View>
        )}
      </Mutation>
    )
  }),
)
