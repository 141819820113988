import { MenuItem, OverflowMenu } from '@ui-kitten/components'
import React, { FC, useState } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { useDispatch } from 'react-redux'
import * as actions from '../../redux/actions'
import { ThemedText } from '../themed/ThemedText'

interface Props {
  title: string
}

export const UpdateDeviceNamePopover: FC<Props> = ({ title }) => {
  const dispatch = useDispatch()
  const [visible, setVisible] = useState<boolean>(false)
  const [isInputUpdate, setIsUpdate] = useState<boolean>(false)

  const renderToggleButton = () => (
    <ThemedText color="foregroundColorMuted65" onPress={() => dispatch(actions.pushModal({ name: 'DETAIL_DEVICE' }))}>
      {title}
    </ThemedText>
  )

  let style = {} as StyleProp<ViewStyle>
  if (isInputUpdate) {
    style = { width: 200 }
  }

  return (
    <View>
      <OverflowMenu
        visible={visible}
        anchor={renderToggleButton}
        style={style}
        onBackdropPress={() => {
          setVisible(false)
          setIsUpdate(false)
        }}
      >
        <MenuItem
          title="Chi tiết thiết bị"
          onPress={() => {
            dispatch(actions.pushModal({ name: 'DETAIL_DEVICE' }))
            setVisible(false)
          }}
        />
      </OverflowMenu>
    </View>
  )
}
