import React from 'react'
import { StyleSheet, View } from 'react-native'
import { AppUpdateDialog } from '../../libs/update-notify/AppUpdateDialog'
import { sharedStyles } from '../../styles/shared'
import { contentPadding, normalTextSize, smallerTextSize } from '../../styles/variables'
import { PRIVACY_POLICY, TERM_OF_SERVICE } from '../../utils/constants'
import { Link } from './Link'
import { Spacer } from './Spacer'

const styles = StyleSheet.create({
  appVersionLink: {
    alignSelf: 'center',
  },

  appVersion: {
    alignSelf: 'center',
    fontSize: normalTextSize,
    lineHeight: normalTextSize * 1.5,
    textAlign: 'center',
  },
})

export const AppVersion = () => (
  <View style={[sharedStyles.vertical, sharedStyles.center]}>
    <Link
      analyticsLabel="app_version"
      openOnNewTab
      style={styles.appVersionLink}
      textProps={{
        color: 'foregroundColorMuted65',
        style: styles.appVersion,
      }}
    >
      <AppUpdateDialog showButton={true} />
    </Link>
    {/*<Link
      analyticsLabel="app_version"
      openOnNewTab
      style={styles.appVersionLink}
      textProps={{
        color: 'foregroundColorMuted65',
        style: { ...styles.appVersion, fontSize: smallerTextSize },
      }}
    >
      {`OS: ${Platform.OS} realOS: ${Platform.realOS} sms: ${Platform.isSupportLazicoSms()}`}
    </Link>*/}
    <View style={[sharedStyles.horizontal, sharedStyles.flexWrap, sharedStyles.justifyContentSpaceBetween]}>
      <Link
        analyticsCategory="preferences_link"
        analyticsLabel="PRIVACY_POLICY"
        openOnNewTab
        textProps={{
          color: 'foregroundColor',
          style: {
            fontSize: smallerTextSize,
            lineHeight: smallerTextSize * 1.5,
            textAlign: 'center',
          },
        }}
        href={PRIVACY_POLICY}
      >
        Privacy Policy
      </Link>
      <Spacer width={2 * contentPadding} />
      <Link
        analyticsCategory="preferences_link"
        analyticsLabel="TERM_OF_SERCIE"
        openOnNewTab
        textProps={{
          color: 'foregroundColor',
          style: {
            fontSize: smallerTextSize,
            lineHeight: smallerTextSize * 1.5,
            textAlign: 'center',
          },
        }}
        href={TERM_OF_SERVICE}
      >
        Terms of Service
      </Link>
    </View>
  </View>
)
