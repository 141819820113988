import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native'
import { Button as RButton } from 'react-native-elements'
import { Button, Dialog, Modal, Portal } from 'react-native-paper'
import { getActiveBackGround, sharedStyles } from '../../styles/shared'
import { normalTextSize, scaleFactor } from '../../styles/variables'
import { foregroundThemeColor } from '../common/Button'
import { TextInput } from '../common/TextInput'
import { useTheme } from '../context/ThemeContext'
import { ThemedIcon } from '../themed/ThemedIcon'
import { ThemedText } from '../themed/ThemedText'

interface DialogInputProps {
  title: string
  titleStyle?: StyleProp<TextStyle>
  buttonStyle?: StyleProp<ViewStyle>
  onFinish: (inputText: string, action?: string) => any
  initValue?: string
  anchorText?: string
  action?: string
  validateInput?: (inputValue: string) => string
  showIcon?: boolean
}

export interface DialogInputRef {
  show: (show: boolean, action?: string) => any
}

export const DialogInput = forwardRef<DialogInputRef, DialogInputProps>(({ title, showIcon, titleStyle, buttonStyle, onFinish, initValue = '', anchorText, action, validateInput }, ref) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [currentText, setCurrentText] = useState<string>(initValue)
  const [errorText, setErrorText] = useState<string>('')
  const [currentAction, setCurrentAction] = useState(action)
  const theme = useTheme()
  useEffect(() => setCurrentText(initValue), [initValue])
  useImperativeHandle(
    ref,
    () => ({
      show: (show, passAction) => {
        setVisible(show)
        setCurrentAction(passAction)
      },
    }),
    [],
  )

  useEffect(() => {
    if (validateInput) {
      setErrorText(validateInput(currentText))
    }
  }, [currentText])

  return (
    <>
      {anchorText ? (
        <RButton
          titleStyle={titleStyle}
          title={anchorText}
          type={`clear`}
          onPress={() => setVisible(true)}
          buttonStyle={[sharedStyles.justifyContentFlexStart, sharedStyles.alignSelfFlexStart, buttonStyle]}
          iconRight={true}
          icon={showIcon ? <ThemedIcon family="material" name={'edit'} style={[sharedStyles.marginHorizontalHalf]} size={16 * scaleFactor} color={'primaryBackgroundColor'} /> : false}
        />
      ) : null}
      <Portal>
        <Modal visible={visible} onDismiss={() => setVisible(false)} contentContainerStyle={[sharedStyles.center, sharedStyles.padding]}>
          <View style={[sharedStyles.columnMaxWidth, sharedStyles.fullWidth, getActiveBackGround(true, '#1a1f28')]}>
            <Dialog.Content>
              <ThemedText style={[sharedStyles.marginVertical, sharedStyles.textLeft]} color={`foregroundColorMuted65`}>
                {title}
              </ThemedText>
              <TextInput placeholder={initValue} value={currentText} borderColor={theme.primaryBackgroundColor} style={{ fontSize: normalTextSize }} onChangeText={(text: string) => setCurrentText(text)} textInputKey={'input_name_key'} />
              {errorText ? (
                <ThemedText style={[sharedStyles.marginVertical]} color={`red`}>
                  {errorText}
                </ThemedText>
              ) : null}
            </Dialog.Content>
            <Dialog.Actions>
              <Button focusable={false} onPress={() => setVisible(false)}>
                Huỷ
              </Button>
              <Button
                focusable={false}
                disabled={!!errorText}
                mode={'contained'}
                onPress={() => {
                  setVisible(false)
                  onFinish(currentText, currentAction)
                }}
              >
                Xong
              </Button>
            </Dialog.Actions>
          </View>
        </Modal>
      </Portal>
    </>
  )
})
