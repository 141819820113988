import { EnhancedDevice } from '@devhub/core/dist'
import { observer } from 'mobx-react-lite'
import React, { useContext, useMemo } from 'react'
import { View } from 'react-native'
import { emitter } from '../../libs/emitter'
import { ACTIVE, DEVICES_CONFIG } from '../../stores/DeviceConfig'
import { RootStoreContext } from '../../stores/RootStore'
import { getActiveColor, sharedStyles } from '../../styles/shared'
import { contentPadding, scaleFactor } from '../../styles/variables'
import { Spacer } from '../common/Spacer'
import { TouchableOpacity } from '../common/TouchableOpacity'
import { ThemedIcon } from '../themed/ThemedIcon'
import { ThemedText } from '../themed/ThemedText'

export const DeviceListItem = React.memo(
  observer(({ device, children }: { device: EnhancedDevice; children: React.ReactNode }) => {
    const rootStore = useContext(RootStoreContext)
    const deviceId = device.id.id
    const deviceActive = rootStore.deviceListStore.state[`${ACTIVE}_${deviceId}`] === true || rootStore.deviceListStore.state[`${ACTIVE}_${deviceId}`] === 'true'
    const deviceColors = useMemo(
      () => ({
        backgroundColor: getActiveColor(deviceActive, '66'),
        borderColor: getActiveColor(deviceActive),
      }),
      [deviceActive],
    )
    const { isSMSTransport } = DEVICES_CONFIG[device?.type || ''] || {}

    return (
      <TouchableOpacity
        onPress={() => {
          rootStore.currentDeviceStore.setDevice(device)
          emitter.emit('FOCUS_ON_COLUMN', {
            animated: true,
            columnId: 'deviceDetail',
            highlight: true,
            scrollTo: true,
          })
        }}
      >
        <View style={[sharedStyles.paddingVerticalHalf, sharedStyles.paddingHorizontalQuarter, sharedStyles.borderFrame, sharedStyles.vertical, sharedStyles.displayFlex, sharedStyles.overflowHidden, deviceColors]}>
          <View style={[sharedStyles.justifyContentCenter, sharedStyles.displayFlex, sharedStyles.horizontal, sharedStyles.justifyContentSpaceBetween, sharedStyles.marginHorizontalHalf]}>
            <ThemedText style={[sharedStyles.itemCode]}>{device.label || device.name}</ThemedText>
            {!deviceActive && !isSMSTransport ? (
              <View style={{ flexDirection: 'row' }}>
                <ThemedIcon style={{ marginRight: 2 }} family="material" name={'wifi'} size={12 * scaleFactor} color={'foregroundColorMuted65'} />
                <ThemedText style={[sharedStyles.itemCode, sharedStyles.textSmall]}>chưa kết nối</ThemedText>
              </View>
            ) : null}
          </View>
          <Spacer height={contentPadding / 2} />
          {children}
        </View>
      </TouchableOpacity>
    )
  }),
)
