import { ItemPushNotification } from '@devhub/core/dist'
import { Avatar } from '@ui-kitten/components'
import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { View } from 'react-native'
import { useReduxAction } from '../../hooks/use-redux-action'
import { emitter } from '../../libs/emitter'
import * as actions from '../../redux/actions'
import { RootStoreContext } from '../../stores/RootStore'
import { getActiveColor, sharedStyles } from '../../styles/shared'
import { TouchableOpacity } from '../common/TouchableOpacity'
import { TimeAgo } from '../elements/TimeAgo'
import { ThemedText } from '../themed/ThemedText'
import { BaseHistoryPages, IHistoryPage } from './BaseHistoryPages'

export interface INotification {
  ts: number
  value: string
}

export const UserNotificationPages = observer(() => {
  const rootStore = useContext(RootStoreContext)
  const closeAllModals = useReduxAction(actions.closeAllModals)

  const renderItem = ({ item, index }: { item: INotification; index: number }) => {
    const bundle = JSON.parse(item.value) as ItemPushNotification
    return (
      <TouchableOpacity
        key={`notification-item-${index}`}
        onPress={() => {
          if (bundle?.entityId && bundle?.entityType === 'DEVICE') {
            closeAllModals()
            rootStore.currentDeviceStore.setDeviceByDeviceId(bundle?.entityId)
            emitter.emit('FOCUS_ON_COLUMN', {
              animated: true,
              columnId: 'deviceDetail',
              highlight: true,
              scrollTo: true,
            })
          }
        }}
      >
        <View
          style={[
            sharedStyles.flex,
            sharedStyles.borderFrame,
            sharedStyles.paddingHorizontalHalf,
            sharedStyles.justifyContentSpaceBetween,
            sharedStyles.alignItemsCenter,
            sharedStyles.horizontal,
            {
              backgroundColor: getActiveColor(false, '66'),
              borderColor: getActiveColor(false),
              minHeight: 60,
            },
          ]}
        >
          <View style={[sharedStyles.justifyContentSpaceBetween, sharedStyles.flex, sharedStyles.vertical]}>
            <TimeAgo value={item.ts} />
            <ThemedText style={[sharedStyles.itemCode]}>{bundle?.title}</ThemedText>
            <ThemedText style={[sharedStyles.itemCode, sharedStyles.textSmall]}>{bundle?.body}</ThemedText>
          </View>
          {bundle?.imageUrl ? <Avatar size="small" source={{ uri: bundle.imageUrl }} /> : null}
        </View>
      </TouchableOpacity>
    )
  }

  const pages: IHistoryPage[] = [
    {
      title: 'Thiết bị',
      refreshing: rootStore.appConfig.state.loadingNotification,
      onRefresh: () => {
        return rootStore.appConfig.fetchCustomerNotifications()
      },
    },
    {
      title: 'Chung',
      refreshing: rootStore.appConfig.state.loadingNotification,
      onRefresh: () => {
        return rootStore.appConfig.fetchGeneralNotifications()
      },
    },
  ]
  return <BaseHistoryPages pages={pages} renderItem={renderItem} />
})
