import * as eva from '@eva-design/eva'
import { ApplicationProvider, IconRegistry } from '@ui-kitten/components'
import { EvaIconsPack } from '@ui-kitten/eva-icons'
import { ApolloClient, ApolloLink } from 'apollo-boost'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { RestLink } from 'apollo-link-rest'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useMemo } from 'react'
import { ApolloProvider } from 'react-apollo'
import { Card } from 'react-native-elements'
import { ElectronTitleBar } from '../components/ElectronTitleBar'
import { useReduxAction } from '../hooks/use-redux-action'
import { useReduxState } from '../hooks/use-redux-state'
import { requestUserPermission } from '../libs/fcm'
import { Platform } from '../libs/platform'
import { AppUpdateDialog } from '../libs/update-notify/AppUpdateDialog'
import * as actions from '../redux/actions'
import * as selectors from '../redux/selectors'
import { MainScreen } from '../screens/MainScreen'
import { StackNavigator } from '../screens/StackNavigator'
import { RootStoreContext } from '../stores/RootStore'

export const AppNavigator = observer(() => {
  const rootStore = useContext(RootStoreContext)
  const refreshTokenRequest = useReduxAction(actions.refreshToken)
  const appToken = useReduxState(selectors.appTokenSelector)
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message }) => {
        console.log(message)
      })
    if (networkError) {
      console.log(`[Network error]`, networkError)
      // @ts-ignore
      if (networkError.statusCode === 401) {
        console.log(`[Network error] check token expire`)
        const refreshToken = rootStore.appConfig.state.refreshToken
        refreshTokenRequest({ refreshToken })
      }
    }
  })
  const currentEnv = rootStore.appConfig.getCurrentEnv()
  const appBaseHostUri = rootStore.appConfig.getAppBaseHostUri()
  const client = useMemo(
    () =>
      new ApolloClient({
        link: ApolloLink.from([
          errorLink,
          new RestLink({
            uri: rootStore.appConfig.getAppBaseHostUri(),
            headers: {
              'X-Authorization': `Bearer ${appToken}`,
            },
          }),
        ]),
        cache: new InMemoryCache(),
      }),
    [appBaseHostUri, appToken],
  )

  useEffect(() => {
    requestUserPermission()
  }, [])

  const screen = useMemo(() => {
    if (appToken) {
      return <MainScreen key="app-main-screen" />
    }
    return <StackNavigator key="stack-navigator-screen" />
  }, [appToken])

  return (
    <>
      <IconRegistry icons={EvaIconsPack} />
      <ApplicationProvider {...eva} theme={eva.light}>
        <ApolloProvider client={client}>
          {currentEnv !== 'product' ? (
            <Card>
              {
                // @ts-ignore
                <Card.Title>{`Đang ở môi trường phát triển và gỡ lỗi`}</Card.Title>
              }
            </Card>
          ) : null}
          {!!Platform.isElectron && <ElectronTitleBar />}
          {screen}
          <AppUpdateDialog />
        </ApolloProvider>
      </ApplicationProvider>
    </>
  )
})

AppNavigator.displayName = 'AppNavigator'
