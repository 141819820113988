import {
  AppColumn,
  DeviceColumn as DeviceColumnType,
  DeviceDetailColumnType,
  InputConfigColumnType,
} from '@devhub/core/dist'
import { useCallback, useMemo } from 'react'
import * as selectors from '../redux/selectors'
import { useReduxState } from './use-redux-state'

const columnsData = {
  device: {
    id: 'device',
    type: 'device',
    subscriptionIds: [],
    subscriptionIdsHistory: [],
    createdAt: 'createdAt',
    updatedAt: 'updatedAt',
  } as DeviceColumnType,
  deviceDetail: {
    id: 'deviceDetail',
    type: 'deviceDetail',
    subscriptionIds: [],
    subscriptionIdsHistory: [],
    createdAt: 'createdAt',
    updatedAt: 'updatedAt',
  } as DeviceDetailColumnType,
  inputConfig: {
    id: 'inputConfig',
    type: 'inputConfig',
    subscriptionIds: [],
    subscriptionIdsHistory: [],
    createdAt: 'createdAt',
    updatedAt: 'updatedAt',
  } as InputConfigColumnType,
} as Record<string, AppColumn>

const icons: Record<string, string> = {
  device: 'home',
  deviceDetails: '',
  inputConfig: 'tools',
}
export function useColumn(columnId: string) {
  const column = useReduxState(
    useCallback(state => selectors.columnSelector(state, columnId), [columnId]),
  )

  const columnIndex = useReduxState(selectors.columnIdsSelector).indexOf(
    columnId,
  )

  const plan = useReduxState(selectors.currentUserPlanSelector)

  const headerDetails = {
    icon: {
      name: icons[columnId] || 'info',
      family: 'Octicons',
    },
    ownerIsKnown: true,
    repoIsKnown: true,
    subtitle: '',
    title:
      columnId === columnsData.device.id
        ? 'Danh Sách'
        : columnId === columnsData.deviceDetail.id
        ? 'Điều Khiển'
        : 'Cấu hình',
    owner: 'owner',
    mainSubscriptionSubtype: undefined,
  }

  const hasCrossedColumnsLimit = false

  const dashboardFromUsername =
    (headerDetails &&
      (headerDetails.mainSubscriptionSubtype === 'USER_RECEIVED_EVENTS' ||
        headerDetails.mainSubscriptionSubtype ===
          'USER_RECEIVED_PUBLIC_EVENTS') &&
      headerDetails.owner) ||
    undefined

  return useMemo(
    () => ({
      column,
      columnIndex,
      dashboardFromUsername,
      hasCrossedColumnsLimit,
      headerDetails,
      plan,
    }),
    [
      column,
      columnIndex,
      dashboardFromUsername,
      hasCrossedColumnsLimit,
      headerDetails,
      plan,
    ],
  )
}
export function useAppColumn(columnId: string) {
  const { columnIndex, headerDetails } = useColumn(columnId)
  const column = useMemo(() => columnsData[columnId], [columnId])
  return {
    columnIndex,
    headerDetails,
    column,
  }
}
