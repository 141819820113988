import React, { FC, useCallback, useContext, useState } from 'react'
import { View } from 'react-native'

import { Mutation } from '@apollo/react-components'
import { CREATE_CUSTOMER } from '../../graphql/gqls'
import { IEnvPageProps, PageType } from '../../screens/StackNavigator'
import { RootStoreContext } from '../../stores/RootStore'
import { sharedStyles } from '../../styles/shared'
import { contentPadding } from '../../styles/variables'
import { getMessageError } from '../../utils/helpers/shared'
import { GitHubLoginButton } from '../common/GitHubLoginButton'
import { Spacer } from '../common/Spacer'
import { useAppLayout } from '../context/LayoutContext'
import { ThemedText } from '../themed/ThemedText'
import { ThemedTextInput } from '../themed/ThemedTextInput'

interface IInputText {
  firstName: string
  lastName: string
  email: string
}

export const AppRegisterScreen: FC<IEnvPageProps> = ({ setPageType }) => {
  const rootStore = useContext(RootStoreContext)
  const { appOrientation } = useAppLayout()
  const isMobile = appOrientation === 'portrait'
  const [response, setResponse] = useState<any>('')
  const [inputText, setInputText] = useState<IInputText>({
    firstName: '',
    lastName: '',
    email: '',
  })

  const onChangeInputText = (name: string, value: string) => {
    setInputText((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const onSubmitForm = useCallback(
    async (saveCustomer, loading) => {
      if (loading) {
        return
      }
      const currentTenantId = rootStore.appConfig.getCurrentTenantId()
      if (currentTenantId) {
        try {
          const userResponse = await saveCustomer({
            variables: {
              input: {
                email: inputText.email,
                firstName: inputText.firstName,
                lastName: inputText.lastName,
                tenantId: {
                  entityType: 'TENANT',
                  id: currentTenantId, // '967657d0-ee4e-11eb-a686-dbac59982f42',//staging '1332a160-3280-11eb-ab9f-c7cb251eb349',
                },
              },
            },
          })
          if (userResponse.data && userResponse.data.createCustomer) {
            setInputText({
              firstName: '',
              lastName: '',
              email: '',
            })
            setPageType(PageType.PASSWORD_CREATE)
          }
        } catch (error) {
          console.error(error)
        }
      }
    },
    [response, inputText],
  )

  const checkExistsValue = () => {
    const allField = (value: string) => value !== ''
    return Object.values(inputText).every(allField)
  }

  return (
    <View>
      <Mutation<any>
        mutation={CREATE_CUSTOMER}
        update={(cache: any, { data }) => {
          setResponse(JSON.stringify(data))
        }}
      >
        {(saveCustomer, { error, loading }) => (
          <View>
            <Spacer height={contentPadding} />
            <View style={isMobile ? [] : [sharedStyles.horizontal]}>
              <ThemedTextInput
                placeholder="Họ"
                textInputKey="firstName"
                autoFocus={true}
                value={inputText.firstName}
                onChangeText={(text) => {
                  onChangeInputText('firstName', text)
                }}
              />
              {isMobile && <Spacer height={contentPadding} />}
              <ThemedTextInput
                textInputKey="lastName"
                placeholder="Tên"
                value={inputText.lastName}
                onChangeText={(text) => {
                  onChangeInputText('lastName', text)
                }}
              />
            </View>
            <Spacer height={contentPadding} />
            <View>
              <ThemedTextInput
                placeholder="Email"
                textInputKey="email"
                value={inputText.email}
                onChangeText={(text) => {
                  onChangeInputText('email', text)
                }}
              />
              {error && (
                <ThemedText color="red">{getMessageError(error)}</ThemedText>
              )}
            </View>
            <Spacer height={2 * contentPadding} />
            <GitHubLoginButton
              analyticsLabel="github_login_public"
              disabled={!checkExistsValue()}
              onPress={() => onSubmitForm(saveCustomer, loading)}
              style={sharedStyles.button}
              title="Đăng ký"
              loading={loading}
            />
          </View>
        )}
      </Mutation>
      <Spacer height={contentPadding} />
      <ThemedText
        style={[sharedStyles.textCenter]}
        color="foregroundColorMuted65"
      >
        Bạn đã có tài khoản?{' '}
        <ThemedText color="blue" onPress={() => setPageType(PageType.LOGIN)}>
          Đăng nhập
        </ThemedText>
      </ThemedText>

      <Spacer height={contentPadding} />
      <ThemedText
        style={[sharedStyles.textCenter]}
        color="foregroundColorMuted65"
      >
        Đã có mã OTP?{' '}
        <ThemedText
          color="blue"
          onPress={() => setPageType(PageType.PASSWORD_CREATE)}
        >
          Hoàn tất
        </ThemedText>
      </ThemedText>
    </View>
  )
}
