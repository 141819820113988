import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { getColumnHeaderDetails } from '@devhub/core'
import FlashMessage from 'react-native-flash-message'
import { FAB } from '../../components/common/FAB'
import { DeviceCardsContainer, DeviceCardsContainerProps } from '../../containers/DeviceCardsContainer'
import { IconProp } from '../../libs/vector-icons'
import * as actions from '../../redux/actions'
import { RootStoreContext } from '../../stores/RootStore'
import { ChangeViewDeviceList } from '../views/ChangeViewDeviceList'
import { ColumnRenderer, ColumnRendererProps } from './ColumnRenderer'

export interface DeviceColumnProps extends Omit<DeviceCardsContainerProps, 'ownerIsKnown' | 'repoIsKnown'> {
  columnIndex: number
  headerDetails: ReturnType<typeof getColumnHeaderDetails>
  pagingEnabled?: boolean
}

export const DeviceColumn = React.memo(
  observer((props: DeviceColumnProps) => {
    const { columnId, columnIndex, headerDetails, pagingEnabled, pointerEvents, swipeable } = props

    const dispatch = useDispatch()
    const rootStore = useContext(RootStoreContext)
    const totalItems = rootStore.deviceListStore.state.totalElements
    const flashMessageRef = useRef<FlashMessage>(null)

    useEffect(() => {
      rootStore.uiReferenceStore.state.deviceListFlashMessageRef = flashMessageRef.current
    }, [flashMessageRef.current])

    const shareWithMe = rootStore.deviceListStore.state.shareWithMeDevices
    const loading = rootStore.deviceListStore.state.loading

    const Children = useMemo<ColumnRendererProps['children']>(
      () => (
        <>
          <DeviceCardsContainer key={`Device-cards-container-${columnId}`} columnId={columnId} pointerEvents={pointerEvents} swipeable={swipeable} />
          <FAB
            key="fab"
            analyticsLabel="add_device"
            icon={{ family: 'octicon', name: 'plus', style: { marginTop: -2 } }}
            style={{ position: 'absolute', bottom: 10, right: 5 }}
            useBrandColor
            onPress={() => dispatch(actions.pushModal({ name: 'ADD_DEVICE' }))}
            tooltip={'Thêm thiết bị mới'}
          />
        </>
      ),
      [columnId, columnIndex, pointerEvents, swipeable, totalItems, loading],
    )

    if (!headerDetails) return null
    const divider = totalItems > 0 && shareWithMe.length > 0 ? ' | ' : ''
    const subTitle = `${totalItems > 0 ? `${totalItems} thiết bị` : ''}${divider}${shareWithMe?.length > 0 ? `${shareWithMe?.length} chia sẻ` : ''}`

    return (
      <>
        <ColumnRenderer
          key={`device-column-${columnId}-inner`}
          avatarImageURL={headerDetails.avatarProps && headerDetails.avatarProps.imageURL}
          avatarLinkURL={headerDetails.avatarProps && headerDetails.avatarProps.linkURL}
          columnId={columnId}
          columnIndex={columnIndex}
          columnType="device"
          icon={headerDetails.icon as IconProp}
          owner={headerDetails.owner}
          pagingEnabled={pagingEnabled}
          repo={headerDetails.repo}
          repoIsKnown={headerDetails.repoIsKnown}
          subtitle={subTitle}
          title={headerDetails.title}
          iconAdge={<></>}
          rightButtons={[<ChangeViewDeviceList key={`change-type-view-${columnId}`} />]}
        >
          {Children}
        </ColumnRenderer>
        <FlashMessage style={{ zIndex: 999 }} ref={flashMessageRef} autoHide={true} duration={3000} />
      </>
    )
  }),
)

DeviceColumn.displayName = 'DeviceColumn'
