import React from 'react'
import { StyleSheet } from 'react-native'

import { sharedStyles } from '../../styles/shared'
import { contentPadding, scaleFactor, smallerTextSize } from '../../styles/variables'
import { getButtonColors, loadingIndicatorStyle } from '../common/Button'
import { TextInput } from '../common/TextInput'
import { ThemedActivityIndicator } from '../themed/ThemedActivityIndicator'
import { ThemedIcon } from '../themed/ThemedIcon'
import { ThemedText } from '../themed/ThemedText'
import { ThemedTextInput, ThemedTextInputProps } from '../themed/ThemedTextInput'

interface IThemedTextInputIconProps extends ThemedTextInputProps {
  loading?: boolean
  iconName?: string
  family?: 'octicon' | 'material'
  error?: string
  onClick?: () => void
}

export const ThemedTextInputWithIcon = React.forwardRef<TextInput, IThemedTextInputIconProps>((props, ref) => {
  const { loading, error, family = 'octicon', iconName = 'search', onClick, style, ...otherProps } = props
  const { foregroundThemeColor } = getButtonColors()

  return (
    <>
      <ThemedTextInput {...otherProps} style={[sharedStyles.paddingHorizontalQuarter, style]} ref={ref} />
      {error ? (
        <ThemedText style={{ fontSize: smallerTextSize, marginTop: contentPadding / 2 }} color="#ff0000">
          {error}
        </ThemedText>
      ) : null}
      {loading ? (
        <ThemedActivityIndicator color={foregroundThemeColor} size="small" style={[styles.icon, loadingIndicatorStyle]} />
      ) : (
        <ThemedIcon family={family} name={iconName as any} size={16 * scaleFactor} color={foregroundThemeColor} style={[styles.icon]} onPress={onClick} />
      )}
    </>
  )
})

const styles = StyleSheet.create({
  icon: {
    position: 'absolute',
    top: 12,
    right: 20,
  },
})

ThemedTextInputWithIcon.displayName = 'ThemedTextInputWithIcon'

export type ThemedTextInputWithIcon = typeof ThemedTextInputWithIcon
