import { IDict } from '@devhub/core/dist'
import React from 'react'
import { Button } from 'react-native-elements'
import { ThemedText } from '../themed/ThemedText'

export const ScheduleActionControl = React.memo(
  ({
    children,
    displayType = 'text',
    title,
    icon,
    ...others
  }: {
    icon: React.ReactElement
    title: string
    displayType?: string
  } & IDict) => {
    return displayType === 'text' ? (
      <ThemedText {...others}>
        {icon}
        {title}
      </ThemedText>
    ) : (
      <Button title={title} icon={icon} {...others} />
    )
  },
)
