import { constants } from '@devhub/core'
import axios, { AxiosResponse } from 'axios'

import { Alert } from 'react-native'
import * as StoreReview from 'react-native-store-review'
import { REHYDRATE } from 'redux-persist'
import { all, fork, put, select, take, takeLatest } from 'redux-saga/effects'
import { analytics } from '../../libs/analytics'
import { bugsnag } from '../../libs/bugsnag'
import * as github from '../../libs/github'
import { rootStore } from '../../stores/RootStore'
import { getDefaultAppHeaders, getDefaultDevHubHeaders } from '../../utils/api'
import { clearOAuthQueryParams } from '../../utils/helpers/auth'
import * as actions from '../actions'
import * as selectors from '../selectors'
import { ExtractActionFromActionCreator } from '../types/base'

function* init() {
  const state = yield select()
  const appToken = selectors.appTokenSelector(state) || ''
  const refreshToken = selectors.refreshTokenSelector(state) || ''
  const isLogged = selectors.isLoggedSelector(state)
  const user = selectors.currentUserSelector(state)
  if (!(appToken && isLogged && user)) yield take('LOGIN_SUCCESS')
  if (appToken || refreshToken) yield put(actions.loginRequest({ appToken, refreshToken }))
}

function* onRehydrate(): Generator<{}, void, string> {
  const appToken = yield select(selectors.appTokenSelector)
  const refreshToken = yield select(selectors.refreshTokenSelector)
  if (!appToken && !refreshToken) return
  yield put(actions.loginRequest({ appToken, refreshToken }))
}

function* getTokenByRefreshToken(refreshToken: string) {
  try {
    if (refreshToken) {
      const resNewToken = yield axios.post(`${rootStore.appConfig.getAppBaseHostUri()}/api/auth/token`, {
        refreshToken,
      })
      const { data: resToken, status: statusResToken } = resNewToken
      if (statusResToken !== 200 || !resToken) {
        throw Object.assign(new Error('get new token fail'), { resNewToken })
      }
      yield put(
        actions.loginRequest({
          appToken: resToken.token,
          refreshToken: resToken.refreshToken,
        }),
      )
    }
  } catch (error) {
    const description = 'get token by refreshToken failed'
    console.warn(description, error)
    yield put(actions.loginFailure(error && error.response && error.response.data && error.response.data.errors && error.response.data.errors[0]))
  }
}

// @ts-ignore
function* onLoginRequest(action: ExtractActionFromActionCreator<typeof actions.loginRequest>): Generator<{}, void, any> {
  const { appToken, refreshToken } = action.payload

  try {
    if (appToken) {
      const response = yield axios.get(`${rootStore.appConfig.getAppBaseHostUri()}/api/auth/user`, {
        headers: getDefaultAppHeaders({ appToken }),
      })
      const { data, status } = response
      if (status !== 200) {
        throw Object.assign(new Error('get token by refresh token fail'), {
          response,
        })
      }
      if (!data) {
        throw Object.assign(new Error('data empty'), { response })
      }
      yield put(actions.loginSuccess({ appToken, refreshToken, user: data }))
    } else if (refreshToken) {
      yield getTokenByRefreshToken(refreshToken)
    } else {
      throw Object.assign(new Error('no token nor refreshToken'), {})
    }
  } catch (error) {
    const status = error && error.response && error.response.data && error.response.data.status
    const errorCode = error && error.response && error.response.data && error.response.data.errorCode

    if (status === 401 && errorCode === 11 && refreshToken) {
      // expired code = 11
      yield getTokenByRefreshToken(refreshToken)
      return
    }
    const description = 'Login failed'
    bugsnag.notify(error, { description })
    console.error(description, error)

    yield put(actions.loginFailure(error && error.response && error.response.data && error.response.data.errors && error.response.data.errors[0]))
  }
}

function* onLoginSuccess(_action: ExtractActionFromActionCreator<typeof actions.loginSuccess>) {
  clearOAuthQueryParams()

  if (StoreReview.isAvailable && !__DEV__) {
    const state = yield select()
    const { loginSuccess: loginCount } = selectors.countersSelector(state)

    if (loginCount >= 5 && loginCount % 5 === 0) {
      StoreReview.requestReview()
    }
  }

  yield put(actions.cleanupArchivedItems())

  const { appToken, refreshToken, user } = _action.payload
  rootStore.startFetchAppData(appToken, refreshToken)
}

function* onRefreshToken(action: ExtractActionFromActionCreator<typeof actions.refreshToken>) {
  const { refreshToken } = action.payload
  yield getTokenByRefreshToken(refreshToken)
}

function* updateLoggedUserOnTools() {
  const state = yield select()

  const preferredDarkThemePair = selectors.preferredDarkThemePairSelector(state)
  const preferredLightThemePair = selectors.preferredLightThemePairSelector(state)
  const themePair = selectors.themePairSelector(state)
  const user = selectors.currentUserSelector(state)

  const githubUser = selectors.currentGitHubUserSelector(state)
  const plan = selectors.currentUserPlanSelector(state)

  if (user && user.id && user.id.id) {
    analytics.setUser(user && user.id && user.id.id)
  }
  analytics.setDimensions({
    dark_theme_id: preferredDarkThemePair.id,
    light_theme_id: preferredLightThemePair.id,
    plan_amount: (plan && plan.amount) || 0,
    theme_id: themePair.id,
  })
  bugsnag?.setUser((user && user.id && user.id.id) || '', (githubUser && (githubUser.login || githubUser.name || githubUser.id)) || '')
}

function* onLoginFailure(action: ExtractActionFromActionCreator<typeof actions.loginFailure>) {
  if (
    action.error &&
    (action.error.status === 401 ||
      (action.error.response &&
        (action.error.response.status === 401 ||
          (action.error.response.data && Array.isArray(action.error.response.data.errors) && action.error.response.data.errors.some((e: any) => e.extensions && e.extensions.code === 'UNAUTHENTICATED')))))
  ) {
    yield put(actions.logout())
  }
}

function onLogout() {
  rootStore.reset()
  github.clearOctokitInstances()
  clearOAuthQueryParams()
}

function* onDeleteAccountRequest() {
  const appToken = yield select(selectors.appTokenSelector)

  try {
    const response: AxiosResponse<{
      data: {
        deleteAccount: boolean | null
      }
      errors?: any[]
    }> = yield axios.post(
      constants.GRAPHQL_ENDPOINT,
      {
        query: `mutation {
          deleteAccount
        }`,
      },
      {
        headers: getDefaultDevHubHeaders({ appToken }),
      },
    )

    const { data, errors } = response.data

    if (errors && errors.length) {
      throw Object.assign(new Error('GraphQL Error'), { response })
    }

    if (!(data && typeof data.deleteAccount === 'boolean')) {
      throw new Error('Invalid response')
    }

    if (!(data && data.deleteAccount)) {
      throw new Error('Failed to delete account')
    }

    yield put(actions.deleteAccountSuccess())
  } catch (error) {
    const description = 'Delete account failed'
    bugsnag.notify(error, { description })
    console.error(description, error)

    yield put(actions.deleteAccountFailure(error && error.response && error.response.data && error.response.data.errors && error.response.data.errors[0]))
  }
}

function onDeleteAccountFailure(action: ExtractActionFromActionCreator<typeof actions.deleteAccountFailure>) {
  bugsnag.notify(action.error)
  Alert.alert('Oops.', `Failed to delete account. Please try again.\n\n${(action.error && action.error.message) || action.error || ''}`.trim())
}

function* onDeleteAccountSuccess() {
  yield put(actions.logout())
}

export function* authSagas() {
  yield all([
    yield fork(init),
    yield takeLatest(REHYDRATE, onRehydrate),
    yield takeLatest([REHYDRATE, 'LOGIN_SUCCESS', 'LOGOUT', 'UPDATE_USER_DATA'], updateLoggedUserOnTools),
    yield takeLatest('LOGIN_REQUEST', onLoginRequest),
    yield takeLatest('LOGIN_FAILURE', onLoginFailure),
    yield takeLatest('LOGIN_SUCCESS', onLoginSuccess),
    yield takeLatest('REFRESH_TOKEN', onRefreshToken),
    yield takeLatest('DELETE_ACCOUNT_REQUEST', onDeleteAccountRequest),
    yield takeLatest('DELETE_ACCOUNT_FAILURE', onDeleteAccountFailure),
    yield takeLatest('DELETE_ACCOUNT_SUCCESS', onDeleteAccountSuccess),
    yield takeLatest('LOGOUT', onLogout),
  ])
}
