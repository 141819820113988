import { MenuItem, OverflowMenu } from '@ui-kitten/components'
import moment from 'moment'
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native'
import { DatePickerModal } from 'react-native-paper-time-duration'
import { LIMIT_MAX, LIMIT_MIN } from '../../stores/DeviceConfig'

import { sharedStyles } from '../../styles/shared'
import { scaleFactor } from '../../styles/variables'
import { A_WEEK, customLast, intervals, last, more } from '../../utils/constants'
import { msToTime } from '../../utils/helpers/shared'
import { IValueTitle } from '../../utils/types'
import { useTheme } from '../context/ThemeContext'
import { ThemedIcon } from '../themed/ThemedIcon'
import { ThemedText } from '../themed/ThemedText'
import { AppButtonGroup, AppButtonGroupRef } from '../views/AppButtonGroup'

export interface TimeRange {
  startTs: number
  endTs: number
}

export interface TimeRangeIntervalFilterProps extends TimeRange {
  interval: number
  onChange?: (variables: TimeRangeIntervalFilterProps) => any
}

export const TimeRangeIntervalFilter = memo(({ startTs, endTs, interval, onChange }: TimeRangeIntervalFilterProps) => {
  const [currentTimeRange, setCurrentTimeRange] = useState<TimeRange>({ startTs, endTs })
  const [intervalValue, setIntervalValue] = useState<number>(interval)
  const [intervalRange, setIntervalRange] = useState<IValueTitle[]>(intervals)
  const [customTitle, setCustomTitle] = useState<string>(customLast.title)
  const [pickerVisible, setPickerVisible] = useState<boolean>(false)
  const end = Date.now()

  const theme = useTheme()
  const btnGroupRef = useRef<AppButtonGroupRef>(null)

  const findInterval = useCallback((iv: number, range: IValueTitle[]) => {
    if (range.length > 0) {
      const found = range.find((itv) => iv === itv.value)
      const newInterval = found ? found.value : range[0].value
      setIntervalValue(newInterval)
      return newInterval
    }
    return 0
  }, [])

  const updateCurrentTimeRange = useCallback(
    (newRange: TimeRange, length: number = 0, shouldOnchange = true) => {
      setCurrentTimeRange(newRange)
      const lastValue = length ? length : newRange.endTs - newRange.startTs
      const newIntervals = intervals.filter((itv) => lastValue / itv.value < LIMIT_MAX && lastValue / itv.value > LIMIT_MIN)
      setIntervalRange(newIntervals)
      const newInterval = findInterval(intervalValue, newIntervals)
      if (newInterval > 0 && shouldOnchange && onChange) {
        onChange({ interval: newInterval, ...newRange })
      }
    },
    [intervalValue],
  )

  const updateCurrent = useCallback(
    (lastValue: number) => {
      const start = end - lastValue
      const newRange = { startTs: start, endTs: end }
      updateCurrentTimeRange(newRange, lastValue)
    },
    [updateCurrentTimeRange],
  )

  const moreButton = useMemo(
    () => (
      <ButtonOverMenu
        {...customLast}
        title={customTitle}
        items={more}
        onSelect={({ value, title }) => {
          updateCurrent(value)
          btnGroupRef.current?.setSelectedIndex(last.length - 2)
          setCustomTitle(title)
        }}
      />
    ),
    [customTitle],
  )

  const startEnd = useMemo(
    () => <InnerButtonGroup showIcon={false} title={moment(currentTimeRange.endTs).locale('vi').format('D/MM')} subTitle={moment(currentTimeRange.startTs).locale('vi').format('D/MM')} onPress={() => setPickerVisible(true)} />,
    [currentTimeRange],
  )

  const onIntervalChange = useCallback(
    ({ value, title }) => {
      setIntervalValue(value)
      if (value > 0 && onChange) {
        onChange({ interval: value, ...currentTimeRange })
      }
    },
    [currentTimeRange],
  )

  const intervalButton = useMemo(
    () => <ButtonOverMenu value={intervalValue} title={intervalRange.find((itv) => itv.value === intervalValue)?.title || `${msToTime(intervalValue)}`} subTitle={`Chu kỳ`} items={intervalRange} onSelect={onIntervalChange} />,
    [intervalRange, intervalValue],
  )

  useEffect(() => {
    if (btnGroupRef.current) {
      let index = last.findIndex((item) => item.value === currentTimeRange.endTs - currentTimeRange.startTs)
      if (index < 0) {
        index = more.findIndex((item) => item.value === currentTimeRange.endTs - currentTimeRange.startTs)
        if (index >= 0) {
          setCustomTitle(more[index].title)
          index = last.length - 2
        } else {
          index = 0
        }
      }
      btnGroupRef.current?.setSelectedIndex(index)
      updateCurrentTimeRange({ startTs, endTs }, 0, false)
    }
  }, [btnGroupRef.current])
  return (
    <>
      <AppButtonGroup
        ref={btnGroupRef}
        initValue={last.findIndex((item) => item.value === currentTimeRange.endTs - currentTimeRange.startTs)}
        effectByInitValue={false}
        onPress={(cIndex: number) => {
          updateCurrent(last[cIndex].value)
          setCustomTitle(customLast.title)
        }}
        buttons={last.map((item, index) => ({
          element: () => {
            switch (index) {
              case 0:
                return startEnd
              case last.length - 2:
                return moreButton
              case last.length - 1:
                return intervalButton
              default:
                return (
                  <ThemedText style={[sharedStyles.textMedium]} color={theme.foregroundColorMuted40}>
                    {item.title}
                  </ThemedText>
                )
            }
          },
        }))}
      />
      <DatePickerModal
        locale="vi"
        mode="range"
        visible={pickerVisible}
        startDate={new Date(currentTimeRange.startTs ? currentTimeRange.startTs : end - A_WEEK)}
        endDate={new Date(currentTimeRange.endTs ? currentTimeRange.endTs : end)}
        onConfirm={({ startDate, endDate }) => {
          if (startDate && endDate) {
            updateCurrentTimeRange({ startTs: startDate.getTime(), endTs: endDate.getTime() })
            btnGroupRef.current?.setSelectedIndex(0)
            setPickerVisible(false)
          }
        }}
        onDismiss={() => setPickerVisible(false)}
        saveLabel="Xong" // optional
        label="Chọn khoảng thời gian" // optional
        startLabel="Từ" // optional
        endLabel="Đến" // optional
      />
    </>
  )
})

export interface DurationOverMenuProp extends IValueTitle {
  items: IValueTitle[]
  onSelect: (value: IValueTitle) => any
  subTitle?: string
  style?: Array<StyleProp<ViewStyle>>
}

export const ButtonOverMenu = memo(({ value, title, onSelect, items, style = [], subTitle }: DurationOverMenuProp) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [currentTitle, setCurrentTitle] = useState<string>(title)

  const renderToggleButton = useCallback(
    () => (
      <TouchableOpacity onPress={() => setVisible(true)} style={[sharedStyles.vertical, { minHeight: 40 }, sharedStyles.alignSelfStretch, sharedStyles.justifyContentCenter, sharedStyles.alignItemsCenter]}>
        {subTitle ? (
          <ThemedText color={'#0255e6'} style={[sharedStyles.textCenter, sharedStyles.textMedium]}>
            {subTitle}
          </ThemedText>
        ) : null}
        <View style={[sharedStyles.horizontal, ...style, subTitle ? {} : sharedStyles.paddingVerticalQuarter]}>
          <ThemedText color={'foregroundColorMuted40'} style={[sharedStyles.textCenter, sharedStyles.textMedium]}>
            {title}
          </ThemedText>
          <ThemedIcon style={[sharedStyles.marginHorizontalQuarter]} family="octicon" name={'chevron-down'} size={15 * scaleFactor} color={'foregroundColorMuted65'} />
        </View>
      </TouchableOpacity>
    ),
    [currentTitle],
  )

  return (
    <OverflowMenu
      visible={visible}
      anchor={renderToggleButton}
      onBackdropPress={() => {
        setVisible(false)
      }}
    >
      {items.map(({ value: v, title: t }, index) => (
        <MenuItem
          key={`OverflowMenu-${index}`}
          title={t}
          onPress={() => {
            setCurrentTitle(t)
            onSelect({ value: v, title: t })
            setVisible(false)
          }}
        />
      ))}
    </OverflowMenu>
  )
})

export interface InnerButtonGroupProp {
  title: string
  onPress: () => any
  subTitle?: string
  showIcon?: boolean
  style?: Array<StyleProp<ViewStyle>>
}

export const InnerButtonGroup = memo(({ title, onPress, style = [], subTitle, showIcon = true }: InnerButtonGroupProp) => {
  return (
    <TouchableOpacity onPress={() => onPress()} style={[sharedStyles.vertical, { minHeight: 40 }, sharedStyles.alignSelfStretch, sharedStyles.justifyContentCenter, sharedStyles.alignItemsCenter]}>
      {subTitle ? (
        <ThemedText color={'#0255e6'} style={[sharedStyles.textCenter, sharedStyles.textMedium]}>
          {subTitle}
        </ThemedText>
      ) : null}
      <View style={[sharedStyles.horizontal, ...style, subTitle ? {} : sharedStyles.paddingVerticalQuarter]}>
        <ThemedText color={'foregroundColorMuted40'} style={[sharedStyles.textCenter, sharedStyles.textMedium]}>
          {title}
        </ThemedText>
        {showIcon ? <ThemedIcon style={[sharedStyles.marginHorizontalQuarter]} family="octicon" name={'chevron-down'} size={15 * scaleFactor} color={'foregroundColorMuted65'} /> : null}
      </View>
    </TouchableOpacity>
  )
})
