import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { Fragment, RefObject, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Mutation } from 'react-apollo'
import { StyleSheet, View } from 'react-native'
import FlashMessage from 'react-native-flash-message'
import { Card } from 'react-native-paper'
import { CALL_RPC, GET_STATUS, QUERY_SHARED_SCOPE_ATTRIBUTE, RPCCallingInput, RPCCallingResponse, SAVE_SHARED_SCOPE_ATTRIBUTE } from '../../graphql/gqls'
import { INPUT_MESSAGE } from '../../stores/DeviceConfig'
import { RootStoreContext } from '../../stores/RootStore'
import { sharedStyles } from '../../styles/shared'
import { contentPadding, scaleFactor } from '../../styles/variables'
import { getButtonColors, loadingIndicatorStyle } from '../common/Button'
import { FullHeightScrollView } from '../common/FullHeightScrollView'
import { GitHubLoginButton } from '../common/GitHubLoginButton'
import { Spacer } from '../common/Spacer'
import { ThemedActivityIndicator } from '../themed/ThemedActivityIndicator'
import { ThemedIcon } from '../themed/ThemedIcon'
import { ThemedText } from '../themed/ThemedText'
import { ThemedTextInput } from '../themed/ThemedTextInput'
import { DeviceRealtimePanel } from '../views/DeviceRealtimePanel'
import { getInputName } from '../views/MutableInputMessage'
import { SensorPresentation } from '../views/SensorPresentation'

const { foregroundThemeColor } = getButtonColors()

enum TopicType {
  SAVE_ATTRIBUTE,
  CALL_RPC,
  GET_ATTRIBUTE,
  GET_STATUS,
}

const topics = [
  {
    value: TopicType.SAVE_ATTRIBUTE,
    text: TopicType[TopicType.SAVE_ATTRIBUTE],
  },
  { value: TopicType.CALL_RPC, text: TopicType[TopicType.CALL_RPC] },
  { value: TopicType.GET_ATTRIBUTE, text: TopicType[TopicType.GET_ATTRIBUTE] },
  { value: TopicType.GET_STATUS, text: TopicType[TopicType.GET_STATUS] },
]

export const getDeviceStatus = (status: boolean) => {
  return status ? 'Mở' : 'Đóng'
}

export interface DeviceStatusModalProps {
  deviceId: string
  showBackButton: boolean
  flashMessageRef: RefObject<FlashMessage>
}

export const DeviceStatusModal = React.memo(
  observer(({ flashMessageRef, deviceId }: DeviceStatusModalProps) => {
    const rootStore = useContext(RootStoreContext)
    const enableDebug = rootStore.appConfig.state.enableDebug

    const [deviceData, setDeviceData] = useState('')
    const didUnmount = useRef(false)
    const [response, setResponse] = useState('')

    const inputErrors = rootStore.currentDeviceStore.getAvailableInputError()
    const errorView = useMemo(() => {
      if (inputErrors && inputErrors.length > 0) {
        return (
          <>
            {inputErrors.map((inputErrorX, index) => {
              const extractText = rootStore.currentDeviceStore.getAttributeByNumber(INPUT_MESSAGE, inputErrorX.inputIndex)?.content
              const title = typeof extractText === 'object' && !_.isEmpty(extractText) ? JSON.stringify(extractText) : _.isEmpty(extractText) ? `CÓ LỖI ĐẦU DÒ ${getInputName(inputErrorX.inputIndex)}` : extractText
              return (
                <Fragment key={`card-view-error-${index}`}>
                  {/* @ts-ignore */}
                  <Card.Title
                    title={title}
                    subtitle={`Hãy kiểm tra đầu dò ${getInputName(inputErrorX.inputIndex)}`}
                    titleStyle={{
                      color: 'red',
                      fontSize: 16,
                    }}
                    subtitleStyle={{
                      color: 'white',
                    }}
                    focusable={true}
                    left={() => <ThemedIcon family="material" name="error" size={32 * scaleFactor} color={'red'} />}
                  />
                </Fragment>
              )
            })}
          </>
        )
      }
      return null
    }, [inputErrors])

    useEffect(() => {
      return () => {
        didUnmount.current = true
      }
    }, [])

    const [topic, setTopic] = useState(TopicType.SAVE_ATTRIBUTE)
    const map = {
      [TopicType.SAVE_ATTRIBUTE]: {
        mutation: SAVE_SHARED_SCOPE_ATTRIBUTE,
        defaultValue: `{"schedule":{"1":{"dayOfWeek":[],"commands":[{"1605550620":160},{"1605551400":400}]}}}`,
      },
      [TopicType.CALL_RPC]: {
        mutation: CALL_RPC,
        defaultValue: `{"method":"setStatus","params":{"value":"on","timeStamp":1607613623123}}`,
      },
      [TopicType.GET_ATTRIBUTE]: {
        mutation: QUERY_SHARED_SCOPE_ATTRIBUTE,
        defaultValue: 'schedule',
      },
      [TopicType.GET_STATUS]: { mutation: GET_STATUS, defaultValue: '' },
    }
    const mutation = useMemo(() => map[topic].mutation, [topic])
    const defaultValue = useMemo(() => map[topic].defaultValue, [topic])

    const handleSubmit = useCallback(
      (updateCalling, loading) => {
        if (loading) {
          return
        }
        if (!deviceData && topic !== TopicType.GET_STATUS) {
          setResponse('textbox empty!')
          return
        }
        let input = deviceData
        if (topic !== TopicType.GET_ATTRIBUTE && topic !== TopicType.GET_STATUS) {
          try {
            input = JSON.parse(deviceData)
          } catch (error) {
            console.error(error)
            setResponse('không thể parse json từ text box. hãy nhập validate json!')
            return
          }
        }
        setResponse('Đang gửi textbox')
        let variables
        switch (topic) {
          case TopicType.SAVE_ATTRIBUTE:
          case TopicType.GET_ATTRIBUTE:
          case TopicType.GET_STATUS:
            variables = {
              deviceId,
              input,
            }
            break
          case TopicType.CALL_RPC:
            variables = {
              deviceId,
              callType: 'oneway', // 'twoway',
              input,
            }
            break
        }
        if (variables) {
          updateCalling({ variables }).then(() => {
            // console.log('handleSubmit rpcMethodCalling sent')
            setResponse('Done')
          })
        } else setResponse('empty input')
      },
      [deviceData, topic],
    )

    return (
      <>
        <FullHeightScrollView alwaysBounceVertical bounces style={sharedStyles.flex}>
          <View>
            <SensorPresentation key={`SensorPresentation-${deviceId}`} />
            {errorView}
            <DeviceRealtimePanel />
            <Spacer height={contentPadding} />
          </View>
          {enableDebug && (
            <Mutation<RPCCallingResponse, RPCCallingInput>
              mutation={mutation}
              context={{ timeout: 15000 }}
              update={(cache: any, { data }) => {
                // console.log('rpc response data', data)
                setResponse(JSON.stringify(data))
              }}
            >
              {(updateCalling, { data, error, loading }) => (
                <>
                  <View style={{ paddingHorizontal: contentPadding }}>
                    {/*<Picker selectedValue={topic} style={{ height: 50, width: 150 }} onValueChange={(itemValue, itemIndex) => setTopic(topics[itemIndex].value)}>
                      {topics.map((aTopic) => (
                        <Picker.Item key={`topic-${aTopic.value}-${aTopic.text}`} label={aTopic.text} value={aTopic.value} />
                      ))}
                    </Picker>*/}
                    <Spacer flex={1} minHeight={contentPadding} />
                    {defaultValue && (
                      <>
                        <View style={[sharedStyles.fullWidth, sharedStyles.fullMinWidth]}>
                          <ThemedTextInput
                            editable={!loading}
                            multiline={true}
                            numberOfLines={7}
                            textInputKey="s"
                            onChangeText={(text) => {
                              setDeviceData(text)
                            }}
                          />
                          <ThemedText color="foregroundColorMuted65">{`sample: \n${defaultValue}`}</ThemedText>
                        </View>
                        <Spacer flex={1} minHeight={contentPadding} />
                      </>
                    )}
                    <GitHubLoginButton analyticsLabel="github_login_public" loading={loading} onPress={() => handleSubmit(updateCalling, loading)} style={sharedStyles.button} title="Submit" />
                    {data && !loading && !error && <ThemedText color="foregroundColorMuted65">{JSON.stringify(data)}</ThemedText>}
                  </View>
                  <Spacer flex={1} minHeight={contentPadding} />
                  {error && <ThemedText color="foregroundColorMuted65">{`error: ${error.message}`}</ThemedText>}
                  {loading && (
                    <View style={[StyleSheet.absoluteFill, sharedStyles.center]} pointerEvents="none">
                      <ThemedActivityIndicator color={foregroundThemeColor} size="small" style={loadingIndicatorStyle} />
                    </View>
                  )}
                </>
              )}
            </Mutation>
          )}
        </FullHeightScrollView>
      </>
    )
  }),
)

// DeviceStatusModal.displayName = 'DeviceStatusModal'
