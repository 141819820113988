import { EnhancedDevice, IDict, IDictArray } from '@devhub/core/dist'
import { get } from 'dottie'
import moment from 'moment'
import { Linking } from '../libs/linking'
import { Platform } from '../libs/platform'
import { convertText2StartStop, getMinutesInDay, getMomentRange, validatePhoneNumber } from '../utils/helpers/shared'
import { SENSOR_ADC_TYPE } from './DeviceModels'

export const ACTIVE = 'active'
export const STATUS = 'status'
export const SCHEDULE_ON = 'scheduleOn'
export const INPUT_ERROR = 'inputError'
export const INPUT_MESSAGE = 'inputMessage'
export const OUTPUT_CONFIG_NAMES = 'outputConfigNames'
export const SCHEDULE = 'schedule'
export const INPUT_CONFIG = 'inputConfig'
export const OUTPUT_CONFIG = 'outputConfig'
export const OUTPUT_CONFIG_MODEL = 'outputConfigModel'
export const SHARED_TO_PHONE_NUMBERS = 'sharedToPhoneNumbers'
export const QUERY_BALANCE = 'QUERY_BALANCE'
export const MOBILE_TOPUP = 'MOBILE_TOPUP'
export const RESET_FACTORY = 'RESET_FACTORY'

export const defaultUrl = require('@devhub/components/assets/control-panel-icon.png') // tslint:disable-line

export const statusOnUrl = require('@devhub/components/assets/metroui-other-power-icon.png') // tslint:disable-line
export const statusOffUrl = require('@devhub/components/assets/power-icon.png') // tslint:disable-line
export const scheduleOnUrl = require('@devhub/components/assets/timer-icon.png') // tslint:disable-line
export const scheduleOffUrl = require('@devhub/components/assets/clock-icon.png') // tslint:disable-line
export const errorUrl = require('@devhub/components/assets/process-warning-icon.png') // tslint:disable-line
export const errorFixedUrl = require('@devhub/components/assets/gear-icon.png') // tslint:disable-line
export const LIMIT_MAX = 450

export const LIMIT_MIN = 10
export const LIMIT_DEFAULT = 150
export const LIMIT_INTERVAL = 600000
export const EW01 = 'EW01'

export const EW01DK = 'EW01DK'
export const EW03 = 'EW03'
export const EW05 = 'EW05'

export const ES01P = 'ES01+'
export const ES01SP = 'ES01S+'
export const ES03P = 'ES03+'
export const ES03SP = 'ES03S+'
export const EV02 = 'EV02'
export const EV04 = 'EV04'

export const wifiDeviceType = [EW01, EW01DK, EW03, EW05]
export const smsDeviceType = [ES01P, ES01SP, ES03P, EV02, EV04]

export const OUTPUT_NUM_MAIN = 5
const MAX_SCHEDULE_COMMAND_NUMBER = 5
const MAX_SCHEDULE_COMMAND_NUMBER_BY_MODEL = 20

const MAX_SCHEDULE_COMMAND_NUMBER_EW = 30
const MAX_SCHEDULE_COUNT_EW = 8
const MAX_SCHEDULE_COUNT_SMS = 1
export const OUTPUT_NUM_MAXIMUM = 37

export enum OUTPUT_TYPE {
  VAN,
  TAI,
  TAI_EX,
}

export enum OUTPUT_TYPE_SMS {
  VAN,
  TAI,
}

export interface IOutputConfig {
  outputType: OUTPUT_TYPE
  dependency?: number
}

export interface IOutputConfigs {
  [key: string]: IOutputConfig
}

export const outputNumberStock = Array.from({ length: OUTPUT_NUM_MAXIMUM }, (_, id) => id)
export const outputNumberSmsStock = Array.from({ length: 12 }, (_, id) => id)

const commonActions = [
  { title: 'Kiểm Tra Trạng Thái', value: STATUS, sms: 'TT' },
  { title: 'Kiểm Tra Lịch Biểu', value: SCHEDULE, sms: 'KTLB' },
  { title: 'Danh Sách Quản lý', value: SHARED_TO_PHONE_NUMBERS, sms: 'KTQLY' },
]
const KTVT = { title: 'Kiểm Tra Cấu Hình', value: OUTPUT_CONFIG, sms: 'KTVT' }
const advanceActions = [
  { title: 'Kiểm Tra tài khoản', value: QUERY_BALANCE, sms: '*101#' },
  { title: 'Nạp Tiền', value: MOBILE_TOPUP, sms: '*100*từ 12-15 ký tự#' },
  // { title: 'Khôi Phục Cài Đặt Gốc', value: RESET_FACTORY, sms: 'KPCD' },
]

export enum INPUT_SPEC_TYPE {
  BIT,
  SEQUENCE,
}

export enum INPUT_TYPE {
  NO,
  NC,
  AS,
  DC,
  LM,
  SU,
  LA,
  LK,
  ADC0,
  ADC1,
  ADC2,
  ADC3,
}

export type InputTypeStrings = keyof typeof INPUT_TYPE

export const BIT_GROUP = [
  { title: 'Mở', type: INPUT_TYPE.NO },
  { title: 'Đóng', type: INPUT_TYPE.NC },
  { title: 'Áp Suất', type: INPUT_TYPE.AS },
  { title: 'Dòng Chảy', type: INPUT_TYPE.DC },
]

export const SEQUENCE_GROUP = [
  { title: 'Làm Mát', type: INPUT_TYPE.LM },
  { title: 'Sưởi', type: INPUT_TYPE.SU },
  { title: 'Làm Ẩm', type: INPUT_TYPE.LA },
  { title: 'Sấy', type: INPUT_TYPE.LK },
]

export const ADCS = [INPUT_TYPE.ADC0, INPUT_TYPE.ADC1, INPUT_TYPE.ADC2, INPUT_TYPE.ADC3]

export const ADC_STRINGS = ADCS.map((type) => INPUT_TYPE[type])

const singleThresholdText = 'Ngưỡng duy nhất được thiết lập, thiết bị sẽ tự động chuyển sang "Xử lý xong sự cố" sau 1 khoảng "Thời lượng xóa sự cố".'
const geText = 'Ngưỡng Khắc Phục Sự Cố cần lớn hơn hoặc bằng Ngưỡng Xẳy Ra Sự Cố'
const leText = 'Ngưỡng Khắc Phục Sự Cố cần nhỏ hơn hoặc bằng Ngưỡng Xẳy Ra Sự Cố'
const validateGeThreshold = (occurAt: number, fixedAt: number) => (occurAt > fixedAt ? geText : undefined)
const validateLeThreshold = (occurAt: number, fixedAt: number) => (occurAt < fixedAt ? leText : undefined)

export const INPUT_TYPE_BUNDLE: { [key: string]: IDict } = {
  [INPUT_TYPE[INPUT_TYPE.LM]]: {
    singleThreshold: singleThresholdText,
    validateThreshold: validateLeThreshold,
  },
  [INPUT_TYPE[INPUT_TYPE.SU]]: {
    singleThreshold: singleThresholdText,
    validateThreshold: validateGeThreshold,
  },
  [INPUT_TYPE[INPUT_TYPE.LA]]: {
    singleThreshold: singleThresholdText,
    validateThreshold: validateGeThreshold,
  },
  [INPUT_TYPE[INPUT_TYPE.LK]]: {
    singleThreshold: singleThresholdText,
    validateThreshold: validateLeThreshold,
  },
  [INPUT_TYPE[INPUT_TYPE.ADC0]]: {
    singleThreshold: singleThresholdText,
  },
  [INPUT_TYPE[INPUT_TYPE.ADC1]]: {
    singleThreshold: singleThresholdText,
  },
  [INPUT_TYPE[INPUT_TYPE.ADC2]]: {
    singleThreshold: singleThresholdText,
  },
  [INPUT_TYPE[INPUT_TYPE.ADC3]]: {
    singleThreshold: singleThresholdText,
  },
}

export enum OPERATION_TYPE_ALIAS {
  'T' = 0,
  'B' = 1,
  'WARNING' = 2,
  'EX' = 3,
  'H' = 4,
}

export const OPERATIONS = {
  [OPERATION_TYPE_ALIAS.T]: {
    title: 'Tắt',
    code: OPERATION_TYPE_ALIAS[OPERATION_TYPE_ALIAS.T],
  },
  [OPERATION_TYPE_ALIAS.B]: {
    title: 'Bật',
    code: OPERATION_TYPE_ALIAS[OPERATION_TYPE_ALIAS.B],
  },
  [OPERATION_TYPE_ALIAS.WARNING]: {
    title: 'Bỏ',
    code: OPERATION_TYPE_ALIAS[OPERATION_TYPE_ALIAS.WARNING],
  },
  [OPERATION_TYPE_ALIAS.EX]: {
    title: 'Tiếp',
    code: OPERATION_TYPE_ALIAS[OPERATION_TYPE_ALIAS.EX],
  },
  [OPERATION_TYPE_ALIAS.H]: {
    title: 'Hẹn Giờ',
    code: OPERATION_TYPE_ALIAS[OPERATION_TYPE_ALIAS.H],
  },
}

export const OPERATION_TYPES = [OPERATION_TYPE_ALIAS.T, OPERATION_TYPE_ALIAS.B, OPERATION_TYPE_ALIAS.WARNING]
export const OPERATION_TYPES_OUTPUTS = [...OPERATION_TYPES, OPERATION_TYPE_ALIAS.H]
export const DONE_OPERATION_TYPES = [...OPERATION_TYPES, OPERATION_TYPE_ALIAS.EX]
export const DONE_OPERATION_TYPES_OUTPUTS = [...OPERATION_TYPES_OUTPUTS, OPERATION_TYPE_ALIAS.EX]
export const HAPPENING_OPERATIONS = OPERATION_TYPES.map((operation) => OPERATIONS[operation])
export const HAPPENING_OPERATIONS_OUTPUTS = OPERATION_TYPES_OUTPUTS.map((operation) => OPERATIONS[operation])
export const DONE_OPERATION = DONE_OPERATION_TYPES.map((operation) => OPERATIONS[operation])
export const DONE_OPERATION_OUTPUTS = DONE_OPERATION_TYPES_OUTPUTS.map((operation) => OPERATIONS[operation])

export const initAttributeKeys = (attribute: string, inputCount: number) => [...Array(inputCount).keys()].map((inputIndex) => `${attribute}_${inputIndex}`)
export const splitOnOffValue = (valueText: string | undefined) => valueText?.replace('*', '').replace('#', '').split('') || []
export const isTurnOn = (valueText?: string) => valueText === 'on' || valueText === '1'

const buildOnOffMessage = (device: EnhancedDevice, method: string, params: IDict, port: number | undefined, enableOffWithEnd?: boolean) => {
  let action = 'T'
  let duration = 0
  let afterMinutes = 0
  const turnOn = isTurnOn(params.value)
  if (turnOn || enableOffWithEnd) {
    const startNow = params.startNow
    action = turnOn ? 'B' : 'T'
    const st = getMomentRange(convertText2StartStop(params.timer))
    const now = moment().utc(true)
    if (st.length === 1) {
      if (st[0].isBefore(now)) {
        st[0].add(1, 'days')
      }
      afterMinutes = startNow ? 0 : Math.abs(st[0].diff(now, 'minute')) || 1
    } else if (st.length > 1) {
      if (startNow) {
        afterMinutes = 0
        if (st[1].isBefore(now)) {
          st[1].add(1, 'days')
        }
        duration = Math.abs(st[1].diff(now, 'minute')) || 1
      } else {
        duration = Math.abs(st[1].diff(st[0], 'minute')) || 1
        if (st[0].isBefore(now)) {
          st[0].add(1, 'days')
        }
        afterMinutes = Math.abs(st[0].diff(now, 'minute')) || 1
      }
    }
  }
  return `M${port === undefined ? 1 : port + 1} ${action} ${duration} S ${afterMinutes}`
}

const buildScheduleMessage = (device: EnhancedDevice, onOff: boolean, inputKey: string, schedule: IDictArray, port: number | undefined, isShorten: boolean = false, addTime?: boolean) => {
  const isSingle = port === undefined
  // @ts-ignore
  const outputIndex = isSingle ? 1 : port + 1
  const path = isSingle ? '1.0.timer' : '0.timer'
  const action = onOff ? 'B' : 'T'
  if (!onOff && isSingle) return `LB${outputIndex} T`
  let scheduleText = ''
  if (onOff) {
    const timerText: string = get(schedule, path, '')
    if (timerText) {
      const startStops = timerText.split(':')
      let count = 0
      const commands = startStops.reduce((pre, startStopText, index) => {
        const st = getMomentRange(convertText2StartStop(startStopText))
        if (st.length >= 2) {
          const overNight = index === startStops.length - 1 && getMinutesInDay(st[0]) > getMinutesInDay(st[1])
          if (isShorten) {
            count = count + 1
            const duration = Math.abs(st[1].diff(st[0], 'minute')) || 1
            pre.push(st[0].utc().format('HHmm'), String(duration).padStart(3, '0'))
          } else {
            count = count + st.length
            pre.push(st[0].utc().format('HH mm B'))
            if (overNight) {
              pre.unshift(st[1].utc().format('HH mm T'))
            } else {
              pre.push(st[1].utc().format('HH mm T'))
            }
          }
        }
        return pre
      }, [] as string[])
      if (count > 0) {
        if (addTime || (addTime === undefined && !isShorten)) {
          commands.push(`TG ${moment().format('HH mm ss DD MM YY')}`)
        }
        scheduleText = `TGB${outputIndex} ${String(count).padStart(2, '0')} ${commands.join(' ').trim()}`
      }
    }
  } else if (!isShorten) {
    scheduleText = `TGB${outputIndex} 01 00 01 T`.trim()
  }
  return `LB${outputIndex} ${action} ${scheduleText}`.trim()
}

const filterRemainOutputNumbers = (outputConfigsState: IOutputConfigs, stock: number[]) => {
  const inUses = Object.keys(outputConfigsState).map((outputNumberText) => Number(outputNumberText))
  return stock.filter((n) => !inUses.includes(n))
}

const historyBundle = [
  {
    keys: [STATUS],
    title: 'Bật/Tắt',
  },
  {
    keys: [SCHEDULE_ON],
    title: 'Lịch biểu',
  },
]

export interface IParams extends IDict {
  value: boolean
}

export const renderErrorMessage = (key: string | undefined, value: string | number, extra?: IDict) => {
  if (key?.startsWith(INPUT_ERROR)) {
    const splits = key.split('_')
    const inputIndex = splits.length >= 2 ? splits[splits.length - 1] : undefined
    if (inputIndex !== undefined) {
      const inputNumber = Number(inputIndex)
      const error = value && value !== '{}'
      const text = error ? 'Xảy ra sự cố ' : 'Đã hết sự cố '
      return { title: `${text} - tại đầu dò ${inputNumber + 1}`, imageUrl: error ? errorUrl : errorFixedUrl }
    }
  }
  return { title: `${key}: ${value}`, imageUrl: defaultUrl }
}

const deviceConfig = (type: string) => {
  const config: IDict = {
    maxOfSchedule: MAX_SCHEDULE_COUNT_EW,
    maxCountScheduleTimer: MAX_SCHEDULE_COMMAND_NUMBER_EW,
    outputCountDefault: 1,
  }
  let order = 0
  let inputCount = 2
  switch (type) {
    case EW01:
    case EW01DK:
      order = type === EW01 ? 1000 : 1100
      Object.assign(config, {
        order,
        inputCount,
        outputCountDefault: 1,
        singleOutput: true,
        basicFeatures: type === EW01DK,
        historyKeys: [...historyBundle, { keys: initAttributeKeys(INPUT_ERROR, inputCount), title: 'Đầu dò' }],
        configKeys: initAttributeKeys(INPUT_MESSAGE, inputCount),
        renderMessage: (key: string, value: string | number, extra?: IDict) => {
          let title
          let imageUrl
          switch (key) {
            case STATUS:
              title = value === 'on' ? 'BẬT đầu ra' : 'TẮT đầu ra'
              imageUrl = value === 'on' ? statusOnUrl : statusOffUrl
              break
            case SCHEDULE_ON:
              title = value === 'true' ? 'BẬT lịch biểu' : 'TẮT lịch biểu'
              imageUrl = value === 'true' ? scheduleOnUrl : statusOffUrl
              break
            default:
              return renderErrorMessage(key, value, extra)
          }
          return { title, imageUrl }
        },
      })
      break
    case EW05:
    case EW03:
      const sensors = [SENSOR_ADC_TYPE.temperature, SENSOR_ADC_TYPE.humidity, ...ADC_STRINGS]
      const outputCountDefault = type === EW03 ? 3 : 5
      order = type === EW03 ? 3000 : 5000
      inputCount = 8
      Object.assign(config, {
        inputCount,
        outputCountDefault,
        order,
        singleOutput: false,
        hasOutputConfig: true,
        maxCountOfOutput: 37,
        sensorFields: sensors,
        nameFields: [...sensors, 'sensorConnect'],
        customizeFields: sensors.map((field) => `customize-${field}`),
        historyKeys: [...historyBundle, { keys: initAttributeKeys(INPUT_ERROR, inputCount), title: 'Đầu dò' }],
        configKeys: [OUTPUT_CONFIG_NAMES, ...initAttributeKeys(INPUT_MESSAGE, inputCount)],
        renderMessage: (key: string, value: string, extra?: IDict) => {
          switch (key) {
            case STATUS:
            case SCHEDULE_ON:
              const object = key === STATUS ? '' : 'lịch biểu '
              const all = splitOnOffValue(value)
              const ons = all.reduce((pre, onOff, index) => {
                if (onOff === '1' || onOff === 'true') {
                  pre.push(index)
                }
                return pre
              }, [] as number[])

              let title
              let imageUrl
              if (ons.length <= 0) {
                title = `TẮT ${object}tất cả đầu ra`
                imageUrl = key === STATUS ? statusOffUrl : scheduleOffUrl
              } else if (ons.length === all.length) {
                title = `BẬT ${object}tất cả đầu ra`
                imageUrl = key === STATUS ? statusOnUrl : scheduleOnUrl
              } else {
                title = `BẬT ${object}${ons.length > 1 ? 'các ' : ''}đầu ra ${ons.map((on) => on + 1).join(', ')}`
                imageUrl = key === STATUS ? statusOnUrl : scheduleOnUrl
              }
              return { title, imageUrl }
          }
          return renderErrorMessage(key, value, extra)
        },
        pickRemainOutputNumbers: (outputConfigsState: IOutputConfigs) => filterRemainOutputNumbers(outputConfigsState, outputNumberStock),
      })
      break
    case ES01P:
    case ES01SP:
      Object.assign(config, {
        isSMSTransport: true,
        singleOutput: true,
        maxOfSchedule: MAX_SCHEDULE_COUNT_SMS,
        maxCountScheduleTimer: type === ES01P ? MAX_SCHEDULE_COMMAND_NUMBER : MAX_SCHEDULE_COMMAND_NUMBER_BY_MODEL,
        refreshActions: [...commonActions, ...advanceActions],
        buildOnOffMessage,
        buildScheduleMessage: (device: EnhancedDevice, onOff: boolean, inputKey: string, schedule: IDictArray, port: number | undefined, addTime?: boolean) =>
          buildScheduleMessage(device, onOff, inputKey, schedule, port, type === ES01SP, addTime),
      })
      break
    case ES03P:
    case ES03SP:
    case EV02:
    case EV04:
      Object.assign(config, {
        isSMSTransport: true,
        singleOutput: false,
        hasOutputConfig: true,
        outputCountDefault: type === ES03P || type === ES03SP ? 3 : type === EV02 ? 2 : 4,
        maxOfSchedule: MAX_SCHEDULE_COUNT_SMS,
        maxCountScheduleTimer: MAX_SCHEDULE_COMMAND_NUMBER,
        maxCountOfOutput: 12,
        refreshActions: [...commonActions, KTVT, ...advanceActions],
        validateStartStop: type === EV02 || type === EV04,
        buildOnOffMessage,
        buildScheduleMessage: (device: EnhancedDevice, onOff: boolean, inputKey: string, schedule: IDictArray, port: number | undefined, addTime?: boolean) =>
          buildScheduleMessage(device, onOff, inputKey, schedule, port, type === ES03SP, addTime),
        buildOutputConfigMessage: (device: EnhancedDevice, outputConfigText: string) => {
          const outputConfig = convertOutputConfig(outputConfigText, device.type || '')
          const { VAN, TAI } = Object.entries(outputConfig).reduce(
            (pre, [outputIndex, bundle]) => {
              if (bundle.outputType === OUTPUT_TYPE.VAN) {
                pre.VAN.push(String(Number(outputIndex) + 1).padStart(2, '0'))
              } else if (bundle.outputType === OUTPUT_TYPE.TAI) {
                pre.TAI.push(String(Number(outputIndex) + 1).padStart(2, '0'))
              }
              return pre
            },
            { VAN: [] as string[], TAI: [] as string[] },
          )

          return `VAN ${String(VAN.length).padStart(2, '0')} ${VAN.join(',')} TAI ${String(TAI.length).padStart(2, '0')} ${TAI.join(',')}`
        },
        pickRemainOutputNumbers: (outputConfigsState: IOutputConfigs) => filterRemainOutputNumbers(outputConfigsState, outputNumberSmsStock),
      })
      break
  }
  return config
}

export const DEVICES_CONFIG: IDict = [...wifiDeviceType, ...smsDeviceType].reduce((pre, deviceType) => {
  pre[deviceType] = deviceConfig(deviceType)
  return pre
}, {} as IDict)

export interface TimeSeriesValue {
  ts: number
  value: number | string
}

export const openAppSMS = (recipient: string, body: string) => {
  if (Platform.isSupportLazicoSms()) {
    if (validatePhoneNumber(recipient)) {
      if (body) {
        const url = `sms://${recipient}&body=${encodeURIComponent(body)}`
        Linking.canOpenURL(url)
          .then((supported) => {
            if (!supported) {
              console.log('Unsupported url: ' + url)
            } else {
              return Linking.openURL(url)
            }
          })
          .catch((err) => console.error('Linking An error occurred', err))
      } else {
        console.log(`openAppSMS body empty ${body}`)
      }
    } else {
      console.log(`invalid recipient`)
    }
  } else {
    console.log(`sms://${recipient}&body=${body}`)
  }
}

export const convertOutputConfig = (configString: string | undefined, type: string) => {
  if (configString === undefined) return {}
  if (!configString)
    return Array.from({ length: DEVICES_CONFIG[type]?.outputCountDefault || OUTPUT_NUM_MAIN }, (_, id) => ({
      outputNumber: id,
    })).reduce((pre, current) => {
      pre[current.outputNumber] = { outputType: OUTPUT_TYPE.TAI }
      return pre
    }, {} as IOutputConfigs)

  const splits = configString.split(';')
  const outputConfigs = splits.reduce((pre, current, cIndex) => {
    let params: IOutputConfig | undefined
    switch (current) {
      case 'b':
        params = {
          outputType: OUTPUT_TYPE.TAI_EX,
        }
        break
      case 't':
        params = {
          outputType: OUTPUT_TYPE.TAI,
        }
        break
      default:
        if (current.startsWith('v')) {
          params = {
            outputType: OUTPUT_TYPE.VAN,
          }
          const parts = current.split(':')
          if (parts.length >= 2) {
            const dependency = Number(parts[1])
            if (!isNaN(dependency)) {
              params.dependency = dependency
            }
          }
        }
        break
    }
    if (params) {
      pre[cIndex] = params
    }
    return pre
  }, {} as IOutputConfigs)

  return outputConfigs
}

export const mapInputTypeField = (inputType: string) =>
  inputType === INPUT_TYPE[INPUT_TYPE.LM] || inputType === INPUT_TYPE[INPUT_TYPE.SU] ? SENSOR_ADC_TYPE.temperature : inputType === INPUT_TYPE[INPUT_TYPE.LA] || inputType === INPUT_TYPE[INPUT_TYPE.LK] ? SENSOR_ADC_TYPE.humidity : inputType
