import { EnhancedDevice } from '@devhub/core/dist'
import { MenuItem, OverflowMenu } from '@ui-kitten/components'
import React, { memo, useContext, useRef, useState } from 'react'
import { DEVICES_CONFIG, MOBILE_TOPUP, openAppSMS } from '../../stores/DeviceConfig'
import { RootStoreContext } from '../../stores/RootStore'
import { FLASH_MESSAGE_SCREEN_REF } from '../../stores/UIReferenceStore'
import { sharedStyles } from '../../styles/shared'
import { scaleFactor } from '../../styles/variables'
import { validatePhoneNumber, validateTopUpMobileCode } from '../../utils/helpers/shared'
import { foregroundThemeColor } from '../common/Button'
import { IconButton } from '../common/IconButton'
import { ThemedIcon } from '../themed/ThemedIcon'
import { ThemedText } from '../themed/ThemedText'
import { DialogInput, DialogInputRef } from './DialogInput'

export const RefreshDataIcon = memo(({ device }: { device?: EnhancedDevice }) => {
  const rootStore = useContext(RootStoreContext)
  const [visible, setVisible] = useState<boolean>(false)
  const { isSMSTransport, refreshActions } = DEVICES_CONFIG[device?.type || ''] || {}
  const dialogRef = useRef<DialogInputRef>(null)

  const renderToggleButton = () => (
    <ThemedText onPress={() => setVisible(true)} style={[sharedStyles.center, sharedStyles.paddingHalf]}>
      <ThemedIcon family="octicon" name={'three-bars'} size={16 * scaleFactor} color={foregroundThemeColor} />
    </ThemedText>
  )

  return refreshActions ? (
    <>
      <OverflowMenu
        visible={visible}
        anchor={renderToggleButton}
        onBackdropPress={() => {
          setVisible(false)
        }}
      >
        {refreshActions.map(({ value: v, title: t, sms }: { title: string; value: string; sms: string }, index: number) => (
          <MenuItem
            key={`OverflowMenu-action-${index}`}
            title={t}
            onPress={() => {
              setVisible(false)
              if (isSMSTransport && sms) {
                if (device?.label) {
                  if (v === MOBILE_TOPUP) {
                    if (dialogRef.current !== null) {
                      dialogRef.current.show(true)
                    }
                  } else {
                    openAppSMS(device.label, sms)
                  }
                } else {
                  rootStore.uiReferenceStore.danger(`Thiết bị chưa được cài đặt số điện thoại`)
                }
              }
            }}
          />
        ))}
      </OverflowMenu>
      <DialogInput
        ref={dialogRef}
        title={`Nhập mã số thẻ cào`}
        validateInput={(inputValue) => (validateTopUpMobileCode(inputValue) ? '' : 'mã thẻ cào phải gồm từ 12 đến 15 chữ số')}
        onFinish={(inputText, action) => {
          if (device?.label) {
            openAppSMS(device.label, `*100*${inputText}#`)
          } else {
            rootStore.uiReferenceStore.danger(`Thiết bị chưa được cài đặt số điện thoại`)
          }
        }}
      />
    </>
  ) : (
    <IconButton family="octicon" name="sync" size={16 * scaleFactor} tooltip={'Tải lại dữ liệu'} onPress={() => rootStore.currentDeviceStore.queryDeviceAttributes()} />
  )
})

RefreshDataIcon.displayName = 'RefreshDataIcon'
