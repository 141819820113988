import { EnhancedDevice, IDict, IDictArray, IKeyFunction } from '@devhub/core/dist'
import { action, observable } from 'mobx'
import { persist } from 'mobx-persist'

import * as actions from '../redux/actions'
import { store } from '../redux/store'
import { extractValues } from './AppWebSocketClient'
import { ACTIVE, convertOutputConfig, DEVICES_CONFIG, INPUT_CONFIG, INPUT_ERROR, INPUT_MESSAGE, OUTPUT_CONFIG, OUTPUT_CONFIG_MODEL, OUTPUT_CONFIG_NAMES, SCHEDULE, SCHEDULE_ON, SHARED_TO_PHONE_NUMBERS, STATUS } from './DeviceConfig'
import { RootStore } from './RootStore'
import { extractOnOffValues, getDeviceInputCount, getOutputNumbers, getRequestOption, getTelemetryKeys } from './StoreUtils'

export const UNKNOWN_SUBSCRIPTION = -1
export const LATEST_TELEMETRY = 100

export const INPUT_CONFIG_ORDER = 'inputConfigOrder'
export const INPUT_CONFIG_RESULT = 'inputConfigResult'
export const FIRST_TIME_CONNECT = 'firstTimeConnect'
export const RPC_RESULT_SET_STATUS = 'rpcResult_setStatus'
export const RPC_RESULT_SET_SCHEDULE_ON = 'rpcResult_setScheduleOn'
export const RPC_RESULT = 'rpcResult'

const CURRENT_FW_VER = 'currentFwVer'
const LAST_CONNECT_TIME = 'lastConnectTime'
const LAST_DISCONNECT_TIME = 'lastDisconnectTime'
const FW_STATE_IS_SYNCED = 'fwStateIsSynced'
const TARGET_FW_VER = 'targetFwVer'
const USER_APPROVE_FW_VER = 'userApproveFwVer'
const RSSI = 'RSSI'

export type IO_ATTRIBUTE_TYPE = `status` | 'inputError' | 'inputConfig' | 'inputMessage' | 'inputConfigResult' | 'rpcResult_setStatus' | 'rpcResult_setScheduleOn'

export interface IAttributeByNumber {
  content: any
  inputIndex: number
  lastUpdateTs?: number
}

export interface ISubsciptionData {
  [key: string]: [[]]
}

export interface SubscriptionUpdate {
  subscriptionId?: number
  cmdId?: number
  errorCode?: number
  errorMsg?: string
  data: ISubsciptionData
  update?: IDictArray
  latestValues?: {
    [key: string]: number
  }
}

// storage
export interface CurrentDeviceValue {
  deviceId: string
  portNumber: number
  inputCount: number
  lastConnectTime: number
  lastDisconnectTime: number
  currentFwVer: string
  fwStateIsSynced: boolean
  targetFwVer: string
  userApproveFwVer: string
  RSSI: number
  [ACTIVE]: boolean
  [SHARED_TO_PHONE_NUMBERS]: string[]

  device: EnhancedDevice | undefined
  loading: boolean

  [key: string]: any
}

const initCurrentDeviceValue = {
  deviceId: '',
  portNumber: 1,
  inputCount: 2,
  device: undefined,
  lastConnectTime: 0,
  lastDisconnectTime: 0,
  fwStateIsSynced: true,
  targetFwVer: '',
  userApproveFwVer: '',
  currentFwVer: '',
  RSSI: 0,
  [ACTIVE]: false,
  [SHARED_TO_PHONE_NUMBERS]: [],
  loading: false,
}

const data: CurrentDeviceValue = observable({ ...initCurrentDeviceValue })

const schema = {
  deviceId: true,
  portNumber: false,
  inputCount: false,
  device: false,
  lastConnectTime: false,
  lastDisconnectTime: false,
  currentFwVer: false,
  fwStateIsSynced: false,
  targetFwVer: false,
  userApproveFwVer: false,
  RSSI: false,
  [ACTIVE]: true,
  [SHARED_TO_PHONE_NUMBERS]: false,
}

const state = persist(schema)(data)

export class CurrentDeviceStore {
  rootStore: RootStore
  state: CurrentDeviceValue
  updateValues: IKeyFunction

  setDeviceByDeviceId = action((deviceId?: string) => {
    if (this.state) {
      if (deviceId) {
        this.state.deviceId = deviceId
      }
      if (this.state.deviceId) {
        const cDevice = this.rootStore.deviceListStore.findDeviceById(this.state.deviceId)
        if (cDevice) {
          this.setDevice(cDevice)
        }
      }
    }
  })

  setDevice = action((value?: EnhancedDevice) => {
    if (this.state.loading) {
      return
    }
    const preDevice = this.state.device
    this.state.device = value
    const shouldFetch = value && value !== preDevice
    if (shouldFetch) {
      this.state.deviceId = value?.id?.id || ''
      this.resetValues()
      this.extractDeviceProperty()
      this.forceQueryDeviceData()
    } else {
      console.log(`deviceId ${value?.id?.id} no change`)
    }
  })

  queryDeviceTelemetry = action(() => {
    const token = this.rootStore.appConfig.state.token
    if (this.state.device && token) {
      this.state.loading = true
      const deviceId = this.getDeviceId()
      console.log(`queryDeviceData of deviceId: ${deviceId}`)
      const keys = getTelemetryKeys(this.state.device, {
        [OUTPUT_CONFIG]: this.rootStore.deviceListStore.state[`${OUTPUT_CONFIG}_${deviceId}`],
      })
      const requestOptions = getRequestOption(token)
      fetch(`${this.rootStore.appConfig.getAppBaseHostUri()}/api/plugins/telemetry/DEVICE/${this.getDeviceId()}/values/timeseries?keys=${keys.join(',')}`, requestOptions)
        .then((response) => {
          console.log(`queryDeviceData response data ok `)
          return response.json()
        })
        .then((subscriptionUpdate: ISubsciptionData) => {
          this.extractValues({ data: subscriptionUpdate }, keys)
          this.state.loading = false
        })
        .catch((e) => {
          this.state.loading = false
          console.log(`queryDeviceData error ${e}`)
        })
    }
  })

  queryDeviceAttributes = action((onReceiveBaseAttributes?: () => any) => {
    const token = this.rootStore.appConfig.state.token
    if (this.state.device && token) {
      this.state.loading = true
      console.log(`queryDeviceAttributes of deviceId: ${this.getDeviceId()}`)
      const inputs = this.getInputs()
      const attributeKeys = [ACTIVE, FIRST_TIME_CONNECT, 'lastActivityTime', FW_STATE_IS_SYNCED, TARGET_FW_VER, USER_APPROVE_FW_VER, SCHEDULE, INPUT_CONFIG_ORDER, OUTPUT_CONFIG, ...inputs.map((orderNum) => `${INPUT_CONFIG}_${orderNum}`)]
      this.queryAttributes(attributeKeys, 'SHARED_SCOPE', token, onReceiveBaseAttributes)
      this.queryAttributes([OUTPUT_CONFIG_NAMES, OUTPUT_CONFIG_MODEL, ...inputs.map((orderNum) => `${INPUT_MESSAGE}_${orderNum}`), ...this.getExtraAttributes()], 'SERVER_SCOPE', token)
      this.queryAttributes([CURRENT_FW_VER], 'CLIENT_SCOPE', token)
    }
  })

  setAttributeByNumber = action((value: any, field: string, index: number, lastUpdateTs: number = 0, parseJson: boolean = false) => {
    if (field && index >= 0 && this.state.deviceId) {
      this.rootStore.deviceListStore.state[`${field}_${this.state.deviceId}`] = {
        content: parseJson ? JSON.parse(value) : value,
        inputIndex: index,
        lastUpdateTs,
      }
    }
  })

  resetValues = action(() => {
    this.resetBasicFetchValues()
  })

  forceQueryDeviceData = action(() => {
    this.queryDeviceAttributes(() => {
      const token = this.rootStore.appConfig.state.token
      if (this.state.device && token) {
        const deviceId = this.getDeviceId()
        const type = this.state.device?.type || ''
        const { isSMSTransport, hasOutputConfig } = DEVICES_CONFIG[type] || {}
        if (isSMSTransport) {
          const scheduleOnAttrs = hasOutputConfig
            ? getOutputNumbers(this.state.device, {
                [OUTPUT_CONFIG]: this.rootStore.deviceListStore.state[`${OUTPUT_CONFIG}_${deviceId}`],
              }).map((port) => `${SCHEDULE_ON}_${port}`)
            : [SCHEDULE_ON]
          this.queryAttributes([SHARED_TO_PHONE_NUMBERS, ...scheduleOnAttrs], 'SERVER_SCOPE', token)
        } else {
          this.queryDeviceTelemetry()
        }
        if (hasOutputConfig) {
          const outputNumbers = getOutputNumbers(this.state.device, {
            [OUTPUT_CONFIG]: this.rootStore.deviceListStore.state[`${OUTPUT_CONFIG}_${deviceId}`],
          })
          this.queryAttributes(
            outputNumbers.map((port) => `${SCHEDULE}_${port}`),
            'SHARED_SCOPE',
            token,
          )
        }
      }
    })
    this.subscribeDevice()
  })

  extractValues = action((subscriptionUpdate: SubscriptionUpdate, attributeKeys: string[] = []) => {
    this.preExtractValues()
    extractValues(subscriptionUpdate, this.updateValues)
    if (attributeKeys.length > 0 && this.state.device) {
      const type = this.state.device?.type || ''
      if (DEVICES_CONFIG[type]?.hasOutputConfig) {
        if (attributeKeys.find((attr) => attr === OUTPUT_CONFIG)) {
          const deviceId = this.getDeviceId()
          if (Array.isArray(subscriptionUpdate.data) && subscriptionUpdate.data.findIndex((item) => item.key === OUTPUT_CONFIG) < 0) {
            this.rootStore.deviceListStore.state[`${OUTPUT_CONFIG}_${deviceId}`] = convertOutputConfig('', type)
          }
        }
      }
    }
  })

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.state = state
    this.updateValues = {
      [ACTIVE]: action((value: string | boolean) => this.setActive(value)),
      [STATUS]: action((value: string) => this.setStatus(value)),
      [SCHEDULE]: action((value: IDict, field: string) => this.setSchedule(value, field)),
      [SCHEDULE_ON]: action((value: string, field: string) => this.setScheduleOn(value, field)),
      [CURRENT_FW_VER]: action((value: string) => this.setCurrentFwVer(value)),
      [FIRST_TIME_CONNECT]: action((value: number) => (this.rootStore.deviceListStore.state[`${FIRST_TIME_CONNECT}_${this.state.deviceId}`] = value)),
      [LAST_CONNECT_TIME]: action((value: number) => this.setLastConnectTime(value)),
      [LAST_DISCONNECT_TIME]: action((value: number) => this.setLastDisconnectTime(value)),
      [RSSI]: action((value: number | string) => this.setRssi(value)),
      [FW_STATE_IS_SYNCED]: action((value: boolean) => this.setFwStateIsSynced(value)),
      [TARGET_FW_VER]: action((value: string) => this.setTargetFwVer(value)),
      [USER_APPROVE_FW_VER]: action((value: string) => this.setUserApproveFwVer(value)),
      [INPUT_CONFIG_ORDER]: action((value: string) => (this.rootStore.deviceListStore.state[`${INPUT_CONFIG_ORDER}_${this.state.deviceId}`] = value)),
      [INPUT_ERROR]: action((value: any, field: string, index: number, lastUpdateTs?: number) => this.setAttributeByNumber(value, field, index, lastUpdateTs)),
      [INPUT_CONFIG_RESULT]: action((value: any, field: string, index: number, lastUpdateTs?: number) => this.setAttributeByNumber(value, field, index, lastUpdateTs)),
      [INPUT_CONFIG]: action((value: any, field: string, index: number, lastUpdateTs?: number) => this.setAttributeByNumber(value, field, index, lastUpdateTs)),
      [OUTPUT_CONFIG]: action((value: string) => (this.rootStore.deviceListStore.state[`${OUTPUT_CONFIG}_${this.state.deviceId}`] = convertOutputConfig(value || '', this.state.device?.type || ''))),
      [OUTPUT_CONFIG_NAMES]: action((value: string) => (this.rootStore.deviceListStore.state[`${OUTPUT_CONFIG_NAMES}_${this.state.deviceId}`] = value)),
      [OUTPUT_CONFIG_MODEL]: action((value: string) => (this.rootStore.deviceListStore.state[`${OUTPUT_CONFIG_MODEL}_${this.state.deviceId}`] = value)),
      [INPUT_MESSAGE]: action((value: any, field: string, index: number, lastUpdateTs?: number) => this.setAttributeByNumber(value, field, index, lastUpdateTs)),
      [RPC_RESULT]: action((value: any, field: string, index: number, lastUpdateTs?: number) => this.setAttributeByNumber(value, field, index, lastUpdateTs, true)),
      [SHARED_TO_PHONE_NUMBERS]: (value: any) => (this.rootStore.deviceListStore.state[`${SHARED_TO_PHONE_NUMBERS}_${this.state.deviceId}`] = value),
    } as IKeyFunction
  }

  reclaim = () => {
    this.setDevice(undefined)
    this.state.deviceId = ''
  }

  preExtractValues = () => {
    const type = this.state.device?.type || ''
    const fields: string[] = [...(DEVICES_CONFIG[type]?.nameFields || []), ...(DEVICES_CONFIG[type]?.customizeFields || [])]
    fields.forEach((attribute: string) => {
      if (!this.updateValues[attribute]) {
        this.updateValues[attribute] = (value: any, field?: string, index?: number, lastUpdateTs?: number) => {
          this.rootStore.deviceListStore.state[`${attribute}_${this.state.deviceId}`] = { value, lastUpdateTs }
        }
      }
    })
  }

  getExtraAttributes = () => (this.state.device?.type ? DEVICES_CONFIG[this.state.device?.type]?.customizeFields || [] : [])

  public reset() {
    this.state.deviceId = initCurrentDeviceValue.deviceId
    this.state.portNumber = initCurrentDeviceValue.portNumber
    this.state.inputCount = initCurrentDeviceValue.inputCount
    this.state.device = initCurrentDeviceValue.device
    this.state[ACTIVE] = initCurrentDeviceValue[ACTIVE]
    this.resetBasicFetchValues()
    this.state.loading = false
  }

  onReceiveMessage(subscriptionUpdate: SubscriptionUpdate | null) {
    if (subscriptionUpdate !== null) {
      if (subscriptionUpdate.errorCode === 0) {
        const deviceId = this.getDeviceId()
        const subscriptionType = this.rootStore.appWebSocketClient.getSubscriptionType(subscriptionUpdate.subscriptionId, deviceId)
        switch (subscriptionType) {
          case LATEST_TELEMETRY:
            this.extractValues(subscriptionUpdate)
            break
          default:
            break
        }
      }
    }
    return true
  }

  setActive(value: string | boolean) {
    this.state[ACTIVE] = value === true || value === 'true'
  }

  setStatus(value: string) {
    this.rootStore.deviceListStore.state[`${STATUS}_${this.state.deviceId}`] = value
    const type = this.state.device?.type || ''
    if (DEVICES_CONFIG[type]?.hasOutputConfig) {
      extractOnOffValues(value, this.state.device).forEach((on, outputIndex) => {
        this.state[`${STATUS}_${outputIndex}`] = on
        this.rootStore.deviceListStore.state[`${STATUS}_${this.state.deviceId}_${outputIndex}`] = on
      })
    }
  }

  setSchedule(value: IDict, field: string) {
    this.rootStore.deviceListStore.state[`${this.state.deviceId}_${field}`] = value
  }

  setScheduleOn(value: string, field: string) {
    this.rootStore.deviceListStore.state[`${this.state.deviceId}_${field}`] = value
    const type = this.state.device?.type || ''
    if (DEVICES_CONFIG[type]?.hasOutputConfig && !DEVICES_CONFIG[type]?.isSMSTransport) {
      extractOnOffValues(value, this.state.device).forEach((on, outputIndex) => {
        this.rootStore.deviceListStore.state[`${this.state.deviceId}_${SCHEDULE_ON}_${outputIndex}`] = on
      })
    }
  }

  setCurrentFwVer(value: string) {
    this.state.currentFwVer = value
  }

  setLastConnectTime(value: number) {
    this.state.lastConnectTime = value
  }

  setLastDisconnectTime(value: number) {
    this.state.lastDisconnectTime = value
  }

  setFwStateIsSynced(value: boolean) {
    this.state.fwStateIsSynced = value
  }

  setTargetFwVer(value: string) {
    this.state.targetFwVer = value
  }

  setUserApproveFwVer(value: string) {
    this.state.userApproveFwVer = value
  }

  setRssi(value: number | string) {
    this.state.RSSI = Number(value)
  }

  public getDeviceId() {
    return this.state.device ? this.state.device.id.id : ''
  }

  public extractDeviceProperty() {
    this.state.inputCount = getDeviceInputCount(this.state.device?.type)
  }

  public isInputConfigOn(inputOrder: number) {
    const inputConfigByNumber = this.getAttributeByNumber(INPUT_CONFIG, inputOrder)
    const config = `${inputConfigByNumber?.content?.content || ''}`
    return config && config.startsWith('B;')
  }

  saveDeviceName(newLabel: string) {
    if (this.state.device) {
      this.state.device.label = newLabel
    }
  }

  getInputs() {
    const type = this.state.device?.type || ''
    const inputCount = DEVICES_CONFIG[type]?.inputCount || 2
    return [...Array(inputCount).keys()]
  }

  getPorts() {
    const outputConfig = this.state[OUTPUT_CONFIG]
    if (outputConfig) {
      return Object.keys(outputConfig).map((inputNumberText) => Number(inputNumberText))
    }
    return [...Array(this.state.portNumber).keys()]
  }

  getCurrentFwVer() {
    return this.state.currentFwVer
  }

  getLastConnectTime() {
    return this.state.lastConnectTime
  }

  getLastDisconnectTime() {
    return this.state.lastDisconnectTime
  }

  getTargetFwVer() {
    return this.state.targetFwVer
  }

  getUserApproveFwVer() {
    return this.state.userApproveFwVer
  }

  getAttributeByNumber(attribute: IO_ATTRIBUTE_TYPE, attributeNumber: number) {
    return this.rootStore.deviceListStore.state[`${attribute}_${attributeNumber}_${this.state.deviceId}`]
  }

  getFwStateIsSynced() {
    return this.state.fwStateIsSynced
  }

  getRssi() {
    return this.state.RSSI
  }

  getAvailableInputError() {
    const inputs = this.getInputs()
    return inputs
      .filter((input) => {
        const inputConfig = this.getAttributeByNumber(INPUT_CONFIG, input)
        const inputError = this.getAttributeByNumber(INPUT_ERROR, input)
        return inputConfig && inputError && inputError.content && inputError.content !== '{}' && typeof inputError.inputIndex === 'number' && this.isInputConfigOn(inputError.inputIndex)
      })
      .map((input) => this.getAttributeByNumber(INPUT_ERROR, input))
  }

  subscribeDevice() {
    if (this.state && this.state.device) {
      const deviceId = this.getDeviceId()
      const { isSMSTransport } = DEVICES_CONFIG[this.state.device.type || ''] || {}
      if (!isSMSTransport) {
        this.rootStore.appWebSocketClient.subscribeDevice(deviceId)
      }
    }
  }

  setScheduleOnForSMSDevice(deviceId: string, postParams: IDict, port?: number) {
    const token = this.rootStore.appConfig.state.token
    this.state.loading = true
    const options = getRequestOption(token, 'POST')
    const portSuffix = port !== undefined ? `_${port}` : ''
    const value = postParams?.value || 'off'
    fetch(`${this.rootStore.appConfig.getAppBaseHostUri()}/api/plugins/telemetry/DEVICE/${deviceId}/attributes/SERVER_SCOPE`, {
      ...options,
      body: JSON.stringify({
        [`${SCHEDULE_ON}${portSuffix}`]: value,
      }),
    })
      .then(() => {
        this.setScheduleOn(value, `${SCHEDULE_ON}${portSuffix}`)
        this.state.loading = false
      })
      .catch((e) => {
        this.state.loading = false
        console.debug(`setScheduleOnForSMSDevice error`, e)
      })
  }

  private queryAttributes(attributeKeys: string[], sharedscope: string, token: string, onReceiveAttributes?: () => any) {
    const requestOptions = getRequestOption(token)
    fetch(`${this.rootStore.appConfig.getAppBaseHostUri()}/api/plugins/telemetry/DEVICE/${this.getDeviceId()}/values/attributes/${sharedscope}?keys=${attributeKeys.join(',')}`, requestOptions)
      .then((response) => {
        console.log(`queryDeviceAttributes -  response data `)
        if (response.status === 401) {
          console.log(`[unauthenticated] current device request refresh token`)
          store.dispatch(
            actions.refreshToken({
              refreshToken: this.rootStore.appConfig.state.refreshToken,
            }),
          )
          return null
        }
        return response.json()
      })
      .then(
        action((subscriptionUpdate: ISubsciptionData) => {
          attributeKeys.forEach((attribute) => this.resetByAttribute(attribute))
          this.extractValues({ data: subscriptionUpdate }, attributeKeys)
          if (onReceiveAttributes) {
            onReceiveAttributes()
          }
          this.state.loading = false
        }),
      )
      .catch((e) => {
        this.state.loading = false
        console.debug(`queryDeviceAttributes error`, e)
      })
  }

  private resetBasicFetchValues() {
    this.state.lastConnectTime = initCurrentDeviceValue.lastConnectTime
    this.state.lastDisconnectTime = initCurrentDeviceValue.lastDisconnectTime
    this.state.fwStateIsSynced = initCurrentDeviceValue.fwStateIsSynced
    this.state.targetFwVer = initCurrentDeviceValue.targetFwVer
    this.state.userApproveFwVer = initCurrentDeviceValue.userApproveFwVer
    this.state.currentFwVer = initCurrentDeviceValue.currentFwVer
    this.state.RSSI = initCurrentDeviceValue.RSSI
  }

  private resetByAttribute(attribute: string) {
    switch (attribute) {
      case 'lastConnectTime':
      case 'lastDisconnectTime':
      case 'RSSI':
        this.state[attribute] = 0
        break
      case 'targetFwVer':
      case 'userApproveFwVer':
      case 'currentFwVer':
        this.state[attribute] = ''
        break
      case 'fwStateIsSynced':
        this.state[attribute] = false
        break
      default:
        break
    }
  }
}
