import { IDict } from '@devhub/core/dist'
import React, { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { INPUT_TYPE_BUNDLE, mapInputTypeField } from '../../stores/DeviceConfig'
import { IMapValueFunction, SENSOR_ADC_BUNDLE } from '../../stores/DeviceModels'
import { contentPadding } from '../../styles/variables'
import { InputConfigRow } from '../columns/InputConfigRow'
import { Spacer } from '../common/Spacer'
import { useTheme } from '../context/ThemeContext'
import { ThemedText } from '../themed/ThemedText'
import { AppToggle } from './AppToggle'
import { InputSpinnerRow } from './InputSpinnerRow'

export interface IThresholdRangeBaseValue {
  range?: number[]
  greaterOrLess?: boolean
}
export interface IThresholdRangeValue extends IThresholdRangeBaseValue {
  invalidText?: string
  useInputProblemRemove?: boolean
}

export interface IThresholdRange extends IThresholdRangeBaseValue {
  inputType?: string
  inputData?: IDict
  inputProblemRemove?: number
  onChange?: (newValue: IThresholdRangeValue) => void
  onSingleThreshold?: () => void
}

export interface IThresholdRangeRef {
  value: () => IThresholdRangeValue
}

export const ThresholdRange = memo(
  forwardRef<IThresholdRangeRef, IThresholdRange>(({ inputType = '', inputData = {}, range = [0, 0], greaterOrLess, inputProblemRemove, onChange, onSingleThreshold }, ref) => {
    const theme = useTheme()
    const inputTypeField = mapInputTypeField(inputType)
    const code = inputData[inputTypeField]?.code
    const { unit = '', min = 0, max = 255, delta = 0, mapValueFunction = ({ num }: IMapValueFunction) => num } = code ? SENSOR_ADC_BUNDLE[code] || {} : {}
    const customMin = inputData[inputType]?.min !== undefined ? inputData[inputType]?.min : min
    const customMax = inputData[inputType]?.max !== undefined ? inputData[inputType]?.max : max
    const customDelta = inputData[inputType]?.delta !== undefined ? inputData[inputType]?.delta : delta
    const customUnit = inputData[inputType]?.unit !== undefined ? inputData[inputType]?.unit : unit
    const [currentGreaterOrLess, setCurrentGreaterOrLess] = useState<boolean>(!greaterOrLess)
    const [upLow, setUpLow] = useState<number[]>([range[0], range[1]])
    useEffect(
      () =>
        setUpLow([mapValueFunction({ num: range[0], min: customMin, max: customMax, delta: customDelta, round2Decimal: true }), mapValueFunction({ num: range[1], min: customMin, max: customMax, delta: customDelta, round2Decimal: true })]),
      [range, customMin, customMax, customDelta],
    )
    useEffect(() => setCurrentGreaterOrLess(!greaterOrLess), [greaterOrLess])

    const { singleThreshold, descText, invalidateText } = useMemo(() => {
      const equal = upLow[0] === upLow[1]
      const validateThreshold = INPUT_TYPE_BUNDLE[inputType]?.validateThreshold
      let invalid = validateThreshold?.(upLow[0], upLow[1])
      if (!invalid && equal && !inputProblemRemove) {
        invalid = `Cần thiết lập Thời Lượng Xóa Sự Cố là 1 khoảng thời gian`
      }
      return {
        singleThreshold: equal,
        descText: equal ? INPUT_TYPE_BUNDLE[inputType]?.singleThreshold : undefined,
        invalidateText: invalid,
      }
    }, [upLow, inputProblemRemove, inputType])

    useImperativeHandle(
      ref,
      () => ({
        value: () => ({
          range: [mapValueFunction({ num: upLow[0], reverse: true, min: customMin, max: customMax, delta: customDelta }), mapValueFunction({ num: upLow[1], reverse: true, min: customMin, max: customMax, delta: customDelta })],
          greaterOrLess: !currentGreaterOrLess,
          useInputProblemRemove: singleThreshold,
          invalidText: invalidateText,
        }),
      }),
      [currentGreaterOrLess, invalidateText, singleThreshold, upLow],
    )

    useEffect(() => {
      if (onChange)
        onChange({
          greaterOrLess: !currentGreaterOrLess,
          useInputProblemRemove: singleThreshold,
          invalidText: invalidateText,
        })
    }, [currentGreaterOrLess, invalidateText, singleThreshold])

    useEffect(() => {
      if (onSingleThreshold && singleThreshold && !inputProblemRemove) {
        onSingleThreshold()
      }
    }, [singleThreshold])

    return (
      <>
        <InputSpinnerRow
          minValue={customMin}
          maxValue={customMax}
          valueType={'real'}
          subject={`Ngưỡng sự cố (${customUnit})`}
          tooltip={`ngưỡng xảy ra sự cố theo ${customUnit}`}
          initValue={upLow[0]}
          onChange={(num: number) => setUpLow([num, upLow[1]])}
        />
        <Spacer height={contentPadding / 2} />
        <InputSpinnerRow
          minValue={customMin}
          maxValue={customMax}
          valueType={'real'}
          subject={`Ngưỡng khắc phục (${customUnit})`}
          tooltip={`ngưỡng khắc phục sự cố theo ${customUnit}`}
          initValue={upLow[1]}
          onChange={(num: number) => setUpLow([upLow[0], num])}
        />
        {singleThreshold && inputType.startsWith('ADC') ? (
          <>
            <Spacer height={contentPadding / 2} />
            <InputConfigRow subject={`Sự cố khi`} tooltip={`Sự cố xảy ra khi vượt hoặc dưới ngưỡng`} inline={true}>
              <AppToggle initValue={currentGreaterOrLess} activeText={'vượt ngưỡng'} inactiveText={'dưới ngưỡng'} onChange={(isChecked) => setCurrentGreaterOrLess(isChecked)} />
            </InputConfigRow>
          </>
        ) : null}
        <Spacer height={contentPadding / 2} />
        {descText ? <ThemedText color={theme.blue}>{descText}</ThemedText> : null}
        {invalidateText ? <ThemedText color={theme.red}>{invalidateText}</ThemedText> : null}
      </>
    )
  }),
)
