import { Layout, ViewPager } from '@ui-kitten/components'
import React, { FC, ReactNode } from 'react'
import { View } from 'react-native'

import { sharedStyles } from '../../styles/shared'
import { useTheme } from '../context/ThemeContext'

interface IItem {
  view: ReactNode
}
interface Props {
  items: IItem[]
  selectedIndex: number
  swipeEnabled: boolean
}

export const ViewPagerLayout: FC<Props> = ({
  items,
  selectedIndex,
  swipeEnabled = false,
  ...otherProps
}) => {
  const theme = useTheme()

  return (
    <ViewPager swipeEnabled={swipeEnabled} selectedIndex={selectedIndex} {...otherProps}>
      {items.map((item: IItem, index: number) => {
        return (
          <View key={`tab-${index}-view`}>
            <Layout style={[sharedStyles.padding, { backgroundColor: theme.backgroundColor }]}>
              {item.view}
            </Layout>
          </View>
        )
      })}
    </ViewPager>
  )
}
