import React, { useCallback, useState } from 'react'
import { View } from 'react-native'
import { sharedStyles } from '../../styles/shared'
import { IconButton } from '../common/IconButton'
import { ThemedText } from '../themed/ThemedText'
import { NumberUnit, TimeInputUnit, TimeUnit } from './InputTimeCoupleModal'
import { TimelineItemPopover } from './TimelineItemPopover'

const MAX_CYCLE = 8

const pulseWidth = 38

const onStyle = {
  borderTopColor: '#fe7013',
  borderRightColor: '#fe7013',
  borderTopWidth: 1,
  borderRightWidth: 1,
  width: pulseWidth,
}

const lastPulseStyle = {
  borderBottomColor: '#fe7013',
  borderBottomWidth: 1,
  width: pulseWidth,
}

const activeColor = '#fe7013'

const offStyle = {
  borderRightColor: activeColor,
  borderRightWidth: 1,
  ...lastPulseStyle,
}

export const PulseLine = (props: {
  cycles: any[]
  onAddPulseItemPress?: () => void
  onUpdatePulseItemPress?: (position: number) => void
  onDeletePulseItemPress?: (position: number) => void
}) => {
  const {
    cycles,
    onAddPulseItemPress,
    onUpdatePulseItemPress,
    onDeletePulseItemPress,
  } = props
  const [unit, setUnit] = useState(TimeInputUnit.SECOND)

  const formatDuration = useCallback(
    (duration: number) => {
      const newValue = duration / TimeUnit[unit].value
      return Number.isInteger(newValue) ? newValue : parseFloat(newValue.toFixed(unit + 1))
    },
    [unit],
  )

  return (
    <View
      style={[
        sharedStyles.displayFlex,
        sharedStyles.horizontalAndVerticallyAligned,
      ]}
    >
      <ThemedText
        color={activeColor}
        onPress={() => setUnit((unit + 1) % NumberUnit)}
      >
        {TimeUnit[unit].text}
      </ThemedText>

      {[...Array(Math.floor(cycles.length / 2)).keys()].map((cIndex) => {
        const keyIndex = 2 * cIndex
        const cycle = cycles[keyIndex]
        const nextIndex = keyIndex + 1
        const cycleView = (
          <View style={cycle.turnOn ? onStyle : offStyle}>
            <ThemedText
              color="foregroundColorMuted65"
              style={[sharedStyles.textCenter, { fontSize: 11 }]}
            >
              {`${formatDuration(cycle.duration)}`}
            </ThemedText>
          </View>
        )

        const nextCycle = nextIndex < cycles.length ? cycles[nextIndex] : null
        const nextCycleView = nextCycle ? (
          <View
            style={
              nextIndex < cycles.length - 1
                ? nextCycle.turnOn
                  ? onStyle
                  : offStyle
                : lastPulseStyle
            }
          >
            <ThemedText
              color="foregroundColorMuted65"
              style={[sharedStyles.textCenter, { fontSize: 11 }]}
            >
              {`${formatDuration(nextCycle.duration)}`}
            </ThemedText>
          </View>
        ) : null

        return (
          <TimelineItemPopover
            key={`cycle-${keyIndex}`}
            toggleView={
              <>
                {cycleView}
                {nextCycleView}
              </>
            }
            onUpdateItemPress={() => {
              if (onUpdatePulseItemPress) onUpdatePulseItemPress(keyIndex)
            }}
            onDeleteItemPress={() => {
              if (onDeletePulseItemPress) onDeletePulseItemPress(keyIndex)
            }}
          />
        )
      })}
      {MAX_CYCLE > cycles.length ? (
        <IconButton
          family="octicon"
          name="plus-circle"
          size={18}
          tooltip="Thêm"
          onPress={() => {
            if (onAddPulseItemPress) onAddPulseItemPress()
          }}
        />
      ) : null}
    </View>
  )
}
