import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { View, ViewStyle } from 'react-native'
import { DEVICES_CONFIG } from '../../stores/DeviceConfig'
import { RootStoreContext } from '../../stores/RootStore'
import { sharedStyles } from '../../styles/shared'
import { ThemedText } from '../themed/ThemedText'
import { MultiPortPanel } from './MultiPortPanel'
import { SinglePortPanel } from './SinglePortPanel'

export const DeviceRealtimePanel = observer(() => {
  const rootStore = useContext(RootStoreContext)
  const deviceType = rootStore.currentDeviceStore.state.device?.type
  const deviceId = rootStore.currentDeviceStore.getDeviceId()
  let controlPanel
  let viewStyle: ViewStyle[] = []

  const singleOutput = deviceType && DEVICES_CONFIG[deviceType]?.singleOutput

  if (singleOutput) {
    controlPanel = <SinglePortPanel deviceId={deviceId} />
  } else if (singleOutput === false) {
    viewStyle = [sharedStyles.flexGrow]
    controlPanel = <MultiPortPanel deviceId={deviceId} />
  } else {
    controlPanel = <ThemedText color="foregroundColor">{`Thiết bị đang được phát triển`}</ThemedText>
  }
  return <View style={[...viewStyle, sharedStyles.paddingVerticalHalf]}>{controlPanel}</View>
})
