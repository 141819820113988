import { BaseColumn, BaseEnhancement, GenericColumnCreation, IssueOrPullRequestColumnFilters } from './devhub'
import { GitHubIssueOrPullRequestSubjectType } from './github'
import { GraphQLUserPlan } from './graphql'

export interface IKeyFunction {
  [key: string]: (value: any, field?: string, index?: number, lastUpdateTs?: number) => any
}

export interface IDict {
  [key: string]: any
}

export interface IDictArray {
  [key: string]: any[]
}

export interface DeviceColumnSubscription {
  id: string
  type: DeviceColumn['type']
  subtype: 'ISSUES' | 'PULLS' | undefined
  params: {
    owners?: IssueOrPullRequestColumnFilters['owners']
    involves?: IssueOrPullRequestColumnFilters['involves']
    subjectType: GitHubIssueOrPullRequestSubjectType | undefined
    state?: IssueOrPullRequestColumnFilters['state']
    draft?: IssueOrPullRequestColumnFilters['draft']
    query?: string
  }
  createdAt: string
  updatedAt: string
}

export interface DevicesColumnSubscription {
  id: string
  type: DevicesColumn['type']
  subtype: 'ISSUES' | 'PULLS' | undefined
  params: {
    owners?: IssueOrPullRequestColumnFilters['owners']
    involves?: IssueOrPullRequestColumnFilters['involves']
    subjectType: GitHubIssueOrPullRequestSubjectType | undefined
    state?: IssueOrPullRequestColumnFilters['state']
    draft?: IssueOrPullRequestColumnFilters['draft']
    query?: string
  }
  createdAt: string
  updatedAt: string
}

export interface AppColumn extends BaseColumn {
  id: string
  type: string
  filters?: any
}

export interface DeviceColumn extends AppColumn {
  type: 'device'
}

export interface DeviceDetailColumnType extends AppColumn {
  type: 'deviceDetail'
}

export interface InputConfigColumnType extends AppColumn {
  type: 'inputConfig'
}

export interface DevicesColumn extends AppColumn {
  type: 'devices'
}

export type DevicesColumnCreation = GenericColumnCreation<DevicesColumn>
export type DeviceColumnCreation = GenericColumnCreation<DeviceColumn>

export const devicesColumn: DevicesColumnCreation = {
  type: 'devices',
} as DevicesColumnCreation
export const deviceColumn: DeviceColumnCreation = {
  type: 'device',
} as DeviceColumnCreation

export interface Device {
  id: EntityType
  title: string
  desc: string
  updated_at: string
  url: string
}

export interface EntityType {
  id: string
  entityType: string
}

export interface DeviceAdditionalInfo {
  active?: string
}

interface ICustomer {
  id?: string
}

export interface EnhancedDevice extends Device, BaseEnhancement {
  unread?: boolean
  forceUnreadLocally?: boolean
  saved?: boolean
  customerId: ICustomer

  name: string
  type: string
  label: string
  createdTime: number
  additionalInfo?: DeviceAdditionalInfo | null
  tenantId: EntityType
  idInfo: EntityType
}

export interface AppUser extends Omit<GraphQLUserPlan, 'id'> {
  id: {
    entityType: string
    id: string
  }
  createdTime: number
  additionalInfo: {
    description: string
    userPasswordHistory: any
    lastLoginTs: number
    failedLoginAttempts: number
  }
  tenantId: {
    entityType: string
    id: string
  }
  customerId: {
    entityType: string
    id: string
  }
  email: string
  authority: string
  firstName: string
  lastName: string
  name: string
}
