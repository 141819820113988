import { EMPTY_OBJ } from '../../utils/constants'
import { RootState } from '../types'

const s = (state: RootState) => state.device || EMPTY_OBJ

export const devicesMapById = (state: RootState) => {
  return s(state).byId
}

export const deviceSelector = (state: RootState, deviceId: string) => {
  if (!deviceId) return

  const byId = s(state).byId
  return (byId && byId[deviceId]) || undefined
}

export const deviceIdSelector = (state: RootState) => {
  return s(state).currentDeviceId
}
