import { constants } from '@devhub/core'
import { IDict } from '@devhub/core/dist'
import moment from 'moment'
import { isMobile as isMobileWithBrowser } from 'react-device-detect'
// @ts-ignore
import { create, PREDEF_RES } from 'react-native-pixel-perfect'
import { DEFAULT_NAMES, SENSOR_ADC_BUNDLE } from '../stores/DeviceModels'
import { isMobile } from './helpers/shared'

export const EMPTY_ARRAY = constants.EMPTY_ARRAY
export const EMPTY_OBJ = constants.EMPTY_OBJ

export const YOUTUBE_PAGE = 'https://www.youtube.com/channel/UClrRH6kNteZRbCYMbgoxAqg'
export const FACEBOOK_PAGE = 'https://www.facebook.com/Lazicosp'
export const PAGE_CHANEL = 'https://lazico.com/'

export const PRIVACY_POLICY = 'https://connect.lazico.com/privacy.html'
export const TERM_OF_SERVICE = 'https://connect.lazico.com/term-service.html'

export const HOSTS = [
  {
    host: 'connect-prod.lazico.com',
    tenants: [
      {
        id: '967657d0-ee4e-11eb-a686-dbac59982f42',
        name: 'khách hàng phổ thông',
      },
    ],
    defaultTenantId: 0,
    env: 'product',
    title: 'Lazico Connect Product',
  },
  {
    host: '150.95.109.70',
    tenants: [
      {
        id: '967657d0-ee4e-11eb-a686-dbac59982f42',
        name: 'khách hàng phổ thông',
      },
    ],
    defaultTenantId: 0,
    env: 'product-ip',
    title: 'Lazico Connect Product-1p',
    uri: 'http',
    websocketSchema: 'ws',
  },
  {
    host: 'connect-staging.lazico.com',
    tenants: [
      {
        id: '47fa3000-f16d-11eb-9095-ff200ab8c63f',
        name: 'Nhóm phát triển',
      },
    ],
    defaultTenantId: 0,
    env: 'staging',
    title: 'Lazico Connect Staging',
  },
  {
    host: '150.95.113.166',
    tenants: [
      {
        id: '47fa3000-f16d-11eb-9095-ff200ab8c63f',
        name: 'Nhóm phát triển',
      },
    ],
    defaultTenantId: 0,
    env: 'staging-ip',
    title: 'Lazico Connect Staging-ip',
    uri: 'http',
    websocketSchema: 'ws',
  },
  {
    host: 'localhost:8080',
    tenants: [
      {
        id: '88b37f50-5ada-11ef-8bfe-a5b988bde6dd',
        name: 'Nhóm phát triển',
      },
    ],
    defaultTenantId: 0,
    env: 'localhost',
    title: 'Lazico Connect Localhost',
    uri: 'http',
    websocketSchema: 'ws',
  },
  {
    host: '192.168.1.9:8080',
    tenants: [
      {
        id: '967657d0-ee4e-11eb-a686-dbac59982f42',
        name: 'Nhóm phát triển',
      },
    ],
    defaultTenantId: 0,
    env: '192.168.1.9',
    title: 'Lazico Connect LAN',
    uri: 'http',
    websocketSchema: 'ws',
  },
  {
    host: 'localhost:8080',
    tenants: [
      {
        id: '97f3c930-5bfb-11ef-b99c-bf447befe9fb',
        name: 'Test',
      },
    ],
    defaultTenantId: 0,
    env: 'customize',
    title: 'customize',
    uri: 'http',
    websocketSchema: 'ws',
  },
]

export const hostsByEnv: IDict = HOSTS.reduce((result, item) => ({ ...result, [item.env]: { ...item } }), {})

// export const APP_BASE_HOST = __DEV__ ? '150.95.113.166' : 'iot.lazico.com' // 150.95.113.166" //'cloud.thingsboard.io' // '34.212.188.78' // '104.196.24.70'

export const SECOND5 = 5000
export const SECOND10 = 10000
export const SECOND15 = 15000
export const SECOND30 = 30000

export const A_MINUTE = 60000
export const MINUTE5 = 5 * A_MINUTE
export const MINUTE10 = 10 * A_MINUTE
export const MINUTE15 = 15 * A_MINUTE
export const MINUTE30 = 30 * A_MINUTE

export const AN_HOUR = 60 * A_MINUTE
export const HOUR2 = 2 * AN_HOUR
export const HOUR4 = 4 * AN_HOUR
export const HOUR12 = 12 * AN_HOUR

export const A_DAY = 24 * AN_HOUR
export const DAY3 = 3 * A_DAY

export const A_WEEK = 7 * A_DAY
export const WEEK2 = 2 * A_WEEK
export const A_MONTH = 30 * A_DAY

export const more = [
  { value: MINUTE5, title: '5p' },
  { value: MINUTE15, title: '15p' },
  { value: MINUTE30, title: '30p' },
  { value: HOUR2, title: '2h' },
  { value: HOUR4, title: '4h' },
  { value: HOUR12, title: '12h' },
  { value: DAY3, title: '3 ngày' },
  { value: A_WEEK, title: '1 tuần' },
  { value: WEEK2, title: '2 tuần' },
  { value: A_MONTH, title: '1 thg' },
]

export const customLast = { value: 0, title: 'Thêm' }

export const last = [{ value: -1, title: 'Tùy chọn' }, { value: MINUTE10, title: '10p' }, { value: AN_HOUR, title: '1h' }, { value: A_DAY, title: '1 ngày' }, customLast, { value: -2, title: 'Chu kỳ' }]

export const formatTimeByInterval = (value: number, interval: number) =>
  moment(value)
    .locale('vi')
    .format(interval >= A_DAY ? 'D MMMM/YY' : interval > SECOND30 ? 'D MMMM - HH:mm' : 'D MMMM - HH:mm:s')

export const intervals = [
  { value: SECOND5, title: '5s' },
  { value: SECOND10, title: '10s' },
  { value: SECOND15, title: '15s' },
  { value: SECOND30, title: '30s' },
  { value: A_MINUTE, title: '1p' },
  { value: MINUTE5, title: '5p' },
  { value: MINUTE15, title: '15p' },
  { value: MINUTE30, title: '30p' },
  { value: AN_HOUR, title: '1h' },
  { value: HOUR2, title: '2h' },
  { value: HOUR4, title: '4h' },
  { value: HOUR12, title: '12h' },
  { value: A_DAY, title: '1n' },
]

export const extractDaysOfWeek = (day: number) => {
  const bits = []
  /* tslint:disable:no-bitwise */
  for (let i = 0; i < 8; i++) bits[i] = (day >> i) & 1
  const daysOfWeek: number[] = []
  for (let i = 0; i < 8; i++) {
    if (i < bits.length && bits[i]) daysOfWeek.push(7 - i)
  }
  return daysOfWeek
}

const FIVE_MILISECONDS = 5 * 60 * 1000

export const getName = (bundle?: IDict | null, containUnit = false) => `${bundle?.name || SENSOR_ADC_BUNDLE[bundle?.code]?.title || DEFAULT_NAMES[bundle?.field] || bundle?.field || ''} ${(containUnit && bundle?.unit) || ''}`

export const getPastTime = (time: number, enableNow: boolean = false) => (enableNow || moment().valueOf() - time >= FIVE_MILISECONDS ? ` ${moment(time).locale('vi').fromNow().replace('một', '1')}` : '')

export const renderSensorName = (item: IDict) => {
  const name = getName(item)
  const unit = SENSOR_ADC_BUNDLE[item.code]?.unit
  return `${name}${unit ? ` (${unit})` : ''}`
}

const calcSize = create(PREDEF_RES.iphone7.px)

const w = 300
const h = 86
export const TOTAL_WIDTH = calcSize(isMobile || isMobileWithBrowser ? w : w / 4)
export const TOTAL_HEIGHT = calcSize(isMobile || isMobileWithBrowser ? h : h / 4)
console.log(`TOTAL_WIDTH ${TOTAL_WIDTH} , TOTAL_HEIGHT ${TOTAL_HEIGHT}, isMobile ${isMobile} , isMobileWithBrowser ${isMobileWithBrowser}`)
