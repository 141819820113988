import React, { RefObject, useEffect, useState } from 'react'
import { useMutation } from 'react-apollo'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import Collapsible from 'react-native-collapsible'
import FlashMessage from 'react-native-flash-message'

import { CHANGE_PASSWORD } from '../../graphql/gqls'
import { sharedStyles } from '../../styles/shared'
import { contentPadding, scaleFactor } from '../../styles/variables'
import { getMessageError } from '../../utils/helpers/shared'
import { Button } from '../common/Button'
import { Spacer } from '../common/Spacer'
import { ThemedTextInputPasswordWithIcon } from '../elements/ThemedTextInputPasswordWithIcon'
import { ThemedIcon } from '../themed/ThemedIcon'
import { ThemedText } from '../themed/ThemedText'

interface IInputValue {
  currentPassword: string
  newPassword: string
  repeatPassword: string
}

interface IInputError {
  currentPassword?: string
  newPassword?: string
  repeatPassword?: string
}

export const styles = StyleSheet.create({
  iconEye: {
    position: 'absolute',
    top: 12,
    right: 20,
  },
  inputPassword: {
    paddingRight: 40,
  },
})

interface Props {
  flashMessageRef: RefObject<FlashMessage>
}

export const ChangePasswordForUser = ({ flashMessageRef }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(true)
  const initalInputValue = {
    currentPassword: '',
    newPassword: '',
    repeatPassword: '',
  }
  const initalInputError = {
    currentPassword: '',
    newPassword: '',
    repeatPassword: '',
  }

  const [inputValue, setInputValue] = useState<IInputValue>(initalInputValue)
  const [changePassword, { loading }] = useMutation(CHANGE_PASSWORD)
  const [inputError, setInputError] = useState<IInputError>(initalInputError)

  const handleChangeInput = (field: string, value: string) => {
    setInputValue((prevState) => ({
      ...prevState,
      [field]: value,
    }))
  }

  useEffect(() => {
    setInputError(initalInputError)
    setInputValue(initalInputValue)
  }, [expanded])

  const handleValidateInput = () => {
    const errors = {} as IInputError
    if (inputValue.currentPassword.length < 6) {
      errors.currentPassword = 'Mật khẩu cũ ít nhất 6 ký tự'
    }
    if (inputValue.newPassword.length < 6) {
      errors.newPassword = 'Mật khẩu mới ít nhất 6 ký tự'
    }
    if (inputValue.repeatPassword.length < 6) {
      errors.repeatPassword = 'Nhập lại mật khẩu ít nhất 6 ký tự'
    } else if (inputValue.newPassword !== inputValue.repeatPassword) {
      errors.repeatPassword = 'Mật khẩu không khớp'
    }
    setInputError(errors)
    return errors
  }

  const handleSubmitForm = async () => {
    if (loading || Object.keys(handleValidateInput()).length > 0) {
      return
    }
    try {
      const response = await changePassword({
        variables: {
          input: {
            currentPassword: inputValue.currentPassword,
            newPassword: inputValue.newPassword,
          },
        },
      })
      if (response && response.data && response.data.changePassword) {
        if (flashMessageRef.current) {
          flashMessageRef.current.showMessage({
            message: 'Thay đổi mật khẩu thành công',
            type: 'success',
          })
        }
        setExpanded(!expanded)
        setInputError(initalInputError)
        setInputValue(initalInputValue)
      }
    } catch (error) {
      if (error && flashMessageRef.current) {
        flashMessageRef.current.showMessage({
          message: `${getMessageError(error)}`,
          type: 'danger',
        })
      }
    }
  }

  return (
    <>
      <TouchableOpacity
        onPress={() => setExpanded(!expanded)}
        style={[
          sharedStyles.horizontal,
          sharedStyles.justifyContentSpaceBetween,
          sharedStyles.padding,
        ]}
      >
        <ThemedText color="foregroundColor">ĐỔI MẬT KHẨU</ThemedText>
        <ThemedIcon
          family="octicon"
          name={expanded ? 'chevron-up' : 'chevron-down'}
          size={16 * scaleFactor}
          color={'foregroundColorMuted65'}
        />
      </TouchableOpacity>
      <Collapsible collapsed={expanded} duration={300}>
        <View style={[sharedStyles.padding]}>
          <View style={[sharedStyles.fullWidth, sharedStyles.fullMinWidth]}>
            <ThemedTextInputPasswordWithIcon
              textInputKey="password"
              placeholder="Mật khẩu cũ"
              iconShowPassword={true}
              value={inputValue.currentPassword || ''}
              style={[styles.inputPassword]}
              onChangeText={(text: string) =>
                handleChangeInput('currentPassword', text)
              }
              showSoftInputOnFocus={true}
            />
          </View>
          {inputError.currentPassword ? (
            <ThemedText color="red">{inputError.currentPassword}</ThemedText>
          ) : null}
          <Spacer height={contentPadding} />
          <View style={[sharedStyles.fullWidth, sharedStyles.fullMinWidth]}>
            <ThemedTextInputPasswordWithIcon
              textInputKey="password"
              placeholder="Mật khẩu mới"
              iconShowPassword={true}
              style={[styles.inputPassword]}
              value={inputValue.newPassword || ''}
              onChangeText={(text: string) =>
                handleChangeInput('newPassword', text)
              }
            />
          </View>
          {inputError && inputError.newPassword ? (
            <ThemedText color="red">{inputError.newPassword}</ThemedText>
          ) : null}
          <Spacer height={contentPadding} />
          <View style={[sharedStyles.fullWidth, sharedStyles.fullMinWidth]}>
            <ThemedTextInputPasswordWithIcon
              textInputKey="password"
              iconShowPassword={true}
              placeholder="Nhập lại mật khẩu mới"
              style={[styles.inputPassword]}
              value={inputValue.repeatPassword || ''}
              onChangeText={(text: string) =>
                handleChangeInput('repeatPassword', text)
              }
            />
          </View>
          {inputError && inputError.repeatPassword ? (
            <ThemedText color="red">{inputError.repeatPassword}</ThemedText>
          ) : null}
          <Spacer height={contentPadding} />
          <Button type="primary" loading={loading} onPress={handleSubmitForm}>
            {`Lưu`}
          </Button>
        </View>
      </Collapsible>
    </>
  )
}
