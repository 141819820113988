import { EnhancedDevice, IDict, IDictArray } from '@devhub/core/dist'
import { observable } from 'mobx'
import { persist } from 'mobx-persist'
import * as actions from '../redux/actions'
import { store } from '../redux/store'
import { extractValues } from './AppWebSocketClient'
import { ISubsciptionData, SubscriptionUpdate } from './CurrentDeviceStore'
import { ACTIVE, convertOutputConfig, DEVICES_CONFIG, LIMIT_DEFAULT, LIMIT_INTERVAL, OUTPUT_CONFIG, OUTPUT_CONFIG_MODEL, OUTPUT_CONFIG_NAMES, STATUS } from './DeviceConfig'
import { rootStore, RootStore } from './RootStore'
import { extractOnOffValues, getRequestOption, getTelemetryKeys } from './StoreUtils'
import { WebSocketClient } from './WebSocketClient'

export const pageSize = 20

const attributeKeys = [].join(',')

export interface ICustomerDevices {
  hasNext: boolean
  totalElements: number
  totalPages: number
  data: EnhancedDevice[]
}

export interface DeviceListSchema {
  totalElements: number
  totalPages: number
  hasNext: boolean
  data: EnhancedDevice[]
  shareWithMeDevices: EnhancedDevice[]

  loading: boolean | undefined
  error: string

  [key: string]: any
}

const data: DeviceListSchema = observable({
  totalElements: 0,
  totalPages: 0,
  hasNext: false,
  data: [],
  shareWithMeDevices: [],

  loading: undefined,
  error: '',
})

const schema = {
  totalElements: true,
  totalPages: true,
  hasNext: true,
  data: false,
  shareWithMeDevices: false,

  loading: false,
  error: false,
}

const state = persist(schema)(data)

export class DeviceListStore {
  rootStore: RootStore
  state: DeviceListSchema

  constructor(rs: RootStore) {
    this.rootStore = rs
    this.state = state
  }

  public reset() {
    this.state.data = []
    this.state.shareWithMeDevices = []
    this.state.totalElements = 0
    this.state.totalPages = 0
    this.state.hasNext = false
    this.state.loading = false
  }

  public fetchAllDevices(shouldSelectDevice = false) {
    this.queryCustomerDevices({
      onReceivedDevices: () => {
        this.queryShareWithMeDevices().then(() => {
          if (shouldSelectDevice) rootStore.currentDeviceStore.setDeviceByDeviceId()
        })
      },
    })
  }

  sendDevicesSubscribe(client: WebSocketClient) {
    const allDevices = [...this.state.data, ...this.state.shareWithMeDevices]
    allDevices.forEach((device) => this.subscribeAvailableDevices(device))
  }

  onReceiveMessage(subscriptionUpdate: SubscriptionUpdate | null) {
    if (subscriptionUpdate !== null) {
      if (subscriptionUpdate.errorCode === 0) {
        const deviceId = this.rootStore.appWebSocketClient.getDeviceId(subscriptionUpdate.subscriptionId)
        extractValues(subscriptionUpdate, {
          [STATUS]: (value: string) => {
            this.state[`${STATUS}_${deviceId}`] = value
            if (deviceId) {
              const device = this.rootStore.deviceListStore.findDeviceById(deviceId)
              if (device) {
                const type = device?.type || ''
                if (DEVICES_CONFIG[type]?.hasOutputConfig) {
                  extractOnOffValues(value, device).forEach((on, outputIndex) => (this.state[`${STATUS}_${deviceId}_${outputIndex}`] = on))
                }
              }
            }
          },
          [ACTIVE]: (value: string | boolean) => {
            this.state[`${ACTIVE}_${deviceId}`] = value
          },
        })
      }
    }
    return true
  }

  public findDeviceById(deviceId: string) {
    const allDevices = [...this.state.data, ...this.state.shareWithMeDevices]
    const foundDevice = allDevices.find((device) => device.id.id === deviceId)
    return foundDevice
  }

  public async queryShareWithMeDevices() {
    const token = this.rootStore.appConfig.state.token
    if (token) {
      const requestOptions = getRequestOption(token)
      return fetch(`${this.rootStore.appConfig.getAppBaseHostUri()}/api/user/shareDeviceInfos`, requestOptions)
        .then((response) => response.json())
        .then((customerDevices: ICustomerDevices) => {
          this.state.shareWithMeDevices = customerDevices?.data || []
          this.fetchAdditionData(customerDevices)
          this.state.shareWithMeDevices.forEach((device) => this.subscribeAvailableDevices(device))
        })
        .catch((e) => {
          console.log(`queryShareWithMeDevices error `, e)
        })
    }
  }

  public queryCustomerDevices({
    onReceivedDevices,
    onPreReceivedDevices,
  }: {
    onReceivedDevices?: () => void
    onPreReceivedDevices?: () => void
  } = {}) {
    console.log(`queryCustomerDevices loading: ${this.state.loading}`)
    if (this.state.loading) return
    const token = this.rootStore.appConfig.state.token
    const reduxState = store.getState()
    const customerId = reduxState?.auth?.user?.customerId?.id
    if (customerId && token) {
      this.state.loading = true
      console.log(`queryCustomerDevices of customerId: ${customerId}`)

      // GET /api/customer/{customerId}/deviceInfos{?type,textSearch,sortProperty,sortOrder,attributeKeys,pageSize,page
      const requestOptions = getRequestOption(token)
      const currentPage = Math.floor(this.state.data.length / pageSize)
      fetch(`${this.rootStore.appConfig.getAppBaseHostUri()}/api/customer/${customerId}/deviceInfos?attributeKeys=${attributeKeys}&pageSize=${pageSize}&page=${currentPage}`, requestOptions)
        .then((response) => {
          console.log(`queryCustomerDevices response response.status `, response.status)
          if (response.status === 401) {
            console.log(`[unauthenticated] request refresh token`)
            store.dispatch(
              actions.refreshToken({
                refreshToken: this.rootStore.appConfig.state.refreshToken,
              }),
            )
            return null
          }
          return response.json()
        })
        .then((customerDevices: ICustomerDevices) => {
          if (customerDevices) {
            if (onPreReceivedDevices) {
              onPreReceivedDevices()
            }
            this.extractCustomerDevices(customerDevices)
            this.fetchAdditionData(customerDevices)
            if (onReceivedDevices) {
              onReceivedDevices()
            }
            this.rootStore.appWebSocketClient.client.open(this.rootStore.appConfig.getWebSocketURI())
            this.state.loading = false
          }
        })
        .catch((e) => {
          this.state.loading = false
          console.log(`queryCustomerDevices error `, e)
        })
    }
  }

  public refresh() {
    console.log(`DeviceListStore refresh `)
    this.queryCustomerDevices({
      onPreReceivedDevices: () => {
        this.reset()
        this.queryShareWithMeDevices().then()
      },
    })
  }

  public reFetch() {
    console.log(`DeviceListStore reFetch `)
  }

  public fetchNextPage() {
    if (this.state.hasNext) {
      console.log(`DeviceListStore fetchNextPage `)
      this.queryCustomerDevices()
    }
  }

  async loadHistory(
    deviceId: string,
    { keys, startTs = Date.now() - 24 * 60 * 60000, agg = 'AVG', ...others }: { keys: string[]; startTs?: number; endTs?: number; interval?: number; limit?: number; agg?: string },
    onFinish?: (error?: any, data?: IDict[]) => any,
  ): Promise<IDictArray | undefined> {
    if (this.state.loading) return
    if (deviceId && keys.length > 0) {
      const token = this.rootStore.appConfig.state.token
      if (token) {
        const requestOptions = getRequestOption(token)
        const variables = {
          agg,
          limit: LIMIT_DEFAULT,
          interval: LIMIT_INTERVAL,
          startTs,
          endTs: startTs + LIMIT_INTERVAL * LIMIT_DEFAULT,
          ...others,
        }
        const params = Object.entries({
          keys: `${keys.join(',')}`,
          ...variables,
        })
          .map(([key, val]) => `${key}=${val}`)
          .join('&')
        this.state.loading = true
        const result = await fetch(`${this.rootStore.appConfig.getAppBaseHostUri()}/api/plugins/telemetry/DEVICE/${deviceId}/values/timeseries?${params}`, requestOptions)
          .then((response) => response.json())
          .then((timeSeries: IDictArray) => {
            const merges: IDict[] = []
            keys.forEach((key) => {
              this.rootStore.deviceListStore.state[`history_${key}_${deviceId}`] = { values: timeSeries[key], variables }
              if (onFinish) {
                const bundle = timeSeries[key]?.map((item) => ({ ...item, key }))
                if (bundle) {
                  merges.push(...bundle)
                }
              }
            })
            if (onFinish) {
              onFinish(
                false,
                merges.sort((a, b) => b.ts - a.ts),
              )
            }
            this.state.loading = false
            return timeSeries
          })
          .catch((e) => {
            console.error(`loadHistory error`, e)
            if (onFinish) onFinish(e)
            this.state.loading = false
            return undefined
          })
        return result
      }
    }
  }

  loadConfig(deviceId: string, configKeys: string[]) {
    return configKeys.reduce((pre, key) => {
      pre[key] = this.state[key]
      return pre
    }, {} as IDict)
  }

  private extractCustomerDevices(customerDevices?: ICustomerDevices) {
    this.state.totalElements = customerDevices?.totalElements || 0
    this.state.totalPages = customerDevices?.totalPages || 0
    this.state.hasNext = customerDevices?.hasNext || false
    const merges = [...this.state.data]
    customerDevices?.data.forEach((cDevice) => {
      const found = this.state.data.findIndex((device) => device.id.id === cDevice.id.id)
      if (found < 0) {
        merges.push(cDevice)
      } else {
        merges[found] = cDevice
      }
    })
    this.state.data = merges
  }

  private fetchAdditionData(customerDevices: ICustomerDevices) {
    customerDevices?.data.forEach((cDevice) => {
      const type = cDevice.type || ''
      if (DEVICES_CONFIG[type]?.hasOutputConfig) {
        const deviceId = cDevice.id?.id
        const token = this.rootStore.appConfig.state.token
        if (deviceId && token) {
          this.queryAttributes(token, deviceId, type, [OUTPUT_CONFIG], 'SHARED_SCOPE')
          this.queryAttributes(token, deviceId, type, [OUTPUT_CONFIG_NAMES, OUTPUT_CONFIG_MODEL], 'SERVER_SCOPE')
        }
      }
    })
  }

  private queryAttributes(token: string, deviceId: string, type: string, attributes: string[], scope: string) {
    const requestOptions = getRequestOption(token)
    fetch(`${this.rootStore.appConfig.getAppBaseHostUri()}/api/plugins/telemetry/DEVICE/${deviceId}/values/attributes/${scope}?keys=${attributes.join(',')}`, requestOptions)
      .then((response) => response.json())
      .then((subscriptionUpdate: ISubsciptionData) => {
        if (scope === 'SHARED_SCOPE') {
          let found = true
          if (Array.isArray(subscriptionUpdate)) {
            found = subscriptionUpdate.find((item) => item.key === OUTPUT_CONFIG)
          }
          if (found) {
            extractValues(
              { data: subscriptionUpdate },
              {
                [OUTPUT_CONFIG]: (value: string) => {
                  const configs = convertOutputConfig(value, type)
                  this.state[`${OUTPUT_CONFIG}_${deviceId}`] = configs
                },
              },
            )
          } else {
            this.state[`${OUTPUT_CONFIG}_${deviceId}`] = convertOutputConfig('', type)
          }
        } else {
          extractValues(
            { data: subscriptionUpdate },
            {
              [OUTPUT_CONFIG_NAMES]: (value: any) => {
                this.state[`${OUTPUT_CONFIG_NAMES}_${deviceId}`] = value
              },
              [OUTPUT_CONFIG_MODEL]: (value: any) => {
                this.state[`${OUTPUT_CONFIG_MODEL}_${deviceId}`] = value
              },
            },
          )
        }
      })
      .catch((e) => {
        console.debug(`fetchAdditionData error`, e)
      })
  }

  private subscribeAvailableDevices(device: EnhancedDevice) {
    const { isSMSTransport } = DEVICES_CONFIG[device.type || ''] || {}
    if (!isSMSTransport) {
      this.rootStore.appWebSocketClient.subscribeDevice(device.id.id, getTelemetryKeys(device))
    }
  }
}
