import React, { useState } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { ThemedText } from '../themed/ThemedText'

interface Props {
  text: string
  maxLength?: number
}

export const ReadMoreText: React.FC<Props> = ({ text, maxLength = 45 }) => {
  const [showMore, setShowMore] = useState(false)

  return (
    <View style={styles.postContentContainer}>
      {text?.length > maxLength ? (
        showMore ? (
          <TouchableOpacity onPress={() => setShowMore(!showMore)}>
            <ThemedText color={'foregroundColor'} style={styles.postDescription}>
              {text}
            </ThemedText>
            <ThemedText color={'foregroundColor'} style={styles.seeMore}>
              Ẩn bớt
            </ThemedText>
          </TouchableOpacity>
        ) : (
          <TouchableOpacity onPress={() => setShowMore(!showMore)}>
            <ThemedText color={'foregroundColor'} style={styles.postDescription}>{`${text.slice(0, maxLength)}... `}</ThemedText>
            <ThemedText color={'foregroundColor'} style={styles.seeMore}>
              Xem thêm
            </ThemedText>
          </TouchableOpacity>
        )
      ) : (
        <ThemedText style={styles.postDescription}>{text}</ThemedText>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  postContentContainer: {
    flexDirection: 'column',
  },
  postMedia: {
    width: '100%',
    height: 280,
    resizeMode: 'cover',
  },
  postDescription: {
    fontSize: 12,
    paddingTop: 15,
  },
  seeMore: {
    fontSize: 12,
    textDecorationLine: 'underline',
  },
})
