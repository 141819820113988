import _ from 'lodash'
import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { AppState, AppStateStatus, StyleSheet, View } from 'react-native'
import { useDispatch } from 'react-redux'

import { AppKeyboardShortcuts } from '../components/AppKeyboardShortcuts'
import { AppBannerMessage } from '../components/banners/AppBannerMessage'
import { ColumnSeparator } from '../components/columns/ColumnSeparator'
import { ColumnsRenderer } from '../components/columns/ColumnsRenderer'
import { Screen } from '../components/common/Screen'
import { Separator } from '../components/common/Separator'
import { SidebarOrBottomBar } from '../components/common/SidebarOrBottomBar'
import { useAppLayout } from '../components/context/LayoutContext'
import { ModalRenderer } from '../components/modals/ModalRenderer'
import { useAppVisibility } from '../hooks/use-app-visibility'
import { useFAB } from '../hooks/use-fab'
import { useReduxState } from '../hooks/use-redux-state'
import { analytics } from '../libs/analytics'
import * as actions from '../redux/actions'
import * as selectors from '../redux/selectors'
import { RootStoreContext } from '../stores/RootStore'

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  innerContainer: {
    flex: 1,
    flexDirection: 'row',
  },
})

const showButton = false

export const MainScreen = React.memo(() => {
  const { appOrientation } = useAppLayout()
  const rootStore = useContext(RootStoreContext)
  const dispatch = useDispatch()
  const currentOpenedModal = useReduxState(selectors.currentOpenedModal)
  const FAB = useFAB()

  const appState = useRef(AppState.currentState)

  useEffect(() => {
    const subscription = AppState.addEventListener('change', _handleAppStateChange)
    // @ts-ignore
    return () => subscription.remove()
  }, [])

  const _handleAppStateChange = (nextAppState: AppStateStatus) => {
    if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
      console.log('App has come to the foreground!')
      rootStore.appConfig.shouldRequestTokenIfExpired()
    }
    appState.current = nextAppState
    console.log('AppState', appState.current)
  }

  const debounceSyncDown = useMemo(() => {
    return _.debounce(
      () => {
        dispatch(actions.syncDown())
      },
      5000,
      {
        leading: true,
        maxWait: 30000,
        trailing: false,
      },
    )
  }, [])

  const isVisible = useAppVisibility()
  const wasVisible = useRef(isVisible)

  // TODO: Use GraphQL Subscriptions, ffs
  useEffect(() => {
    if (isVisible && !wasVisible.current) {
      debounceSyncDown()
    }

    wasVisible.current = isVisible
  }, [isVisible])

  useEffect(() => {
    if (!currentOpenedModal) {
      analytics.trackScreenView('MAIN_SCREEN')
    }
  }, [currentOpenedModal])

  return (
    <>
      <AppKeyboardShortcuts />
      <Screen statusBarBackgroundThemeColor="transparent" enableSafeArea={false}>
        <AppBannerMessage />
        <View
          style={[
            styles.container,
            {
              flexDirection: appOrientation === 'portrait' ? 'column-reverse' : 'row',
            },
          ]}
        >
          <SidebarOrBottomBar key="main-screen-sidebar" type={appOrientation === 'portrait' ? 'bottombar' : 'sidebar'} />
          {appOrientation === 'portrait' ? <Separator horizontal zIndex={1000} /> : <ColumnSeparator zIndex={1000} />}
          <View key="main-screen-content-container" style={styles.innerContainer}>
            <ModalRenderer key="modal-renderer" renderSeparator={appOrientation === 'landscape'} />
            <ColumnsRenderer key="columns-renderer" />
            {showButton && FAB.Component}
          </View>
        </View>
      </Screen>
    </>
  )
})

// MainScreen.displayName = 'MainScreen'
