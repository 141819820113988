import AsyncStorage from '@react-native-async-storage/async-storage'
import { create } from 'mobx-persist'
import { createContext } from 'react'
import { Platform } from '../libs/platform'
import { AppConfigStore } from './AppConfig'
import { AppWebSocketClient } from './AppWebSocketClient'
import { CurrentDeviceStore, SubscriptionUpdate } from './CurrentDeviceStore'
import { DeviceListStore } from './DeviceListStore'
import { UIReferenceStore } from './UIReferenceStore'
import { UserStore } from './UserStore'
import { WebSocketClient } from './WebSocketClient'

const hydrate = create({
  storage: AsyncStorage,
  jsonify: true,
})

export class RootStore {
  appConfig: AppConfigStore
  currentDeviceStore: CurrentDeviceStore
  deviceListStore: DeviceListStore
  userStore: UserStore
  uiReferenceStore: UIReferenceStore
  appWebSocketClient: AppWebSocketClient

  constructor() {
    this.appConfig = new AppConfigStore(this)
    hydrate('appConfig', this.appConfig.state)

    this.currentDeviceStore = new CurrentDeviceStore(this)
    hydrate('currentDeviceStore', this.currentDeviceStore.state)

    this.deviceListStore = new DeviceListStore(this)
    hydrate('deviceListStore', this.deviceListStore.state)

    this.userStore = new UserStore(this)
    hydrate('userStore', this.userStore.state)

    this.uiReferenceStore = new UIReferenceStore(this)
    hydrate('uiReferenceStore', this.uiReferenceStore.state)

    this.appWebSocketClient = new AppWebSocketClient(this, {
      onOpens: [(client: WebSocketClient) => this.deviceListStore.sendDevicesSubscribe(client), (client: WebSocketClient) => this.currentDeviceStore.subscribeDevice()],
      onMessages: [
        (subscriptionUpdate: SubscriptionUpdate | null) => this.deviceListStore.onReceiveMessage(subscriptionUpdate),
        (subscriptionUpdate: SubscriptionUpdate | null) => this.currentDeviceStore.onReceiveMessage(subscriptionUpdate),
      ],
    })
    console.log(`Platform.OS ${Platform.OS} Platform.realOS ${Platform.realOS} Platform.isSupportLazicoSms ${Platform.isSupportLazicoSms()}`)
  }

  startFetchAppData(appToken: string, refreshToken: string) {
    rootStore.appConfig.setToken(appToken, refreshToken)
    rootStore.deviceListStore.fetchAllDevices(true)
    // rootStore.appConfig.fetchNotifications()
  }

  reset() {
    this.deviceListStore.reset()
    this.currentDeviceStore.reset()
    this.appConfig.reset()
  }
}

export const rootStore = new RootStore()

export const RootStoreContext = createContext(rootStore)
