import { IDict } from '@devhub/core/dist'
import { observer } from 'mobx-react-lite'
import React, { memo } from 'react'
import { View } from 'react-native'
import { sharedStyles } from '../../styles/shared'
import { ModalColumn } from '../columns/ModalColumn'
import { useAppLayout } from '../context/LayoutContext'
import { DeviceHistoryPages } from '../views/DeviceHistoryPages'
import { UserNotificationPages } from '../views/UserNotificationPages'

export const TimeSeriesListPagesModal = memo(
  observer((props: IDict) => {
    const { sizename } = useAppLayout()
    const { showBackButton, deviceId, type } = props
    const isTimeSeries = type === 'DEVICE' && deviceId
    return (
      <ModalColumn hideCloseButton={sizename === '1-small'} name="USER_NOTIFICATION" showBackButton={showBackButton} title={isTimeSeries ? 'Nhật ký thiết bị' : 'Thông báo'}>
        <View style={[sharedStyles.flex, sharedStyles.justifyContentFlexStart, sharedStyles.paddingVerticalHalf]}>{isTimeSeries ? <DeviceHistoryPages deviceId={deviceId} /> : <UserNotificationPages />}</View>
      </ModalColumn>
    )
  }),
)
