import firebase from 'firebase/app'
import React, { useContext, useEffect, useState } from 'react'
import FirebaseAuth from 'react-firebaseui-vi/StyledFirebaseAuth'
import { View } from 'react-native'
import { RootStoreContext } from '../../stores/RootStore'
import {
  AppFirebaseUser,
  FirebaseUIAuthViewProps,
} from './FirebaseUIAuth.shared'
import { firebaseUIAuth } from './index'
import { firebaseUIConfig } from './index.web'

export const FirebaseUIAuth = (props: FirebaseUIAuthViewProps) => {
  const { style, onUserSignedIn, ...otherProps } = props
  const rootStore = useContext(RootStoreContext)
  const [isSignedIn, setIsSignedIn] = useState<boolean | undefined>(undefined)
  const firebaseApp: firebase.app.App = firebaseUIAuth.firebaseApp

  const currentUser = rootStore.appConfig.state.currentFirebaseUser

  useEffect(() => {
    const unregisterAuthObserver = firebaseApp
      .auth()
      .onAuthStateChanged((user) => {
        setIsSignedIn(!!user)
      })
    return () => {
      unregisterAuthObserver()
    }
  })

  useEffect(() => {
    if (isSignedIn && !!currentUser) {
      currentUser?.getIdToken().then((idToken: string) => {
        if (idToken) {
          if (onUserSignedIn) {
            onUserSignedIn(idToken)
          }
          rootStore.appConfig.signInByIdToken(idToken)
        }
      })
    }
  }, [isSignedIn, currentUser])

  return (
    <View {...otherProps} style={[style]}>
      {isSignedIn !== undefined && !isSignedIn ? (
        <View>
          <FirebaseAuth
            uiConfig={firebaseUIConfig}
            firebaseAuth={firebaseApp.auth()}
          />
        </View>
      ) : null}
      {isSignedIn ? (
        <AppFirebaseUser
          userRecord={{
            displayName: firebaseApp.auth().currentUser?.displayName,
            phoneNumber: firebaseApp.auth().currentUser?.phoneNumber,
            email: firebaseApp.auth().currentUser?.email,
            photoURL: firebaseApp.auth().currentUser?.photoURL,
          }}
          loading={true}
        />
      ) : null}
    </View>
  )
}
