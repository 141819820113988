import { observer } from 'mobx-react-lite'
import React, { useContext, useMemo } from 'react'
import { EW01, EW01DK, EW03, EW05 } from '../../stores/DeviceConfig'
import { RootStoreContext } from '../../stores/RootStore'
import { EWSensorPresentation } from './EWSensorPresentation'

export const SensorPresentation = observer(() => {
  const rootStore = useContext(RootStoreContext)
  const deviceType = rootStore.currentDeviceStore.state.device?.type
  const deviceId = rootStore.currentDeviceStore.getDeviceId()

  const view = useMemo(() => {
    switch (deviceType) {
      case EW03:
      case EW05:
        return <EWSensorPresentation deviceId={deviceId} deviceType={deviceType} />
      case EW01:
      case EW01DK:
        return null
      default:
        return null
    }
  }, [deviceType])
  return view
})
