import { IDict } from '@devhub/core'
import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { Image, StyleSheet, View } from 'react-native'
import { ImageSourcePropType } from 'react-native/Libraries/Image/Image'
import { SENSOR_ADC_BUNDLE } from '../../stores/DeviceModels'
import { formatSensorData } from '../../stores/StoreUtils'
import { sharedStyles } from '../../styles/shared'
import { normalTextSize, smallerTextSize, xxSmallerTextSize } from '../../styles/variables'
import { getName, getPastTime } from '../../utils/constants'
import { ThemedText } from '../themed/ThemedText'

const logo = require('@devhub/components/assets/temperature.png') // tslint:disable-line

const styles = StyleSheet.create({
  temperatureTextLeft: {
    fontWeight: '100',
    fontSize: normalTextSize * 2,
  },
  percent: {
    marginTop: 5,
  },
  temperatureTextRight: {
    fontWeight: '100',
    display: 'flex',
    fontSize: normalTextSize * 2,
  },
  temperatureIcon: {
    width: 10,
    height: 10,
    marginRight: 5,
  },
})

export const SensorCell = ({ data, textColor = 'foregroundColor', timeBottom = false, icon }: { data: IDict | null; textColor?: string; timeBottom?: boolean; icon?: ImageSourcePropType | undefined }) => {
  const [isFormat, setIsFormat] = useState(true)
  const code = data?.code
  // @ts-ignore
  const { mapValueFunction } = code ? SENSOR_ADC_BUNDLE[code] || {} : {}
  const rawValue = data?.value && mapValueFunction ? Math.round(mapValueFunction?.({ num: data?.value, min: data?.min, max: data?.max, delta: data?.delta }) * 100) / 100 : data?.value
  const formatValue = useMemo(() => (isFormat ? formatSensorData(rawValue) : rawValue), [isFormat, rawValue])

  return data ? (
    <View>
      <ThemedText color={textColor} style={{ fontSize: smallerTextSize }} onPress={() => setIsFormat(!isFormat)}>
        <Image resizeMode="contain" source={icon || logo} style={styles.temperatureIcon} />
        {getName(data, true)}
        {timeBottom ? null : <LastTimeUpdate {...data} />}
      </ThemedText>
      <View style={[sharedStyles.horizontal, sharedStyles.flex]}>
        <ThemedText color={textColor} style={[styles.temperatureTextLeft]}>
          {formatValue}
        </ThemedText>
        {data?.value !== '-' && SENSOR_ADC_BUNDLE[data?.code]?.unit ? (
          <ThemedText color={textColor} style={[styles.percent, sharedStyles.marginHorizontalQuarter]}>
            {SENSOR_ADC_BUNDLE[data?.code]?.unit}
          </ThemedText>
        ) : null}
      </View>
      {timeBottom ? <LastTimeUpdate textColor={textColor} {...data} /> : null}
    </View>
  ) : null
}

export const LastTimeUpdate = ({ value, lastUpdateTs, textColor }: { value?: number; lastUpdateTs?: number; textColor?: string }) => {
  if (_.isNaN(Number(value)) || !lastUpdateTs) {
    return null
  }
  return (
    <ThemedText color={textColor ? textColor : undefined} style={{ fontSize: xxSmallerTextSize, opacity: 0.8 }}>
      {getPastTime(lastUpdateTs)}
    </ThemedText>
  )
}
