import { createThemeFromColor } from './custom'

export const theme = createThemeFromColor(
  '#FFFFFF',
  'light-white',
  'Trắng nhạt',
  {
    tintColor: '#1F3247',
  },
)
