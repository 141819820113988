import axios from 'axios'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-apollo'
import { TouchableOpacity, View } from 'react-native'
import Collapsible from 'react-native-collapsible'
import { Button, Divider } from 'react-native-elements'
import { useDispatch } from 'react-redux'
import { ADD_USER_SHARE_DEVICE, GET_USER_SHARE_DEVICE, LOOKING_FOR_USER } from '../../graphql/gqls'
import { formatUserIdentify } from '../../libs/firebaseui-auth/FirebaseUIAuth.shared'
import * as actions from '../../redux/actions'
import { DEVICES_CONFIG, openAppSMS, SHARED_TO_PHONE_NUMBERS } from '../../stores/DeviceConfig'
import { RootStoreContext } from '../../stores/RootStore'
import { getRequestOption } from '../../stores/StoreUtils'
import { FLASH_MESSAGE_SCREEN_REF } from '../../stores/UIReferenceStore'
import { commonCardStyles, sharedStyles } from '../../styles/shared'
import { contentPadding, scaleFactor, smallerTextSize } from '../../styles/variables'
import { extractPhoneFromIdentify, getMessageError, validatePhoneNumber } from '../../utils/helpers/shared'
import { InputConfigRow } from '../columns/InputConfigRow'
import { getButtonColors, loadingIndicatorStyle } from '../common/Button'
import { Spacer } from '../common/Spacer'
import { useTheme } from '../context/ThemeContext'
import { DialogInput, DialogInputRef } from '../elements/DialogInput'
import { SmsSharedButton } from '../elements/SmsSharedButton'
import { ThemedTextInputWithIcon } from '../elements/ThemedTextInputWithIcon'
import { ThemedActivityIndicator } from '../themed/ThemedActivityIndicator'
import { ThemedIcon } from '../themed/ThemedIcon'
import { ThemedText } from '../themed/ThemedText'
import { SwipeListUserShare } from '../views/SwipeListUserShare'

interface ICustomer {
  id?: string
}
interface IUserShare {
  createdTime?: string
  firstName?: string
  lastName?: string
  email: string
  customerId: ICustomer
}

interface UserListShareDeviceProps {
  deviceId: string
  currentCustomerId?: string
  style?: any
}

export const UserListShareDevice = observer(({ deviceId, currentCustomerId, style = [] }: UserListShareDeviceProps) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { foregroundThemeColor } = getButtonColors()
  const rootStore = useContext(RootStoreContext)
  const [saveAddShareDevice, { loading: loadingAddUser }] = useMutation(ADD_USER_SHARE_DEVICE)
  const [seachUserShare, { loading: loadingSearchUser }] = useMutation(LOOKING_FOR_USER)
  const [showRecordSearch, setShowRecordSearch] = useState<boolean>(false)
  const [errorInputSearch, setErrorInputSearch] = useState<string>('')
  const [kSearch, setKSearch] = useState<string>('')
  const [showShared, setShowShared] = useState<boolean>(false)
  const [inputQuery, setInputQuery] = useState([])
  const device = rootStore.deviceListStore.findDeviceById(deviceId)
  const { isSMSTransport } = DEVICES_CONFIG[device?.type || ''] || {}
  const storeSmsUsers = rootStore.deviceListStore.state[`${SHARED_TO_PHONE_NUMBERS}_${deviceId}`]
  const smsUsers = storeSmsUsers || []

  const dialogRef = useRef<DialogInputRef>(null)
  const { data: listUserShare, refetch: loadRequest } = useQuery(GET_USER_SHARE_DEVICE, {
    variables: { deviceId },
    fetchPolicy: 'no-cache',
  })

  const onSubmitShare = (user: string): void => {
    if (loadingAddUser) {
      return
    }
    saveAddShareDevice({
      variables: {
        deviceId,
        input: {
          userIdentify: user,
        },
      },
    })
      .then((res) => {
        if (res && res.data) {
          rootStore.uiReferenceStore.success(`Chia sẻ cho ${user}`, FLASH_MESSAGE_SCREEN_REF.MODAL)
          setKSearch('')
          setShowRecordSearch(false)
          loadRequest().then(() => {
            if (isSMSTransport && device?.label) {
              const extractUser = extractPhoneFromIdentify(user)
              if (validatePhoneNumber(extractUser)) {
                openAppSMS(device?.label, `TQLY ${extractUser}`)
              } else {
                dialogRef.current?.show(true, 'TQLY')
              }
            }
          })
        }
      })
      .catch((error) => {
        rootStore.uiReferenceStore.danger(`${getMessageError(error)}`, FLASH_MESSAGE_SCREEN_REF.MODAL)
      })
  }

  const validateInputSeach = (value: string) => {
    let error = ''
    if (!value || value === '') {
      error = 'Nhập email cần tìm kiếm'
    } else if (value.length < 3) {
      error = 'Nhập ít nhất 3 ký tự'
    }
    setErrorInputSearch(error)
    return error
  }

  const onHandleSeachUser = (keyword: string) => {
    if (validateInputSeach(keyword) || loadingSearchUser) {
      return
    }
    seachUserShare({
      variables: {
        input: {
          userIdentify: keyword,
        },
      },
    })
      .then((res) => {
        if (res && res.data) {
          setShowRecordSearch(true)
          setInputQuery(res?.data?.lookingForUser?.data)
        }
      })
      .catch((error) => {
        rootStore.uiReferenceStore.danger(`${getMessageError(error)}`, FLASH_MESSAGE_SCREEN_REF.MODAL)
      })
  }

  const onRemoveUser = async (user: IUserShare) => {
    const requestOptions = getRequestOption(rootStore.appConfig.state.token)
    try {
      const response = await axios.delete(`${rootStore.appConfig.getAppBaseHostUri()}/api/device/${deviceId}/share`, {
        headers: requestOptions.headers,
        data: {
          userIdentify: user.email,
        },
      })
      if (response.status === 200) {
        rootStore.uiReferenceStore.success(`Bỏ chia sẻ cho ${user.email}`, FLASH_MESSAGE_SCREEN_REF.MODAL)
        if (currentCustomerId === user.customerId.id) {
          dispatch(actions.popModal())
          rootStore.currentDeviceStore.reclaim()
          await rootStore.deviceListStore.queryShareWithMeDevices()
        } else {
          loadRequest().then(() => {
            if (isSMSTransport && device?.label) {
              const phoneNumber = extractPhoneFromIdentify(user.email)
              if (validatePhoneNumber(phoneNumber)) {
                openAppSMS(device?.label, `XQLY ${phoneNumber}`)
              } else {
                dialogRef.current?.show(true, 'XQLY')
              }
            }
          })
        }
      } else {
        rootStore.uiReferenceStore.danger(`Không thành công!`, FLASH_MESSAGE_SCREEN_REF.MODAL)
      }
    } catch (error) {
      rootStore.uiReferenceStore.danger(`${getMessageError(error)}`, FLASH_MESSAGE_SCREEN_REF.MODAL)
    }
  }

  let beShared = inputQuery
  if (inputQuery?.length > 0) {
    const valueDifference = inputQuery.filter((search: IUserShare) => !listUserShare?.getUserShareDevice?.data.some((user: IUserShare) => search.email === user.email))
    beShared = valueDifference
  }

  return (
    <View style={[sharedStyles.vertical, ...style]}>
      <TouchableOpacity onPress={() => setShowShared(!showShared)}>
        <View style={[sharedStyles.horizontal, sharedStyles.justifyContentSpaceBetween, sharedStyles.alignItemsCenter, sharedStyles.marginVertical]}>
          <InputConfigRow subject={`CHIA SẺ THIẾT BỊ`} tooltip={`CHIA SẺ THIẾT BỊ`} inline={false} />
          <ThemedIcon family="octicon" name={showShared ? 'plus' : 'chevron-down'} size={16 * scaleFactor} color={foregroundThemeColor} />
        </View>
      </TouchableOpacity>
      <Collapsible collapsed={showShared}>
        <ThemedTextInputWithIcon
          textInputKey="enter-name-device"
          placeholder="tên/email/số điện thoại(ít nhất 3 ký tự)"
          value={kSearch || ''}
          autoCapitalize="none"
          onChangeText={(text: string) => setKSearch(text)}
          loading={loadingSearchUser}
          error={errorInputSearch}
          onClick={() => onHandleSeachUser(kSearch)}
        />
        <View style={[sharedStyles.marginVertical]}>
          {showRecordSearch ? <ThemedText color="foregroundColorMuted65">Kết quả tìm kiếm</ThemedText> : null}
          {showRecordSearch && !beShared.length ? (
            <>
              <Spacer height={contentPadding} />
              <ThemedText
                color="foregroundColor"
                style={[
                  sharedStyles.textCenter,
                  {
                    fontSize: smallerTextSize,
                    textDecorationLine: 'underline',
                  },
                ]}
              >
                Không có ai cả
              </ThemedText>
              {isSMSTransport && validatePhoneNumber(kSearch) && smsUsers.length + (listUserShare?.getUserShareDevice?.data || []).length < 5 ? (
                <>
                  <Spacer height={contentPadding} />
                  <SmsSharedButton
                    deviceId={deviceId}
                    users={smsUsers}
                    beShareUser={kSearch}
                    onSuccess={() => {
                      setKSearch('')
                      setShowRecordSearch(false)
                    }}
                  />
                </>
              ) : null}
            </>
          ) : null}
          {loadingSearchUser ? (
            <ThemedActivityIndicator color={foregroundThemeColor} size="small" style={loadingIndicatorStyle} />
          ) : (
            <View>
              {beShared?.map((item: IUserShare, index: number) => {
                return (
                  <View key={index} style={[sharedStyles.horizontal, sharedStyles.justifyContentSpaceBetween, { paddingTop: contentPadding * 1.3 }]}>
                    <ThemedText
                      color="foregroundColor"
                      style={{
                        fontSize: smallerTextSize,
                        width: contentPadding * 15,
                      }}
                    >
                      {item?.firstName}
                      {item?.lastName} ({formatUserIdentify(item?.email)})
                    </ThemedText>
                    <ThemedText
                      color={theme.primaryBackgroundColor}
                      style={{
                        fontSize: smallerTextSize,
                        textDecorationLine: 'underline',
                        width: contentPadding * 3,
                      }}
                      onPress={() => onSubmitShare(item.email)}
                    >
                      Chia sẻ
                    </ThemedText>
                  </View>
                )
              })}
            </View>
          )}
          <Divider style={[sharedStyles.marginVertical]} color={theme.backgroundColorLighther3} orientation="horizontal" width={1} />
        </View>
      </Collapsible>
      <ThemedText color="foregroundColorMuted65">Đã chia sẻ với</ThemedText>
      {listUserShare?.getUserShareDevice?.data?.map((user: IUserShare, index: number) => {
        return (
          <SwipeListUserShare
            key={`share-to-${user.customerId}-${index}`}
            enabled={true}
            children={
              <View style={[...commonCardStyles, sharedStyles.marginVerticalHalf]}>
                <View style={[sharedStyles.vertical]}>
                  <ThemedText color="foregroundColor">{user.firstName || user.firstName ? `${user.firstName || ''} ${user.lastName || ''}` : formatUserIdentify(user.email)}</ThemedText>
                  {user.firstName || user.firstName ? (
                    <ThemedText color="foregroundColor" style={[sharedStyles.textSmall]}>
                      {formatUserIdentify(user.email)}
                    </ThemedText>
                  ) : null}
                </View>
                <ThemedText color="foregroundColor" style={[sharedStyles.textMedium]}>
                  {moment(user.createdTime).format('DD-MM-YYYY')}
                </ThemedText>
                {isSMSTransport ? (
                  <Button
                    title={`SMS`}
                    type={'clear'}
                    iconRight={true}
                    icon={{
                      name: 'send',
                      size: 15,
                      color: 'white',
                      type: 'material',
                    }}
                    onPress={() => {
                      if (isSMSTransport && device?.label) {
                        const extractUser = extractPhoneFromIdentify(user.email)
                        if (validatePhoneNumber(extractUser)) {
                          openAppSMS(device?.label, `TQLY ${extractUser}`)
                        } else {
                          dialogRef.current?.show(true, 'TQLY')
                        }
                      }
                    }}
                  />
                ) : null}
              </View>
            }
            onClick={() => onRemoveUser(user)}
          />
        )
      })}
      {isSMSTransport && smsUsers && smsUsers.length > 0 ? (
        <View style={[sharedStyles.vertical, sharedStyles.marginVertical]}>
          <ThemedText color="foregroundColorMuted65">Danh sách quản lý</ThemedText>
          {smsUsers.map((user: string, index: number) => (
            <View style={[...commonCardStyles, sharedStyles.marginVerticalHalf]} key={`smsuser-${index}`}>
              <ThemedText
                color="foregroundColor"
                style={{
                  fontSize: smallerTextSize,
                  width: contentPadding * 15,
                }}
              >
                {user}
              </ThemedText>
              <Button
                title={`SMS`}
                type={'clear'}
                iconRight={true}
                icon={{
                  name: 'send',
                  size: 15,
                  color: 'white',
                  type: 'material',
                }}
                onPress={() => {
                  if (isSMSTransport && device?.label && validatePhoneNumber(user)) {
                    openAppSMS(device?.label, `TQLY ${user}`)
                  }
                }}
              />
              <SmsSharedButton deviceId={deviceId} users={smsUsers} deleteIndex={index} beShareUser={''} onSuccess={() => openAppSMS(device?.label || '', `XQLY ${user}`)} />
            </View>
          ))}
        </View>
      ) : null}
      {isSMSTransport ? (
        <DialogInput
          ref={dialogRef}
          title={`Số điện thoại của quản lý`}
          onFinish={(inputText, action) => {
            if (device?.label) {
              if (validatePhoneNumber(inputText)) {
                openAppSMS(device?.label, `${action} ${inputText}`)
              } else {
                rootStore.uiReferenceStore.danger(`Số điện thoại không hợp lệ`, FLASH_MESSAGE_SCREEN_REF.MODAL)
              }
            }
          }}
        />
      ) : null}
    </View>
  )
})
