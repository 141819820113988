import { EnhancedDevice } from '@devhub/core/dist'
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useMutation } from 'react-apollo'
import { Image, NativeModules, TouchableOpacity, View } from 'react-native'
import { Input } from 'react-native-elements'
import FlashMessage from 'react-native-flash-message'
import UdpSockets from 'react-native-udp-ts'
import { NativeEventEmitter } from '../../events'
import { CLAIM_DEVICE, SAVE_DEVICE_SMS } from '../../graphql/gqls'
import { useReduxAction } from '../../hooks/use-redux-action'
import { Platform } from '../../libs/platform'
import * as actions from '../../redux/actions'
import { openAppSMS, smsDeviceType } from '../../stores/DeviceConfig'
import { RootStoreContext } from '../../stores/RootStore'
import { sharedStyles } from '../../styles/shared'
import { contentPadding, normalTextSize, scaleFactor } from '../../styles/variables'
import { getMessageError, isMobile, validatePhoneNumber } from '../../utils/helpers/shared'
import { InputConfigRow } from '../columns/InputConfigRow'
import { ModalColumn } from '../columns/ModalColumn'
import { Button } from '../common/Button'
import { IconButton } from '../common/IconButton'
import { Spacer } from '../common/Spacer'
import { useAppLayout } from '../context/LayoutContext'
import { useTheme } from '../context/ThemeContext'
import { ViewPagerLayout } from '../elements/ViewPagerLayout'
import { ThemedText } from '../themed/ThemedText'
import { ThemedTextInput } from '../themed/ThemedTextInput'
import { DeviceListSMS } from '../views/DeviceListSMS'

const logo = require('@devhub/components/assets/logo_new_device.png') // tslint:disable-line

const port = 6969
const MAX_TRY = 35
const DELAY_TRY = 1000
const CLAIM_COUNT = 5

enum DEVICE_TYPE {
  SIM_DATA,
  WIFI,
  SMS_DEVICE,
}

const DEVICE_TYPES =
  Platform.OS === 'android'
    ? [
        { title: 'Qua định danh thiết bị', deviceType: DEVICE_TYPE.SIM_DATA },
        { title: 'Thiết bị wifi', deviceType: DEVICE_TYPE.WIFI },
      ]
    : [
        { title: 'Qua định danh thiết bị', deviceType: DEVICE_TYPE.SIM_DATA },
        { title: 'Thiết bị wifi', deviceType: DEVICE_TYPE.WIFI },
        { title: 'Thiết bị SMS', deviceType: DEVICE_TYPE.SMS_DEVICE },
      ]

enum TABS_INDEX {
  TOUCH = 0,
  WIFI = 1,
  SETTINGS = 2,
  FINISH = 3,
}

enum STEP_RESULT {
  SEARCH,
  SETTING,
  UPLOAD,
  COMPLETE,
}

enum ACTION_STATUS {
  QUEUE,
  DOING,
  SUCCESS,
  ERROR,
  CLAIMED,
}

const statusColor = {
  [ACTION_STATUS.QUEUE]: '#7f8c8d',
  [ACTION_STATUS.DOING]: '#7f8c8d',
  [ACTION_STATUS.SUCCESS]: '#50A4BC',
  [ACTION_STATUS.ERROR]: 'red',
  [ACTION_STATUS.CLAIMED]: 'orange',
}

const stepResultsValue = Object.values(STEP_RESULT)
  .filter((result) => isNaN(Number(result)) === false)
  .map<STEP_RESULT>((result) => Number(result))

interface IIconStep {
  icon: string
  active: boolean
  tabIndex: number
}

const initalTabs = () => [
  {
    icon: 'touch-app',
    active: true,
    tabIndex: TABS_INDEX.TOUCH,
  },
  {
    icon: 'wifi',
    active: false,
    tabIndex: TABS_INDEX.WIFI,
  },
  {
    icon: 'settings',
    active: false,
    tabIndex: TABS_INDEX.SETTINGS,
  },
  {
    icon: 'tune',
    active: false,
    tabIndex: TABS_INDEX.FINISH,
  },
]

const RESULTS = () => ({
  [STEP_RESULT.SEARCH]: {
    name: 'Tìm thiết bị',
    icon: 'search',
    status: ACTION_STATUS.QUEUE,
  },
  [STEP_RESULT.SETTING]: {
    name: 'Cấu hình',
    icon: 'settings',
    status: ACTION_STATUS.QUEUE,
  },
  [STEP_RESULT.UPLOAD]: {
    name: 'Gửi lên máy chủ',
    icon: 'upload',
    status: ACTION_STATUS.QUEUE,
  },
  [STEP_RESULT.COMPLETE]: {
    name: 'Hoàn thành',
    icon: 'check',
    status: ACTION_STATUS.QUEUE,
  },
})

interface AddDeviceModalProps {
  showBackButton: boolean
}

export const AddDeviceModal = React.memo((props: AddDeviceModalProps) => {
  const theme = useTheme()
  const { showBackButton } = props
  const { sizename } = useAppLayout()
  const rootStore = useContext(RootStoreContext)
  const flashMessageRef = useRef<FlashMessage>(null)

  const [tabsList, setTabsList] = useState(initalTabs())
  const [result, setResult] = useState(RESULTS())
  const closeAllModals = useReduxAction(actions.closeAllModals)

  const [activeDeviceType, setActiveDeviceType] = useState<number>(DEVICE_TYPE.SIM_DATA)
  const [tryCount, setTryCount] = useState<number>(0)

  const [deviceName, setDeviceName] = useState<string>('')
  const [deviceIp, setDeviceIp] = useState<string>('')
  const [processText, setProcessText] = useState<string>('')
  const [smsDevice, setSmsDevice] = useState<string>(smsDeviceType[0])

  const [isConnecting2Device, setIsConnecting2Device] = useState<boolean>(false)
  const [claimedDevice, setClaimedDevice] = useState<EnhancedDevice | undefined>(undefined)
  const [deviceSMSReponse, setDeviceSMSReponse] = useState<EnhancedDevice | undefined>(undefined)
  const [visible, setVisible] = useState<boolean>(false)
  const timerRef = useRef<any>(null)
  const [claimDeviceAction, { loading }] = useMutation(CLAIM_DEVICE)
  const [saveDeviceSMS] = useMutation(SAVE_DEVICE_SMS)
  const taskIsCompletedRef = useRef<boolean>(false)

  const selectedIndex = useMemo(() => {
    for (let i = tabsList.length - 1; i >= 0; i--) {
      if (tabsList[i].active) return tabsList[i].tabIndex
    }
    return 0
  }, [tabsList])

  const actionLabel = useMemo(() => {
    if (selectedIndex === TABS_INDEX.SETTINGS) {
      return result[STEP_RESULT.SETTING].status === ACTION_STATUS.CLAIMED || result[STEP_RESULT.UPLOAD].status === ACTION_STATUS.CLAIMED ? 'Xem Danh sách' : 'Chi tiết'
    }
    return activeDeviceType === DEVICE_TYPE.SMS_DEVICE ? 'Đăng ký' : 'Tiếp theo'
  }, [selectedIndex, result])

  const resetSubmitDeviceName = () => {
    clearTimeout(timerRef.current)
    timerRef.current = null
    setTryCount(0)
  }
  useEffect(() => () => resetSubmitDeviceName(), [])

  const updateResult = useCallback((action: STEP_RESULT, resultText: string, status: ACTION_STATUS) => {
    const uResults = RESULTS()
    stepResultsValue.forEach((step) => (uResults[step].status = step < action ? ACTION_STATUS.SUCCESS : ACTION_STATUS.QUEUE))
    uResults[action] = { ...uResults[action], name: resultText, status }
    setResult(uResults)
    setActiveTab(TABS_INDEX.SETTINGS)
    taskIsCompletedRef.current = true
  }, [])

  const handleSubmit = useCallback(
    (dName: string, counter = 0) => {
      console.log(`handleSubmit counter ${counter}  loading ${loading}`)
      if (loading) {
        return
      }
      if (counter <= 0) {
        updateResult(STEP_RESULT.SETTING, 'Hoàn thành', ACTION_STATUS.SUCCESS)
      }
      if (activeDeviceType === DEVICE_TYPE.SMS_DEVICE) {
        saveDeviceSMS({
          variables: {
            input: {
              devicePhoneNumber: deviceName,
              type: smsDevice,
            },
          },
        })
          .then((res) => {
            if (res?.data?.saveDeviceSMS?.id) {
              updateResult(STEP_RESULT.COMPLETE, 'Hoàn thành', ACTION_STATUS.SUCCESS)
              rootStore.deviceListStore.refresh()
              rootStore.currentDeviceStore.setDevice(res?.data?.saveDeviceSMS)
              setDeviceSMSReponse(res.data.saveDeviceSMS)
              openAppSMS(deviceName, 'DK')
            }
          })
          .catch((e) => {
            updateResult(STEP_RESULT.UPLOAD, 'Tải lên thất bại', ACTION_STATUS.ERROR)
            if (flashMessageRef.current) {
              flashMessageRef.current.showMessage({
                message: getMessageError(e),
                type: 'danger',
              })
            }
          })
      } else {
        claimDeviceAction({
          variables: {
            deviceName: dName.trim(),
            input: {
              secretKey: '',
            },
          },
        })
          .then((res) => {
            if (res?.data?.saveDevice?.response === 'SUCCESS' && res?.data?.saveDevice?.device) {
              updateResult(STEP_RESULT.COMPLETE, 'Hoàn thành', ACTION_STATUS.SUCCESS)
              rootStore.deviceListStore.refresh()
              rootStore.currentDeviceStore.setDevice(res?.data?.saveDevice?.device)
              setClaimedDevice(res?.data?.saveDevice?.device)
              setTryCount(0)
            } else {
              updateResult(STEP_RESULT.UPLOAD, 'Không thành công. Hãy kiểm tra lại', ACTION_STATUS.ERROR)
            }
          })
          .catch((e: any) => {
            setTryCount(0)
            const message = `${getMessageError(e)}`
            updateResult(STEP_RESULT.UPLOAD, message === 'CLAIMED' ? 'Đã ghép nối từ trước' : message, message === 'CLAIMED' ? ACTION_STATUS.CLAIMED : ACTION_STATUS.ERROR)
            if (flashMessageRef.current) {
              flashMessageRef.current.showMessage({
                message,
                type: 'danger',
              })
            }
          })
      }
    },
    [loading, deviceName, smsDevice],
  )

  useEffect(() => {
    if (tryCount >= CLAIM_COUNT && timerRef.current) {
      const message = 'Thêm thiết bị không thành công!'
      updateResult(STEP_RESULT.UPLOAD, message, ACTION_STATUS.ERROR)
      if (flashMessageRef.current) {
        flashMessageRef.current.showMessage({
          message,
          type: 'danger',
        })
      }
      resetSubmitDeviceName()
    }
  }, [tryCount])

  useEffect(() => {
    if (isMobile) {
      const udpSocketClient = UdpSockets.createSocket({ type: 'udp4' })
      udpSocketClient.bind(port, (err: any) => {
        if (err) {
          console.log(`error bind ${port}`, err)
        }
      })

      const myModuleEvt = new NativeEventEmitter(NativeModules.Smartconfig)
      myModuleEvt.addListener('SmartconfigCancel', (e: Event) => {
        setIsConnecting2Device(false)
      })

      myModuleEvt.addListener('ReceiveDeviceIpEvent', (e: Event) => {
        console.log(`receive data`, e)
        // @ts-ignore
        const ip = e.DeviceIp
        console.log(`Device IP: ${ip}`)
        setDeviceIp(ip)

        let retryCount = 0
        const sendDeviceNameMessageByUDP = (gotIp: string) => {
          if (retryCount <= MAX_TRY && !taskIsCompletedRef.current) {
            const msg = 'device name'
            console.log(`sendDeviceNameMessageByUDP: ${msg} retryCount: ${retryCount}`)
            setProcessText(`Lấy mã định danh thiết bị\n...${retryCount >= 1 ? Array.from({ length: retryCount }, (_, i) => '.').join('') : ''}`)
            retryCount++
            udpSocketClient.send(msg, 0, msg.length, port, gotIp, (err) => {
              if (err) {
                console.warn(`error send to ${gotIp}`, err)
                if (retryCount <= MAX_TRY) {
                  setProcessText(`Không gửi được yêu cầu mã định danh\n${err?.message}`)
                  setTimeout(() => {
                    if (sendDeviceNameMessageByUDP) {
                      sendDeviceNameMessageByUDP(gotIp)
                    }
                  }, DELAY_TRY)
                } else {
                  const message = `Không lấy được định danh thiết bị!\n${err?.message}`
                  setIsConnecting2Device(false)
                  updateResult(STEP_RESULT.SEARCH, message, ACTION_STATUS.ERROR)
                }
              } else {
                // setProcessText(`Đã gửi yêu cầu mã định danh tới thiết bị. Chờ thiết bị phản hồi`)
                console.log(`send to ${gotIp}`, msg, port)
                if (retryCount <= MAX_TRY) {
                  // setProcessText(`Gửi lại yêu cầu ...`)
                  setTimeout(() => {
                    if (sendDeviceNameMessageByUDP) {
                      sendDeviceNameMessageByUDP(gotIp)
                    }
                  }, DELAY_TRY)
                } else {
                  setTimeout(() => {
                    if (!deviceName) {
                      const message = `Không lấy được định danh thiết bị!\nThiết bị không phản hồi trong thời gian cho phép`
                      setIsConnecting2Device(false)
                      updateResult(STEP_RESULT.SEARCH, message, ACTION_STATUS.ERROR)
                    }
                  }, DELAY_TRY)
                }
              }
            })
          }
        }

        if (ip) {
          udpSocketClient.on('message', (data, rinfo) => {
            console.log(`udpSocketClient on message response`, data)
            try {
              const response = String.fromCharCode.apply(null, data)
              const responseObject = JSON.parse(response)
              const dName = responseObject.serial
              console.log(`device name: ${dName} response ${response}`, responseObject)
              setDeviceName(dName)
              if (dName) {
                setIsConnecting2Device(false)
                handleSubmit(dName)
              }
            } catch (e) {
              console.log(`error parse response from device`, e)
              const message = `Không lấy được định danh thiết bị!${e?.message}`
              setIsConnecting2Device(false)
              updateResult(STEP_RESULT.SEARCH, message, ACTION_STATUS.ERROR)
            }
          })
          setTimeout(() => {
            sendDeviceNameMessageByUDP(ip)
          }, 1000)
        } else {
          const message = 'Không lấy được IP thiết bị!'
          updateResult(STEP_RESULT.SEARCH, message, ACTION_STATUS.ERROR)
          setIsConnecting2Device(false)
        }
      })
      return () => {
        udpSocketClient.close()
        myModuleEvt.removeAllListeners('SmartconfigCancel')
        myModuleEvt.removeAllListeners('ReceiveDeviceIpEvent')
      }
    }
  }, [])

  const submit = useCallback(() => {
    handleSubmit(deviceName)
  }, [deviceName])

  const onStepSelect = (item: IIconStep, _position: number, loadingNow: boolean) => {
    if (!loadingNow && item.active && (item.icon === 'touch-app' || item.icon === 'wifi' || item.icon === 'settings')) {
      setActiveTab(item.tabIndex)
      if (item.tabIndex !== TABS_INDEX.SETTINGS) {
        resetSubmitDeviceName()
      }
    }
  }

  const renderTabs = () => {
    return (
      <View style={[sharedStyles.marginVerticalHalf, sharedStyles.horizontal, sharedStyles.justifyContentCenter]}>
        {tabsList.map((item: IIconStep, tIndex: number) => {
          return (
            <Fragment key={`render-${tIndex}-tab`}>
              <IconButton
                key={`tabs-${tIndex}`}
                family="material"
                style={{
                  backgroundColor: tabsList[tIndex].active ? theme.primaryBackgroundColor : theme.gray,
                }}
                name={item.icon as any}
                type={'white'}
                size={18 * scaleFactor}
                onPress={() => onStepSelect(item, tIndex, loading)}
              />
              {item !== tabsList[tabsList.length - 1] ? (
                <View
                  style={[
                    sharedStyles.borderSteps,
                    {
                      borderTopColor: tabsList[tIndex + 1].active ? theme.primaryBackgroundColor : '#7f7f7f',
                    },
                  ]}
                />
              ) : null}
            </Fragment>
          )
        })}
      </View>
    )
  }

  const renderSelectDevice = () => {
    return (
      <View style={[sharedStyles.horizontal]}>
        {DEVICE_TYPES.map(({ deviceType, title }, index: number) => {
          const width = `${Math.round(90 / DEVICE_TYPES.length)}%` as `${number}%`
          return (
            <TouchableOpacity
              key={`device-type-${deviceType}-${index}`}
              activeOpacity={0.6}
              // @ts-ignore
              style={[
                sharedStyles.device,
                {
                  borderColor: theme.primaryBackgroundColor,
                  backgroundColor: activeDeviceType === deviceType ? theme.primaryBackgroundColor : theme.transparent,
                  marginRight: contentPadding - 1,
                  width,
                },
              ]}
              onPress={() => setActiveDeviceType(deviceType)}
            >
              <ThemedText color={activeDeviceType === deviceType ? theme.white : theme.primaryBackgroundColor}>{title}</ThemedText>
            </TouchableOpacity>
          )
        })}
      </View>
    )
  }

  const renderResultSearch = () => {
    return (
      <>
        {stepResultsValue.map((stepResult: STEP_RESULT, index: number) => {
          const item = result[stepResult]
          return (
            <View key={`step-${stepResult}-${index}`} style={[sharedStyles.resultSteps, sharedStyles.horizontal]}>
              <IconButton family="octicon" style={{ backgroundColor: statusColor[item.status] }} name={item.icon as any} size={16 * scaleFactor} type="white" />
              <ThemedText
                color={statusColor[item.status]}
                style={{
                  marginTop: 5,
                  marginLeft: 10,
                }}
              >
                {item.name}
              </ThemedText>
            </View>
          )
        })}
      </>
    )
  }

  const renderRss = () => (
    <>
      {/*@ts-ignore*/}
      <Input placeholder="Nhập tên thiết bị" value={deviceName} style={{ fontSize: normalTextSize }} onChangeText={(text: string) => setDeviceName(text)} />
      {deviceIp ? <ThemedText color={theme.blue}>{`Thiết bị đã nhận mạng wifi.\n IP của thiết bị ${deviceIp}`}</ThemedText> : null}
      {processText ? <ThemedText color={theme.blue}>{processText}</ThemedText> : null}
      <Spacer height={contentPadding * 6} />
    </>
  )

  const setActiveTab = useCallback(
    (tNext: number) => {
      const tabListActived = [...tabsList]
      if (tNext < tabListActived.length) {
        tabListActived[tNext].active = true
        for (let i = tNext + 1; i < tabListActived.length; i++) {
          tabListActived[i].active = false
        }
        setTabsList(tabListActived)
      }
    },
    [tabsList],
  )

  const disableNextStep = useCallback(
    (loadingNow: boolean) => {
      if (loadingNow) return true
      if (selectedIndex === TABS_INDEX.WIFI && !deviceName && !isMobile) return true
      if (
        selectedIndex === TABS_INDEX.SETTINGS &&
        result[STEP_RESULT.COMPLETE].status !== ACTION_STATUS.SUCCESS &&
        result[STEP_RESULT.COMPLETE].status !== ACTION_STATUS.CLAIMED &&
        result[STEP_RESULT.UPLOAD].status !== ACTION_STATUS.CLAIMED
      )
        return true
    },
    [tabsList, selectedIndex, deviceName, activeDeviceType, result],
  )

  const handleValidateSteps = () => {
    if (selectedIndex === TABS_INDEX.TOUCH) {
      if (activeDeviceType < 0 && flashMessageRef.current) {
        return flashMessageRef.current.showMessage({
          message: 'Hãy chọn loại thiết bị!',
          type: 'danger',
        })
      }
      if (isMobile && activeDeviceType === DEVICE_TYPE.WIFI) {
        setIsConnecting2Device(true)
        NativeModules.Smartconfig.showWifiSetup('')
      }
    }
    if (selectedIndex === TABS_INDEX.WIFI) {
      if (deviceName) {
        if (activeDeviceType === DEVICE_TYPE.SMS_DEVICE && !validatePhoneNumber(deviceName) && flashMessageRef.current) {
          return flashMessageRef.current.showMessage({
            message: 'Số điện thoại bắt đầu là 0 và có 10 ký tự',
            type: 'danger',
          })
        }
        submit()
      } else {
        if (flashMessageRef.current) {
          return flashMessageRef.current?.showMessage({
            message: 'Nhập tên thiết bị',
            type: 'danger',
          })
        }
      }
    }
    if (selectedIndex === TABS_INDEX.SETTINGS && result[STEP_RESULT.UPLOAD].status === ACTION_STATUS.CLAIMED) {
      closeAllModals()
      return
    }
    const tNext = selectedIndex + 1
    setActiveTab(tNext)
  }

  const completeView = useMemo(
    () => ({
      view: claimedDevice ? (
        <View style={[sharedStyles.cardContainer]}>
          <InputConfigRow subject={`Tên thiết bị`}>
            <ThemedText color={theme.blue}>{claimedDevice?.name}</ThemedText>
          </InputConfigRow>
          <Spacer width={contentPadding} />
          <InputConfigRow subject={`Loại - Model`}>
            <ThemedText color={theme.blue}>{claimedDevice?.type}</ThemedText>
          </InputConfigRow>
          <Spacer width={contentPadding} />
          <InputConfigRow subject={`ID`}>
            <ThemedText color={theme.blue}>{claimedDevice?.id?.id}</ThemedText>
          </InputConfigRow>
          <Spacer width={contentPadding} />
        </View>
      ) : deviceSMSReponse ? (
        <View style={[sharedStyles.cardContainer]}>
          <InputConfigRow subject={`Định danh`}>
            <ThemedText color={theme.blue}>{deviceSMSReponse?.name}</ThemedText>
          </InputConfigRow>
          <Spacer width={contentPadding} />
          <InputConfigRow subject={`Tên thiết bị`}>
            <ThemedText color={theme.blue}>{deviceSMSReponse?.label}</ThemedText>
          </InputConfigRow>
          <Spacer width={contentPadding} />
          <InputConfigRow subject={`Loại thiết bị`}>
            <ThemedText color={theme.blue}>{deviceSMSReponse?.type}</ThemedText>
          </InputConfigRow>
          <Spacer width={contentPadding} />
        </View>
      ) : null,
    }),
    [claimedDevice, deviceSMSReponse],
  )

  const tabs = [
    {
      view: (
        <>
          <View style={[sharedStyles.cardContainer]}>
            <ThemedText color={theme.primaryBackgroundColor}>
              {activeDeviceType === DEVICE_TYPE.SMS_DEVICE
                ? 'Bạn cần lắp sim vào thiết bị SMS và khởi động lên trước khi tiếp tục.'
                : activeDeviceType === DEVICE_TYPE.SIM_DATA
                ? 'Ấn và giữ liên tục nút B/T ( với EW01) hoặc B/T 1 (với EW03-EW05) từ 11s - 20s rồi nhả ra, đèn Wifi( màu đỏ) sẽ nhấp nháy 3 lần - Chỉ dùng kết nối qua định danh khi thiết bị đã nhận mạng Wifi của bạn từ lần trước đó'
                : 'Ấn và giữ liên tục nút B/T ( với EW01) hoặc B/T 1 (với EW03-EW05) từ 5s - 10s rồi nhả ra, đèn Wifi( màu đỏ) sẽ nhấp nháy đều liên tục'}
            </ThemedText>
          </View>
          {renderSelectDevice()}
        </>
      ),
    },
    {
      view:
        activeDeviceType === DEVICE_TYPE.SMS_DEVICE ? (
          <>
            <View style={[sharedStyles.cardContainer, sharedStyles.horizontal, sharedStyles.justifyContentSpaceBetween, { backgroundColor: '#1f2229' }]}>
              <InputConfigRow subject={`Loại thiết bị`} tooltip={`Loại thiết bị`} inline={false} />
              <DeviceListSMS value={smsDevice} visible={visible} setVisible={setVisible}>
                {smsDeviceType.map((item: string, index: number) => {
                  return (
                    <Fragment key={index}>
                      <TouchableOpacity
                        style={{
                          width: 70,
                          padding: 10,
                          backgroundColor: item === smsDevice ? theme.primaryBackgroundColor : '',
                        }}
                        onPress={() => {
                          setSmsDevice(item)
                          setVisible(false)
                        }}
                      >
                        <ThemedText color={item === smsDevice ? '#ffffff' : 'foregroundColorMuted65'}>{item}</ThemedText>
                      </TouchableOpacity>
                      {index === smsDeviceType.length - 1 ? null : <View style={{ borderBottomWidth: 1, borderBottomColor: '#e7e7e7', width: '100%' }} />}
                    </Fragment>
                  )
                })}
              </DeviceListSMS>
            </View>
            <InputConfigRow subject={`Số điện thoại trên thiết bị`} tooltip={`Loại thiết bị`} inline={false} style={{ marginTop: 20 }} />
            <View style={{ marginTop: 20 }}>
              <ThemedTextInput placeholder="Nhập số điện thoại" textInputKey="email" value={deviceName} onChangeText={(text: string) => setDeviceName(text)} />
            </View>
          </>
        ) : (
          <View style={[sharedStyles.cardContainer]}>
            <ThemedText style={[sharedStyles.marginHalf]}>TÊN THIẾT BỊ</ThemedText>
            {renderRss()}
          </View>
        ),
    },
    {
      view: (
        <View style={[sharedStyles.cardContainer, sharedStyles.marginHalf]}>
          <ThemedText>GHÉP NỐI THIẾT BỊ</ThemedText>
          {renderResultSearch()}
        </View>
      ),
    },
    completeView,
  ]

  return (
    <ModalColumn flashMessageRef={flashMessageRef} hideCloseButton={sizename === '1-small'} name="ADD_DEVICE" showBackButton={showBackButton} title="Thêm thiết bị mới">
      <View style={[sharedStyles.backgroundHeader]}>
        <Image resizeMode="contain" source={logo} style={sharedStyles.iotlogo} />
      </View>
      {renderTabs()}
      <ViewPagerLayout items={tabs} swipeEnabled={false} selectedIndex={selectedIndex} />
      <View style={[sharedStyles.horizontal, sharedStyles.justifyContentCenter]}>
        {selectedIndex === TABS_INDEX.FINISH ? (
          <Button analyticsLabel="next" size={42 * scaleFactor} type="primary" style={[sharedStyles.btnSave]} onPress={() => closeAllModals()}>
            {`Đóng`}
          </Button>
        ) : (
          <Button analyticsLabel="next" size={42 * scaleFactor} type="primary" style={[sharedStyles.btnSave]} disabled={disableNextStep(loading)} loading={loading || (isMobile && isConnecting2Device)} onPress={handleValidateSteps}>
            {actionLabel}
          </Button>
        )}
      </View>
    </ModalColumn>
  )
})

// AddDeviceModal.displayName = 'AddDeviceModal'
