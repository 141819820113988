import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { Ref, useCallback, useContext, useEffect, useImperativeHandle, useState } from 'react'
import { useMutation } from 'react-apollo'
import { TouchableOpacity, View } from 'react-native'
import Collapsible from 'react-native-collapsible'
import { SAVE_SERVER_SCOPE_ATTRIBUTE } from '../../graphql/gqls'
import { INPUT_MESSAGE } from '../../stores/DeviceConfig'
import { RootStore, RootStoreContext } from '../../stores/RootStore'
import { FLASH_MESSAGE_SCREEN_REF } from '../../stores/UIReferenceStore'
import { sharedStyles } from '../../styles/shared'
import { contentPadding } from '../../styles/variables'
import { DeviceCommonViewProps } from '../columns/InputConfigRender'
import { InputConfigRow } from '../columns/InputConfigRow'
import { IconButton } from '../common/IconButton'
import { Spacer } from '../common/Spacer'
import { ThemedTextInput } from '../themed/ThemedTextInput'

export interface RefEditableTextWithSave {
  saveInputMessage: () => void
}

export const submitMessage = (deviceId: string, saveMessage: (variables: any) => any, loading: boolean, inputOrderNumber: number, warningContent: string, rootStore: RootStore) => {
  if (loading) {
    return
  }
  saveMessage({
    variables: {
      deviceId,
      input: {
        [`${INPUT_MESSAGE}_${inputOrderNumber}`]: warningContent,
      },
    },
  })
    .then(() => {
      rootStore.uiReferenceStore.success(`Lưu nội dung cảnh báo thành công`, FLASH_MESSAGE_SCREEN_REF.INPUT_CONFIG)
      rootStore.currentDeviceStore.setAttributeByNumber(warningContent, `${INPUT_MESSAGE}_${inputOrderNumber}`, inputOrderNumber, moment().valueOf())
    })
    .catch((e: any) => {
      console.debug(`saveMessage `, e)
      rootStore.uiReferenceStore.danger(`Có lỗi khi lưu nội dung cảnh báo. Hãy thử lại sau!`, FLASH_MESSAGE_SCREEN_REF.INPUT_CONFIG)
    })
}

export const EditableTextWithSave = React.memo(
  observer(
    ({
      deviceId,
      inputOrderNumber,
      eRef,
    }: DeviceCommonViewProps & {
      eRef?: Ref<RefEditableTextWithSave>
    }) => {
      const rootStore = useContext(RootStoreContext)
      const [saveMessage, { loading }] = useMutation(SAVE_SERVER_SCOPE_ATTRIBUTE)
      const [warningContent, setWarningContent] = useState<string>('')
      const inputMessage = rootStore.currentDeviceStore.getAttributeByNumber(INPUT_MESSAGE, inputOrderNumber)
      const [expanded, setExpanded] = useState<boolean>(false)

      const resetInputMessage = useCallback(() => setWarningContent(`${typeof inputMessage?.content === 'string' && inputMessage?.content ? inputMessage?.content : ''}`), [inputMessage])

      const handleSubmitMessage = useCallback(() => {
        if (deviceId) {
          submitMessage(deviceId, saveMessage, loading, inputOrderNumber, warningContent, rootStore)
        }
      }, [deviceId, warningContent, loading, inputOrderNumber])

      useEffect(() => resetInputMessage(), [inputMessage])

      if (eRef) {
        useImperativeHandle(eRef, () => ({
          saveInputMessage: () => handleSubmitMessage(),
        }))
      }

      return (
        <>
          <View>
            <TouchableOpacity style={[sharedStyles.horizontal, sharedStyles.justifyContentSpaceBetween]} onPress={() => setExpanded(!expanded)}>
              <InputConfigRow subject={`Nội dung cảnh báo`} tooltip={`Nội dung cảnh báo`} />
              <IconButton style={{ marginTop: -5, marginRight: -10 }} key="edit-abletext" analyticsLabel="remove_column" family="material" name={expanded ? 'edit' : 'save'} disabled={expanded} onPress={handleSubmitMessage} />
            </TouchableOpacity>
          </View>
          <Collapsible collapsed={expanded} duration={300}>
            <ThemedTextInput textInputKey="content" placeholder="Nhập nội dung cảnh báo" autoCapitalize="none" value={warningContent || ''} onChangeText={(text: string) => setWarningContent(text)} />
            <Spacer height={contentPadding / 2} />
          </Collapsible>
        </>
      )
    },
  ),
)
