import axios from 'axios'
import React, { useContext, useState } from 'react'
import { View } from 'react-native'
import { Button as RButton } from 'react-native-elements'
import { Button as PButton, Dialog, Modal, Portal } from 'react-native-paper'
import { useDispatch } from 'react-redux'
import { useReduxState } from '../../hooks/use-redux-state'
import { firebaseUIAuth } from '../../libs/firebaseui-auth'
import * as actions from '../../redux/actions'
import * as selectors from '../../redux/selectors'
import { RootStoreContext } from '../../stores/RootStore'
import { getActiveBackGround, sharedStyles } from '../../styles/shared'
import { contentPadding } from '../../styles/variables'
import { getDefaultAppHeaders } from '../../utils/api'
import { Button } from '../common/Button'
import { Spacer } from '../common/Spacer'
import { ThemedText } from '../themed/ThemedText'

export const LogoutView = ({ showDeActiveAccount }: { showDeActiveAccount?: boolean }) => {
  const rootStore = useContext(RootStoreContext)
  const dispatch = useDispatch()
  const existingAppToken = useReduxState(selectors.appTokenSelector)
  const [visible, setVisible] = useState<boolean>(false)
  const logout = () => {
    const headers = getDefaultAppHeaders({
      appToken: existingAppToken,
    })
    if (rootStore.appConfig.state.fcmDeviceToken) {
      headers['FCM-DEVICE-TOKEN'] = rootStore.appConfig.state.fcmDeviceToken
    }
    axios.post(`${rootStore.appConfig.getAppBaseHostUri()}/api/auth/logout`, {}, { headers }).then(() => {
      firebaseUIAuth.logout().then(() => dispatch(actions.logout()))
    })
  }
  return (
    <View style={[sharedStyles.paddingHorizontal, sharedStyles.vertical]}>
      <Spacer height={contentPadding} />
      <Button key="logout-button" onPress={() => logout()}>
        Đăng xuất
      </Button>
      {showDeActiveAccount ? (
        <>
          <Spacer height={contentPadding} />
          <RButton title={'Deactivate Account/Xóa Tài Khoản'} type={`clear`} onPress={() => setVisible(true)} />
          <Portal>
            <Modal visible={visible} onDismiss={() => setVisible(false)} contentContainerStyle={[sharedStyles.center, sharedStyles.padding]}>
              <View style={[sharedStyles.columnMaxWidth, sharedStyles.fullWidth, getActiveBackGround(true, '#1a1f28')]}>
                <Dialog.Content>
                  <ThemedText style={[sharedStyles.marginVertical]} color={`foregroundColorMuted65`}>
                    {`Bạn có chắc muốn xóa bỏ tải khoản? Chúng tôi sẽ tiến hành xóa tài khoản của bạn. Tuy nhiên 1 vài thông tin sẽ không thể xóa được ngay mà phải chờ sau 1 thời gian (thông thường là 3 tháng) để chúng tôi rà soát và tuân thủ theo chính sách dữ liệu riêng tư của chúng tôi. Bạn nên chắc chắn bỏ các thiết bị khác của bạn trước khi làm việc này. Trong thời gian này nếu bạn đăng nhập lại thì việc xóa này sẽ tạm ngừng, bạn có thể tiếp tục sử dụng dịch vụ của chúng tôi`}
                  </ThemedText>
                </Dialog.Content>
                <Dialog.Actions>
                  <PButton focusable={false} onPress={() => setVisible(false)}>
                    Huỷ
                  </PButton>
                  <PButton
                    focusable={false}
                    mode={'contained'}
                    onPress={() => {
                      setVisible(false)
                      logout()
                    }}
                  >
                    Chắc chắn
                  </PButton>
                </Dialog.Actions>
              </View>
            </Modal>
          </Portal>
        </>
      ) : null}
    </View>
  )
}
