import { observer } from 'mobx-react-lite'
import React, { useContext, useMemo } from 'react'
import { Mutation } from 'react-apollo'
import { RPCCallingInput, RPCCallingResponse, SAVE_SERVER_SCOPE_ATTRIBUTE } from '../../graphql/gqls'
import { INPUT_MESSAGE, INPUT_SPEC_TYPE } from '../../stores/DeviceConfig'
import { RootStoreContext } from '../../stores/RootStore'
import { getInputSpecType } from '../columns/InputConfigRender'
import { EditableCollapsibleText } from './EditableCollapsibleText'
import { submitMessage } from './EditableTextWithSave'

export interface IInputConfig {
  inputOrderNumber: number
  name: string
  message: string
}

export const getInputName = (order: number) => (getInputSpecType(order) === INPUT_SPEC_TYPE.SEQUENCE ? `Cảm biến ${order - 2}` : `Tiếp điểm ${order + 1}`)

export const MutableInputMessage = React.memo(
  observer(({ deviceId, config }: { deviceId: string; config: IInputConfig }) => {
    const rootStore = useContext(RootStoreContext)
    const inputMessage = rootStore.currentDeviceStore.getAttributeByNumber(INPUT_MESSAGE, config.inputOrderNumber)

    const inputMessageContent = useMemo(() => {
      const text = `${typeof inputMessage?.content === 'string' && inputMessage?.content ? inputMessage.content : ''}`
      return text || config?.message || ''
    }, [inputMessage, config])

    return (
      <Mutation<RPCCallingResponse, RPCCallingInput> mutation={SAVE_SERVER_SCOPE_ATTRIBUTE}>
        {(updateCalling, { loading }) => (
          <EditableCollapsibleText
            title={inputMessageContent}
            isInline={false}
            headerText={'Nhập nội dung cảnh báo'}
            onUpdate={(updateText: string) => {
              submitMessage(deviceId, updateCalling, loading, config.inputOrderNumber, updateText, rootStore)
            }}
          />
        )}
      </Mutation>
    )
  }),
)
