import { IDict } from '@devhub/core/dist'
import gql from 'graphql-tag'

export const GRAPHQL_QUERY = gql`
  query listWallets($address: String!) {
    listWalletStatus(address: $address) {
      wallets {
        address
        platform
        deviceToken
        registerPushNotification
      }
    }
  }
`

export const DEVICES_TYPE_QUERY = gql`
  query devicesQuery {
    devices @rest(type: "DeviceTypes", path: "/api/device/types") {
      entityType
      tenantId @type(name: "Tenant") {
        entityType
        id
      }
      type
    }
  }
`

export const DEVICE_QUERY = gql`
  query deviceQuery($deviceId: String!) {
    deviceById(deviceId: $deviceId) @rest(type: "DeviceDetail", path: "/api/device/{args.deviceId}", method: "GET") {
      label
      name
      type
      additionalInfo
      createdTime
      customerId @type(name: "Entity") {
        id
      }
      id
      tenantId @type(name: "Entity") {
        id
      }
    }
  }
`

export const DEVICES_QUERY = gql`
  query devicesQuery($pageSize: Int!, $page: Int!) {
    devicesByTenant(pageSize: $pageSize, page: $page) @rest(type: "DeviceList", path: "/api/tenant/devices?{args}&sortProperty=createdTime&sortOrder=desc") {
      hasNext
      nextPageLink @type(name: "NextPageLink") {
        idOffset
        limit
        textOffset
        textSearch
        textSearchBound
      }
      data @type(name: "Devices") {
        name
        type
        label
        idInfo: id @export(as: "idInfo") @type(name: "EntityId") {
          id
          entityType
        }
        createdTime
        additionalInfo
        tenantId @type(name: "EntityId") {
          id
          entityType
        }
        customerId @type(name: "EntityId") {
          id
          entityType
        }
      }
    }
  }
`

export const CUSTOMER_DEVICES_QUERY = gql`
  query devicesQuery($customerId: String!, $pageSize: Int!, $page: Int!) {
    customerDevice(customerId: $customerId, pageSize: $pageSize, page: $page) @rest(type: "DeviceList", path: "/api/customer/{args.customerId}/devices?{args}&sortProperty=createdTime&sortOrder=desc") {
      hasNext
      nextPageLink @type(name: "NextPageLink") {
        idOffset
        limit
        textOffset
        textSearch
        textSearchBound
      }
      data @type(name: "Devices") {
        name
        type
        label
        idInfo: id @export(as: "idInfo") @type(name: "EntityId") {
          id
          entityType
        }
        createdTime
        additionalInfo
        tenantId @type(name: "EntityId") {
          id
          entityType
        }
        customerId @type(name: "EntityId") {
          id
          entityType
        }
      }
    }
  }
`

export const QUERY_SHARE_WITH_ME = gql`
  query queryShareWithMe {
    queryShareWithMe @rest(method: "GET", path: "/api/user/shareDeviceInfos", type: "QueryShareWithMeResponse") {
      data
    }
  }
`

export const CALL_RPC = gql`
  mutation rpcMethodCalling($callType: String!, $deviceId: String!, $input: RPCCallingInput!) {
    callMethod(callType: $callType, deviceId: $deviceId, input: $input) @rest(method: "POST", path: "/api/plugins/rpc/{args.callType}/{args.deviceId}", type: "RPCCallingResponse") {
      status
    }
  }
`

export const SAVE_SHARED_SCOPE_ATTRIBUTE = gql`
  mutation saveAttribute($input: SaveAttributeInput!) {
    saveAttribute(deviceId: $deviceId, input: $input) @rest(method: "POST", path: "/api/plugins/telemetry/DEVICE/{args.deviceId}/attributes/SHARED_SCOPE", type: "SaveAttributeResponse") {
      status
    }
  }
`

export const SAVE_SERVER_SCOPE_ATTRIBUTE = gql`
  mutation saveAttribute($input: SaveAttributeInput!) {
    saveAttribute(deviceId: $deviceId, input: $input) @rest(method: "POST", path: "/api/plugins/telemetry/DEVICE/{args.deviceId}/attributes/SERVER_SCOPE", type: "SaveAttributeResponse") {
      status
    }
  }
`

export const QUERY_SHARED_SCOPE_ATTRIBUTE = gql`
  query queryAttribute($input: GetAttributeInput!) {
    queryAttribute(deviceId: $deviceId, input: $input) @rest(method: "GET", path: "/api/plugins/telemetry/DEVICE/{args.deviceId}/values/attributes/SHARED_SCOPE?keys={args.input}", type: "GetAttributeResponse") {
      lastUpdateTs
      key
      value
    }
  }
`

export const GET_STATUS = gql`
  mutation getTelemetry {
    getTelemetry(deviceId: $deviceId) @rest(method: "GET", path: "/api/plugins/telemetry/DEVICE/{args.deviceId}/values/timeseries?keys=status", type: "GetTelemetryResponse") {
      status
    }
  }
`

export const GET_USER_SHARE_DEVICE = gql`
  query getUserShareDevice {
    getUserShareDevice(deviceId: $deviceId) @rest(method: "GET", path: "/api/device/{args.deviceId}/share", type: "GetUserShareResponse") {
      data
    }
  }
`

export const ADD_USER_SHARE_DEVICE = gql`
  mutation addUserShareDevice($input: AddUserShareDeviceInput!) {
    addUserShareDevice(deviceId: $deviceId, input: $input) @rest(method: "POST", path: "/api/device/{args.deviceId}/share", type: "AddUserShareDeviceResponse") {
      data
    }
  }
`

export const LOOKING_FOR_USER = gql`
  mutation lookingForUser($input: LookingForUserInput!) {
    lookingForUser(input: $input) @rest(method: "POST", path: "/api/users/lookup", type: "LookingForUserResponse") {
      data
    }
  }
`

export const LOGIN = gql`
  mutation login($input: UserInput!) {
    login(input: $input) @rest(method: "POST", path: "/api/auth/login", type: "Token") {
      token
      refreshToken
    }
  }
`

export const CREATE_PASSWORD = gql`
  mutation password($input: UserInput!) {
    password(input: $input) @rest(method: "POST", path: "/api/noauth/activate?sendActivationMail=false", type: "Token") {
      token
      refreshToken
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation password($input: UserInput!) {
    password(input: $input) @rest(method: "POST", path: "/api/noauth/resetPassword", type: "Token") {
      token
      refreshToken
    }
  }
`

export const CLAIM_DEVICE = gql`
  mutation saveDevice($input: ClaimInput!) {
    saveDevice(deviceName: $deviceName, input: $input) @rest(method: "POST", path: "/api/customer/device/{args.deviceName}/claim", type: "SaveDeviceResponse") {
      device
      response
    }
  }
`

export const DELETE_DEVICE = gql`
  mutation deleteDevice($deviceName: String!) {
    deleteDevice(deviceName: $deviceName) @rest(method: "DELETE", path: "/api/customer/device/{args.deviceName}/claim", type: "DeleteDeviceResponse") {
      result
      setOrExpired
    }
  }
`

export const CREATE_CUSTOMER = gql`
  mutation createCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) @rest(method: "POST", path: "/api/user/register", type: "CreateCustomerResponse") {
      id {
        entityType
        id
      }
      createdTime
      additionalInfo
      tenantId {
        entityType
        id
      }
      customerId {
        entityType
        id
      }
      email
      authority
      firstName
      lastName
      name
    }
  }
`

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) @rest(method: "POST", path: "/api/noauth/resetPasswordByEmail", type: "ForgotPasswordResponse") {
      result
    }
  }
`

export const CHANGE_PASSWORD = gql`
  mutation changePassword($input: ForgotPasswordInput!) {
    changePassword(input: $input) @rest(method: "POST", path: "/api/auth/changePassword", type: "ChangePasswordResponse") {
      result
    }
  }
`

export const SAVE_DEVICE_NAME = gql`
  mutation saveDeviceName($input: SaveDeviceNameInput!) {
    saveDeviceName(deviceId: $deviceId, input: $input) @rest(method: "POST", path: "/api/device/{args.deviceId}/properties", type: "SaveDeviceNameResponse") {
      result
    }
  }
`

export const SAVE_DEVICE_SMS = gql`
  mutation saveDeviceSMS($input: SaveDeviceSMSInput!) {
    saveDeviceSMS(input: $input) @rest(method: "POST", path: "/api/device/sms-device", type: "saveDeviceSMSResponse") {
      id {
        entityType
        id
      }
      label
      name
      type
    }
  }
`

export const DELETE_DEVICE_SMS = gql`
  mutation deleteDeviceSMS($deviceId: String!) {
    deleteDeviceSMS(deviceId: $deviceId) @rest(method: "DELETE", path: "/api/device/sms-device/{args.deviceId}", type: "DeleteDeviceSMS") {
      data
    }
  }
`

export interface RPCCallingResponse extends Record<string, any> {
  callMethod: { status: any }
}

export interface RPCCallingInput {
  deviceId: string
  callType: string
  method: string
  input: IDict
}

export interface SaveDeviceResponse extends Record<string, any> {
  callMethod: { result: any; setOrExpired: any }
}

export interface DeleteDeviceResponse extends Record<string, any> {
  callMethod: { result: any; setOrExpired: any }
}

export interface SaveAttributeInput {
  deviceId: string
  input: Record<string, any>
}

export interface SaveAttributeResponse {}
