import React, { FC, useState } from 'react'
import { ITimePickerModalProps, PossibleInputTypes, reverseInputTypes, TimePickerModal } from 'react-native-paper-time-duration'
import { sharedStyles } from '../../styles/shared'
import { IconButton } from '../common/IconButton'

type InputIconMap = {
  [inputType in PossibleInputTypes]: string
}

export const inputTypeIcons: InputIconMap = {
  keyboard: 'keyboard',
  picker: 'clock',
}

interface ITimePickerModal extends ITimePickerModalProps {}

export const AppTimePickerModal: FC<ITimePickerModal> = ({
  locale = 'vi',
  pickerType = 'picker',
  label = 'Chọn giờ',
  cancelLabel = 'Hủy',
  confirmLabel = 'Ok',
  ...otherProps
}) => {
  const [inputType, setInputType] = useState<PossibleInputTypes>(pickerType)

  return (
    <TimePickerModal
      iconToggle={
        <IconButton
          key="toggle-type-picker"
          style={[sharedStyles.toggleInputPicker]}
          family="octicon"
          name={inputTypeIcons[reverseInputTypes[inputType]] as any}
          type="primary"
          onPress={() => setInputType(reverseInputTypes[inputType])}
        />
      }
      label={label}
      cancelLabel={cancelLabel}
      confirmLabel={confirmLabel}
      locale={locale}
      pickerType={inputType}
      {...otherProps}
    />
  )
}

AppTimePickerModal.displayName = 'AppTimePickerModal'
