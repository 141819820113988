import React, { useEffect, useRef, useState } from 'react'
import { View } from 'react-native'
import Collapsible from 'react-native-collapsible'
import { Button as RButton } from 'react-native-elements'
import { Button, Dialog, Modal, Portal } from 'react-native-paper'
import { getActiveBackGround, sharedStyles, webSharedStyles } from '../../styles/shared'
import { normalTextSize, scaleFactor } from '../../styles/variables'
import { foregroundThemeColor } from '../common/Button'
import { TextInput } from '../common/TextInput'
import { useTheme } from '../context/ThemeContext'
import { DialogInput } from '../elements/DialogInput'
import { ThemedTextInputWithIcon } from '../elements/ThemedTextInputWithIcon'
import { ThemedIcon } from '../themed/ThemedIcon'
import { ThemedText } from '../themed/ThemedText'

export const EditableCollapsibleText = React.memo(
  ({
    title,
    defaultInputText,
    onUpdate,
    showIcon = false,
    isInline = true,
    inputStyle,
    headerText,
  }: {
    title: string
    defaultInputText?: string
    onUpdate: (updateText: string) => any
    headerText?: string
    showIcon?: boolean
    isInline?: boolean
    inputStyle?: any
  }) => {
    const theme = useTheme()
    const [expanded, setExpanded] = useState<boolean>(false)
    const [currentText, setCurrentText] = useState<string>(defaultInputText || title)
    const inputRef = useRef<TextInput>(null)
    const hideWhenExpanse = false

    useEffect(() => setCurrentText(title), [title])
    const view = isInline ? (
      <View style={[sharedStyles.flex, sharedStyles.vertical, sharedStyles.paddingVerticalQuarter, sharedStyles.alignSelfStretch]}>
        {hideWhenExpanse ? null : (
          <ThemedText color={theme.primaryForegroundColor} numberOfLines={2} ellipsizeMode="tail" style={[sharedStyles.flexWrap, webSharedStyles.wrapText]} onPress={() => setExpanded(!expanded)}>
            {`${title}  `}
            {showIcon ? <ThemedIcon family="material" name={'edit'} size={16 * scaleFactor} color={'primaryForegroundColor'} /> : null}
          </ThemedText>
        )}
        <Collapsible collapsed={!expanded}>
          <ThemedTextInputWithIcon
            textInputKey="enter-output-config-name"
            iconName={'save'}
            family={'material'}
            placeholder={title}
            value={currentText}
            autoCapitalize="none"
            ref={inputRef}
            style={inputStyle}
            autoFocus={false}
            onChangeText={(text: string) => setCurrentText(text)}
            onClick={() => {
              onUpdate(currentText)
              setExpanded(false)
            }}
          />
        </Collapsible>
      </View>
    ) : (
      <DialogInput titleStyle={[sharedStyles.textLeft]} title={headerText || title} showIcon={showIcon} buttonStyle={{ paddingLeft: 0 }} anchorText={title} initValue={currentText} onFinish={(inputText) => onUpdate(inputText)} />
    )

    return view
  },
)
