import { EnhancedDevice } from '@devhub/core/dist'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { View } from 'react-native'
import { DEVICES_CONFIG, IOutputConfigs, OUTPUT_CONFIG, OUTPUT_CONFIG_MODEL, OUTPUT_CONFIG_NAMES } from '../../stores/DeviceConfig'
import { RootStoreContext } from '../../stores/RootStore'
import { groupOutputsByDependence, IOutputConfigItem } from '../../stores/StoreUtils'
import { sharedStyles } from '../../styles/shared'
import { contentPadding, scaleFactor, smallerTextSize } from '../../styles/variables'
import { foregroundThemeColor, loadingIndicatorStyle } from '../common/Button'
import { IconButton } from '../common/IconButton'
import { Spacer } from '../common/Spacer'
import { ThemedActivityIndicator } from '../themed/ThemedActivityIndicator'
import { ThemedText } from '../themed/ThemedText'
import { DeviceListItem } from './DeviceListItem'
import { SimpleOnOfControlView } from './SimpleOnOfControlView'

export const MultiOutputControlItem = React.memo(
  observer(({ device, position }: { device: EnhancedDevice; position: number }) => {
    const rootStore = useContext(RootStoreContext)
    const deviceId = device.id.id
    const ports: IOutputConfigs = rootStore.deviceListStore.state[`${OUTPUT_CONFIG}_${deviceId}`]
    const names = rootStore.deviceListStore.state[`${OUTPUT_CONFIG_NAMES}_${deviceId}`]
    const outputModel = rootStore.deviceListStore.state[`${OUTPUT_CONFIG_MODEL}_${deviceId}`]
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [sections, setSections] = useState<IOutputConfigItem[]>([])
    const { isSMSTransport } = DEVICES_CONFIG[device?.type || ''] || {}

    useEffect(() => {
      if (ports) {
        const configsState: IOutputConfigs = { ...ports }
        const groupedPorts = groupOutputsByDependence(configsState, names, outputModel, isSMSTransport)
        setSections(groupedPorts)
      }
    }, [ports, names, outputModel, isSMSTransport])

    return (
      <DeviceListItem device={device} key={`device-controller-item-${deviceId}-${position}`}>
        {ports ? null : <ThemedActivityIndicator color={foregroundThemeColor} size="small" style={loadingIndicatorStyle} />}
        <View>
          {sections.length > 0 && selectedIndex >= 0 && selectedIndex < sections.length ? (
            <View style={[sharedStyles.vertical, sharedStyles.paddingHorizontalHalf]}>
              <View style={[sharedStyles.horizontal, sharedStyles.flexWrap, sharedStyles.justifyContentSpaceBetween]}>
                {sections[selectedIndex].data.map(({ outputIndex, title: text }) => {
                  return <SimpleOnOfControlView key={`device-SimpleOnOfControlView-item-${deviceId}-${outputIndex}`} name={text} deviceId={deviceId} port={outputIndex} device={device} />
                })}
              </View>
            </View>
          ) : null}
          <Spacer height={contentPadding} />
          <View style={[sharedStyles.horizontal, sharedStyles.justifyContentCenter, sharedStyles.alignItemsCenter]}>
            {selectedIndex > 0 ? <IconButton family="octicon" name="arrow-left" size={16 * scaleFactor} tooltip={'truớc'} onPress={() => setSelectedIndex(selectedIndex - 1)} /> : null}
            <ThemedText
              color="foregroundColor"
              style={[
                {
                  fontSize: smallerTextSize,
                  width: contentPadding * 15,
                },
                sharedStyles.textCenter,
              ]}
            >
              {sections[selectedIndex]?.title}
            </ThemedText>
            {selectedIndex < sections.length - 1 ? <IconButton family="octicon" name="arrow-right" size={16 * scaleFactor} tooltip={'truớc'} onPress={() => setSelectedIndex(selectedIndex + 1)} /> : null}
          </View>
        </View>
      </DeviceListItem>
    )
  }),
)
