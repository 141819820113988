import firebase from 'firebase/app'
import 'firebase/auth'
import { rootStore } from '../../stores/RootStore'
import { FirebaseUIAuth } from './'

export const firebaseUIConfig = {
  signInFlow: 'popup',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    {
      provider: 'apple.com',
    },
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    signInSuccessWithAuthResult: () => false,
  },
}

const initializeFirebaseApp = () => {
  let firebaseApp
  try {
    const firebaseConfig = require('./firebase-config.json').result.sdkConfig
    firebaseApp = firebase.initializeApp(firebaseConfig)
    firebaseApp.auth().onAuthStateChanged((user) => {
      rootStore.appConfig.state.currentFirebaseUser = user
    })
  } catch (e) {
    console.log(`initializeFirebaseApp error`, e)
  }
  return firebaseApp
}

export const firebaseUIAuth: FirebaseUIAuth = {
  firebaseApp: initializeFirebaseApp(),
  async logout() {
    return this.firebaseApp.auth().signOut()
  },
  loadCurrentUser() {
    console.log('firebaseUIAuth loadCurrentUser')
  },
}
