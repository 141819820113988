import { getColumnHeaderDetails } from '@devhub/core'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { Image, ImageSourcePropType, StyleSheet, View } from 'react-native'
import { Tooltip } from 'react-native-elements'
import FlashMessage from 'react-native-flash-message'
import { useDispatch } from 'react-redux'
import { DeviceCardsContainerProps } from '../../containers/DeviceCardsContainer'
import { IconProp } from '../../libs/vector-icons'
import * as actions from '../../redux/actions'
import { ACTIVE } from '../../stores/DeviceConfig'
import { RootStoreContext } from '../../stores/RootStore'
import { getDeviceStatusText } from '../../stores/StoreUtils'
import { FLASH_MESSAGE_DURATION } from '../../stores/UIReferenceStore'
import { sharedStyles } from '../../styles/shared'
import { scaleFactor } from '../../styles/variables'
import { EmptyCards } from '../cards/EmptyCards'
import { foregroundThemeColor } from '../common/Button'
import { IconButton } from '../common/IconButton'
import { RefreshDataIcon } from '../elements/RefreshDataIcon'
import { DeviceStatusModal } from '../modals/DeviceStatusModal'
import { ThemedActivityIndicator } from '../themed/ThemedActivityIndicator'
import { ThemedText } from '../themed/ThemedText'
import { UpdateDeviceNamePopover } from '../views/UpdateDeviceNamePopover'
import { ColumnRenderer, ColumnRendererProps } from './ColumnRenderer'

export interface DeviceDetailColumnProps extends Omit<DeviceCardsContainerProps, 'ownerIsKnown' | 'repoIsKnown'> {
  columnIndex: number
  headerDetails: ReturnType<typeof getColumnHeaderDetails>
  pagingEnabled?: boolean
}

export const DeviceDetailColumn = React.memo(
  observer((props: DeviceDetailColumnProps) => {
    const { columnId, columnIndex, headerDetails, pagingEnabled, pointerEvents, swipeable } = props

    const rootStore = useContext(RootStoreContext)
    const device = rootStore.currentDeviceStore.state.device
    const deviceId = rootStore.currentDeviceStore.getDeviceId()
    const rssi = rootStore.currentDeviceStore.state.RSSI
    const flashMessageRef = useRef<FlashMessage>(null)
    const dispatch = useDispatch()
    const active = rootStore.currentDeviceStore.state[ACTIVE]

    useEffect(() => {
      rootStore.uiReferenceStore.state.devicePanelFlashMessageRef = flashMessageRef.current
    }, [flashMessageRef.current])

    const Children = useMemo<ColumnRendererProps['children']>(
      () =>
        !!deviceId ? (
          <DeviceStatusModal key={`device-detail-cards-container-${columnId}-${columnIndex}-${deviceId}`} deviceId={deviceId} showBackButton={true} flashMessageRef={flashMessageRef} />
        ) : (
          <View style={[StyleSheet.absoluteFill, sharedStyles.center]} pointerEvents="none">
            <ThemedText color="foregroundColorMuted65"> {`Hãy chọn 1 thiết bị`} </ThemedText>
          </View>
        ),
      [columnId, columnIndex, pointerEvents, swipeable, deviceId],
    )

    const rightButtons = useMemo(() => {
      let wifiStrength: ImageSourcePropType = require('@devhub/components/assets/rssi_wifi_no_signal.png')
      let connectionSignal = 'Chưa kết nối'
      if (active) {
        wifiStrength = require('@devhub/components/assets/rssi_wifi_good.png')
        if (rssi < 0 && rssi > -50) {
          connectionSignal = `Tín hiệu wifi rất tốt <RSSI: ${rssi}>`
          wifiStrength = require('@devhub/components/assets/rssi_wifi_very_good.png')
        }
        if (rssi < -50) {
          connectionSignal = `Tín hiệu wifi tốt <RSSI: ${rssi}>`
          wifiStrength = require('@devhub/components/assets/rssi_wifi_good.png')
        }
        if (rssi < -60) {
          connectionSignal = `Tín hiệu wifi bình thường <RSSI: ${rssi}>`
          wifiStrength = require('@devhub/components/assets/rssi_wifi_low.png')
        }
        if (rssi < -70) {
          connectionSignal = `Tín hiệu wifi yếu <RSSI: ${rssi}>`
        }
      }
      return [
        <IconButton
          key={`right-button-${deviceId}-history`}
          family="octicon"
          name="clock"
          size={16 * scaleFactor}
          tooltip={'Nhật ký thiết bị'}
          onPress={() => dispatch(actions.pushModal({ name: 'USER_NOTIFICATION', params: { deviceId, type: 'DEVICE' } }))}
        />,
        // @ts-ignore
        <Tooltip key={`device-detail-${deviceId}-rightButton-active-status`} overlayColor={'rgba(250, 250, 250, 0)'} popover={<ThemedText color={'white'}>{getDeviceStatusText(active, connectionSignal)}</ThemedText>}>
          <Image key={`device-detail-${deviceId}-rssi-Image`} source={wifiStrength} style={sharedStyles.rssiLength} />
        </Tooltip>,
        <RefreshDataIcon key={`device-detail-${deviceId}-refresh`} device={device} />,
      ]
    }, [deviceId, rssi, active, device])

    if (!headerDetails) return null
    if (!deviceId) return <EmptyCards clearMessage={`Hãy chọn 1 thiết bị`} columnId={columnId} />

    const title: string = device ? device.label || device.name : headerDetails.title
    const subtitle: string = device?.type || '--'

    return (
      <>
        <ColumnRenderer
          key={`device-detail-column-${columnId}-${deviceId}-inner`}
          avatarImageURL={headerDetails.avatarProps && headerDetails.avatarProps.imageURL}
          avatarLinkURL={headerDetails.avatarProps && headerDetails.avatarProps.linkURL}
          columnId={columnId}
          columnIndex={columnIndex}
          columnType="deviceDetail"
          icon={headerDetails.icon as IconProp}
          owner={headerDetails.owner}
          pagingEnabled={pagingEnabled}
          repo={headerDetails.repo}
          repoIsKnown={headerDetails.repoIsKnown}
          subtitle={subtitle}
          iconAdge={<></>}
          title={<UpdateDeviceNamePopover title={title} />}
          rightButtons={rightButtons}
          onPressIconLeft={() => dispatch(actions.pushModal({ name: 'DETAIL_DEVICE' }))}
        >
          {Children}
        </ColumnRenderer>

        {rootStore.currentDeviceStore.state.loading && (
          <View style={[StyleSheet.absoluteFill, sharedStyles.center]} pointerEvents="none">
            <ThemedActivityIndicator color={foregroundThemeColor} size="small" style={sharedStyles.loadingIndicatorStyle} />
          </View>
        )}
        <FlashMessage ref={flashMessageRef} autoHide={true} duration={FLASH_MESSAGE_DURATION} />
      </>
    )
  }),
)

DeviceDetailColumn.displayName = 'DeviceDetailColumn'
