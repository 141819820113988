import { IDict } from '@devhub/core/dist'
import { Toggle } from '@ui-kitten/components'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { Mutation } from 'react-apollo'
import { View } from 'react-native'
import { Button } from 'react-native-elements'
import { CALL_RPC, RPCCallingResponse } from '../../graphql/gqls'
import { RPC_RESULT_SET_STATUS } from '../../stores/CurrentDeviceStore'
import { DEVICES_CONFIG, IParams, openAppSMS, STATUS } from '../../stores/DeviceConfig'
import { RootStoreContext } from '../../stores/RootStore'
import { FLASH_MESSAGE_SCREEN_REF } from '../../stores/UIReferenceStore'
import { getActiveBackGround, getColorTextActive, sharedStyles } from '../../styles/shared'
import { scaleFactor } from '../../styles/variables'
import { getMessageError } from '../../utils/helpers/shared'
import { foregroundThemeColor, loadingIndicatorStyle } from '../common/Button'
import { IconButton } from '../common/IconButton'
import { useTheme } from '../context/ThemeContext'
import { ThemedActivityIndicator } from '../themed/ThemedActivityIndicator'
import { ThemedIcon } from '../themed/ThemedIcon'
import { ThemedText } from '../themed/ThemedText'
import { IScheduleTextMethod, ScheduleActionText } from './ScheduleActionText'

export interface IPostResult {
  params: IParams
  response?: any
  error?: any
}

export interface IOnOffControl {
  deviceId: string
  method: string
  port?: number
  postValue?: (params: IParams) => any
  prePostValue?: (params: IParams) => any
  onPostValueResult?: (result: IPostResult) => any
  active: boolean
  name?: string
  isToggle?: boolean
  portNumber?: number
  onChange?: (isModified: boolean) => any
  preConfirm?: (callback: () => any) => void
  [key: string]: any
}

export interface IOnOffControlRef {
  value: () => boolean
  isModified: boolean
  onSubmitFinish: () => void
  onSaved: () => any
}

export const OnOffControlCardView = observer(
  (
    {
      deviceId,
      method,
      disabled,
      postValue,
      prePostValue,
      onPostValueResult,
      active,
      name,
      isToggle = false,
      containerStyles = [],
      innerStyles = [],
      iconSize = 50,
      flashMessageRef = FLASH_MESSAGE_SCREEN_REF.DEVICE_DETAIL,
      deviceActive = true,
      port,
      isSimpleView = false,
      enableActionSchedule = true,
      textStyle,
      nameStyles = [],
      scheduleActionDisplayType = 'text',
      rightElement,
      scheduleOn,
      preConfirm,
      onChange,
      ...others
    }: IOnOffControl,
    ref,
  ) => {
    const rootStore = useContext(RootStoreContext)
    const device = rootStore.currentDeviceStore.state.device
    const theme = useTheme()
    const [activeState, setActiveState] = useState(active)
    const [isModified, setIsModified] = useState<boolean>(false)

    const actionTextRef = useRef<IScheduleTextMethod | null>(null)
    const [now, setNow] = useState(moment().utc(true))

    const extraParams = port !== undefined ? { pin: port } : {}

    useEffect(() => {
      setActiveState(active)
      setIsModified(false)
      setNow(moment().utc(true).subtract(1, 'second'))
    }, [active])

    const { isSMSTransport, buildOnOffMessage, validateStartStop } = useMemo(() => DEVICES_CONFIG[device?.type || ''] || {}, [device])
    const postRPC = useCallback(
      (updateCalling, loading, params: IParams) => {
        if (loading) {
          return
        }
        const newActive = params.value
        setActiveState(newActive)
        setIsModified(true)
        if (prePostValue) {
          prePostValue(params)
        }
        const postParams = postValue ? postValue(params) : params
        if (isSMSTransport) {
          if (method === 'setStatus') {
            const path = port === undefined ? `${STATUS}_${deviceId}` : `${STATUS}_${deviceId}_${port}`
            if (onPostValueResult) {
              onPostValueResult({ params })
            } else {
              rootStore.deviceListStore.state[path] = postParams.value
            }
            if (validateStartStop) {
              return
            }
            if (buildOnOffMessage) {
              const smsBody = buildOnOffMessage(device, method, postParams, port)
              if (device?.label) {
                openAppSMS(device?.label, smsBody)
              } else {
                rootStore.uiReferenceStore.danger(`Thiết bị chưa được cài đặt số điện thoại`, flashMessageRef)
              }
            } else {
              rootStore.uiReferenceStore.danger(`Thiết bị ${device?.type} chưa cài đặt cú pháp sms bật tắt`, flashMessageRef)
            }
          } else if (method === 'setScheduleOn') {
            rootStore.currentDeviceStore.setScheduleOnForSMSDevice(deviceId, postParams, port)
          }
          return
        }
        updateCalling({
          variables: {
            deviceId,
            callType: 'oneway', // 'twoway',
            input: {
              method,
              params: postParams,
            },
          },
        })
          .then((response: any) => {
            if (onPostValueResult) {
              onPostValueResult({
                params,
                response,
              })
            }
          })
          .catch((error: any) => {
            console.log('post value error', error)
            setActiveState(active)
            if (onPostValueResult) {
              onPostValueResult({
                params,
                error,
              })
            }

            rootStore.uiReferenceStore.danger(error?.networkError?.statusCode === 403 ? `${getMessageError(error)}` : `Không được!\n${device?.label || device?.name} đang kết nối?`, flashMessageRef)
            actionTextRef.current?.reset()
          })
      },
      [prePostValue, onPostValueResult, postValue, method, deviceId, device, isSMSTransport, buildOnOffMessage, validateStartStop],
    )

    useEffect(() => {
      if (onChange) {
        onChange(isModified)
      }
    }, [isModified])

    useImperativeHandle(
      ref,
      () => ({
        value: () => activeState,
        isModified,
        onSubmitFinish: () => setIsModified(false),
        onSaved: () => {
          setIsModified(false)
          actionTextRef.current?.onUndoFinish()
        },
      }),
      [activeState, isModified, actionTextRef.current],
    )

    return (
      <Mutation<RPCCallingResponse, IDict>
        key={`mutation-${deviceId}`}
        mutation={CALL_RPC}
        context={{ timeout: 15000 }}
        update={(cache: any, { data }) => {
          console.log('rpc response data', data)
        }}
      >
        {(updateCalling, { loading }) => {
          const scheduleAction =
            !isToggle && enableActionSchedule ? (
              <ScheduleActionText
                deviceId={deviceId}
                active={!active}
                disabledAction={isSMSTransport && !validateStartStop}
                alwaysEnd={validateStartStop}
                hideSecond={isSMSTransport}
                enableUndo={isSMSTransport}
                valueRef={actionTextRef}
                key={`schedule-action-${deviceId}-${port}`}
                portNumber={port}
                now={now}
                loading={loading}
                textStyle={textStyle}
                displayType={scheduleActionDisplayType}
                onConfirm={({ startStop, delayActive, startNow }) => {
                  const cb = () =>
                    postRPC(updateCalling, loading, {
                      value: delayActive,
                      timer: startStop.join('_'),
                      startNow,
                      ...extraParams,
                    })
                  if (preConfirm) preConfirm(cb)
                  else cb()
                }}
                onHandleUndo={() => {
                  if (isSMSTransport) {
                    if (method === 'setStatus') {
                      const path = port === undefined ? `${STATUS}_${deviceId}` : `${STATUS}_${deviceId}_${port}`
                      const portNumber = port || 0
                      rootStore.deviceListStore.state[path] = ''
                      rootStore.currentDeviceStore.setAttributeByNumber({}, `${RPC_RESULT_SET_STATUS}_${portNumber}`, portNumber)
                      actionTextRef.current?.onUndoFinish()
                    }
                  }
                }}
              />
            ) : null

          const nameView =
            !isToggle && name ? (
              <ThemedText key={`name-${deviceId}-${port}`} numberOfLines={1} ellipsizeMode="head" style={[sharedStyles.itemCode, sharedStyles.textLeft, nameStyles]}>
                {`${name}${scheduleOn !== undefined ? '  ' : ''}`}
                {scheduleOn !== undefined ? <ThemedIcon family="material" name={scheduleOn ? 'schedule' : 'gamepad'} size={12 * scaleFactor} color={'foregroundColorMuted65'} /> : null}
              </ThemedText>
            ) : null
          return (
            <>
              {isToggle ? (
                <View {...others}>
                  <View style={[sharedStyles.toggleControlContainer, sharedStyles.horizontal, sharedStyles.justifyContentCenter, sharedStyles.alignItemsCenter]}>
                    {rightElement ? (
                      <ThemedText color={getColorTextActive(!active, theme)}>{`Điều khiển `}</ThemedText>
                    ) : (
                      <Button
                        title="Điều Khiển"
                        type={active ? 'clear' : `solid`}
                        icon={{
                          name: 'gamepad',
                          size: 15,
                          color: 'white',
                          type: 'material',
                        }}
                      />
                    )}
                    <Toggle
                      style={[sharedStyles.toggle]}
                      status="primary"
                      checked={activeState}
                      onChange={(isChecked) =>
                        postRPC(updateCalling, loading, {
                          value: isChecked,
                          ...extraParams,
                        })
                      }
                    />
                    {rightElement ? (
                      rightElement
                    ) : (
                      <Button
                        title="Lịch Biểu"
                        type={active ? 'solid' : `clear`}
                        iconRight={true}
                        icon={{
                          name: 'schedule',
                          size: 15,
                          color: 'white',
                          type: 'material',
                        }}
                      />
                    )}
                  </View>
                  {loading && <ThemedActivityIndicator color={foregroundThemeColor} size="small" style={loadingIndicatorStyle} />}
                </View>
              ) : (
                <View {...others}>
                  <View style={[getActiveBackGround(active), sharedStyles.justifyContentSpaceBetween, sharedStyles.vertical, ...containerStyles]}>
                    <View style={[enableActionSchedule ? sharedStyles.flex : {}, sharedStyles.alignItemsCenter, sharedStyles.justifyContentCenter, { borderColor: theme.primaryBackgroundColor }, ...innerStyles]}>
                      <IconButton
                        family="material"
                        disabled={disabled}
                        name="power-settings-new"
                        onPress={() => {
                          if (validateStartStop) {
                            actionTextRef.current?.startNowWithPicker()
                            return
                          }
                          const cb = () =>
                            postRPC(updateCalling, loading, {
                              value: !activeState,
                              ...extraParams,
                            })
                          if (preConfirm) preConfirm(cb)
                          else cb()
                        }}
                        size={iconSize * scaleFactor}
                        tooltip="status"
                        active={activeState}
                      />
                      {loading && <ThemedActivityIndicator color={foregroundThemeColor} size="small" style={[loadingIndicatorStyle, sharedStyles.absolute, { top: 12 }]} />}
                    </View>
                    {!isSimpleView ? [scheduleAction, nameView] : null}
                  </View>
                  {isSimpleView ? [scheduleAction, nameView] : null}
                </View>
              )}
            </>
          )
        }}
      </Mutation>
    )
  },
  { forwardRef: true },
)
