import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useMutation } from 'react-apollo'
import { TouchableOpacity, View } from 'react-native'
import Collapsible from 'react-native-collapsible'
import { Divider } from 'react-native-elements'
import { SAVE_DEVICE_NAME, SAVE_SHARED_SCOPE_ATTRIBUTE } from '../../graphql/gqls'
import { useReduxState } from '../../hooks/use-redux-state'
import * as selectors from '../../redux/selectors'
import { FIRST_TIME_CONNECT } from '../../stores/CurrentDeviceStore'
import { DEVICES_CONFIG, openAppSMS } from '../../stores/DeviceConfig'
import { RootStoreContext } from '../../stores/RootStore'
import { FLASH_MESSAGE_SCREEN_REF } from '../../stores/UIReferenceStore'
import { sharedStyles } from '../../styles/shared'
import { contentPadding, scaleFactor } from '../../styles/variables'
import { getMessageError } from '../../utils/helpers/shared'
import { InputConfigRow } from '../columns/InputConfigRow'
import { IconButton } from '../common/IconButton'
import { Spacer } from '../common/Spacer'
import { useTheme } from '../context/ThemeContext'
import { ThemedIcon } from '../themed/ThemedIcon'
import { ThemedText } from '../themed/ThemedText'
import { ThemedTextInput } from '../themed/ThemedTextInput'
import { UserListShareDevice } from '../views/UserListShareDevice'
import { OverlayModal } from '../widgets/OverlayModal'

interface Props {
  icon?: ReactNode
}

export const UserApproveAttribute: FC<Props> = observer(({ icon }) => {
  const rootStore = useContext(RootStoreContext)
  const [saveVersion, { loading }] = useMutation(SAVE_SHARED_SCOPE_ATTRIBUTE)
  const [saveDeviceName, { loading: loadingDeviceName }] = useMutation(SAVE_DEVICE_NAME)
  const customerId = useReduxState(selectors.currentCustomerIdSelector)

  const deviceId = rootStore.currentDeviceStore.getDeviceId()
  const { value, firstTimeConnect, currentFwVer, targetFwVer, userApproveFwVer } = {
    value: rootStore.currentDeviceStore.state.device,
    firstTimeConnect: rootStore.deviceListStore.state[`${FIRST_TIME_CONNECT}_${deviceId}`],
    currentFwVer: rootStore.currentDeviceStore.getCurrentFwVer(),
    targetFwVer: rootStore.currentDeviceStore.getTargetFwVer(),
    userApproveFwVer: rootStore.currentDeviceStore.getUserApproveFwVer(),
  }

  const isUserShare = customerId && value?.customerId?.id && customerId !== value.customerId.id
  const theme = useTheme()
  const [text, setText] = useState<string | undefined>('')
  const [editDeviceName, setEditDeviceName] = useState<boolean>(true)
  const [visible, setVisible] = useState<boolean>(false)

  useEffect(() => {
    if (value) {
      setText(value?.label)
    }
  }, [value])

  const isSMSTransport = value?.type && DEVICES_CONFIG[value.type]?.isSMSTransport
  const enableShareDevice = deviceId && !isSMSTransport

  const onUpdateItem = useCallback(
    (dcvName: string) => {
      saveDeviceName({
        variables: {
          deviceId,
          input: {
            label: dcvName,
          },
        },
      })
        .then(() => {
          rootStore.uiReferenceStore.success(`Cập nhật tên thành công`, FLASH_MESSAGE_SCREEN_REF.MODAL)
          rootStore.currentDeviceStore.saveDeviceName(dcvName)
          if (value?.label) {
            const { isSMSTransport: sms } = DEVICES_CONFIG[value?.type || ''] || {}
            if (sms) {
              openAppSMS(value?.label, 'DK')
            }
          }
        })
        .catch((error) => {
          rootStore.uiReferenceStore.danger(`${getMessageError(error)}`, FLASH_MESSAGE_SCREEN_REF.MODAL)
        })
    },
    [value],
  )

  const firstTimeConnectDate = useMemo(() => {
    if (firstTimeConnect) {
      const mm = moment(firstTimeConnect)
      if (mm.isValid()) {
        return mm
      }
    }
    return null
  }, [firstTimeConnect])

  let renderFwVer: string = targetFwVer
  const fwStateIsSynced = !targetFwVer || targetFwVer === currentFwVer
  if (fwStateIsSynced && currentFwVer) {
    renderFwVer = 'Đã cập nhật'
  } else if (targetFwVer && targetFwVer === userApproveFwVer) {
    renderFwVer = `Đang cập nhật - ${targetFwVer}`
  }

  const handleUpdateVersion = async () => {
    if (targetFwVer) {
      saveVersion({
        variables: {
          deviceId,
          input: {
            userApproveFwVer: targetFwVer,
          },
        },
      })
        .then((response) => {
          if (response.data && response.data.saveAttribute) {
            rootStore.uiReferenceStore.success(`Đã thiết lập\nThiết bị sẽ nâng cấp khi sẵn sàng`, FLASH_MESSAGE_SCREEN_REF.MODAL)
            rootStore.currentDeviceStore.setUserApproveFwVer(targetFwVer)
            setVisible(false)
          } else {
            rootStore.uiReferenceStore.danger('Có lỗi xảy ra vui lòng thử lại sau!', FLASH_MESSAGE_SCREEN_REF.MODAL)
          }
        })
        .catch((error) => {
          rootStore.uiReferenceStore.danger(`${getMessageError(error)}`, FLASH_MESSAGE_SCREEN_REF.MODAL)
        })
    } else {
      rootStore.uiReferenceStore.danger(`Không tìm thấy phiên bản mới nhất`, FLASH_MESSAGE_SCREEN_REF.MODAL)
    }
  }

  return (
    <>
      <TouchableOpacity style={[sharedStyles.horizontal, sharedStyles.justifyContentSpaceBetween]} disabled={true}>
        <InputConfigRow subject={`THÔNG TIN THIẾT BỊ`} tooltip={`Thông tin thiết bị`} inline={false} />
        {icon ? isUserShare ? null : icon : <ThemedIcon family="octicon" name={'chevron-down'} size={16 * scaleFactor} color={'foregroundColorMuted65'} />}
      </TouchableOpacity>
      <Spacer height={contentPadding / 2} />
      <View
        style={{
          borderBottomWidth: 1,
          borderBottomColor: theme.primaryBackgroundColor,
        }}
      />
      <Spacer height={contentPadding} />
      <View>
        <BasicView title={'Định danh'} content={`${value?.name}`} />
        <CommonView
          color={'foregroundColor'}
          content={`${value?.label ? value?.label : '--'}`}
          title={
            <TouchableOpacity onPress={() => setEditDeviceName(!editDeviceName)}>
              <View style={[sharedStyles.horizontal, sharedStyles.justifyContentCenter, sharedStyles.alignSelfCenter]}>
                <ThemedText color="foregroundColorMuted65">{`Tên thiết bị`}</ThemedText>
                <IconButton
                  style={[sharedStyles.marginHorizontalHalf, { marginTop: -4 }]}
                  key="edit-abletext"
                  analyticsLabel="remove_column"
                  family="material"
                  name={editDeviceName ? 'edit' : 'save'}
                  disabled={editDeviceName || loadingDeviceName}
                  onPress={() => onUpdateItem(text as string)}
                />
              </View>
            </TouchableOpacity>
          }
          subElement={
            <Collapsible collapsed={editDeviceName}>
              <ThemedTextInput textInputKey="enter-name-device" placeholder="Nhập Định danh thiết bị" value={text || ''} autoCapitalize="none" onChangeText={(newText: string) => setText(newText)} />
            </Collapsible>
          }
        />
        <BasicView title={'Loại thiết bị'} content={`${value?.type}`} />
        {isSMSTransport ? null : (
          <>
            <CommonView
              color={theme.blue}
              content={renderFwVer}
              title={`Phiên bản hiện tại: ${currentFwVer}`}
              onPress={() => {
                if (!fwStateIsSynced && targetFwVer !== userApproveFwVer && targetFwVer) {
                  setVisible(true)
                }
              }}
            />
            <BasicView title={'Kích hoạt bảo hành'} content={`${firstTimeConnectDate ? firstTimeConnectDate.format('DD-MM-YYYY') : 'chưa kích hoạt'}`} />
            <BasicView title={'Hết hạn bảo hành'} content={`${firstTimeConnectDate ? moment(firstTimeConnectDate).add(1, 'years').format('DD-MM-YYYY') : '--'}`} />
          </>
        )}
        <Divider style={[sharedStyles.marginVerticalQuarter]} color={theme.primaryBackgroundColor} orientation="horizontal" width={1} />
        {enableShareDevice ? <UserListShareDevice deviceId={deviceId} currentCustomerId={customerId} /> : null}
        <OverlayModal
          loading={loading}
          title={`Cập nhật phiên bản ${targetFwVer}`}
          children={<ThemedText color={'#000000'}>{`Tiến hành nâng cấp phiên bản cho thiết bị không?`}</ThemedText>}
          visible={visible}
          setVisible={setVisible}
          onClickButtonRight={handleUpdateVersion}
        />
      </View>
    </>
  )
})

const CommonView = ({ title, color, subElement = null, content, onPress }: { title: string | React.ReactElement; color: string; subElement?: React.ReactElement | null; content: React.ReactElement | string; onPress?: () => any }) => (
  <View style={[sharedStyles.vertical, sharedStyles.marginVerticalQuarter]}>
    <View style={[sharedStyles.horizontal, sharedStyles.justifyContentSpaceBetween]}>
      <ThemedText color="foregroundColorMuted65">{title}</ThemedText>
      <ThemedText color={color} onPress={() => (onPress ? onPress() : null)}>
        {content}
      </ThemedText>
    </View>
    <Spacer height={contentPadding / 2} />
    {subElement}
  </View>
)

const BasicView = ({ title, content }: { title: string; content: string }) => (
  <View style={[sharedStyles.horizontal, sharedStyles.justifyContentSpaceBetween, sharedStyles.marginVerticalQuarter]}>
    <ThemedText color="foregroundColorMuted65">{title}</ThemedText>
    <ThemedText color="foregroundColor" style={{ textAlign: 'right' }}>
      {content}
    </ThemedText>
  </View>
)
