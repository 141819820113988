import { Theme } from '@devhub/core'
import { StyleSheet } from 'react-native'
import { Platform } from '../libs/platform'
import { contentPadding, mutedOpacity, scaleFactor, smallerTextSize, superMutedOpacity, xxSmallerTextSize } from './variables'

export const sharedStyles = StyleSheet.create({
  absolute: { position: 'absolute' },
  absoluteFill: StyleSheet.absoluteFillObject,
  relative: { position: 'relative' },
  flex: { flex: 1 },
  flexGrow: { flexGrow: 1 },
  flexGrowMore: { flexGrow: 4 },
  flexNoGrow: { flexGrow: 0 },
  flexWrap: { flexWrap: 'wrap' },
  flexNoWrap: { flexWrap: 'nowrap' },
  flexShrink0: { flexShrink: 0 },
  flexShrink1: { flexShrink: 1 },
  horizontal: { flexDirection: 'row' },
  horizontalReverse: { flexDirection: 'row-reverse' },
  vertical: { flexDirection: 'column' },
  verticalReverse: { flexDirection: 'column-reverse' },
  alignItemsCenter: { alignItems: 'center' },
  alignItemsFlexEnd: { alignItems: 'flex-end' },
  alignItemsFlexStart: { alignItems: 'flex-start' },
  alignSelfCenter: { alignSelf: 'center' },
  alignSelfFlexEnd: { alignSelf: 'flex-end' },
  alignSelfFlexStart: { alignSelf: 'flex-start' },
  alignSelfStretch: { alignSelf: 'stretch' },
  displayFlex: { display: 'flex' },
  displayNone: { display: 'none' },
  fullHeight: { height: '100%' },
  fullMinWidth: { minWidth: '100%' },
  fullMaxWidth: { maxWidth: '100%' },
  fullWidth: { width: '100%' },
  width70: { width: '70%' },
  justifyContentCenter: { justifyContent: 'center' },
  justifyContentFlexEnd: { justifyContent: 'flex-end' },
  justifyContentFlexStart: { justifyContent: 'flex-start' },
  justifyContentSpaceBetween: { justifyContent: 'space-between' },
  justifyContentSpaceEvenly: { justifyContent: 'space-evenly' },
  margin: { margin: contentPadding },
  marginHalf: { margin: contentPadding / 2 },
  marginHorizontal: { marginHorizontal: contentPadding },
  marginHorizontalHalf: { marginHorizontal: contentPadding / 2 },
  marginHorizontalQuarter: { marginHorizontal: contentPadding / 4 },
  marginVertical: { marginVertical: contentPadding },
  marginVerticalHalf: { marginVertical: contentPadding / 2 },
  marginVerticalQuarter: { marginVertical: contentPadding / 4 },
  muted: { opacity: mutedOpacity },
  superMuted: { opacity: superMutedOpacity },
  opacity0: { opacity: 0 },
  opacity100: { opacity: 1 },
  overflowHidden: { overflow: 'hidden' },
  overflowVisible: { overflow: 'visible' },
  padding: { padding: contentPadding },
  paddingHalf: { padding: contentPadding / 2 },
  paddingQuarter: { padding: contentPadding / 4 },
  paddingHorizontal: { paddingHorizontal: contentPadding },
  paddingHorizontalHalf: { paddingHorizontal: contentPadding / 2 },
  paddingHorizontalQuarter: { paddingHorizontal: contentPadding / 4 },
  paddingHorizontalNone: { paddingHorizontal: 0 },
  paddingVertical: { paddingVertical: contentPadding },
  paddingVerticalHalf: { paddingVertical: contentPadding / 2 },
  paddingVerticalQuarter: { paddingVertical: contentPadding / 4 },
  paddingVerticalNone: { paddingVertical: 0 },
  marginHorizontalNone: { marginHorizontal: 0 },
  textCenter: { textAlign: 'center' },
  textLeft: { textAlign: 'left' },
  textRight: { textAlign: 'right' },
  directionRtl: { direction: 'rtl' },

  columnMaxWidth: { maxWidth: 400 },

  center: {
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },

  horizontalAndVerticallyAligned: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
  },

  loadingIndicatorStyle: {
    transform: [{ scale: 0.8 }],
  },

  toggle: {
    margin: 2,
  },
  toggleControlContainer: {
    borderRadius: 4,
    margin: 1,
    padding: 2,
  },
  picker: {
    borderRadius: 4,
    padding: 5,
  },
  tabs: {
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#93a1b9',
  },
  tab: {
    padding: 5,
    borderRightColor: '#93a1b9',
  },
  statusModel: {
    marginLeft: 135,
  },
  icon: {
    position: 'relative',
    top: 4,
    left: 5,
    width: 17,
    height: 17,
  },
  normalIcon: {
    position: 'relative',
    width: 17,
    height: 17,
  },
  container: {
    flex: 1,
    alignSelf: 'center',
    maxWidth: 430,
    width: '100%',
  },

  contentContainer: {
    flex: 1,
    alignItems: 'stretch',
    alignSelf: 'center',
    justifyContent: 'center',
    padding: contentPadding,
  },

  header: {
    alignItems: 'center',
    justifyContent: 'center',
  },

  mainContentContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },

  footer: {
    alignItems: 'center',
    justifyContent: 'center',
  },

  logo: {
    alignSelf: 'center',
    height: 80,
    marginBottom: contentPadding / 2,
    width: 80,
  },

  logoSidebar: {
    alignSelf: 'center',
    height: 20,
    marginBottom: contentPadding / 2,
    width: 20,
    borderRadius: 50,
  },

  title: {
    fontSize: 30,
    fontWeight: 'bold',
    lineHeight: 36,
    textAlign: 'center',
  },

  textSmall: {
    fontSize: xxSmallerTextSize,
  },

  textMedium: {
    fontSize: smallerTextSize,
  },

  subtitle: {
    fontSize: 15,
    fontWeight: '300',
    lineHeight: 20,
    textAlign: 'center',
  },

  button: {
    alignSelf: 'stretch',
    marginTop: contentPadding / 2,
  },

  footerLink: {},

  footerLinkText: {
    fontSize: 14,
    lineHeight: 18,
    textAlign: 'center',
  },

  footerSeparatorText: {
    paddingHorizontal: contentPadding / 2,
    fontStyle: 'italic',
  },
  gridView: {
    marginTop: 20,
    flex: 1,
  },
  itemContainer: {
    justifyContent: 'flex-end',
    borderRadius: 5,
    padding: 10,
    height: 150,
  },
  controlContainer: {
    borderRadius: 5,
  },
  itemCode: {
    fontWeight: '600',
    fontSize: 12,
    color: '#fff',
  },
  itemCodeList: {
    position: 'absolute',
    left: 25,
    height: 50,
    maxWidth: 185,
    marginVertical: contentPadding / 2,
  },
  cardIconListView: {
    width: 55,
    height: 52,
    position: 'absolute',
    left: 5,
  },
  cardViewList: {
    marginRight: 15,
    flexDirection: 'row-reverse',
  },
  boderItemCode: {
    borderWidth: 1,
    padding: 5,
    borderRadius: 5,
  },
  borderIconPower: {
    borderWidth: 3,
    borderRadius: 5,
  },
  borderFrame: {
    borderRadius: 5,
  },
  sectionHeader: {
    flex: 1,
    fontSize: 15,
    fontWeight: '600',
    alignItems: 'center',
    backgroundColor: '#636e72',
    color: 'white',
    padding: 10,
  },
  toggleInputPicker: {
    marginTop: 0,
    marginLeft: 5,
  },
  cardContainer: {
    flex: 1,
    justifyContent: 'center',
    padding: 20,
    backgroundColor: '#fff',
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  advanceContainer: {
    paddingVertical: contentPadding,
    paddingHorizontal: contentPadding / 4,
    borderColor: '#50A4BC',
    borderRadius: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 1,
      height: 3,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  box: {
    borderColor: '#50A4BC',
    borderRadius: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 1,
      height: 3,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  backgroundHeader: {
    backgroundColor: '#e8e8e8',
  },
  customBackgroundHeader: {
    backgroundColor: '#636e72',
  },
  btnSave: {
    minWidth: 150,
  },
  device: {
    marginTop: 30,
    width: '30%',
    borderWidth: 2,
    padding: 20,
    borderRadius: 8,
  },
  borderSteps: {
    borderTopWidth: 2,
    top: 17,
    width: '18%',
  },
  resultSteps: {
    marginTop: 10,
  },
  iotlogo: {
    marginTop: 10,
    alignSelf: 'center',
    height: 110 * scaleFactor,
    marginBottom: contentPadding / 2,
    width: 110 * scaleFactor,
  },
  rssiLength: {
    width: 25,
    height: 25,
    marginTop: 4,
  },
  sectionAccordion: {
    borderWidth: 1,
    borderColor: '#49d3b4',
    borderRadius: 8,
    margin: 2,
    padding: 5,
  },
  bodySchedule: {
    borderWidth: 1,
    borderColor: '#2089dc',
    borderRadius: 8,
  },
})

export const webSharedStyles = StyleSheet.create({
  // @ts-ignore
  wrapText: Platform.OS === 'web' ? { 'white-space': 'break-spaces' } : {},
})

export const getActiveBackGround = (active: boolean, activeColor?: string) => ({
  backgroundColor: active ? activeColor || '#50A4BC' : '#7f8c8d',
})

export const getCommonBorder = (active: boolean, activeColor?: string) => ({
  borderWidth: 3,
  borderRadius: 2,
  borderColor: active ? activeColor || '#50A4BC' : '#7f8c8d',
  margin: 2,
})

export const getActiveColor = (active: boolean, alpha: string = 'FF') => (active ? `#50A4BC${alpha}` : `#7f8c8d${alpha}`)

export const getColorTextActive = (activeState: boolean, theme: Theme, activeColor?: string) => {
  return activeState ? activeColor || theme.primaryBackgroundColor : theme.gray
}

export const singleCellStyle = { flex: 1 }
export const widenCellStyle = { flex: 2 }
export const moreWidenCellStyle = { flex: 3 }
export const extendWidenCellStyle = { flex: 4 }

export const commonCardStyles = [
  sharedStyles.flex,
  sharedStyles.borderFrame,
  sharedStyles.paddingHorizontalHalf,
  sharedStyles.justifyContentSpaceBetween,
  sharedStyles.alignItemsCenter,
  sharedStyles.horizontal,
  {
    backgroundColor: getActiveColor(false, '66'),
    borderColor: getActiveColor(false),
    minHeight: 60,
  },
]
