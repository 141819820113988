import { Toggle } from '@ui-kitten/components'
import React, { useEffect, useMemo, useState } from 'react'
import { View } from 'react-native'
import { TimeKeyboardModal, useSwitchColors } from 'react-native-paper-time-duration'
import { getColorTextActive, sharedStyles } from '../../styles/shared'
import { TouchableOpacity } from '../common/TouchableOpacity'
import { useTheme } from '../context/ThemeContext'
import { ThemedText } from '../themed/ThemedText'

export interface IInputTimeCoupleBase {
  duration?: number
  afterSecond?: number
  turnOnTime?: number
  turnOffTime?: number
}

export interface IInputTimeCoupleModal {
  children?: React.ReactNode
  duration?: number
  afterSecond?: number
  enableCycle?: boolean
  onCycle?: number
  offCycle?: number
  onConfirm: (base: IInputTimeCoupleBase) => any
  [key: string]: any
}

export enum TimeInputUnit {
  SECOND,
  MINUTE,
  HOUR,
}

export const NumberUnit = Object.keys(TimeInputUnit).length / 2

export const TimeUnit = {
  [TimeInputUnit.SECOND]: { text: 'Giây', value: 1, max: 86400 },
  [TimeInputUnit.MINUTE]: { text: 'Phút', value: 60, max: 1440 },
  [TimeInputUnit.HOUR]: { text: 'Giờ', value: 3600, max: 24 },
}

export const getSecondValue = (timeValue: number, unit: TimeInputUnit) => timeValue * TimeUnit[unit].value

export const getUnitByDuration = (duration: number) => {
  if (duration < 60) return TimeInputUnit.SECOND
  if (duration % 3600 === 0) return TimeInputUnit.HOUR
  if (duration % 60 === 0) return TimeInputUnit.MINUTE
  return TimeInputUnit.SECOND
}

export const InputTimeCoupleModal = React.memo(({ onConfirm, onDismiss, duration, afterSecond, showPicker = false, enableCycle = false, onCycle, offCycle, children, ...others }: IInputTimeCoupleModal) => {
  const theme = useTheme()
  const { color } = useSwitchColors(true)
  const [visible, setVisible] = useState(false)
  const [useCycle, setUseCycle] = useState(onCycle !== undefined && offCycle !== undefined)
  const [calculatedDuration, setCalculatedDuration] = useState<number | undefined>(duration)
  const [calculatedAfterSecond, setCalculatedAfterSecond] = useState<number | undefined>(afterSecond)
  const [calculatedOnCycle, setCalculatedOnCycle] = useState<number | undefined>(onCycle)
  const [calculatedOffCycle, setCalculatedOffCycle] = useState<number | undefined>(offCycle)

  const [firstInputUnit, setFirstInputUnit] = useState(TimeInputUnit.SECOND)
  const [secondInputUnit, setSecondInputUnit] = useState(TimeInputUnit.SECOND)
  const [thirdInputUnit, setThirdInputUnit] = useState(TimeInputUnit.SECOND)
  const [forthInputUnit, setForthInputUnit] = useState(TimeInputUnit.SECOND)

  useEffect(() => setVisible(showPicker), [showPicker])

  useEffect(() => {
    if (enableCycle && visible) {
      setUseCycle(onCycle !== undefined && offCycle !== undefined)
    }
  }, [enableCycle, visible])

  useEffect(() => {
    if (duration !== undefined) {
      const durationUnit = getUnitByDuration(duration)
      setSecondInputUnit(durationUnit)
      setCalculatedDuration(duration / TimeUnit[durationUnit].value)
    } else {
      setCalculatedDuration(undefined)
    }
  }, [duration])

  useEffect(() => {
    if (afterSecond !== undefined) {
      const afterSecondUnit = getUnitByDuration(afterSecond)
      setFirstInputUnit(afterSecondUnit)
      setCalculatedAfterSecond(afterSecond / TimeUnit[afterSecondUnit].value)
    } else {
      setCalculatedAfterSecond(undefined)
    }
  }, [afterSecond])

  useEffect(() => {
    if (onCycle !== undefined) {
      const onCycleUnit = getUnitByDuration(onCycle)
      setThirdInputUnit(onCycleUnit)
      setCalculatedOnCycle(onCycle / TimeUnit[onCycleUnit].value)
    }
  }, [onCycle])

  useEffect(() => {
    if (offCycle !== undefined) {
      const offCycleUnit = getUnitByDuration(offCycle)
      setForthInputUnit(offCycleUnit)
      setCalculatedOffCycle(offCycle / TimeUnit[offCycleUnit].value)
    }
  }, [offCycle])

  const anchor = children ? <TouchableOpacity onPress={() => setVisible(true)}>{children}</TouchableOpacity> : null

  const footerLeft = useMemo(
    () =>
      enableCycle ? (
        <View style={[sharedStyles.vertical]}>
          <Toggle
            style={[sharedStyles.toggle]}
            status="primary"
            checked={useCycle}
            onChange={(on) => {
              setUseCycle(on)
              if (on && calculatedOnCycle === undefined) {
                setCalculatedOnCycle(60)
                setCalculatedOffCycle(60)
              }
            }}
          />
          <ThemedText style={{ marginLeft: 4 }} color={getColorTextActive(useCycle, theme, '#6200ee')}>{`Chu kỳ`}</ThemedText>
        </View>
      ) : null,
    [enableCycle, useCycle],
  )
  return (
    <>
      {anchor}
      <TimeKeyboardModal
        locale={'vi'}
        visible={visible}
        cancelLabel="Hủy"
        confirmLabel="Ok"
        textDurationDown={
          <ThemedText color={color} onPress={() => setSecondInputUnit((secondInputUnit + 1) % NumberUnit)}>
            {TimeUnit[secondInputUnit].text}
          </ThemedText>
        }
        textAfterSecondDown={
          <ThemedText color={color} onPress={() => setFirstInputUnit((firstInputUnit + 1) % NumberUnit)}>
            {TimeUnit[firstInputUnit].text}
          </ThemedText>
        }
        duration={calculatedDuration}
        afterSecond={calculatedAfterSecond}
        maxAfterSeconds={TimeUnit[firstInputUnit].max}
        maxDuration={TimeUnit[secondInputUnit].max}
        minAfterSeconds={0}
        minDuration={0}
        textTurnOnTimeUp={'Bật'}
        textTurnOnTimeDown={
          <ThemedText color={color} onPress={() => setThirdInputUnit((thirdInputUnit + 1) % NumberUnit)}>
            {TimeUnit[thirdInputUnit].text}
          </ThemedText>
        }
        textTurnOffTimeUp={'Tắt'}
        textTurnOffTimeDown={
          <ThemedText color={color} onPress={() => setForthInputUnit((forthInputUnit + 1) % NumberUnit)}>
            {TimeUnit[forthInputUnit].text}
          </ThemedText>
        }
        turnOnTime={useCycle ? calculatedOnCycle : undefined}
        turnOffTime={useCycle ? calculatedOffCycle : undefined}
        footerLeft={footerLeft}
        onDismiss={() => {
          setVisible(false)
          onDismiss()
        }}
        onConfirm={({ duration: gotDuration, afterSecond: gotAfterSecond, turnOnTime: onTime, turnOffTime: offTime }) => {
          setVisible(false)
          onConfirm({
            duration: Math.floor(getSecondValue(gotDuration, secondInputUnit)),
            afterSecond: Math.floor(getSecondValue(gotAfterSecond, firstInputUnit)),
            turnOnTime: useCycle && onTime !== undefined ? Math.floor(getSecondValue(onTime, thirdInputUnit)) : undefined,
            turnOffTime: useCycle && offTime !== undefined ? Math.floor(getSecondValue(offTime, forthInputUnit)) : undefined,
          })
        }}
        {...others}
      />
    </>
  )
})
