import { Button, Icon, MenuItem, OverflowMenu, Text } from '@ui-kitten/components'
import { IndexPath } from '@ui-kitten/components/devsupport'
import React, { useCallback, useEffect, useState } from 'react'
import { sharedStyles } from '../../styles/shared'
import { foregroundThemeColor, loadingIndicatorStyle } from '../common/Button'
import { ThemedActivityIndicator } from '../themed/ThemedActivityIndicator'

export interface IMenuItemData {
  title: string
  [key: string]: any
}

export interface SimpleMenuProps {
  items: IMenuItemData[]
  disableItem?: (item: IMenuItemData) => boolean
  onSelectItem?: (itemSelect: any, selectedIndex: number) => void
  defaultValue?: number
  loading?: boolean
}

const LoadingIndicator = () => <ThemedActivityIndicator color={foregroundThemeColor} size="small" style={loadingIndicatorStyle} />

export const MenuIcon = (props: any) => <Icon {...props} name="menu-2-outline" />

export const SimpleMenu = React.memo(({ onSelectItem, items, disableItem, defaultValue, loading }: SimpleMenuProps) => {
  const [selectedIndex, setSelectedIndex] = useState<IndexPath>(new IndexPath(defaultValue || 0))
  const [visible, setVisible] = useState(false)
  useEffect(() => setSelectedIndex(new IndexPath(defaultValue || 0)), [defaultValue])

  const onItemSelect = useCallback(
    (index: IndexPath) => {
      setSelectedIndex(index)
      setVisible(false)
      if (onSelectItem) {
        onSelectItem(items[index.row], index.row)
      }
    },
    [onSelectItem],
  )

  const renderToggleButton = useCallback(() => {
    const row = selectedIndex.row
    const accessoryRight = loading ? LoadingIndicator : MenuIcon
    return (
      <Button appearance={`ghost`} status="primary" onPress={() => setVisible(true)} accessoryRight={accessoryRight} style={[sharedStyles.paddingHorizontalNone, sharedStyles.paddingVerticalNone]}>
        {(evaProps) => (
          <Text {...evaProps} style={[evaProps?.style, sharedStyles.marginHorizontalNone]}>
            {row >= 0 && items[row] ? items[row]?.title : 'hãy chọn ...'}
          </Text>
        )}
      </Button>
    )
  }, [selectedIndex, items, loading])

  return (
    <OverflowMenu anchor={renderToggleButton} visible={visible} selectedIndex={selectedIndex} onSelect={onItemSelect} onBackdropPress={() => setVisible(false)}>
      {items.map((item, iIndex) => (
        <MenuItem key={`menuitem-${iIndex}`} title={item.title} disabled={disableItem ? disableItem(item) : false} />
      ))}
    </OverflowMenu>
  )
})
