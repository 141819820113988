import { ViewPager } from '@ui-kitten/components'
import React, { useRef, useState } from 'react'
import { View } from 'react-native'
import { Button, Tab } from 'react-native-elements'
import { sharedStyles } from '../../styles/shared'
import { TimeSeriesList, TimeSeriesListRef } from './TimeSeriesList'

export interface IHistoryPage {
  title: string
  refreshing: boolean | undefined
  onRefresh: () => any
}

export interface IBaseHistoryPages {
  renderItem: any
  pages: IHistoryPage[]
}

export const BaseHistoryPages = React.memo(({ renderItem, pages }: IBaseHistoryPages) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const itemsRef = useRef<Array<TimeSeriesListRef | null>>([])

  return pages.length > 0 ? (
    <>
      {/* @ts-ignore */}
      <Tab key={`notification-tab`}>
        <View style={[sharedStyles.flex, sharedStyles.alignItemsCenter, sharedStyles.horizontal, sharedStyles.justifyContentCenter]}>
          {pages.map((page, index) => (
            <Button
              key={`button-history-page-${index}`}
              title={page.title}
              type={selectedIndex === index ? 'solid' : `clear`}
              onPress={() => {
                if (itemsRef.current && selectedIndex !== index) itemsRef.current[index]?.refreshIfEmpty()
                setSelectedIndex(index)
              }}
            />
          ))}
        </View>
      </Tab>
      <ViewPager swipeEnabled={false} selectedIndex={selectedIndex}>
        {pages.map((page, index) => (
          <TimeSeriesList ref={(ele) => (itemsRef.current[index] = ele)} key={`timeserie-history-page-${index}`} refreshing={page.refreshing || false} onRefresh={page.onRefresh} renderItem={renderItem} />
        ))}
      </ViewPager>
    </>
  ) : null
})
