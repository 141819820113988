import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import Collapsible from 'react-native-collapsible'
import { DataTable } from 'react-native-paper'
import { moreWidenCellStyle, sharedStyles, webSharedStyles, widenCellStyle } from '../../styles/shared'
import { useTheme } from '../context/ThemeContext'
import { ThemedText } from '../themed/ThemedText'
import { SimpleMenu, SimpleMenuProps } from '../views/SimpleMenu'
import { ThemedTextInputWithIcon } from './ThemedTextInputWithIcon'

export const OutputConfigRow = React.memo(
  ({
    title,
    isVanType,
    onUpdate,
    outputTypesProps,
    defaultInputText,
    exOutputTypesProps,
  }: {
    title: string
    isVanType: boolean
    onUpdate: (updateText: string) => any
    outputTypesProps: SimpleMenuProps
    defaultInputText?: string
    exOutputTypesProps?: SimpleMenuProps
  }) => {
    const theme = useTheme()
    const [expanded, setExpanded] = useState<boolean>(false)
    const [currentText, setCurrentText] = useState<string>(defaultInputText || title)
    useEffect(() => setCurrentText(defaultInputText || title), [defaultInputText, title])

    return (
      <View style={[sharedStyles.vertical]}>
        <Collapsible collapsed={!expanded}>
          <DataTable.Row>
            <ThemedTextInputWithIcon
              textInputKey="OutputConfigRow-output-input-key"
              iconName={'save'}
              family={'material'}
              value={currentText}
              style={[sharedStyles.fullWidth]}
              autoCapitalize="none"
              onChangeText={(text: string) => setCurrentText(text)}
              onClick={() => {
                onUpdate(currentText)
                setExpanded(false)
              }}
            />
          </DataTable.Row>
        </Collapsible>
        <DataTable.Row>
          <View style={[moreWidenCellStyle, sharedStyles.justifyContentCenter]}>
            <ThemedText color={theme.foregroundColor} numberOfLines={2} ellipsizeMode="tail" style={[sharedStyles.flexWrap, webSharedStyles.wrapText]} onPress={() => setExpanded((pre) => !pre)}>
              {title}
            </ThemedText>
          </View>
          <View style={[widenCellStyle]}>
            <SimpleMenu items={outputTypesProps.items} defaultValue={outputTypesProps.defaultValue} onSelectItem={outputTypesProps.onSelectItem} />
          </View>
          {exOutputTypesProps ? <View style={[widenCellStyle]}>{isVanType ? <SimpleMenu items={exOutputTypesProps.items} defaultValue={exOutputTypesProps.defaultValue} onSelectItem={exOutputTypesProps.onSelectItem} /> : null}</View> : null}
        </DataTable.Row>
      </View>
    )
  },
)
