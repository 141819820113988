import { useEffect, useState } from 'react'
import { Keyboard } from 'react-native'

export type KeyboardVisibility = 'appearing' | 'visible' | 'hiding' | 'hidden'

export function useKeyboardVisibility() {
  const [visibility, setVisibility] = useState<KeyboardVisibility>('hidden')

  useEffect(() => {
    const handleWillShow = () => {
      setVisibility('appearing')
    }

    const handleDidShow = () => {
      setVisibility('visible')
    }

    const handleWillHide = () => {
      setVisibility('hiding')
    }

    const handleDidHide = () => {
      setVisibility('hidden')
    }

    const keyboardWillShow = Keyboard.addListener('keyboardWillShow', handleWillShow)
    const keyboardDidShow = Keyboard.addListener('keyboardDidShow', handleDidShow)
    const keyboardWillHide = Keyboard.addListener('keyboardWillHide', handleWillHide)
    const keyboardDidHide = Keyboard.addListener('keyboardDidHide', handleDidHide)

    return () => {
      keyboardWillShow.remove()
      keyboardDidShow.remove()
      keyboardWillHide.remove()
      keyboardDidHide.remove()
    }
  }, [visibility])

  return visibility
}
