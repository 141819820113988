import { ViewPager } from '@ui-kitten/components'
import { get } from 'dottie'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { View } from 'react-native'
import { Button, Tab } from 'react-native-elements'
import { useDimensions } from '../../hooks/use-dimensions'
import { RPC_RESULT_SET_STATUS } from '../../stores/CurrentDeviceStore'
import { DEVICES_CONFIG, IOutputConfigs, isTurnOn, openAppSMS, OUTPUT_CONFIG, OUTPUT_CONFIG_MODEL, OUTPUT_CONFIG_NAMES, OUTPUT_TYPE } from '../../stores/DeviceConfig'
import { RootStoreContext } from '../../stores/RootStore'
import { groupOutputsByDependence, IOutputConfigItem, IOutputConfigViewProps } from '../../stores/StoreUtils'
import { sharedStyles } from '../../styles/shared'
import { convertText2StartStop, findInvalidStartStopIndex } from '../../utils/helpers/shared'
import { useTheme } from '../context/ThemeContext'
import { BaseMultiPortPanel } from '../elements/BaseMultiPortPanel'
import { ThemedText } from '../themed/ThemedText'
import { OverlayModal } from '../widgets/OverlayModal'
import { ControlPanel } from './ControlPanel'
import { IOnOffControlRef } from './OnOffControlCardView'
import { ScheduleForSingleOutput, ScheduleForSingleOutputRef } from './ScheduleForSingleOutput'

export const MultiPortPanel = React.memo(
  observer(({ deviceId }: { deviceId: string }) => {
    const rootStore = useContext(RootStoreContext)
    const theme = useTheme()
    const dimensions = useDimensions()
    const configs = rootStore.deviceListStore.state[`${OUTPUT_CONFIG}_${deviceId}`]
    const names = rootStore.deviceListStore.state[`${OUTPUT_CONFIG_NAMES}_${deviceId}`]
    const outputModel = rootStore.deviceListStore.state[`${OUTPUT_CONFIG_MODEL}_${deviceId}`]
    const device = rootStore.currentDeviceStore.state.device
    const deviceType = device?.type
    const { isSMSTransport, buildOnOffMessage, validateStartStop, buildScheduleMessage } = DEVICES_CONFIG[deviceType || ''] || {}

    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const [visible, setVisible] = useState<boolean>(false)
    const [warningText, setWarningText] = useState<string>('Kênh này được thiết lập ràng buộc với các kênh khác. Bạn muốn thao tác chỉ áp dụng cho duy nhất kênh này?')
    const [callback, setCallback] = useState<() => () => void>()
    const [sections, setSections] = useState<IOutputConfigItem[]>([])
    const itemsRef = useRef<Array<ScheduleForSingleOutputRef | null>>([])
    const onOffRef = useRef<Array<IOnOffControlRef | null>>([])

    useEffect(() => {
      itemsRef.current = itemsRef.current.slice(0, sections.length)
      onOffRef.current = onOffRef.current.slice(0, sections.length)
    }, [sections])

    useEffect(() => {
      if (configs) {
        const configsState: IOutputConfigs = { ...configs }
        const groupedPorts = groupOutputsByDependence(configsState, names, outputModel, isSMSTransport)
        setSections(groupedPorts)
      }
    }, [configs, names, outputModel])

    return (
      <View style={[sharedStyles.flex, sharedStyles.justifyContentFlexStart]}>
        {/* @ts-ignore */}
        <Tab key={`tab-multi-output-${deviceId}`}>
          <View style={[sharedStyles.flex, sharedStyles.alignItemsCenter, sharedStyles.horizontal, sharedStyles.justifyContentCenter]}>
            <Button
              title="Điều Khiển"
              type={selectedIndex === 0 ? 'solid' : `clear`}
              onPress={() => setSelectedIndex(0)}
              icon={{
                name: 'gamepad',
                size: 15,
                color: 'white',
                type: 'material',
              }}
            />
            <Button
              title="Lịch Biểu"
              type={selectedIndex === 1 ? 'solid' : `clear`}
              onPress={() => setSelectedIndex(1)}
              iconRight={true}
              icon={{
                name: 'schedule',
                size: 15,
                color: 'white',
                type: 'material',
              }}
            />
          </View>
        </Tab>
        <ViewPager swipeEnabled={false} selectedIndex={selectedIndex}>
          <BaseMultiPortPanel
            key={`layout-control-panel-grid-${deviceId}`}
            deviceId={deviceId}
            itemDimension={62}
            sections={sections}
            spacing={10}
            actionTitle={isSMSTransport ? 'Gửi SMS' : ''}
            renderItem={({ item, section }) => {
              const preConfirm =
                item.outputType === OUTPUT_TYPE.TAI_EX && get(section, 'data.0', []).length > 1
                  ? (cb: () => any) => {
                      setCallback(() => () => cb())
                      setWarningText(`Kênh ${item.title} được thiết lập ràng buộc với các kênh khác. Bạn muốn thao tác chỉ áp dụng cho duy nhất kênh này?`)
                      setVisible(true)
                    }
                  : undefined
              return (
                <ControlPanel
                  key={`control-${deviceId}-${item.outputIndex}`}
                  deviceId={deviceId}
                  name={item.title}
                  port={item.outputIndex}
                  isSimpleView={true}
                  iconSize={32}
                  innerStyles={[]}
                  scheduleActionDisplayType={'text'}
                  ref={(ele: IOnOffControlRef) => (onOffRef.current[item.outputIndex] = ele)}
                  style={
                    item.outputType === OUTPUT_TYPE.TAI_EX
                      ? [sharedStyles.borderIconPower, sharedStyles.alignItemsCenter, sharedStyles.justifyContentCenter, sharedStyles.paddingVerticalQuarter, { borderColor: theme.primaryBackgroundColor }]
                      : []
                  }
                  containerStyles={[{ height: 50, width: 50 }]}
                  textStyle={[sharedStyles.marginVerticalHalf]}
                  preConfirm={preConfirm}
                />
              )
            }}
            onGroupedAction={(outputIndexes) => {
              if (buildOnOffMessage) {
                const allStartStop: number[][] = []
                let errorText = ''
                const modifiedOnOffs = outputIndexes?.filter(({ outputIndex }) => onOffRef.current[outputIndex]?.isModified)
                const batchSMS = modifiedOnOffs
                  ?.map(({ outputIndex }) => {
                    const rpcResult = rootStore.deviceListStore.state[`${RPC_RESULT_SET_STATUS}_${outputIndex}_${deviceId}`]
                    const postParams = rpcResult?.content?.rpc?.params
                    if (postParams) {
                      if (validateStartStop) {
                        const startStop = convertText2StartStop(postParams?.timer)
                        if (startStop.length <= 0) {
                          errorText = `Cần đặt thời lượng cho lệnh!`
                          return
                        }
                        if (isTurnOn(postParams?.value)) {
                          if (startStop.length === 1) {
                            allStartStop.push([moment().utc(true).unix(), startStop[0]])
                          } else {
                            allStartStop.push([startStop[0], startStop[1]])
                          }
                        }
                      }
                      return postParams ? buildOnOffMessage(device, 'setStatus', postParams, outputIndex, validateStartStop) : ''
                    }
                    return ''
                  })
                  ?.join(` `)
                  .replace(/\s+/g, ' ')
                  .trim()
                if (validateStartStop) {
                  if (errorText) {
                    rootStore.uiReferenceStore.danger(errorText)
                    return
                  }
                  if (allStartStop.length > 0) {
                    allStartStop.sort((a, b) => a[0] - b[0])
                    const iIndex = findInvalidStartStopIndex(allStartStop)
                    if (iIndex >= 0) {
                      if (allStartStop[iIndex].length >= 2) {
                        rootStore.uiReferenceStore.danger(`Có 2 lệnh bật đang chồng thời gian lên nhau!`)
                        return
                      }
                    }
                  }
                }

                if (batchSMS) {
                  if (device?.label) {
                    openAppSMS(device?.label, batchSMS)
                    modifiedOnOffs?.forEach(({ outputIndex }) => onOffRef.current[outputIndex]?.onSaved())
                  } else {
                    rootStore.uiReferenceStore.danger(`Thiết bị chưa được cài đặt số điện thoại`)
                  }
                }
              } else {
                rootStore.uiReferenceStore.danger(`Thiết bị ${device?.type} chưa cài đặt cú pháp sms bật tắt`)
              }
            }}
          />
          <BaseMultiPortPanel
            key={`layout-schedule-panel`}
            itemDimension={dimensions.width}
            deviceId={deviceId}
            sections={sections}
            actionTitle={isSMSTransport ? 'Gửi SMS' : ''}
            renderItem={({ item }: { item: IOutputConfigViewProps }) =>
              item.outputType === OUTPUT_TYPE.TAI_EX ? (
                <ThemedText color={theme.blue} style={[]}>
                  {(item.childOutputs?.length || 0) > 1
                    ? `${item.title} sẽ được bật tự động khi 1 trong số các kênh con dưới đây được bật`
                    : `Hãy cấu hình đầu ra cho các kênh phụ thuộc ${item.title}. ${item.title} sẽ được bật tự động khi 1 trong số các kênh con được bật`}
                </ThemedText>
              ) : (
                <ScheduleForSingleOutput
                  key={`schedule-output-${deviceId}-${item.outputIndex}`}
                  deviceId={deviceId}
                  name={item.title}
                  outputIndex={item.outputIndex}
                  ref={(ele: ScheduleForSingleOutputRef) => (itemsRef.current[item.outputIndex] = ele)}
                />
              )
            }
            onGroupedAction={(outputIndexes) => {
              if (buildScheduleMessage) {
                const modifiedSchedules = outputIndexes?.filter(({ outputIndex }) => itemsRef.current[outputIndex]?.isModified())
                const batchSMS = modifiedSchedules
                  ?.map(({ outputIndex }) => {
                    const scheduleOn = itemsRef.current[outputIndex]?.scheduleOn()
                    const { inputKey, newValue } = itemsRef.current[outputIndex]?.schedule() || {}
                    return newValue ? buildScheduleMessage(device, scheduleOn, inputKey, newValue, outputIndex, false) : ''
                  })
                  ?.join(` `)
                  .replace(/\s+/g, ' ')
                  .trim()
                if (batchSMS) {
                  if (device?.label) {
                    openAppSMS(device?.label, batchSMS)
                    modifiedSchedules?.forEach(({ outputIndex }) => itemsRef.current[outputIndex]?.onSaved())
                  } else {
                    rootStore.uiReferenceStore.danger(`Thiết bị chưa được cài đặt số điện thoại`)
                  }
                }
              } else {
                rootStore.uiReferenceStore.danger(`Thiết bị ${device?.type} chưa cài đặt cú pháp sms lịch biểu`)
              }
            }}
          />
        </ViewPager>
        <OverlayModal
          title="Cảnh báo"
          visible={visible}
          setVisible={setVisible}
          children={<ThemedText color={'#000000'}>{warningText}</ThemedText>}
          onClickButtonRight={() => {
            setVisible(false)
            if (callback) {
              callback()
            }
          }}
        />
      </View>
    )
  }),
)
