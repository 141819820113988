import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { RPC_RESULT_SET_STATUS } from '../../stores/CurrentDeviceStore'
import { IParams, SCHEDULE_ON, STATUS } from '../../stores/DeviceConfig'
import { RootStoreContext } from '../../stores/RootStore'
import { sharedStyles } from '../../styles/shared'
import { IOnOffControl, IPostResult, OnOffControlCardView } from './OnOffControlCardView'

export function getParams({ value, timer, ...others }: IParams) {
  const textValue = value ? 'on' : 'off'
  if (timer) {
    return {
      value: textValue,
      timer,
      ...others,
    }
  }
  return { value: textValue, ...others }
}

export const buildStatusBundle = (deviceId: string, port: number | undefined, status: string | boolean, rootStore: any) => ({
  deviceId,
  port,
  method: 'setStatus',
  active: status === 'on' || status === '1',
  postValue: (params: IParams) => getParams(params),
  onPostValueResult: ({ params, error }: IPostResult) => {
    const extraAction = {
      rpc: {
        method: 'setStatus',
        params: getParams(params),
      },
      result: error?.networkError?.statusCode === 409 ? 'timeout' : error ? 'error' : 'sent',
    }
    const lastUpdateTs = moment().valueOf()
    const portNumber = port || 0
    rootStore.currentDeviceStore.setAttributeByNumber(extraAction, `${RPC_RESULT_SET_STATUS}_${portNumber}`, portNumber, lastUpdateTs)
  },
})

export const ControlPanel = React.memo(
  observer(
    ({ deviceId, port, isToggle = false, containerStyles = [], ...othersProps }: { port?: number; isToggle?: boolean; deviceId: string } & IOnOffControl, ref) => {
      const portNumber = port || 0
      const rootStore = useContext(RootStoreContext)
      const status = port === undefined ? rootStore.deviceListStore.state[`${STATUS}_${deviceId}`] : rootStore.deviceListStore.state[`${STATUS}_${deviceId}_${portNumber}`]

      const storeValue = rootStore.deviceListStore.state[`${deviceId}_${SCHEDULE_ON}_${portNumber}`]
      const scheduleOn = storeValue === '1' || storeValue === 'on'

      const [item, setItem] = useState<any>(null)

      useEffect(() => setItem(buildStatusBundle(deviceId, port, status, rootStore)), [status, deviceId, port])

      return (
        <OnOffControlCardView
          key={`OnOffControlCardView-${deviceId}-${portNumber}`}
          scheduleOn={scheduleOn}
          isToggle={isToggle}
          scheduleActionDisplayType={'button'}
          innerStyles={[sharedStyles.borderIconPower]}
          ref={ref}
          {...item}
          containerStyles={[sharedStyles.itemContainer, { height: 90, width: 90 }, ...containerStyles]}
          {...othersProps}
        />
      )
    },
    { forwardRef: true },
  ),
)
