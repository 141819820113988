import { constants } from '@devhub/core'
import moment, { Moment } from 'moment'
import qs from 'qs'
import { ReactNode } from 'react'
import { findDOMNode } from 'react-dom'

import { AppLayoutProviderState, getAppLayout } from '../../components/context/LayoutContext'
import { Browser } from '../../libs/browser'
import { Linking } from '../../libs/linking'
import { Platform } from '../../libs/platform'

export const isMobile = !Platform.isElectron && ['android', 'ios'].includes(Platform.OS)

export function findNode(ref: any) {
  try {
    let node = ref && (ref.current || ref)

    if (node && (node as any).getNode && (node as any).getNode()) node = (node as any).getNode()

    if (node && (node as any)._touchableNode) node = (node as any)._touchableNode

    if (node && (node as any)._node) node = (node as any)._node

    if (node && Platform.OS === 'web') node = findDOMNode(node)

    return node
  } catch (error) {
    console.error('Failed to find node', error, { ref })
    return null
  }
}

export function tryFocus(ref: any) {
  const node = findNode(ref)

  if (node && node.focus) {
    if (!(node.tabIndex >= 0)) node.tabIndex = -1

    node.focus({ preventScroll: true })
    return node
  }

  return false
}

export function getGitHubAppInstallUri(
  options: {
    redirectUri?: string | undefined
    suggestedTargetId?: number | string | undefined
    repositoryIds?: Array<number | string> | undefined
  } = {},
) {
  const query: Record<string, any> = {}

  const redirectUri = options.redirectUri || (Platform.OS === 'ios' || Platform.OS === 'android' || Platform.isElectron ? `${constants.APP_DEEP_LINK_SCHEMA}://` : Platform.OS === 'web' ? window.location.origin : '')

  if (redirectUri) query.redirect_uri = redirectUri
  if (options.repositoryIds) query.repository_ids = options.repositoryIds
  if (options.suggestedTargetId) query.suggested_target_id = options.suggestedTargetId

  const querystring = qs.stringify(query, {
    arrayFormat: 'brackets',
    encode: false,
  })
  const baseUri = `${constants.API_BASE_URL}/github/app/install`

  return `${baseUri}${querystring ? `?${querystring}` : ''}`
}

export async function openAppStore({ showReviewModal }: { showReviewModal?: boolean } = {}) {
  try {
    if (Platform.realOS === 'android') {
      let storeUrl = `market://details?id=${constants.GOOGLEPLAY_ID}`

      if (Platform.OS === 'android' && (await Linking.canOpenURL(storeUrl))) {
        if (__DEV__) console.log(`Requested to open Play Store: ${storeUrl}`) // tslint:disable-line no-console
        await Linking.openURL(storeUrl)
        return true
      }

      storeUrl = `https://play.google.com/store/apps/details?id=${constants.GOOGLEPLAY_ID}`
      if (__DEV__) console.log(`Requested to open Play Store: ${storeUrl}`) // tslint:disable-line no-console
      await Browser.openURL(storeUrl)
      return true
    }

    if (Platform.realOS === 'ios') {
      let storeUrl = `itms-apps://itunes.apple.com/app/id${constants.APPSTORE_ID}`

      if (Platform.OS === 'ios' && (await Linking.canOpenURL(storeUrl))) {
        if (__DEV__) console.log(`Requested to open App Store: ${storeUrl}`) // tslint:disable-line no-console
        await Linking.openURL(storeUrl)
        return true
      }

      storeUrl = showReviewModal
        ? `https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewContentsUserReviews?id=${constants.APPSTORE_ID}&pageNumber=0&sortOrdering=2&mt=8`
        : `https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=${constants.APPSTORE_ID}&pageNumber=0&sortOrdering=2&mt=8`
      if (__DEV__) console.log(`Requested to open App Store: ${storeUrl}`) // tslint:disable-line no-console
      await Browser.openURL(storeUrl)
      return true
    }

    throw new Error(`Invalid platform: ${Platform.realOS}`)
  } catch (error) {
    if (__DEV__) console.error(`Failed to open App Store: ${error}`) // tslint:disable-line no-console
    return false
  }
}

export function genericParseText<T extends string>(text: string, pattern: RegExp, fn: (match: T) => ReactNode) {
  if (!(text && typeof text === 'string')) return [text].filter(Boolean)

  const matches = text.match(new RegExp(pattern, 'g')) as T[]
  if (!(matches && matches.length)) return [text].filter(Boolean)

  return text.split(pattern).reduce((result, item, index) => {
    if (!matches[index]) return result.concat([item].filter(Boolean))

    return result.concat([item, fn(matches[index])].filter(Boolean))
  }, [] as ReactNode[])
}

export function isBigEnoughForMultiColumnView(sizename?: AppLayoutProviderState['sizename']) {
  return (sizename || getAppLayout().sizename) >= '2-medium'
}

export function vibrateHapticFeedback() {
  if (Platform.OS === 'ios' || Platform.OS === 'android') {
    const ReactNativeHapticFeedback = require('react-native-haptic-feedback').default

    ReactNativeHapticFeedback.trigger('selection', {
      enableVibrateFallback: true,
      ignoreAndroidSystemSettings: true,
    })
  } else if (Platform.OS === 'web' && window.navigator && window.navigator.vibrate) {
    window.navigator.vibrate(50)
  }
}

export function roundToEven(n: number) {
  return Math.round(n) + (Math.round(n) % 2)
}

export const formatToTimeInDay = (timeInDay: number, enableHiddenSeconds: boolean = true, unUseSecond: boolean = false) => {
  return unUseSecond || (timeInDay % 60 === 0 && enableHiddenSeconds) ? convertToTime(timeInDay).format('HH:mm') : convertToTime(timeInDay).format('HH:mm:ss')
}
export const formatMomentToTimeInDay = (momentTime: Moment, enableHiddenSeconds: boolean = true, unUseSecond: boolean = false) => {
  return unUseSecond || (momentTime.unix() % 60 === 0 && enableHiddenSeconds) ? momentTime.utc().format('HH:mm') : momentTime.utc().format('HH:mm:ss')
}

export const convertToTime = (timeInDay: number) => moment.utc().startOf('day').second(timeInDay)
export const convertToEpochTime = (epochTime: number) => moment.unix(epochTime)

export const getSecondsInDay = (hours: number, minutes: number, seconds: number = 0) => {
  const time = moment.utc().hours(hours).minutes(minutes).second(seconds)
  const mmtMidnight = time.clone().startOf('day')
  return time.diff(mmtMidnight, 'seconds')
}

export const getEpochTime = (hours: number, minutes: number, seconds: number = 0) => {
  const now = moment().utc(true)
  const time = now.clone().hours(hours).minutes(minutes).second(seconds)
  if (time.isBefore(now)) {
    time.add(1, 'day')
  }
  return time
}

export const diffInMinutes = (start: number, stop: number) => Math.round((stop - start) / 60)

export const convertText2StartStop = (startStopText?: string) => {
  const couple = startStopText ? startStopText.split('_') : []
  return couple.length === 1 ? [Number(couple[0])] : couple.length >= 2 ? [Number(couple[0]), Number(couple[1])] : []
}

export const getMinutesInDay = (time: Moment) => time.diff(time.clone().utc().startOf('day'), 'minutes')

export const getTimeCouple = (startStop: number[]) => {
  if (startStop.length >= 1) {
    const momentTime = convertToTime(startStop[0])
    if (startStop.length >= 2) {
      const endMoment = convertToTime(startStop[1])
      return {
        hours: momentTime.hours(),
        minutes: momentTime.minutes(),
        seconds: momentTime.seconds(),
        endHours: endMoment.hours(),
        endMinutes: endMoment.minutes(),
        endSeconds: endMoment.seconds(),
      }
    }
    return {
      hours: momentTime.hours(),
      minutes: momentTime.minutes(),
      seconds: momentTime.seconds(),
    }
  }
  return {}
}

export const getMomentRange = (startStop: number[], forceAddDay: boolean = false) => {
  if (startStop.length >= 1) {
    const now = moment().utc(true)
    let startTime = convertToEpochTime(startStop[0])
    startTime = forceAddDay && startTime.isBefore(now) ? startTime.add(1, 'day') : startTime
    if (startStop.length >= 2) {
      let endMoment = convertToEpochTime(startStop[1])
      endMoment = forceAddDay && endMoment.isBefore(now) ? endMoment.add(1, 'day') : endMoment
      endMoment = endMoment.isBefore(startTime) ? endMoment.add(1, 'day') : endMoment
      return [startTime, endMoment]
    }
    return [startTime]
  }
  return []
}

export const findInvalidStartStopIndex = (startStopArray: number[][]) => {
  let iIndex = -1
  for (let i = 0; i < startStopArray.length - 1; i++) {
    const endTimeNumber = startStopArray[i][1]
    for (let j = i + 1; j < startStopArray.length; j++) {
      if (endTimeNumber > startStopArray[j][0]) {
        iIndex = j
        break
      }
    }
    if (iIndex > 0) break
  }
  return iIndex
}

export const formatStartStop = (couple: number[], separator: string = '\n', unUseSecond: boolean = false) => {
  const enableHiddenSeconds = couple[0] % 60 === 0 && couple[1] % 60 === 0
  return `${formatToTimeInDay(couple[0], enableHiddenSeconds, unUseSecond)}${separator}${couple[1] === 86400 ? (enableHiddenSeconds ? '24:00' : '24:00:00') : formatToTimeInDay(couple[1], enableHiddenSeconds, unUseSecond)}`
}

export const getMessageError = (error: any) => {
  return error && (error.response?.data?.message || (error.networkError?.result && (typeof error.networkError?.result === 'string' ? error.networkError.result : error.networkError.result.message)) || error.message)
}

export const msToTime = (ms: number) => {
  const seconds = ms / 1000
  const minutes = ms / (1000 * 60)
  const hours = ms / (1000 * 60 * 60)
  const days = ms / (1000 * 60 * 60 * 24)
  if (seconds < 60) return seconds + 's'
  if (minutes < 60) return minutes + 'p'
  if (hours < 24) return hours + 'h'
  return days + 'ng'
}

export const validatePhoneNumber = (value: string | undefined) => {
  if (!value) return false
  const pattern = /^0[0-9]\d{8}$/g
  return pattern.test(value)
}

export const validateTopUpMobileCode = (value: string | undefined) => {
  if (!value) return false
  const pattern = /^[0-9]\d{11,14}$/g
  return pattern.test(value)
}

export const extractPhoneFromIdentify = (email: string) => {
  const splits = email?.split('@')
  if (splits?.length >= 2) {
    const phoneNumber = splits[0].replace('+84', '0').trim()
    if (validatePhoneNumber(phoneNumber)) return phoneNumber
  }
  return undefined
}
