import { Radio } from '@ui-kitten/components'
import React, { useState } from 'react'
import { sharedStyles } from '../../styles/shared'
import { smallTextSize } from '../../styles/variables'
import { ThemedText } from '../themed/ThemedText'

export const AppRadio = React.memo(
  ({
    title,
    onChange,
    initValue = false,
  }: {
    title?: string
    initValue?: boolean
    onChange: (isChecked: boolean) => any
  }) => {
    const [checked, setChecked] = useState(initValue)
    return (
      <Radio
        status={'info'}
        checked={checked}
        style={sharedStyles.marginHorizontalQuarter}
        onChange={(isChecked) => {
          setChecked(isChecked)
          onChange(isChecked)
        }}
      >
        {() => (
          <ThemedText
            color="foregroundColorMuted65"
            style={[{ fontSize: smallTextSize }, sharedStyles.fullWidth]}
          >
            {title ? `  ${title}` : ''}
          </ThemedText>
        )}
      </Radio>
    )
  },
)
