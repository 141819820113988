import { IDict } from '@devhub/core/dist'
import { Button } from '@ui-kitten/components'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Mutation } from 'react-apollo'
import { ImageSourcePropType } from 'react-native/Libraries/Image/Image'
import { useDispatch } from 'react-redux'
import { RPCCallingInput, RPCCallingResponse, SAVE_SERVER_SCOPE_ATTRIBUTE } from '../../graphql/gqls'
import * as actions from '../../redux/actions'
import { SENSOR_ADC_BUNDLE } from '../../stores/DeviceModels'
import { RootStoreContext } from '../../stores/RootStore'
import { sharedStyles } from '../../styles/shared'
import { getName, renderSensorName } from '../../utils/constants'
import { foregroundThemeColor } from '../common/Button'
import { SensorCell } from '../elements/SensorCell'
import { SelectWithInputPopOver, SelectWithInputPopOverRef } from '../popover/SelectWithInputPopOver'
import { ThemedActivityIndicator } from '../themed/ThemedActivityIndicator'

export const SensorPresentationView = memo(
  observer(({ deviceId, field, onDataExist, ...otherProps }: { deviceId: string; field: string; timeBottom?: boolean; textColor?: string; icon?: ImageSourcePropType | undefined; onDataExist?: (dataExist: boolean) => void }) => {
    const rootStore = useContext(RootStoreContext)
    const dispatch = useDispatch()
    const [inputData, setInputData] = useState<IDict | null>(null)
    const observerValue = rootStore.deviceListStore.state[`${field}_${deviceId}`]
    const customize = rootStore.deviceListStore.state[`customize-${field}_${deviceId}`]
    const code = customize?.value?.code
    const popoverRef = useRef<SelectWithInputPopOverRef>(null)

    useEffect(() => {
      const exist = !_.isEmpty(observerValue?.value)
      if (exist) {
        setInputData({
          name: customize?.value?.name,
          value: observerValue?.value,
          code,
          field,
          lastUpdateTs: observerValue.lastUpdateTs,
          unit: customize?.value?.unit,
          min: customize?.value?.min,
          max: customize?.value?.max,
          delta: customize?.value?.delta,
        })
      }
      onDataExist?.(exist)
    }, [observerValue, customize])

    const renderButtonAction = useCallback(
      () => (
        <Button
          style={[sharedStyles.button]}
          appearance="ghost"
          onPress={() => {
            popoverRef.current?.hide()
            if (inputData) {
              dispatch(
                actions.pushModal({
                  name: 'DATA_HISTORY',
                  params: { deviceId, field: inputData.field, bundle: inputData },
                }),
              )
            }
          }}
        >
          {`Lịch sử`}
        </Button>
      ),
      [deviceId, inputData],
    )

    return _.isEmpty(inputData) ? null : (
      <Mutation<RPCCallingResponse, RPCCallingInput> mutation={SAVE_SERVER_SCOPE_ATTRIBUTE}>
        {(updateCalling, { loading }) => (
          <>
            <SelectWithInputPopOver
              items={Object.values(SENSOR_ADC_BUNDLE)}
              ref={popoverRef}
              onUpdateValue={({ selected, inputValue, ...others }) => {
                console.log('onUpdateValue ', selected, field, code)
                const customizeField = `customize-${field}`
                const value = { name: inputValue, code: selected, ...others }
                updateCalling({
                  // @ts-ignore
                  variables: {
                    deviceId,
                    input: {
                      [customizeField]: value,
                    },
                  },
                })
                  .then(() => {
                    rootStore.deviceListStore.state[`${customizeField}_${deviceId}`] = { value }
                  })
                  .catch((e: any) => {
                    console.error(`SensorStatistic updateCalling error`, e)
                  })
              }}
              action={renderButtonAction()}
              initValue={{ inputValue: getName(inputData), ...(inputData || {}) }}
              itemTextRender={(itemSensor) => renderSensorName(itemSensor)}
              anchorView={<SensorCell data={inputData} {...otherProps} />}
            />
            {loading ? <ThemedActivityIndicator color={foregroundThemeColor} size="small" style={sharedStyles.loadingIndicatorStyle} /> : null}
          </>
        )}
      </Mutation>
    )
  }),
)
