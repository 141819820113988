import { EMPTY_OBJ } from '../../utils/constants'
import { RootState } from '../types'

const s = (state: RootState) => state.auth || EMPTY_OBJ

export const authErrorSelector = (state: RootState) => s(state).error

export const isDeletingAccountSelector = (state: RootState) =>
  s(state).isDeletingAccount

export const isLoggingInSelector = (state: RootState) => s(state).isLoggingIn

export const isLoggedSelector = (state: RootState) => {
  if (appTokenSelector(state)) {
    return !!(s(state).user && s(state).user!.id)
  }
  return false
}

export const appTokenSelector = (state: RootState) =>
  s(state).appToken || undefined

export const refreshTokenSelector = (state: RootState) =>
  s(state).refreshToken || undefined

export const currentCustomerIdSelector = (state: RootState) => {
  const user = currentUserSelector(state)
  return (user && user.customerId && user.customerId.id) || undefined
}

export const currentUserSelector = (state: RootState) => {
  if (isLoggedSelector(state)) {
    return s(state).user
  }
  return undefined
}

export const currentUserPlanSelector = (state: RootState) => {
  const user = currentUserSelector(state)
  return user || undefined
}

export const isPlanExpiredSelector = (state: RootState): boolean => {
  return false
}

export const currentUserIdSelector = (state: RootState) => {
  const user = currentUserSelector(state)
  return (user && user.id) || undefined
}
