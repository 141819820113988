import React from 'react'
import { Animated, TouchableOpacity } from 'react-native'
import { GestureHandlerRootView, Swipeable } from 'react-native-gesture-handler'
import { sharedStyles } from '../../styles/shared'
import { contentPadding, smallerTextSize } from '../../styles/variables'
import { useTheme } from '../context/ThemeContext'
import { ThemedText } from '../themed/ThemedText'

interface Props {
  children: React.ReactNode
  onClick: () => void
  enabled?: boolean
}

export const SwipeListUserShare = ({ children, onClick, enabled, ...others }: Props) => {
  const theme = useTheme()

  const renderRightActions = () => (
    <TouchableOpacity style={[sharedStyles.justifyContentCenter, { marginTop: contentPadding }]} onPress={onClick}>
      <Animated.Text>
        <ThemedText
          color={theme.primaryBackgroundColor}
          style={{
            fontSize: smallerTextSize,
            textDecorationLine: 'underline',
          }}
        >
          Xóa
        </ThemedText>
      </Animated.Text>
    </TouchableOpacity>
  )

  return (
    <GestureHandlerRootView>
      <Swipeable enabled={enabled} renderRightActions={renderRightActions} {...others}>
        {children}
      </Swipeable>
    </GestureHandlerRootView>
  )
}
