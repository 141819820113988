import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { RefObject, useContext, useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { NestableScrollContainer } from 'react-native-draggable-flatlist'
import FlashMessage from 'react-native-flash-message'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { useReduxState } from '../../hooks/use-redux-state'
import * as selectors from '../../redux/selectors'
import { FIRST_TIME_CONNECT } from '../../stores/CurrentDeviceStore'
import { DEVICES_CONFIG } from '../../stores/DeviceConfig'
import { RootStoreContext } from '../../stores/RootStore'
import { sharedStyles } from '../../styles/shared'
import { isMobile } from '../../utils/helpers/shared'
import { foregroundThemeColor } from '../common/Button'
import { FullHeightScrollView } from '../common/FullHeightScrollView'
import { ThemedActivityIndicator } from '../themed/ThemedActivityIndicator'
import { MANAGER, MultiPortDeviceConfig, OUTPUT } from '../views/MultiPortDeviceConfig'
import { SortProbeInputConfig } from '../views/SortProbeInputConfig'
import { UpgradeDeviceModelView } from '../views/UpgradeDeviceModelView'
import { UserListShareDevice } from '../views/UserListShareDevice'

interface Props {
  deviceId: string
  flashMessageRef: RefObject<FlashMessage>
}

export const InputConfigView = React.memo(
  observer(({ deviceId }: Props) => {
    const rootStore = useContext(RootStoreContext)
    const loading = rootStore.currentDeviceStore.state.loading
    const device = rootStore.deviceListStore.findDeviceById(deviceId)
    const customerId = useReduxState(selectors.currentCustomerIdSelector)
    const firstTimeConnect = rootStore.deviceListStore.state[`${FIRST_TIME_CONNECT}_${deviceId}`]

    const configRender = useMemo(() => {
      const { isSMSTransport, singleOutput, hasOutputConfig, basicFeatures } = DEVICES_CONFIG[device?.type || ''] || {}
      if (isSMSTransport && !hasOutputConfig) {
        return (
          <View style={[sharedStyles.paddingHorizontalHalf]}>
            <UserListShareDevice deviceId={deviceId} currentCustomerId={customerId} style={[sharedStyles.paddingHorizontalHalf]} />
          </View>
        )
      }
      if (singleOutput) {
        let warningText
        if (basicFeatures) {
          if (firstTimeConnect) {
            const mm = moment(firstTimeConnect)
            if (mm.isValid()) {
              const expiredTrialDate = mm.add(1, 'month')
              if (expiredTrialDate.isAfter(moment().utc(true))) {
                warningText = `Quý Khách đang sử dụng 30 ngày miễn phí tính năng đầu dò cho thiết bị ${device?.label || device?.name}. Thời gian hết hạn là ${expiredTrialDate.format(
                  'DD-MM-YYYY',
                )}. Quý Khách vui lòng liên hệ với công ty hoặc đại lý của Lazico để nâng cấp và sử dụng lâu dài tính năng đầu dò`
                return <SortProbeInputConfig deviceId={deviceId} deviceType={device?.type} warningText={warningText} />
              }
            }
          }
          return <UpgradeDeviceModelView />
        }
        return <SortProbeInputConfig deviceId={deviceId} deviceType={device?.type} />
      }
      if (hasOutputConfig) {
        const params = isSMSTransport
          ? [
              {
                title: 'Đầu ra',
                code: OUTPUT,
                icon: 'subdirectory-arrow-right',
              },
              {
                title: 'Chia sẻ/Quản lý',
                code: MANAGER,
                icon: 'account-circle',
                iconRight: true,
              },
            ]
          : undefined
        return <MultiPortDeviceConfig params={params} />
      }
      return null
    }, [device, firstTimeConnect])

    return (
      <>
        {isMobile ? (
          <GestureHandlerRootView>
            <NestableScrollContainer>{configRender}</NestableScrollContainer>
          </GestureHandlerRootView>
        ) : (
          <FullHeightScrollView alwaysBounceVertical bounces style={sharedStyles.flex}>
            <NestableScrollContainer>{configRender}</NestableScrollContainer>
          </FullHeightScrollView>
        )}
        {loading ? (
          <View style={[StyleSheet.absoluteFill, sharedStyles.center]} pointerEvents="none">
            <ThemedActivityIndicator color={foregroundThemeColor} size="small" style={sharedStyles.loadingIndicatorStyle} />
          </View>
        ) : null}
      </>
    )
  }),
)

InputConfigView.displayName = 'InputConfigView'
