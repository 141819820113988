// @ts-ignore
import { w3cwebsocket as WebSocket } from 'websocket'

const MAX_RETRY = 120

export class WebSocketClient {
  url: string = ''
  messageNumber = 0 // Message number
  autoReconnectInterval = 5 * 1000 // ms
  instance: WebSocket
  private retryCount: number = 0

  open = (url: string) => {
    this.url = url
    this.instance = new WebSocket(url)
    this.instance.onopen = () => {
      console.log('WebSocket Client Connected')
      this.retryCount = 0
      this.onopen()
    }

    this.instance.onmessage = (data: any, flags: any) => {
      this.messageNumber++
      this.onmessage(data, flags, this.messageNumber)
    }

    this.instance.onclose = (e: { code: number }) => {
      console.log('WebSocket Client Closed')
      switch (e.code) {
        case 1000: // CLOSE_NORMAL
          console.log('WebSocket: closed')
          break
        default:
          // Abnormal closure
          this.reconnect(e)
          break
      }
    }

    this.instance.onerror = (e: { code: string }) => {
      console.log('WebSocket Client error', e)
      switch (e.code) {
        case 'ECONNREFUSED':
          this.reconnect(e)
          break
        default:
          this.onerror(e)
          break
      }
    }
  }
  send = (data: any, option?: any) => {
    try {
      if (this.instance.send && typeof this.instance.send === 'function') {
        this.instance.send(data, option)
      }
    } catch (e) {
      if (this.instance.emit && typeof this.instance.emit === 'function') {
        this.instance.emit('error', e)
      }
      console.log('WebSocketClient send error', e)
    }
  }
  reconnect = (e: any) => {
    if (this.retryCount >= MAX_RETRY) {
      console.log('WebSocketClient retry max reach ', MAX_RETRY)
      return
    }
    this.retryCount++
    console.log(`WebSocketClient: retry in ${this.autoReconnectInterval}ms`, e)
    if (
      this.instance.removeAllListeners &&
      typeof this.instance.removeAllListeners === 'function'
    ) {
      this.instance.removeAllListeners()
    }
    setTimeout(() => {
      console.log('WebSocketClient: reconnecting...')
      this.open(this.url)
    }, this.autoReconnectInterval)
  }
  onopen = () => {
    console.log('WebSocketClient: open')
  }
  onmessage = (responseData: any, flags: any, messageNumber: number) => {
    console.log('WebSocketClient: message', responseData)
  }
  onerror = (e: any) => {
    console.log('WebSocketClient: error', e)
  }
  onclose = (e: any) => {
    console.log('WebSocketClient: closed', e)
  }
}
