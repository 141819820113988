import React, { useState } from 'react'
import { StyleSheet } from 'react-native'

import { scaleFactor } from '../../styles/variables'
import { getButtonColors } from '../common/Button'
import { TextInput } from '../common/TextInput'
import { ThemedIcon } from '../themed/ThemedIcon'
import {
  ThemedTextInput,
  ThemedTextInputProps,
} from '../themed/ThemedTextInput'

interface IThemedTextInputIconProps extends ThemedTextInputProps {
  iconShowPassword?: boolean
}

export const ThemedTextInputPasswordWithIcon = React.forwardRef<
  TextInput,
  IThemedTextInputIconProps
>((props, ref) => {
  const { iconShowPassword, ...otherProps } = props
  const { foregroundThemeColor } = getButtonColors()
  const [isShowPassword, setIsShowPassword] = useState<boolean>(true)

  return (
    <>
      <ThemedTextInput
        secureTextEntry={isShowPassword}
        {...otherProps}
        ref={ref}
      />
      {iconShowPassword ? (
        <ThemedIcon
          family="octicon"
          name={!isShowPassword ? 'eye' : 'eye-closed'}
          size={16 * scaleFactor}
          color={foregroundThemeColor}
          style={[styles.icon]}
          onPress={() => setIsShowPassword(!isShowPassword)}
        />
      ) : null}
    </>
  )
})

const styles = StyleSheet.create({
  icon: {
    position: 'absolute',
    top: 12,
    right: 20,
  },
})

ThemedTextInputPasswordWithIcon.displayName = 'ThemedTextInputPasswordWithIcon'

export type ThemedTextInputPasswordWithIcon = typeof ThemedTextInputPasswordWithIcon
