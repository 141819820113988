import { Button, ButtonProps } from '@ui-kitten/components'
import React, { useMemo, useState } from 'react'
import { sharedStyles } from '../../styles/shared'

export interface BaseOption {
  title?: string
  code: string | number
}

export interface DynamicStateButtonProps extends ButtonProps {
  states: BaseOption[]
  initValue: BaseOption
  onStateChange: (newState: BaseOption) => any
}

const ButtonStatus = ['primary', 'success', 'info', 'warning', 'danger']
export const DynamicStateButton = React.memo(({ states, initValue, onStateChange, ...others }: DynamicStateButtonProps) => {
  const statusIndex = states.findIndex((state) => initValue.code === state.code)
  const [currentStateIndex, setCurrentStateIndex] = useState<number>(statusIndex)
  const currentState = useMemo(() => (currentStateIndex >= 0 ? states[currentStateIndex] : initValue), [currentStateIndex, initValue])

  return (
    <Button
      style={[sharedStyles.marginHorizontalNone]}
      {...others}
      appearance="outline"
      status={ButtonStatus[currentStateIndex % ButtonStatus.length]}
      onPress={() => {
        if (states.length > 0) {
          const newStateIndex = (currentStateIndex + 1) % states.length
          setCurrentStateIndex(newStateIndex)
          onStateChange(states[newStateIndex])
        } else {
          onStateChange(initValue)
        }
      }}
    >
      {currentState?.title || currentState?.code}
    </Button>
  )
})
