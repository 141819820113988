import { ExecutionResult } from '@apollo/react-common'
import { IDict } from '@devhub/core/dist'
import { Card, Layout, ViewPager } from '@ui-kitten/components'
import { observer } from 'mobx-react-lite'
import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { useMutation } from 'react-apollo'
import { Dimensions, TouchableOpacity, View } from 'react-native'
import { NestableDraggableFlatList, RenderItemParams, ScaleDecorator } from 'react-native-draggable-flatlist'
import { Tab } from 'react-native-elements'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { SAVE_SHARED_SCOPE_ATTRIBUTE } from '../../graphql/gqls'
import { INPUT_CONFIG_ORDER } from '../../stores/CurrentDeviceStore'
import { RootStore, RootStoreContext } from '../../stores/RootStore'
import { FLASH_MESSAGE_SCREEN_REF } from '../../stores/UIReferenceStore'
import { getActiveBackGround, sharedStyles } from '../../styles/shared'
import { normalTextSize } from '../../styles/variables'
import { getMessageError } from '../../utils/helpers/shared'
import { InputConfigRender } from '../columns/InputConfigRender'
import { foregroundThemeColor, loadingIndicatorStyle } from '../common/Button'
import { useTheme } from '../context/ThemeContext'
import { ThemedActivityIndicator } from '../themed/ThemedActivityIndicator'
import { ThemedText } from '../themed/ThemedText'

interface Props {
  deviceId: string | undefined
  deviceType?: string
  warningText?: string
}

const getInputName = (item: number) => `Đầu dò ${item + 1}`

export const submitInputOrder = (deviceId: string, draggSort: number[], loading: boolean, saveSortItems: (options?: any) => Promise<ExecutionResult<IDict>>, rootStore: RootStore) => {
  if (loading) {
    return
  }
  const invalidSort = draggSort.some((orderNumber) => orderNumber === undefined || orderNumber === null || isNaN(orderNumber))
  if (invalidSort) {
    rootStore.uiReferenceStore.danger(`Hãy thử lại`, FLASH_MESSAGE_SCREEN_REF.INPUT_CONFIG)
    return
  }
  saveSortItems({
    variables: {
      deviceId,
      input: {
        inputConfigOrder: draggSort,
      },
    },
  })
    .then((res) => {
      if (res && res.data && res.data.saveAttribute) {
        rootStore.uiReferenceStore.success(`Đổi thứ tự thành công`, FLASH_MESSAGE_SCREEN_REF.INPUT_CONFIG)
        rootStore.deviceListStore.state[`${INPUT_CONFIG_ORDER}_${deviceId}`] = draggSort
      }
    })
    .catch((e) => {
      console.debug('saveSortItems error', e)
      rootStore.uiReferenceStore.danger(`${getMessageError(e)}`, FLASH_MESSAGE_SCREEN_REF.INPUT_CONFIG)
    })
}

export const SortProbeInputConfig: FC<Props> = React.memo(
  observer(({ deviceId, deviceType, warningText }) => {
    const [saveSortItems, { loading }] = useMutation(SAVE_SHARED_SCOPE_ATTRIBUTE)
    const theme = useTheme()
    const rootStore = useContext(RootStoreContext)
    const orders: number[] | undefined = rootStore.deviceListStore.state[`${INPUT_CONFIG_ORDER}_${deviceId}`]
    const [sortItems, setSortItems] = useState<number[]>([])
    const [tabIndex, setTabIndex] = useState<number>(0)
    const { width } = Dimensions.get('window')

    useEffect(() => {
      const invalidSort = orders?.some((orderNumber) => orderNumber === undefined || orderNumber === null || isNaN(orderNumber))
      if (!orders || orders.length <= 0 || invalidSort) {
        setSortItems(rootStore.currentDeviceStore.getInputs())
      } else {
        setSortItems(orders)
      }
    }, [orders])

    const renderItem = useCallback(
      ({ item, drag }: RenderItemParams<number>) => (
        <ScaleDecorator>
          <View style={{ width: width < 580 ? width / 2 : 170 }}>
            <TouchableOpacity
              key={`item-dragged-${deviceId}-${item}`}
              onLongPress={drag}
              onPress={() => setTabIndex(item)}
              style={{
                backgroundColor: tabIndex === item ? '#36253d' : (null as any),
                padding: 20,
              }}
            >
              <ThemedText style={[sharedStyles.textCenter, { fontSize: normalTextSize * 1.3 }]} color={'#ca71eb'}>
                {getInputName(item).toUpperCase()}
              </ThemedText>
            </TouchableOpacity>
          </View>
        </ScaleDecorator>
      ),
      [tabIndex, width],
    )

    const onSaveSortInput = useCallback(
      (inputOrder: number[]) => {
        setSortItems(inputOrder)
        if (deviceId) {
          submitInputOrder(deviceId, inputOrder, loading, saveSortItems, rootStore)
        }
      },
      [deviceId, loading],
    )

    return (
      <View style={[sharedStyles.flex, sharedStyles.justifyContentFlexStart]}>
        {warningText ? (
          <Card status="warning" style={[sharedStyles.marginHalf, getActiveBackGround(true)]}>
            <ThemedText color={theme.yellow}>{warningText}</ThemedText>
          </Card>
        ) : null}
        <Tab key={`tab-inputConfig-${deviceId}`}>
          <View style={[sharedStyles.flex, sharedStyles.alignItemsCenter, sharedStyles.horizontal, sharedStyles.justifyContentCenter]}>
            <GestureHandlerRootView>
              <NestableDraggableFlatList
                key={`NestableDraggableFlatList-inputConfig-${deviceId}`}
                data={sortItems}
                keyExtractor={(item, index) => `draggable-item-${item}-${index}`}
                horizontal={true}
                renderItem={renderItem}
                onDragEnd={({ data: inputOrder, from, to }) => {
                  if (from !== to) onSaveSortInput(inputOrder)
                }}
              />
            </GestureHandlerRootView>
          </View>
        </Tab>
        {loading ? <ThemedActivityIndicator color={foregroundThemeColor} size="small" style={loadingIndicatorStyle} /> : null}
        <ViewPager swipeEnabled={false} selectedIndex={sortItems.indexOf(tabIndex)}>
          {sortItems.map((orderNumber: number) => (
            <Layout style={{ backgroundColor: theme.backgroundColor }} key={`Layout-input-config-${orderNumber}`}>
              <InputConfigRender deviceId={deviceId} inputOrderNumber={orderNumber} deviceType={deviceType} />
            </Layout>
          ))}
        </ViewPager>
      </View>
    )
  }),
)
